<div 
  class="checkout-page__container"
  [ngClass]="{
    'is-map-not-displayed': !basketService.isServiceDelivery() && !basketService.isServiceMagicDelivery(),
    'checkout-page__info-zone__if-address-incomplete':!basketService.location.mainAddress && (basketService.isServiceDelivery() || basketService.isServiceMagicDelivery())     
    }"
>
  <form 
    [formGroup]="formGroup" 
    class="checkout-page__info-zone"
  >
    <div class="checkout-page__info-zone-title">
      <span style="margin-left: 8px">Informations de commande</span>
      <img
        class="checkout-page__info-zone-title-image"
        src="../../../assets/images/order_icon_1.png"
      />
    </div>
    <div
      *ngIf="basketService.isServiceDelivery() || basketService.isServiceMagicDelivery() "  
      (click)="redirectToMapPage()"
      class="checkout-page__info-zone-google-maps__container"
      #mapRef
    >
      <div class="checkout-page__info-zone-google-maps__container-transparent"></div>    
      <app-google-maps
        class="checkout-page__info-zone-google-maps__container-agm-map"
        disableUseCurrenntLocationButton="true"
        disableChangingMap="true"
      >
      </app-google-maps>
    </div>
    <div 
      *ngIf="(basketService.isServiceDelivery() || basketService.isServiceMagicDelivery()) && !basketService.location.mainAddress"
      class="checkout-page__info-zone-address-details__container" 
      (click)="redirectUserToMapPage()"
    >
      <mat-icon  class="checkout-page__info-zone-address-details__container-error-icon">
        error
      </mat-icon>
      <div class="checkout-page__info-zone-address-details__container-text">
        Adresse incomplète
      </div>
      <img 
        src="../../../assets/images/send-icon-white.png" 
        class="checkout-page__info-zone-address-details__container-send-icon"
      >
    </div>
    <mat-form-field
      class="checkout-page__info-zone-text-field"
      style="height: fit-content !important"
    >
      <mat-label class="checkout-page__info-zone-text-field-margin">Ajouter un commentaire pour le restaurant</mat-label>
      <textarea formControlName="note" matInput maxlength="200"></textarea>
    </mat-form-field>


  <div 
    *ngIf="basketService.basket.promoCodeInfos.promoCode==null"
    (click)="openPopupPromoCode()"
    class="checkout-page__info-zone-promo-container-desktop">
    <span class="checkout-page__info-zone-promo-container-desktop-text">
      Appliquer un code promo 
    </span>
    <img class="checkout-page__info-zone-promo-container-desktop-img" src="../../../assets/images/send-icon.png" alt="">
  </div>

      <mat-button-toggle-group
        class="checkout-page__info-zone-toggle"
        #group="matButtonToggleGroup"
        [value]="basketService.getService()"
        (change)="onServiceChange(group.value)"
        *ngIf="basketService.basket.hasDelivry || basketService.basket.hasTakeway"
      >
        <mat-button-toggle
          *ngIf="basketService.getService()=='magicDelivery'"
          [disabled]="!basketService.basket.hasDelivry"
          value="magicDelivery"
          class="checkout-page__info-zone-toggle-button"
        >
          Livraison
        </mat-button-toggle>
        
        <mat-button-toggle
          *ngIf="basketService.getService()!='magicDelivery'"
          [disabled]="!basketService.basket.hasDelivry"
          value="Delivery"
          class="checkout-page__info-zone-toggle-button"
        >
          Livraison
        </mat-button-toggle>
        
        <mat-button-toggle
          [disabled]="!basketService.basket.hasTakeway"
          value="Takeaway"
          class="checkout-page__info-zone-toggle-button"
        >
          Emporter
        </mat-button-toggle>

      </mat-button-toggle-group>

      <mat-button-toggle-group
        class="checkout-page__info-zone-toggle"
        formControlName="order_type"
        (change)="resetPreOrderDate()" 
        *ngIf="this.isRestaurantDeliverToUserLocation && this.isRestaurantDeliverToUserLocation.pre_commande"
      >
        <mat-button-toggle
          [disabled]="this.isRestaurantDeliverToUserLocation && this.isRestaurantDeliverToUserLocation.closed"
          value="immediate"
          class="checkout-page__info-zone-toggle-button"
        >
          Immediat
        </mat-button-toggle>
        
        <mat-button-toggle
          [disabled]="false"
          value="preOrder"
          class="checkout-page__info-zone-toggle-button"
        >
          Plus tard
        </mat-button-toggle>

      </mat-button-toggle-group>

    <div *ngIf="!orderTypeStatus()" class="pre-order" #preOrderRef>

    <div 
      *ngIf="!preOrderDateIsSelected && formGroup.get('preOrderDate').invalid"
      class="pre-order__date-not-selected"
    >
      <mat-icon class="pre-order__date-not-selected-icon">
        error
      </mat-icon>
      <div class="pre-order__date-not-selected-text">
        Merci de sélectionner la date à laquelle vous souhaitez recevoir votre commande !
      </div> 
    </div>
      
    <div class="pre-order__choose-date">  
      <mat-form-field 
        appearance="fill" 
        class="pre-order__mat-form-field" 
        (click)="closeDatePicker()" 
        [ngClass]="{'pre-order__mat-form-field-invalid': !preOrderDateIsSelected && formGroup.get('preOrderDate').invalid }"
        >
        <mat-label>Valider le jour sélectionné </mat-label>
        <input matInput [matDatepicker]="myDatePicker" [min]="minDate" value="formGroup.get('preOrderDate').value" formControlName="preOrderDate" disabled>
        <mat-hint></mat-hint>
        <mat-datepicker-toggle matSuffix [for]="myDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div 
      *ngIf="!preOrderCreneauIsSelected && formGroup.get('creneau').invalid"
      class="pre-order__creneau-not-selected"
    >
      <mat-icon class="pre-order__creneau-not-selected-icon">
        error
      </mat-icon>
      <div class="pre-order__creneau-not-selected-text">
        Merci de sélectionner la plage horaire à laquelle vous souhaitez recevoir votre commande !
      </div> 
    </div>

    <div>
      <mat-form-field 
        appearance="fill" 
        *ngIf="creneaux && creneaux.length > 0" 
        class="pre-order__mat-form-field"
        [ngClass]="{'pre-order__mat-form-field-invalid': !preOrderCreneauIsSelected && formGroup.get('creneau').invalid}"
        >
        <mat-label>Plage horaire souhaitée</mat-label>
        <select matNativeControl formControlName="creneau">
          <option></option>
          <option *ngFor="let creneau of creneaux">{{ creneau }}</option>
        </select>
      </mat-form-field>
    </div>

    <div *ngIf="creneaux && creneaux.length < 1" class="pre-order__restaurant-closed-message">
      * Nous sommes désolé, ce restaurant est fermé à la date sélectionnée. <br>
      * Merci de choisir une autre journée.
    </div>

    </div>

    <mat-button-toggle-group
      formControlName="moyen_paiement"
      class="checkout-page__info-zone-toggle"
    >
      <mat-button-toggle
        value="1"
        class="checkout-page__info-zone-toggle-button"
        [disabled]="basketService.basket.isOnlinePaymentAllowed !== '1'"
      >
        Payer en ligne&nbsp;&nbsp;&nbsp;<mat-icon>payment</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="0"
        class="checkout-page__info-zone-toggle-button"
        [disabled]="basketService.basket.isCashPaymentAllowed !== '1'"
      >
        Payer en espèce&nbsp;&nbsp;&nbsp;<mat-icon>payments</mat-icon>
      </mat-button-toggle>
      <mat-error *ngIf="formGroup.get('moyen_paiement').invalid">
        {{ getErrorMessage("moyen_paiement") }}
      </mat-error>
    </mat-button-toggle-group>

    
    <div *ngIf="moyenPaiementStatus()" class="moneyBack">

      <div style="display: flex; flex-direction:column">
          <span>Prix total de la commande est : </span>
          <!--
          <div *ngIf="basketService.basket.promoCodeInfos.reduction>0" 
               class="total-order-price">
              {{ basketService.total() + getDeliveryPrice() + basketService.getServiceCharge() }} Dhs
          </div>
          -->
          <div class="total-order-price">
              {{ getTotalPriceAfterapplyingReduction() | number : '1.2-2' }} Dhs
          </div>
      </div>
      
      <label id="moneyBack" class="message-money-back">Quelle somme allez-vous payer ?</label>
      <mat-radio-group
        aria-labelledby="moneyBack"
        class="example-radio-group radio-group-money-back"
        formControlName="moneyBack">
        <mat-radio-button class="example-radio-button" value="0">
          J'ai la somme exacte
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" value="1">
          <mat-form-field class="example-full-width" appearance="fill" *ngIf="!amountToPay()">
            <mat-label>Je vais payer ( montant )</mat-label>
            <input matInput placeholder="123" type="number" disabled>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="fill" *ngIf="amountToPay()">
            <mat-label>Je vais payer ( montant )</mat-label>
            <input matInput placeholder="123" type="number" formControlName="moneyBackMontant">
          </mat-form-field>  
        </mat-radio-button>
      </mat-radio-group>
      
    </div>
  
  


    
  <!--
    <div
      class="checkout-page__info-zone-text-field 
        checkout-page__info-zone-text-field-confirm-condition 
        --padding-small"
      style="height: fit-content !important"
      *ngIf="formGroup.getRawValue().moyen_paiement !== '0'"
    >
      <input
        (change)="conditionsPrivacyChanged()"
        type="checkbox"
        [value]="acceptPrivacy"
        class="example-margin"
        id="acceptPrivacy"
      />
      <label for="acceptPrivacy"
        >Je reconnais avoir lu et accepté
        <a
          href="https://lalivraison.ma/conditionpdf/cgvlalivraison.ma.pdf"
          target="_blank"
          >les conditions générales d'utilisation.</a
        >
      </label>
    </div>
  -->
    <button
      *ngIf="!loading"
      mat-raised-button
      class="checkout-page__info-zone-button"
      (click)="submit()"
      color="primary"
      id="validateButton"
    >
      Commander
    </button>
    <span class="checkout-page__info-zone-loading"><app-loading *ngIf="loading"></app-loading></span>
  </form>
  <div class="checkout-page__order-zone">
    <div class="checkout-page__order-zone-frame">
      <div class="checkout-page__order-zone-header">
        <div class="checkout-page__order-zone-header-text">Votre commande</div>
        <div>
          <img
            class="checkout-page__order-zone-frame-item-image"
            style="margin-right: 5px"
            src="../../../assets/images/swiper-3.png"
          />
          <img
            class="checkout-page__order-zone-frame-item-image"
            src="../../../assets/images/swiper-0.png"
          />
        </div>
      </div>
      <div class="checkout-page__order-zone-frame-items">
        <div
          *ngFor="let item of basketService.basket.items"
          class="checkout-page__order-zone-frame-item"
          (click)="showDialogDetails(item)"
        >
          <img
            class="checkout-page__order-zone-frame-item-image"
            [src]="item.image"
          />
          <div class="checkout-page__order-zone-frame-item-details">
            <span class="checkout-page__order-zone-frame-item-details-name"
              >{{ item.name }}
              <span *ngIf="!item.compositions">
                {{ item.declinations?.selectedValue?.label | lowercase }}
              </span>
            </span>
            <div class="checkout-page__order-zone-frame-item-details-text">
              <p class="checkout-page__order-zone-frame-item-details-text-small-p" 
              *ngIf="item.remark;else info_message">{{item.remark}}</p>
              <ng-template #info_message>
                <p class="checkout-page__order-zone-frame-item-details-text-small-p"
                >Cliquez ici pour ajouter un commentaire</p>
              </ng-template>
              <span>{{ item.count }}&nbsp;&nbsp;</span>x
              <span
                class="checkout-page__order-zone-frame-item-details-text-price"
              >
                &nbsp;&nbsp;{{ item.unitPrice }} Dhs
              </span>
            </div>
          </div>
        </div>

        <!--<app-coupon-code-->
          <!--[orderData]="this.formGroup.getRawValue()"-->
          <!--[active]="isDisabled()"-->
        <!--&gt;-->
        <!--</app-coupon-code>-->

        <div class="checkout-page__order-zone-frame-bill">
          <div class="checkout-page__order-zone-frame-bill-line">
            <div>Sous-total</div>
            <div class="checkout-page__order-zone-frame-bill-line-price">
              {{ basketService.totalWithoutPromo() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div 
            class="checkout-page__order-zone-frame-bill-line" 
            style="color: red;"
            *ngIf="(basketService.totalWithoutPromo() - basketService.total())>0"
            >
            <div>Réduction</div>
            <div class="checkout-page__order-zone-frame-bill-line-price">
              - {{ basketService.totalWithoutPromo() - basketService.total() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div 
            class="checkout-page__order-zone-frame-bill-line" 
            style="color: red;"
            *ngIf="montantFidelite>0"
            >
            <div>Réduction</div>
            <div class="checkout-page__order-zone-frame-bill-line-price">
              - {{ montantFidelite | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div 
            *ngIf="(isDelivery() || isMagicDelivery() ) && getDeliveryPrice() !== undefined"
            class="checkout-page__order-zone-frame-bill-line"
          >
            <div>Frais de livraison</div>
            <div class="checkout-page__order-zone-frame-bill-line-price">
              {{ getDeliveryPrice() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div
            class="checkout-page__order-zone-frame-bill-line"
          >
            <div>Frais de service</div>
            <div class="checkout-page__order-zone-frame-bill-line-price">
              {{ basketService.getServiceCharge() | number : '1.2-2' }} Dhs
            </div>
          </div>
          

          <div 
          *ngIf="basketService.basket.promoCodeInfos.reduction!=0 && montantFidelite == 0 && basketService.basket.promoCodeInfos.promoCodeType == 0"
          class="checkout-page__order-zone-frame-bill-line">
            <div>Réduction</div>
            <div class="checkout-page__order-zone-frame-bill-line-reduction">
              -{{ basketService.basket.promoCodeInfos.reduction }} Dhs
            </div>
          </div>

          <div 
          *ngIf="basketService.basket.promoCodeInfos.reduction!=0 && montantFidelite == 0 && basketService.basket.promoCodeInfos.promoCodeType == 1"
          class="checkout-page__order-zone-frame-bill-line">
            <div>Réduction</div>
            <div class="checkout-page__order-zone-frame-bill-line-reduction">
              -{{ basketService.basket.promoCodeInfos.reduction }}%
            </div>
          </div>

          <hr class="checkout-page__order-zone-frame-bill-line-separator" />
          <div class="checkout-page__order-zone-frame-bill-line">
            <div>Total</div>
            <div 
              *ngIf="basketService.basket.promoCodeInfos.reduction>0"
              class="checkout-page__order-zone-frame-bill-line-old-price">
              {{ basketService.total() + getDeliveryPrice() + basketService.getServiceCharge() | number : '1.2-2' }} Dhs
            </div>
            <div 
            class="checkout-page__order-zone-frame-bill-line-new-price">
              {{ getTotalPriceAfterapplyingReduction() | number : '1.2-2' }} Dhs
            </div>
          </div>
        </div>

        <div 
          *ngIf="basketService.basket.promoCodeInfos.promoCode==null"
          (click)="openPopupPromoCode()"
          class="checkout-page__order-zone-promo-container-phone">
          <span class="checkout-page__order-zone-promo-container-phone-text">
            Appliquer un code promo
          </span>
          <img class="checkout-page__order-zone-promo-container-phone-img" src="../../../assets/images/send-icon.png" alt="">
        </div>

        <button
          *ngIf="!loading"
          class="checkout-page__order-zone-button"
          (click)="submit()"
          mat-raised-button
          color="primary"
          id="validateButton2"
          #validateButton
        >
          Commander
        </button>
        <span class="checkout-page__order-zone-loading"><app-loading *ngIf="loading"></app-loading></span>
      </div>
    </div>
    <img
      src="../../../assets/images/shape10.png"
      class="checkout-page__order-zone-footer"
      style="margin-top: -8px"
    />
    <app-about-zone class="checkout-page__about-zone-mobile"></app-about-zone>
  </div>
</div>
<app-about-zone class="checkout-page__about-zone-desktop"></app-about-zone>
