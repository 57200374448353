import { MapsAPILoader } from '@agm/core';
import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CheckoutService } from './checkout.service';
import { SharedDataService } from './shared-data.service';

@Injectable()

export class MapsService  implements OnDestroy{
  //private geoCoder:any;
  private readonly  mapsOption = {
    componentRestrictions: { country: "mar" },
  }
  private subjectThatWeUseToUnsibscribeFromTimer:Subject<any> = new Subject();
  private subjectThatWeUseToUnsibscribefromAllObservables:Subject<any> = new Subject();
  onLocationChanges:BehaviorSubject<{latitude,longitude,userAddress,zoom}>;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private __sharedDataService: SharedDataService,
    private __checkoutService:CheckoutService
  ) 
  { 
    this.onLocationChanges = new BehaviorSubject(this.getDefaultLocation());
  }

  hideMapAndChoiceButton:boolean = true;
  searchFieldValue = "";

  loadMapsApi(searchElementRef:ElementRef){
     //load Places Autocomplete
     this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      this.initializeMapsCoords();
      //this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(searchElementRef.nativeElement,this.mapsOption);
      /*
        // Matrix
        var origin1 = new google.maps.LatLng(55.930385, -3.118425);
        var destinationA = new google.maps.LatLng(50.087692, 14.421150);
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin1],
            destinations: [destinationA],
            travelMode: google.maps.TravelMode.DRIVING,
            //transitOptions: TransitOptions,
            //drivingOptions: DrivingOptions,
            //unitSystem: UnitSystem,
            //avoidHighways: Boolean,
            //avoidTolls: Boolean,
          }, callback
          );
        
        function callback(response, status) {
          
          // See Parsing the Results for
          // the basics of a callback function.
        }
      */
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {

          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return null;
          }

          //set latitude, longitude and zoom
          const locationInfos = {
            latitude:place.geometry.location.lat(),
            longitude:place.geometry.location.lng(),
            zoom:17,
            userAddress: searchElementRef.nativeElement.value
          };
          this.onLocationChanges.next(locationInfos);
          this.hideMapAndChoiceButton = false;
        });
      });
    });
  }

  initializeMapsCoords(){
    this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      takeUntil(this.subjectThatWeUseToUnsibscribefromAllObservables),
      tap(coords => {
        if(this.areCoordsNotEmpty(coords)){
          const locationInfos = {
            latitude:+coords.latitude,
            longitude:+coords.longitude,
            zoom:17,
            userAddress:null
          }
          this.onLocationChanges.next(locationInfos);
        }
      })
    )
    .subscribe();
  }

  areCoordsNotEmpty(coords):boolean{
    return ( coords && (coords["latitude"] && coords["longitude"]) );
  }

/*
  getAddress(latitude:any, longitude:any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results:any, status:any) => {
      if (status === 'OK') {
        if (results[0]) {
          const locationInfos = {
            latitude,
            longitude,
            zoom:17,
            userAddress:results[0].formatted_address
          }
          this.onLocationChanges.next(locationInfos)
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

*/

  onCenterChanged(coords){    
    this.subjectThatWeUseToUnsibscribeFromTimer.next("cancelTimer");
    timer(1000)
    .pipe(
     takeUntil(this.subjectThatWeUseToUnsibscribeFromTimer),
     tap(_ => {
      const locationInfos = {
        latitude:coords.lat,
        longitude:coords.lng,
        zoom:17,
        userAddress:null
      }
      this.onLocationChanges.next(locationInfos);
      //this.getAddress(coords.lat, coords.lng);
      if(this.hideMapAndChoiceButton) this.hideMapAndChoiceButton = false;
     })
    )
   .subscribe()
  }

  getDefaultLocation(){
    return {
      latitude:33.4985208850455,
      longitude:-7.600348150805933,
      zoom:17,
      userAddress:null
    }
  }

  makeSearchFieldEmpty(){
    this.searchFieldValue = "";
  }

  locateUser(){
    const modernLoaderPopupRef = this.__checkoutService.openModernPopupLoader();
    const navigatorLocationOptions = {
      enableHighAccuracy: true,
      timeout: 1200,
      maximumAge: 70000
    };
    if(navigator && navigator.geolocation){
      navigator.geolocation.getCurrentPosition(
        position => this.handleNavigatorGeolocationSucessefullResponse(position,modernLoaderPopupRef),
        error => this.handleNavigatorGeolocationFailedResponse(error,modernLoaderPopupRef),
        navigatorLocationOptions
      );
    }
  }

  handleNavigatorGeolocationSucessefullResponse(position,modernLoaderPopupRef){
    const coords = {lat:position.coords.latitude, lng:position.coords.longitude}
    this.onCenterChanged(coords);
    modernLoaderPopupRef.close();
  }

  handleNavigatorGeolocationFailedResponse(error,modernLoaderPopupRef){
    const text = "Nous n'avons pas pu récupérer votre position. Merci de saisir votre adresse pour que nous puissions vous localiser."
    const data = {
      imgSrc:"map-location.png",
      text,
      btn1Text:"Continuer",
      btn2Text:"",
      btn1Fun:() => {},
      btn2Fun:() => {
        
      },
      subjectToUnsubscribeFromObservables:this.subjectThatWeUseToUnsibscribefromAllObservables
    }
    modernLoaderPopupRef.close();
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }

  ngOnDestroy(): void {
    this.subjectThatWeUseToUnsibscribeFromTimer.complete();
    this.subjectThatWeUseToUnsibscribefromAllObservables.next();
    this.subjectThatWeUseToUnsibscribefromAllObservables.complete();
    this.onLocationChanges.complete();
  }

}
