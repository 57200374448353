import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { BasketService } from "./services/basket.service";
import { HomePageService } from "./services/home-page.service";
import { SharedDataService } from "./services/shared-data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit,OnDestroy{
  isInMakeAnOrderPage: boolean;
  currentUrl: string = '';
  oldVersionAlertOpen: boolean = true;
  winodw: Window = window;
  private observables:Subscription[] = [];

  constructor(
    public router: Router, 
    public basketService: BasketService,
    private __homeService:HomePageService,
    private __http:HttpClient,
    public __sharedDataService: SharedDataService,
    private dialog: MatDialog
    ) {
    let observable = router.events.subscribe((events) => {
      this.currentUrl = this.router.url.split("/")[1];
    });
    this.observables.push(observable);
  }
  
  ngOnInit(): void {
    this.dialog.closeAll()
    this.router.events.subscribe((events)=> {
      this.currentUrl = this.router.url.split("/")[1]
      this.router.events.subscribe((events)=> {
        if(this.currentUrl.includes("makeAnOrder")) this.isInMakeAnOrderPage = true 
        else this.isInMakeAnOrderPage = false   
      })
    })
    this.checkingTheVersionOfUserBasket();
  }

  redirectToBasketPage(): void{
    if(this.basketService.basket.items.length>0){
      const restaurantName = this.basketService.basket.items[0].restaurant_name;
      this.router.navigate(["panier",restaurantName])
    }
  }

  closeVersionAlert(): void {
    this.oldVersionAlertOpen = false;
  }

  checkingTheVersionOfUserBasket(){
    this.__homeService.checkingTheVersionOfUserBasket();
  }

  ngOnDestroy(): void {
    this.observables.forEach(subscription => subscription.unsubscribe());
  }
}
