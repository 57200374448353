import { Component, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { SelectedHeadingItemDialogComponent } from "./selected-heading-item-dialog/selected-heading-item-dialog.component";
import { Router } from "@angular/router";
import { BasketService } from "../../../../services/basket.service";
import { Item } from "../../../../models/item";
import { ItemType } from "../../../../models/item-type.enum";
import { Subscription } from "rxjs";
import { SharedDataService } from "src/app/services/shared-data.service";


@Component({
  selector: "app-selected-heading-item",
  templateUrl: "./selected-heading-item.component.html",
  styleUrls: ["./selected-heading-item.component.scss"],
})

export class SelectedHeadingItemComponent implements OnInit {
  // @Input()
  // name: string;
  //
  // @Input()
  // image: string;
  //
  // @Input()
  // price: number;

  @Input()
  headingItem: any;
  @Input()
  restaurantClosed: any;
  @Input()
  preOrderStatus: any;
  private __subscriptions:Subscription[] = [];
  count = 0
  basket 
  itemsOfBasket
  nbrItem = 0
  nbrNewItem = 0

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private basketService: BasketService,
    public sharedData: SharedDataService
  ) {}

  ngOnInit(): void {    
  }

  

  openSnackBar(message: string, action?: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = ["custom-class"];
    config.duration = 2000;
    config.horizontalPosition = "center";
    this.snackBar.open(message, action ? "Action Label" : undefined, config);
  }

  addToBasket() {
    if (this.restaurantClosed && !this.preOrderStatus) {
      return this.openSnackBar("Le restaurant est fermé temporairement", "");
    }
    else if(!this.basketService.basket.hasDelivry && !this.basketService.basket.hasTakeway)
      return this.openSnackBar("No delivry && no TakeAway", "");
    else if (
      (this.headingItem.declinaisons &&
        this.headingItem.declinaisons.length > 1) ||
      this.headingItem.formule.length
    ) {
      this.dialog.open(SelectedHeadingItemDialogComponent, {
        height: window.innerWidth < 500 ? "85%" : "740px",
        width: window.innerWidth < 500 ? "85%" : "400px",
        autoFocus: false,
        data: {
          item: this.headingItem,
        },
        panelClass:"popupNeedRaduis"
      });    
    } else {      
      const item1 = new Item();
      item1.id = this.headingItem.id_produit;
      item1.id_produit = this.headingItem.id_produit;
      item1.id_prix = this.headingItem.id_prix;
      item1.name = this.headingItem.designation;
      item1.unitPrice = this.headingItem.prix;
      item1.originalPrice = this.headingItem.prix;
      item1.count = 1;
      item1.image = this.headingItem.image;
      item1.description = this.headingItem.description;
      item1.restaurant_id = this.headingItem.restaurant_id;
      item1.restaurant_name = this.headingItem.restaurant_name;
      item1.quantite_stock = this.headingItem.quantite_stock
  
      //const declinations = this.headingItem.declinaisons;
      const declinations:{items:Item[],selectedValue:Item} = {items:this.headingItem.declinaisons,selectedValue:null};      
      declinations.selectedValue = this.headingItem.declinaisons[0];
      item1.declinations = declinations;

      if(this.headingItem.prixpromo != 0 && this.headingItem.prixpromo != this.headingItem.prix){
        item1.unitPrice = declinations.selectedValue.prixpromo;
      }else{
        item1.unitPrice = declinations.selectedValue.prix;
      }

      item1.id = declinations.selectedValue.produit_id;
      this.basketService.addItem(item1); 
      
      

      // Shake basket
      const BasketElementToShake = document.getElementById('basketCount')
      if(BasketElementToShake){
        BasketElementToShake.classList.remove("shake")
        setTimeout(()=>{BasketElementToShake.classList.add("shake") }, 50)
        navigator.vibrate(800)
      }
      
      
      /* Snack bar
      let config = new MatSnackBarConfig();
      config.duration = 1500;
      config.horizontalPosition = "center";
      config.verticalPosition = "top";
      this.snackBar.open(
        this.headingItem.designation.toUpperCase(),
        "Ajouté(e) au panier",
        config
      );
      */
      
    }
  }


  showItemDetails(): void {
    /*
    if (this.restaurantClosed) {
      return this.openSnackBar("Le restaurant est fermé temporairement", "");
    }
    */
    this.dialog.open(SelectedHeadingItemDialogComponent, {
      height: window.innerWidth < 500 ? "85%" : "740px", // 480px
      width: window.innerWidth < 500 ? "85%" : "400px",
      autoFocus: false,
      data: {
        item: {
          ...this.headingItem,
        },
        restaurantClosed:this.restaurantClosed,
        preOrderStatus: this.preOrderStatus,

      },
      panelClass:"popupNeedRaduis"
    });
  }

  ngOnDestroy(){
    this.__subscriptions.forEach(sub=>sub.unsubscribe());
  }

  getTheLowestPriceOfHeadingItem(item):number{
    if(item.declinaisons && item.declinaisons.length>1){
      let min = item.declinaisons[0].prix;
      for(let i=1;i<item.declinaisons.length;i++){
        if(item.declinaisons[i].prix<min) min=item.declinaisons[i].prix;
      }
      return min;
    }
    else return item.prix;
  }

  getPriceWithPromo(item):number{
    if(item.declinaisons && item.declinaisons.length>1){
      let min = item.declinaisons[0].prixpromo;
      for(let i=1;i<item.declinaisons.length;i++){
        if(item.declinaisons[i].prixpromo<min) min=item.declinaisons[i].prixpromo;
      }
      return min;

    }
    else return item.prixpromo;
  }

}
