<div class="bank-cards">
    <div class="bank-cards__container">
        <div class="bank-cards__container-header">
            MES CARTES BANCAIRES
        </div>

        <div
        #carouselRef
        class="bank-cards__container-carousel-phone-container"
        [hidden]="!isTheUserHasAnyBankCard"
        >
            <div
            (click)="ModifyBankCard(card)"
            *ngFor="let card of userCards"
            [ngStyle]="{background:'url(assets/images/cardwithlogo11.png)'}" 
            class="bank-cards__container-carousel-phone-container-item">
                <span class="bank-cards__container-carousel-phone-container-item-rectangle-1">
                    {{card.nom_client}}
                </span>
                <span class="bank-cards__container-carousel-phone-container-item-rectangle-2">
                    {{card.label}} 
                    <br>
                    {{card.number_card}}
                    <br>
                    Exp: {{card.expiry_date}}
                </span>
                <span
                class="bank-cards__container-carousel-phone-container-item-rectangle-4">
                {{ card.etat == '1' ? 'Activé' : 'Désactivé' }}
                </span>
    
            </div>
        </div>

        <div class="bank-cards__container-modify-card" *ngIf="isTheUserHasAnyBankCard">
            <button 
            (click)="submit()"
            class="bank-cards__container-modify-card-btn-2"
            >Ajouter une carte
                <mat-icon class="bank-cards__container-modify-card-btn-2-icon">touch_app</mat-icon>
            </button>
        </div>

        <div 
        *ngIf="isTheUserHasAnyBankCard"
        class="bank-cards__container-usage-history">
            <div class="bank-cards__container-usage-history-header">
                Historique des paiements
                <hr>
            </div>

            <div class="bank-cards__container-usage-history-content">
                <div class="bank-cards__container-usage-history-content-date">
                    <div class="bank-cards__container-usage-history-content-date-header">
                        Date
                    </div>

                    <div 
                    *ngFor="let commande of usageHistory"
                    class="bank-cards__container-usage-history-content-date-element">
                       {{commande.date_commande}}
                    </div>

                </div>

               <!-- <div class="bank-cards__container-usage-history-content-action">
                    <div class="bank-cards__container-usage-history-content-action-header">
                        Action
                    </div>

                    <div class="bank-cards__container-usage-history-content-action-element">
                        Crédité
                    </div>
                    <div class="bank-cards__container-usage-history-content-action-element">
                        Débité
                    </div>

                </div>-->

                <div class="bank-cards__container-usage-history-content-montant">
                    <div class="bank-cards__container-usage-history-content-montant-header">
                        Montant
                    </div>

                    <div 
                    *ngFor="let commande of this.usageHistory"
                    class="bank-cards__container-usage-history-content-montant-element">
                        {{commande.prix_total}} DH
                    </div>

                </div>
            </div>
        </div>

        <!-- There is no card -->

        <div 
        *ngIf="!isTheUserHasAnyBankCard"
        class="bank-cards__container-no-card">
            <div class="bank-cards__container-no-card-label">
                Vous n'avez pas encore ajouté de carte bancaire, veuillez ajouter votre carte !
            </div>
            <button 
            (click)="submit()"
            class="bank-cards__container-no-card-btn"
            >Ajouter une carte
                <mat-icon>touch_app</mat-icon>
            </button>

        </div>
    </div>
</div>
<app-about-zone></app-about-zone>


