<link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,600,0,200" />

<section class="failed-payment">

    <span class="spacebetween">
        <img style="width: 180px;" src="../../../assets/images/cancel-white.svg" alt="">
    </span>

    <div class="titre" style="text-transform:uppercase">
        OOOPS!
    </div>
    
    <div class="text">
        Nous n'avons pas reçu votre paiement !
    </div>

    <!--
    <div class="redirect" (click)="redirect()">
        Réessayer
    </div>
    -->
    

</section>