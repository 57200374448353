import {
  Component,
  ElementRef,
  Inject, Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { BasketService } from "../../../services/basket.service";
import { Item } from "../../../models/item";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { SharedDataService } from 'src/app/services/shared-data.service';
import { MenuBasketService } from 'src/app/services/menu-basket.service';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit, OnDestroy {

  @ViewChild("addToBasket") button: ElementRef;
    
      itemCount = 1;
      item: any;
      restaurantClosed = false;
      selectedDeclination: any;
      selectedComposition: any;
      selectedFormula: any;
      displayedPrice: number;
    
      @Input() remark: any;
    
      constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private basketService: BasketService,
        public sharedData: SharedDataService,
        public menuBasketService: MenuBasketService,
      ) {}
    
      ngOnInit(): void {
        this.restaurantClosed = this.data["restaurantClosed"];
        // if (this.data.item.declinaisons && this.data.item.declinaisons.length > 1
        //   && !this.selectedDeclination) {
        if (!this.data.item.formule.length) {
          this.selectedDeclination = this.data.item.declinaisons[0];
        }
        if (this.data.item.formula) {
          this.selectedFormula = this.data.item.formule.map((formule) => {
            return {
              id: formule.name,
              items: formule.items,
              selectedItem: formule.items[0],
            };
          });
        }
        if (this.data.item.formule.length) {
          this.selectedComposition = this.data.item.formule.map((composition) => {
            return {
              titre: composition.titre,
              nb_choix: composition.nb_choix,
              requis: composition.requis,
              composants: composition.composants.map((item) => {
                return {
                  name: item.designation + " " + item.label,
                  price: item.prix,
                  id: item.id_prix,
                  id_titre: composition.id_titre,
                  id_prix: item.id_prix,
                  id_produit: item.id_produit,
                  id_tpf: item.id_tpf,
                  selected: false,
                };
              }),
            };
          });
          this.selectedComposition.prix = this.data.item.prix;
          this.selectedComposition.prixpromo = this.data.item.prixpromo
        }
        this.item = this.data.item;
        if(this.data.item.prixpromo == 0){
          this.displayedPrice = this.data.item.prix;
        }else{
          this.displayedPrice = this.data.item.prixpromo;
        }
        
      }
    
      disableButton(): boolean {
        // if (this.data.item.compositions) {
        //   const totalPrice = this.selectedComposition.flatMap(element => element.items)
        //   .filter(item => item.selected === true)
        //   .map(item => item.price)
        //   .reduce((acc, val) => acc += val, 0);
        //   return totalPrice > 0 ? false : true;
        // }
        if (this.data.item.formule.length) {
          let map = this.selectedComposition.map(
            (e) =>
              e.requis <= e.composants.filter((c) => c.selected === true).length
          );
    
          return map.includes(false);
        }
        return false;
      }
    
      addToBasket(): void {
        let config = new MatSnackBarConfig();
        config.duration = 1500;
        config.horizontalPosition = "center";
        config.verticalPosition = "top";

        const item1 = new Item();
        item1.id = this.data.item.id_produit;
        item1.id_produit = this.data.item.id_produit;
        item1.id_prix = this.data.item.id_prix;
        item1.name = this.data.item.designation;
        item1.unitPrice = this.displayedPrice;
        item1.count = this.itemCount;
        item1.image = this.data.item.image;
        item1.description = this.data.item.description;
        item1.restaurant_id = this.data.item.restaurant_id;
        item1.restaurant_name = this.data.item.restaurant_name;
        item1.compositions = this.selectedComposition;
        item1.remark = this.remark;
        item1.quantite_stock = this.data.item.quantite_stock
        if (this.selectedComposition){
          if(this.selectedComposition.prixpromo == 0){
            item1.originalPrice = this.displayedPrice
          }else{
            item1.originalPrice = this.displayedPrice - this.selectedComposition.prixpromo + this.selectedComposition.prix
          }
        }
        
        
        if (!this.data.item.formule.length) {
          //const declinations = Object.assign({}, this.data.item.declinaisons);
          const declinations:{items:Item[],selectedValue:null} = {items:this.data.item.declinaisons ,selectedValue:null};
          declinations.selectedValue = this.selectedDeclination;
          item1.declinations = declinations;
          item1.id = this.selectedDeclination.produit_id;
          item1.id_prix = this.selectedDeclination.id;
          if (this.selectedDeclination){
            if(this.selectedDeclination.prixpromo == 0){
              item1.unitPrice = this.selectedDeclination.prix;
            }else{
              item1.unitPrice = this.selectedDeclination.prixpromo;
            }
          }
          if (this.selectedDeclination){
              item1.originalPrice = this.selectedDeclination.prix;     
          }
    
          // item1.declinations.selectedValue = this.selectedDeclination;
        }
        this.menuBasketService.addItem(item1);    
        // if (this.data.item.compositions) {
        //   this.data.item.price = null;
        // }

        const BasketElementToShake = document.getElementById('basketCount')
        BasketElementToShake.classList.remove("shake")
        setTimeout(()=>{BasketElementToShake.classList.add("shake") }, 50)
        navigator.vibrate(800)
      }
    
      decrementCount(): void {
        if(this.restaurantClosed) return this.openSnackBar("Le restaurant est fermé temporairement", "");
        if (this.itemCount > 1) {
          --this.itemCount;
        }
      }
    
      incrementCount(): void {
        if(this.restaurantClosed) return this.openSnackBar("Le restaurant est fermé temporairement", "");
        ++this.itemCount;
      }
    
      ngOnDestroy(): void {
       
      }
    
      updatePrice(event: MatCheckboxChange): void {
        if(this.restaurantClosed) {
          event.source.checked = false;
          return this.openSnackBar("Le restaurant est fermé temporairement", "");
        }
        const titre = event.source.id.split("__")[0];
        const composantId = event.source.id.split("__")[1];
    
        if (event.checked) {
          //   event.source.toggle();
          let find = this.selectedComposition
            .find((e) => e.titre === titre)
            .composants.filter((composant) => composant.selected === true).length;
          if (
            this.selectedComposition.find((e) => e.titre === titre).nb_choix < find
          ) {
            event.source.toggle();
            this.selectedComposition
              .find((e) => e.titre === titre)
              .composants.find(
                (composant) => composant.id === composantId
              ).selected = false;
          }
        }
      
    
        if(this.selectedComposition.prixpromo == 0){
          this.displayedPrice =
          this.selectedComposition
            .flatMap((element) => element.composants)
            .filter((item) => item.selected === true)
            .map((item) => item.price)
            .reduce((acc, val) => (acc += val), 0) + this.selectedComposition.prix;
        }else{
          this.displayedPrice =
          this.selectedComposition
            .flatMap((element) => element.composants)
            .filter((item) => item.selected === true)
            .map((item) => item.price)
            .reduce((acc, val) => (acc += val), 0) + this.selectedComposition.prixpromo;
        }
      }
    
      showErrorMessage(category): boolean {
        if (category.requis > 0) {
          return (
            category.composants.filter((composant) => composant.selected === true)
              .length < category.requis
          );
        }
        return false;
      }
    
      addRemark(): void {
        let currentProduct = this.data.item;
        let remarkContent = this.remark;
    
        this.basketService.addRemarkToProdcut(currentProduct, remarkContent);
      }
    
      onRemarkChange(value): void {
        this.remark = value;
      }
    
      openSnackBar(message: string, action?: string) {
        let config = new MatSnackBarConfig();
        config.panelClass = ["custom-class"];
        config.duration = 2000;
        config.horizontalPosition = "center";
        this.snackBar.open(message, action ? "Action Label" : undefined, config);
      }

}
