<div class="restaurants__container">
  <div class="restaurants__title">
    <img
      src="../../../assets/images/categories/restaurants.png"
      class="restaurants__title--image"
    />
    <span class="restaurants__title--text">&nbsp;{{ title }}</span>
  </div>
  <div class="restaurants__body-container">
    <mat-spinner
      style="margin: auto"
      strokeWidth="6"
      diameter="60"
      *ngIf="!restaurants"
    >
    </mat-spinner>
    <div class="restaurants__body" *ngIf="restaurants">
      <app-restaurant
        *ngFor="let restaurant of restaurants"
        [image]="restaurant.image"
        [city]="restaurant.city"
        [name]="restaurant.name | uppercase"
        [delivery]="restaurant.delivery"
        [takeAway]="restaurant.takeAway"
        [type]="restaurant.type"
        [categories]="restaurant.categories"
        [closed]="restaurant.ferme"
        [preOrder]="restaurant.pre_commande"
        [estimationDuration]="restaurant.duree"
        [remise]="restaurant.remise"
        class="restaurants__restaurant"
        (click)="redirectToRestaurantPage(restaurant.url_unique)"
      >
      </app-restaurant>

      <div
        (click)="getMore()"
        *ngIf="showSpinnerForGetMore === false && restaurants"
        class="restaurants__body-button-mobile-container"
      >
        <div class="restaurants__body-button-mobile-text">
          Plus de {{ title | lowercase}}
        </div>
        <button
          class="restaurants__body-button-mobile"
          mat-icon-button
          color="primary"
        >
          <mat-icon>add</mat-icon>
        </button>
        <mat-spinner
          style="margin: auto"
          strokeWidth="4"
          diameter="40"
          *ngIf="showSpinnerForGetMore === true"
        >
        </mat-spinner>
      </div>
    </div>
    <div class="restaurants__body-button-container">
      <button
        class="restaurants__body-button"
        *ngIf="showSpinnerForGetMore === false && restaurants"
        (click)="getMore()"
        mat-raised-button
        color="primary"
      >
        Voir plus
      </button>
      <mat-spinner
        style="margin: auto"
        strokeWidth="4"
        diameter="40"
        *ngIf="showSpinnerForGetMore === true"
      >
      </mat-spinner>
    </div>
  </div>
</div>
