<div
    [hidden]="restaurants.length==0"
    class="promotion-restaurants">

    <div class="promotion-restaurants-header">
        <img
            class="promotion-restaurants-header-img"
            src="../../../assets/images/sale.png"
            >
        <div class="promotion-restaurants-header-text">
            Promotions
        </div>
    </div>

    <div class="promotion-restaurants-container">
        <mat-icon 
            [ngClass]="{ 'hideMe': restaurants.length <= 2 }"
            class="promotion-restaurants-container-btn-left"
            (click)="scrollLeft(carousel)"
        >
        keyboard_arrow_left
        </mat-icon>
        <div 
            [ngClass]="{ 'needJustifyContentStart': restaurants.length <= 2 }"
            class="promotion-restaurants-container-carousel" 
            #carousel
        >
            <app-promotion-restaurant
                [ngClass]="[i!=(restaurants.length-1) ? 'needMarginRight' : 'needLittleMargin']"
                class="promotion-restaurants-container-carousel-item"
                *ngFor="let restaurant of restaurants; index as i"
                [restaurant]="restaurant"
            >

            </app-promotion-restaurant>
        </div>
        <mat-icon 
            [ngClass]="{ 'hideMe': restaurants.length <= 2 }"
            class="promotion-restaurants-container-btn-right"
            (click)="scrollRight(carousel)"
        >
        keyboard_arrow_right
    </mat-icon>

    </div>
</div>