import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-popup-loader',
  templateUrl: './popup-loader.component.html',
  styleUrls: ['./popup-loader.component.scss']
})
export class PopupLoaderComponent implements OnInit, OnDestroy {
  onCancelButtongetClicked:BehaviorSubject<boolean> = new BehaviorSubject(false);
  text:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  {}

  ngOnInit(): void {
    this.text = this.data["text"];
  }

  stopProcess(){
    this.onCancelButtongetClicked.next(true);
  }

  ngOnDestroy(): void {
    this.onCancelButtongetClicked.complete();
  }

}
