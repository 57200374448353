import { SharedDataService } from 'src/app/services/shared-data.service';
import { SearchFieldComponent } from './../search-field/search-field.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Restaurant } from 'src/app/models/restaurant';

@Component({
  selector: 'app-search-restaurant',
  templateUrl: './search-restaurant.component.html',
  styleUrls: ['./search-restaurant.component.scss']
})
export class SearchRestaurantComponent implements OnInit {

  category
  restaurants: Restaurant[] = []
  searchFieldValue = ""

  constructor(
      private httpService: HttpService,
      private route: ActivatedRoute,
      private router:Router,
      private sharedDataService: SharedDataService
    ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params =>{
      if(params.searchFieldValue){
      this.searchFieldValue = params.searchFieldValue
      this.httpService.afterLocateUserAutomaticlllyGetResturantsForSearchRestaurant(this.searchFieldValue)
      .subscribe((restaurants: any[]) => (this.restaurants = restaurants));
      }
    })
  }

  redirectToRestaurantPage(restaurantName){
   this.router.navigate(["restaurant"],{queryParams:{name:restaurantName}});
  }



 



 

}