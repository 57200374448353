import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-inputs-errors',
  templateUrl: './popup-inputs-errors.component.html',
  styleUrls: ['./popup-inputs-errors.component.scss']
})
export class PopupInputsErrorsComponent implements OnInit {
  errors:string[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.errors = this.data.errors;
  }

}
