import {ItemType} from './item-type.enum';

export class Item {
  id: number;
  name: string;
  unitPrice: number;
  count: number;
  image: string;
  description: string;
  restaurant: string;
  itemType: ItemType;
  declinations: {items:Item[],selectedValue:Item};
  compositions: any;
  categories: any;
  remark: string;
  restaurant_name:string
  id_produit: string;
  id_prix: string;
  restaurant_id: string;
  prix;
  produit_id;
  prixpromo;
  originalPrice;
  quantite_stock;
  id_table: any;
  constructor() {
  }


  // constructor(id: number, name: string, unitPrice: number, count: number, image: string,
  //             description: string, restaurant: string, itemType: ItemType, compositions: any, categories: any) {
  //   this.id = id;
  //   this.name = name;
  //   this.unitPrice = unitPrice;
  //   this.count = count;
  //   this.image = image;
  //   this.description = description;
  //   this.restaurant = restaurant;
  //   this.itemType = itemType;
  //   this.compositions = compositions;
  //   this.categories = categories;
  // }
}
