<div class="partner-page__container">
  <div class="partner-page__container-frame">

    <div class="partner-page__title">
      <span>Devenir partenaire</span>
      <img class="partner-page__title-image"
           src="../../../assets/images/order_icon_1.png">
    </div>

    <div class="partner-page__zones" *ngIf="formGroup">
      <form [formGroup]="formGroup" class="partner-page__zones-left">

        <div class="partner-page__frame-info-left">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/6.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Boostez votre chiffres d’affaire</div>
            <div class="partner-page__frame-info-text-zone-description">
              Faites partie de notre plateforme, augmentez votre visibilité sur votre ville et
              attirez de nouveaux clients
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-left">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/5.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Gérez mieux vos livraisons</div>
            <div class="partner-page__frame-info-text-zone-description">
              Vous recevrez vos commandes directement sur votre caisse
              pour gérer au mieux votre activité
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-left-last">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/1.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Fidélisez vos clients</div>
            <div class="partner-page__frame-info-text-zone-description">
              Des notifications seront envoyés à vos clients à chaque étape de préparation
              de leurs commandes !
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-mobile" style="margin-top: 0">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/2.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Synchronisez vos menus</div>
            <div class="partner-page__frame-info-text-zone-description">
              Plus besoin de resaisir vos menus et vos stock, votre catalogue de vente est mis à
              jour automatiquement
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-mobile">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/4.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Paiement en ligne sécurisé</div>
            <div class="partner-page__frame-info-text-zone-description">
              Permettez à vos clients de payer en ligne en toute sécurité par carte bancaire
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-mobile" style="margin-bottom: 40px;">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/3.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Gérez votre flotte de livreurs
            </div>
            <div class="partner-page__frame-info-text-zone-description">
              Dispatchez vos livraisons efficacement, et permettez à vos clients de suivre leurs
              commandes !
            </div>
          </div>
        </div>

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin"> Saisissez votre nom
          </mat-label>
          <input formControlName="nom" class="partner-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('nom').invalid">
            {{getErrorMessage('nom')}}
          </mat-error>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin"> Saisissez votre prénom
          </mat-label>
          <input formControlName="prenom" class="partner-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('prenom').invalid">
            {{getErrorMessage('prenom')}}
          </mat-error>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin"> Saisissez votre téléphone
          </mat-label>
          <input formControlName="telephone" class="partner-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('telephone').invalid">
            {{getErrorMessage('telephone')}}
          </mat-error>
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin"> Saisissez votre email
          </mat-label>
          <input formControlName="email" class="partner-page__text-field-margin" email="true" matInput>
          <mat-error *ngIf="formGroup.get('email').invalid">
            {{getErrorMessage('email')}}
          </mat-error>
          <mat-icon matSuffix>alternate_email</mat-icon>
        </mat-form-field>

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin"> Saisissez votre ville</mat-label>
          <input formControlName="ville" class="partner-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('ville').invalid">
            {{getErrorMessage('ville')}}
          </mat-error>
          <mat-icon matSuffix>location_city</mat-icon>
        </mat-form-field>

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin">Saisissez votre type d'activité</mat-label>
          <mat-select class="partner-page__text-field-margin" formControlName="activite">
            <mat-option value="restaurant">Restaurant</mat-option>
            <mat-option value="boutique">Boutique</mat-option>
            <mat-option value="patisserie">Pâtisserie</mat-option>
          </mat-select>

          <mat-error *ngIf="formGroup.get('activite').invalid">
            {{getErrorMessage('activite')}}
          </mat-error>
          <mat-icon matSuffix>room_service</mat-icon>
        </mat-form-field>

      
      
 
  

        
      
      

        <mat-form-field class="partner-page__text-field">
          <mat-label class="partner-page__text-field-margin"> Saisissez l'url de votre page Instagram</mat-label>
          <input formControlName="urlInstagram" class="partner-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('urlInstagram').invalid">
            {{getErrorMessage('urlInstagram')}}
          </mat-error>
          <mat-icon matSuffix>link</mat-icon>
        </mat-form-field>

      </form>
      <div class="partner-page__zones-right">

        <div class="partner-page__frame-info-right">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/2.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Synchronisez vos menus</div>
            <div class="partner-page__frame-info-text-zone-description">
              Plus besoin de resaisir vos menus et vos stock, votre catalogue de vente est mis à jour automatiquement
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-right">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/4.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Paiement en ligne sécurisé</div>
            <div class="partner-page__frame-info-text-zone-description">
              Permettez à vos clients de payer en ligne en toute sécurité par carte bancaire
            </div>
          </div>
        </div>

        <div class="partner-page__frame-info-right" style="margin-bottom: 40px;">
          <div class="partner-page__frame-info-image-zone">
            <img class="partner-page__frame-info-image-zone-image"
                 src="../../../assets/images/3.png">
          </div>
          <div class="partner-page__frame-info-text-zone">
            <div class="partner-page__frame-info-text-zone-title">Gérez votre flotte de livreurs</div>
            <div class="partner-page__frame-info-text-zone-description">
              Dispatchez vos livraisons efficacement, et permettez à vos clients de suivre leurs commandes !
            </div>
          </div>
        </div>

        <img style="width: 90%; margin-top: 30px" src="../../../assets/images/store_illustration_1.png">

      </div>
    </div>
    <button class="partner-page__button"
            mat-raised-button
            [disabled]="formGroup.invalid"
            (click)="validate()"
            color="primary">
      Rejoindre
    </button>
  </div>

</div>
<app-about-zone class="partner-page__about-zone"></app-about-zone>
