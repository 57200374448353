<div 
*ngIf="commands.length>0; else noCommandFound"
class="commands">
    <div class="commands-container">

        <div class="commands-container-header">
            MES COMMANDES
        </div>
    
        <div class="commands-container-list">
            <div 
            *ngFor="let command of commands"
            class="commands-container-list-item">
                <app-command [command]="command"></app-command>
            </div>
        </div>
    
    </div>
</div>

<ng-template #noCommandFound>
    <div class="no-command-found-container">
        <img src="assets/images/cartfD.png" class="no-command-found-container-img">
        <div class="no-command-found-container-msg">
            Vous avez pas de commande.
        </div>
    </div>
</ng-template>

<div class="paginator">
    <div class="paginator-container">
        <mat-paginator 
              [pageIndex]="0"
              [length]="count"
              [pageSize]="10"
              (page)="getPaginatorData($event)"
              >
</mat-paginator>
    </div>
</div>


<div class="footer">
    <app-about-zone></app-about-zone>
</div>