<div class="swiper__container">
  <div class="swiper__middle-panel">
    <div class="swiper__middle-panel-main-zone">
      <!--<mat-spinner-->
        <!--style="margin: auto"-->
        <!--strokeWidth="6"-->
        <!--diameter="60"-->
        <!--*ngIf="!images"-->
      <!--&gt;-->
      <!--</mat-spinner>-->
      
      
      
      
      <!--
      <img
        *ngIf="getCurrentSectionName() == 'restaurants'"
        src="../../../assets/images/restaurants-cover.png"
        style="height: 100%; width: 100%; border-radius: 13px; object-fit: fill"
        alt="images"
      />
      <img
        *ngIf="getCurrentSectionName() == 'boutiques'"
        src="../../../assets/images/boutiques-cover.png"
        style="height: 100%; width: 100%; border-radius: 13px; object-fit: fill"
        alt="images"
      />
      <img
        *ngIf="getCurrentSectionName() == 'specialnord'"
        src="../../../assets/images/specialnord.jpeg"
        style="height: 100%; width: 100%; border-radius: 13px; object-fit: fill"
        alt="images"
      />
      <img
        *ngIf="getCurrentSectionName() == 'patisseries'"
        src="../../../assets/images/patisseries-cover.png"
        style="height: 100%; width: 100%; border-radius: 13px; object-fit: fill"
        alt="images"
      />
      -->
    </div>
  </div>
</div>
