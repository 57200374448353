<div 
    id="preloader"
    *ngIf="!restaurantData"
>
    <div class="loading-spinner"></div>
 </div>

  <app-rotate-your-ipad
        *ngIf="screenWidth < screenHeight && restaurantData"
        [ngStyle]="{'width': screenWidthInPixels, 'height': screenHeightInPixels}"
  >
  </app-rotate-your-ipad>

<section 
    *ngIf="!showBasket"
    class="choose-table"
    [ngStyle]="{'width': screenWidthInPixels, 'height': screenHeightInPixels}"
>
    <div
        class="choose-table_restaurant-logo"
    >
        <img [src]="restaurantData.image" alt="">
    </div>

    <div
        class="choose-table_dividing-line"
    >

    </div>

    <div
        class="choose-table_restaurant-tables"
    >   
        <div class="choose-table_restaurant-tables--text">Veuillez choisir une table</div>
        <input 
            type="text" 
            class="choose-table_restaurant-tables--input"
            [formControl]="myControl" 
            [matAutocomplete]="auto" 
            placeholder="TABLE №"
            (input)="checkIfTheTableExists(myControl.value)" >
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="checkIfTheTableExists(option.id_table)">
            {{option.nom_table}}
            </mat-option>
        </mat-autocomplete>
        <button 
            *ngIf="TableExists" 
            (click)="goToBasketPage()" 
            class="choose-table_restaurant-tables--button"
        >
            Suivant
        </button>
    </div>
</section>

<div *ngIf="showBasket">
 <div class="header">
    <button mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <div class="list-categories">    
        <div 
            class="categorie"
            *ngFor="let categorie of restaurantCategories"
            (click)="fetchDataByCategorie(categorie.cat_name)"
        >
            {{ categorie.cat_name }}
        </div>
    </div>

    <button mat-icon-button>
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>

    
</div>

<div class="container"
    [ngStyle]="{'justify-content': (menuBasketService.menuBasket.items.length == 0) ? 'center' : 'left',
                height: menuHeight}"
>

    <div class="selected-heading__body"
        [ngStyle]="{'width': (menuBasketService.menuBasket.items.length == 0) ? '95%' : '65%'}"
    >
        <div 
            class="selected-heading__body-item"
            *ngFor="let product of categorie.produits" 
            [ngStyle]="{'width': (menuBasketService.menuBasket.items.length == 0) ? 'calc((100% - 80px)/4)' : 'calc((100% - 60px)/3)'}"
        >
            <div class="selected-heading-item__container">
                <img
                    [src]="product.image"
                    class="selected-heading-item__upper-zone"
                    (click)="showItemDetails(product)"
                />
                <div class="selected-heading-item__lower-zone">
                    <div class="selected-heading-item__lower-zone-name">
                    {{ product.designation | uppercase }}
                    </div>
                    <div class="selected-heading-item__lower-zone-price">
                        <div>
                        {{ getTheLowestPriceOfHeadingItem(product) }}
                        <span *ngIf="product.prix !== null">Dhs</span>
                        </div>
                    <button 
                        (click)="addToBasket(product)"
                        class="selected-heading-item__lower-zone-add-item"
                    >
                        <mat-icon>add_circle</mat-icon>
                    </button>   
                </div>
            </div>
          </div>
        </div>
    </div>

    <div 
        class="selected-heading__basket"
        *ngIf="menuBasketService.menuBasket.items.length > 0"
    >
        <div 
            class="selected-heading__basket-countainer"
            [ngStyle]="{height: basketHeight}"
        >
            <div class="selected-heading__basket-countainer--top">
                <h1>Votre commande</h1>
            </div>
            <div class="selected-heading__basket-countainer--content">  <!-- ----------------------- -->
                <div
                    *ngFor="let item of menuBasketService.menuBasket.items; let i = index"
                    class="selected-heading__basket-countainer--content_item"
                >
                    <img 
                        [src]="item.image" 
                        class="selected-heading__basket-countainer--content_item-image"
                    >
                    <div
                        class="selected-heading__basket-countainer--content_item-details"
                    > 
                        <div class="selected-heading__basket-countainer--content_item-details--name">{{ item.name }}
                        </div>
                        <div class="selected-heading__basket-countainer--content_item-details--price-count">
                            <div class="selected-heading__basket-countainer--content_item-details--price-count---count">
                                <mat-icon
                                    (click)="decrementItemCount(item, i)"
                                    class="selected-heading__basket-countainer--content_item-details--price-count---count----remove"
                                >
                                    remove
                                </mat-icon>    
                                <span class="selected-heading__basket-countainer--content_item-details--price-count---count----text" >{{ item.count }}</span>
                                <mat-icon
                                    (click)="incrementItemCount(item, i)"
                                    class="selected-heading__basket-countainer--content_item-details--price-count---count----add"
                                >
                                    add
                                </mat-icon>
                            </div>
                            <div class="selected-heading__basket-countainer--content_item-details--price-count---price">{{ item.count * item.unitPrice | number : '1.2-2' }} Dhs</div>
                        </div>

                        <div 
                            class="selected-heading__basket-countainer--content_item-details--item-remark"
                            *ngIf="item.remark"
                        >
                            <span
                                class="selected-heading__basket-countainer--content_item-details--item-remark--title"
                            >Remarques</span>
                            <span 
                                class="selected-heading__basket-countainer--content_item-details--item-remark--text"
                            >{{ item.remark }}</span>
                        </div>

                        <div class="selected-heading__basket-countainer--content_item-details--item-details"
                            *ngIf="item.compositions && getItemCompositionDetails(item, i).length > 0"
                        >
                            <span class="selected-heading__basket-countainer--content_item-details--item-details--title">détails</span>
                            <div
                                *ngFor="let composition of getItemCompositionDetails(item, i)"
                                class="selected-heading__basket-countainer--content_item-details--item-details--text"
                            >
                                <span
                                    class="selected-heading__basket-countainer--content_item-details--item-details--text---composition"
                                >
                                    {{ composition.titre }}
                                </span>
                                <span
                                    class="selected-heading__basket-countainer--content_item-details--item-details--text---composant"
                                    *ngFor="let composant of composition.composants">
                                    - {{ composant.name }} <p>+{{ composant.price | number : '1.2-2'}} Dhs</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="selected-heading__basket-countainer--content_item-delete-item"
                        (click)="removeItem(item, i)"
                    >x</div>
                </div>
            </div>
            <div class="selected-heading__basket-countainer--bottom">
                <span class="selected-heading__basket-countainer--bottom---text">
                    Total : {{ menuBasketService.totalWithoutPromo() | number : '1.2-2' }} Dhs
                </span>
                <div 
                    class="selected-heading__basket-countainer--bottom---reset-button"
                    (click)="reset()"
                >
                    <img src="../../../assets/images/reset.png" alt="">
                </div>
            </div>

            <div 
                *ngIf="!orderClicked"
                class="selected-heading__basket-countainer--validate"
                (click)="validate()"
            >
                Passer la commande
            </div>

            <div 
                *ngIf="orderClicked && !orderConfirmed"
                class="selected-heading__basket-countainer--confirmation"
                (click)="confirmation()"         
            >
                <div 
                    class="selected-heading__basket-countainer--confirmation---countdown"
                    *ngIf="seconds > 0"
                >
                       <span id="seconds-to-wait">{{ seconds }}</span>
                </div>
                Confirmer
            </div>
            <div 
                *ngIf="orderConfirmed"
                class="selected-heading__basket-countainer--loader"
            >
                <img src="../../../assets/images/local-order-loader.svg" alt="">
            </div>
        </div>
    </div> 
</div>

</div>
