<div class="header__container">



  <div class="header__first-line-container">
    <div 
      #leftPart
      class="header__first-line-container--left"
    >
      <img
          *ngIf="!isListOpened"
          (click)="openList()"
          class="header__first-line-container--left-menu-icon"
          src="../../../assets/images/menu.png"
      />
      <img
        src="../../../assets/images/logo-header.png"
        [routerLink]="['/']"
        class="header__logo-desktop"
      />
    </div>

    <div
      (click)="redirectToMapPage()"
      [ngClass]="{needMarginLeft:isListOpened}"
      class="header__first-line-container--center"
    >
      <div class="header__first-line-container--center-level1">
        <span 
          #textAlignShouldBeLeft
          class="header__first-line-container--center-level1-text">
          Livrer à
        </span>
      </div>

     <div
      #justifyContentShouldBeStart
      class="header__first-line-container--center-level2">
      <span 
        *ngIf="basketService.getLocation().isEmpty(); else currentLocation"
        class="header__first-line-container--center-level2-text">
        <!--Me localiser-->
        Cliquez pour vous localiser
      </span>
      <ng-template #currentLocation>
        <span
          *ngIf="!basketService.getLocation().isEmpty() && basketService.getLocation().mainAddress"
          class="header__first-line-container--center-level2-text"
        >
        {{ getMainAddress() }} 
        </span>
        <span
         *ngIf="!basketService.getLocation().isEmpty() && !basketService.getLocation().mainAddress"
          class="header__first-line-container--center-level2-text"
        >
        Localisation actuelle
      </span>
      </ng-template>
      <img src="../../../assets/images/send.png" class="header__first-line-container--center-level2-down-icon">
     </div>
        
    </div>

    <div
      *ngIf="currentUrl !== 'restaurant'"
      class="header__first-line-container--right"
    >

    <!--
      // They don't want basket icon anymore
      <div class="header__button--cart">
        <img
          *ngIf="currentUrl !== 'suivi-commande'"
          [routerLink]="['/panier']"
          class="header__button--cart-image"
          [src]="
            basketService.count()
              ? '../../../assets/images/cartfD.png'
              : '../../../assets/images/cartf.png'
          "
        />

        <span
          *ngIf="basketService.count()"
          class="header__button--cart-count"
          >{{ basketService.count() }}</span
        >
      </div>
          
          

    -->

    <!-- Toggle Search and Cancel Search For Search Restaurants   -->
        <img
        *ngIf="(isInRestaurantsPage || isInSearchRestaurantsPage ) && !__sharedDataService.showSearchFieldToSearchForARestaurant" 
          (click)="toggleSearchFieldOfRestaurants()"
          class="header__first-line-container--right-search-icon"
          src="../../../assets/images/search.png"
          [ngClass]="{'removeThisElement':isListOpened}"
        />

        <img
        *ngIf="(isInRestaurantsPage || isInSearchRestaurantsPage ) && __sharedDataService.showSearchFieldToSearchForARestaurant" 
          (click)="toggleSearchFieldOfRestaurants()"
          class="header__first-line-container--right-search-icon cancel"
          src="../../../assets/images/cancel.png"
          [ngClass]="{'removeThisElement':isListOpened}"
        />

        <app-search-field
          *ngIf="__sharedDataService.showSearchFieldToSearchForARestaurant && !isListOpened && (isInRestaurantsPage || isInSearchRestaurantsPage)"
          [ngClass]="{'showSearchFieldWithAnimation': __sharedDataService.showSearchField}"
          class="home-page__search-field"
          [searchFieldValue]="searchFieldValue"
        >
        </app-search-field>

 

    <!-- Toggle Search and Cancel Search For Search In A restaurant page-->
        
          <img
          *ngIf=" isInARestaurantPage &&  !__sharedDataService.showSearchFieldToSearchInARestaurantPage " 
            (click)="toggleSearchFieldOfRestaurantPage()"
            class="header__first-line-container--right-search-icon"
            src="../../../assets/images/search.png"
            [ngClass]="{'removeThisElement':isListOpened}"
          />

          <img
          *ngIf=" isInARestaurantPage && __sharedDataService.showSearchFieldToSearchInARestaurantPage " 
            (click)="toggleSearchFieldOfRestaurantPage()"
            class="header__first-line-container--right-search-icon cancel"
            src="../../../assets/images/cancel.png"
            [ngClass]="{'removeThisElement':isListOpened}"
            (click)="clearSearch()"
          />

          <app-search-field
          *ngIf="__sharedDataService.showSearchFieldToSearchInARestaurantPage && !isListOpened && isInARestaurantPage"
          [ngClass]="{'showSearchFieldWithAnimation': __sharedDataService.showSearchField}"
          class="home-page__search-field"
          [searchFieldValue]="searchFieldValue"
          >
          </app-search-field>

        <img
          (click)="closeList()"
          *ngIf="isListOpened"
          class="header__first-line-container--right-cancel-icon"
          src="../../../assets/images/cancel.png"
        />

      <div
        (click)="openMenu()"
        *ngIf="menuState"
        class="header__menu__container"
      >
        <div [routerLink]="['/devenir-partenaire']" class="header__menu--btn">
          Devenir partenaire
        </div>
        <div
          (click)="openMenu()"
          [routerLink]="['/devenir-livreur']"
          class="header__menu--btn"
        >
          Devenir livreur
        </div>
      </div>
      <!-- <span class="header__button-text">&nbsp;Menu</span> -->

      <!-- <mat-menu
        #menu="matMenu"
        xPosition="before"
        yPosition="below"
        class="header__drop-down__container"
      >
        <button [routerLink]="['/devenir-partenaire']" mat-menu-item>
          <span>Devenir partenaire</span>
        </button>
        <button [routerLink]="['/devenir-livreur']" mat-menu-item>
          <span>Devenir livreur</span>
        </button>
      </mat-menu> -->
    </div>
  </div>

  <!-- <mat-form-field class="header__search-field">
      <mat-label> Rechercher un plat, une spécialté, un restaurant ...</mat-label>
      <input type="text" matInput>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field> -->

  <!-- <mat-form-field class="header__search-field-mobile">
    <mat-label> Rechercher un plat, une spécialté, un restaurant ...</mat-label>
    <input type="text" matInput />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field> -->

  

  
</div>



