import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, range, Subject } from 'rxjs';
import { CommandsService } from 'src/app/services/commands.service';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {
  commands:{prix_total, created_at, status, index?, uuid, logo, restaurant_name}[] = [];
  count:number = 0;
  howManyPage:number = 0;
  start:number = 1;
  end:number = 0;

  constructor(
    private __activatedRoute:ActivatedRoute,
    private __commandsService:CommandsService
  ) { }

  ngOnInit(): void {
    this.__activatedRoute.data
    .subscribe(response=>this.handleResponse(response.uls));
  }

  handleResponse(response){
    this.commands = response.orders;
    this.count = response.count;
  }


  getPaginatorData(event){
    this.__commandsService.getCommands(event.pageIndex)
    .then((response) => {
      this.commands = [];
      this.commands = response.orders
    })
  }

}
