import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { tap } from "rxjs/operators";
import { BasketService } from "./basket.service";
import { HttpClientWithTokenService } from "./http-client-with-token.service";
import { SharedDataService } from "./shared-data.service";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: "root",
})
export class RestaurantService {
  private _restaurantPhone: string;

  constructor(
    private __sharedDataService:SharedDataService,
    private __basketService:BasketService,
    private __httpClientWithTokenService:HttpClientWithTokenService
  ) {}

  addRestaurantNumber(restaurantPhone: string): void {
    this._restaurantPhone = restaurantPhone;
  }

  getRestaurantNumber(): string {
    return this._restaurantPhone;
  }

  setLastRestaurantVisitedSession(restaurantName){
    sessionStorage.setItem("lastRestaurantVisited",restaurantName);
  }

  getAndSetMagicDeliveryAmount(restaurantId):Observable<any>{
    return new Observable(observer => {
      this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
      .pipe(
        tap(data => {          
          if(data){
            if(data.latitude || !this.__basketService.location.isEmpty()){
               this.getMagicDeliveryAmount(restaurantId)
              .pipe(
                tap(amount =>{
                  this.__basketService.setMagicDeliveryAmount(amount.frais_livraison);
                  observer.next(amount.frais_livraison);
                  observer.complete();
                })
              )
              .subscribe();
            }
            else{
              observer.next(0);
              observer.complete();
            }
          }
        })
      )
      .subscribe();
    })
  }

  getMagicDeliveryAmount(restaurantId):Observable<any>{
    if(this.__basketService.getLocation().isEmpty())
      return new Observable((observer:Observer<any>)=>{
        observer.next({frais_livraison:0});
        observer.complete();
      });
    const url = `${environment.apiUrl}magic/apply`;
    const params = new HttpParams().append("restaurantId",restaurantId).append("street",this.__basketService.location.district);
    return this.__httpClientWithTokenService.get(url,params);
  }

}
