import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-popup-location-error-checkout-page',
  templateUrl: './popup-location-error-checkout-page.component.html',
  styleUrls: ['./popup-location-error-checkout-page.component.scss']
})
export class PopupLocationErrorCheckoutPageComponent implements OnInit {
  dialogInfos:{header,msg,
    button1:{
    buttonClass,
    buttonText
    },
    button2:{
    buttonClass,
    buttonText
   }
  };
  onUserClickOnBuutton1:BehaviorSubject<boolean> = new BehaviorSubject(false);
  onUserClickOnBuutton2:BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  ngOnInit(): void {
    this.dialogInfos = this.data;
  }

  onButton1GetClicked(){
    this.onUserClickOnBuutton1.next(true);
  }

  onButton2GetClicked(){
    this.onUserClickOnBuutton2.next(true);
  }

}
