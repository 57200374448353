export class Location {
  constructor(
    public city: string, 
    public district:string,
    public latitude:number,
    public longitude:number,
    public mainAddress,
    public additionalAddressInfos,
    public id:number
    )
    {}

  isEmpty(): boolean {
    return !this.city || !this.district || !this.latitude || !this.longitude;
  }

  isMainAddressEmpty(){
    return !this.mainAddress;
  }

  areAddtionalAddressInfosEmpty(){
    return !this.additionalAddressInfos;
  }
}
