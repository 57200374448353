<!--{{data | json}}-->
<div class="basket-item-dialog__container">
    <div class="basket-item-dialog__top">
        <button
                mat-icon-button
                class="basket-item-dialog__top-close-button"
                [mat-dialog-close]="true"
        >
            <mat-icon class="basket-item-dialog__top-close-button-icon"
            >close
            </mat-icon
            >
        </button>
        <img [src]="data.item.image" class="basket-item-dialog__top-image"/>
    </div>

    <div class="basket-item-dialog__content">
        <div class="basket-item-dialog__lower-zone-name">
            {{ data.item.name | uppercase }}
        </div>

        <mat-form-field appearance="fill" [style.width.%]="80">
            <mat-label>Remarques sur le produit</mat-label>
            <textarea matInput [(ngModel)]="remark"
                      (change)="onRemarkChange($event.target.value)"

                      placeholder="ex : Sans sel, allergie..."
                      *ngIf="!data.item['isReadOnly']">
                      >
      </textarea>
      <textarea matInput [(ngModel)]="remark"
                      (change)="onRemarkChange($event.target.value)"
                      placeholder="ex : Sans sel, allergie..."
                      *ngIf="data.item['isReadOnly']"
                      readonly>
                      placeholder="ex : Sans sel, allergie...">
      </textarea>
        </mat-form-field>

        <button
                *ngIf="!data.item['isReadOnly']"
                mat-stroked-button
                (click)="addRemark()"
                color="primary"
                [style.width.%]="80"
                [style.marginBottom.%]="4"
        >
            Valider
        </button>

        <div class="basket-item-dialog__lower-zone-description">
            {{ data.item.description }}
        </div>

        <!--Compositions-->
        <div *ngIf="data.item.compositions">
            <div *ngFor="let composition of data.item.compositions" class="">
                <div
                        class="basket-item-dialog__content-items-container-composition-name"
                >
                    {{ composition.name }}
                </div>
                <mat-checkbox
                        *ngFor="let i of composition.items"
                        disabled=""
                        [(ngModel)]="i.selected"
                        class="basket-item-dialog__content-items-container-composition-item"
                        color="primary"
                >
                    <div
                            class="
              basket-item-dialog__content-items-container-composition-item-label
            "
                    >
            <span>{{
                i.name.length >= 18 ? i.name.slice(0, 18) + "..." : i.name
                }}</span>
                        <span>{{ i.price }} Dhs</span>
                    </div>
                </mat-checkbox>
            </div>
        </div>

        <!--Déclinaisons-->
        <div *ngIf="data.item.declinations && data.item.declinations.length > 1">
            <mat-radio-group
                    class="basket-item-dialog__content-items-container"
                    disabled
                    [(ngModel)]="data.item.declinations.selectedValue"
            >
                <mat-radio-button
                        *ngFor="let declination of data.item.declinations"
                        class="basket-item-dialog__content-items-container-declination-item"
                        color="primary"
                        [value]="declination"
                >
                    <div
                            class="
              basket-item-dialog__content-items-container-declination-item-label
            "
                    >
                        <span>{{ declination.label }}</span>
                        <span>{{ declination.prix }} Dhs</span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <!--Formule-->
        <!--[(ngModel)]="category.selectedItem"-->
        <div *ngIf="data.item.compositions">
            <!--{{data.item | json}}-->
            <div
                    *ngFor="let category of data.item.compositions"
                    class="basket-item-dialog__content-items-container"
            >
                <div class="basket-item-dialog__content-items-container-formula-name">
                    {{ category.titre }}
                </div>
                <mat-checkbox
                        *ngFor="let i of category.composants"
                        disabled
                        class="basket-item-dialog__content-items-container-formula-label"
                        color="primary"
                        [(ngModel)]="i.selected"
                >
                    <div
                            style="
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 210px;
              font-size: 12px;
            "
                    >
                        <!--&nbsp;&nbsp;&nbsp;&nbsp;-->
                        <span>{{
                            i.name.length >= 18 ? i.name.slice(0, 18) + "..." : i.name
                            }}</span>
                        <span>{{ i.price }} Dhs</span>
                    </div>
                </mat-checkbox>
            </div>
        </div>
    </div>

    <!--<div class="basket-item-dialog__bottom">-->
    <!--<button-->
    <!--mat-button-->
    <!--[mat-dialog-close]="true"-->
    <!--(click)="navigateToRestaurant()"-->
    <!--&gt;-->
    <!--Revisiter le restaurant-->
    <!--</button>-->
    <!--</div>-->
</div>
