import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {Location, ViewportScroller} from "@angular/common";

import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from "@angular/router";
import {BasketService} from "../../services/basket.service";
import {Item} from "../../models/item";
import {BasketItemDialogComponent} from "../basket-page/basket-page-item/basket-item-dialog/basket-item-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";

import {HttpService} from "../../services/http.service";
import {SelectedHeadingComponent} from "./selected-heading/selected-heading.component";
import {LocationPopupService} from "../../services/location-popup.service";
import {RestaurantService} from "../../services/restaurant.service";
import {HoldingDataService} from "../../services/holding-data.service";
import {catchError, filter, pairwise, takeUntil, tap, timeout} from "rxjs/operators";
import {Subject, Subscription, throwError, timer} from "rxjs";
import { PromotionRestaurantsService } from "src/app/services/promotion-restaurants.service";
import { SharedDataService } from "src/app/services/shared-data.service";
import { CheckoutService } from "src/app/services/checkout.service";
import { RestaurantPageService } from "src/app/services/restaurant-page.service";
import { PopupModernLoaderComponent } from "../popup-modern-loader/popup-modern-loader.component";
import { SelectedHeadingItemComponent } from "./selected-heading/selected-heading-item/selected-heading-item.component";

@Component({
  selector: 'app-restaurant-page',
  templateUrl: './restaurant-page.component.html',
  styleUrls: ['./restaurant-page.component.scss'],
})
export class RestaurantPageComponent implements OnInit, OnChanges, AfterViewInit, DoCheck, OnDestroy {
  @Input()
  image: string;

  @Input()
  subImage: string;

  @ViewChild('menuItems') menuItems: ElementRef;
  @ViewChildren(SelectedHeadingComponent, { read: ElementRef })
  components: QueryList<ElementRef>;
  restaurant: any;
  isFamily: boolean;
  families: any;
  faimilyCategories: any = [];
  restaurantStreets: any;
  restaurantDescriptionOpened = false;
  visibleHeading = "Entrées";
  visibleFamilyHeading: any;
  allHeadings = [];
  intersectionObserver: IntersectionObserver;
  seeMoreClicked = false;
  franchise: any;
  produit: any;
  restaurantCategories: any;
  searchResult: any
  searchField: string="";
  nameOfRestaurant: string="";
  utm: string="";
  
  private __subscriptionsArray:Subscription[] = [];
  private subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet:Subject<any>;
  @ViewChild("magicTextRef") private magicTextRef:ElementRef;
  private modernLoaderPopup:MatDialogRef<PopupModernLoaderComponent>;

  getDescription(): string {
    if (!this.seeMoreClicked && this.restaurant.description.length > 100) {
      return this.restaurant.description.substring(0, 100) + ' ...';
    }
    return this.restaurant.description;
  }

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public basketService: BasketService,
    private httpService: HttpService,
    private viewportScroller: ViewportScroller,
    private locationPopupService: LocationPopupService,
    private snackBar: MatSnackBar,
    public restaurantService: RestaurantService,
    private restaurantPageService: RestaurantPageService,
    public holdingDataService:HoldingDataService,
    private __PromotionRestaurantService:PromotionRestaurantsService,
    private __sharedDataService:SharedDataService,
    private __checkoutService:CheckoutService,
  ) {
    this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet = new Subject();



    }

  ngOnChanges(changes: SimpleChanges): void {
 
  }

  getCityName(city): string {
    if (typeof city === 'string') { return city; }
    return city?.name;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.intersectionObserver = new IntersectionObserver(
        (entries, observer) => {
          this.checkForIntersection(entries, observer);
        },
        {
          root: null,
          rootMargin:
            window.innerWidth > 500
              ? '-150px 0px -75% 0px'
              : '-150px 0px -75% 0px',
          threshold: window.innerWidth > 500 ? 0 : 0,
        }
      );
      this.components.forEach((c) =>
        this.intersectionObserver.observe(c.nativeElement)
      );
    }, 5000);
    // setTimeout(() => {this.openLocationDialog()}, 2000);
  }

  private checkForIntersection = (
    entries: Array<IntersectionObserverEntry>,
    observer
  ) => {    
    if (entries[0].isIntersecting === true) {
      this.visibleHeading = this.ejectHeadingId(
        entries[0].target.id,
        'category'
      );

      this.visibleFamilyHeading = this.ejectHeadingId(
        entries[0].target.id,
        'family'
      );

      this.openFamilySubMenu(this.visibleFamilyHeading, true);
    }
  }
  ngOnInit(): void {
    this.nameOfRestaurant = this.route.snapshot.queryParamMap.get('name');
    if(!this.nameOfRestaurant.includes('ghostkitchen')){
      this.router.navigateByUrl('/restaurant?name=ghostkitchen')
    }
    this.utm = this.route.snapshot.queryParamMap.get('utm');
    if(this.utm) this.saveUtmInSessionStorage()
    this.__checkoutService.getLoyaltyReductionUsingUniqueUrl(this.nameOfRestaurant)
    this.__sharedDataService.showSearchFieldToSearchInARestaurantPage = false
    this.__sharedDataService.showSearchFieldToSearchForARestaurant = false
    this.modernLoaderPopup = this.__checkoutService.openModernPopupLoader();
    this.route.queryParams.subscribe(
      d => {
        if(this.restaurant && this.restaurant.franchise) {
          this.onGettingRestaurantData(null);
        }
        this.setLastRestaurantVisitedSession('ghostkitchen');
      }
    );
    this.fetchData();
  }



  setLastRestaurantVisitedSession(restaurantName){
    this.restaurantService.setLastRestaurantVisitedSession(restaurantName);
  }

  checkIfFamily(categories): boolean {
    const families = categories.filter((cat) => cat.family);
    return Boolean(families.length);
  }

  updateRestaurantOpeningState(closed ,openning, closing): void {
    const date = new Date();
    const hours = date.getHours();

    if (openning && closing && !closed) {
      this.basketService.basket.restaurantOpen =
        hours >= Number(openning.slice(0, 2)) &&
        hours <= Number(closing.slice(0, 2));
    } else if(closed) { this.basketService.basket.restaurantOpen = false; }
    else { this.basketService.basket.restaurantOpen = false; }
  }

  formatRestaurantCategories(categories: any): void {
    const families: any = new Set();
    const familiesCategories: any = [];

    categories
      .filter((cat) => cat.family)
      .forEach((cat) => families.add(cat.familyname));

    //
    families.forEach((family) => {
      familiesCategories.push({
        name: family,
        categories: categories.filter((cat) => cat.familyname === family),
        open: false,
        family: false,
      });
    });
    this.families = familiesCategories;
  }

  showRestaurantDescription(): void {
    this.restaurantDescriptionOpened = !this.restaurantDescriptionOpened;
  }

  setSelectedHeading(selectedHeading: string): void {
    let elementById: any = document.getElementById(selectedHeading);
    const isFamilyName = this.families?.find(
      (family) => family.name === selectedHeading
    );

    if (isFamilyName) {
      elementById = document.getElementById(
        this.generateHeadingId(isFamilyName.categories[0])
      );
    }

    if (this.isFamily && isFamilyName) {
      this.openFamilySubMenu(selectedHeading, false);
    }

    if (elementById && this.families && this.families?.length > 0) {
      let top = elementById.offsetTop;
      if(this.WhereTheUserWannaScrollTo(selectedHeading)=="top")  window.scrollTo({top:(top-165),behavior:"smooth"});
      else window.scrollTo({top:(top-170),behavior:"smooth"});
    }
    else if (elementById) {
      let top = elementById.offsetTop;
      if(this.WhereTheUserWannaScrollTo(selectedHeading)=="top")  window.scrollTo({top:(top-60),behavior:"smooth"});
      else window.scrollTo({top:(top-70),behavior:"smooth"});
    }

    if (isFamilyName) {
      // setTimeout(() => {
      this.visibleFamilyHeading = selectedHeading;
      // }, 600);
    } else {
      // setTimeout(() => {
      this.visibleHeading = this.ejectHeadingId(selectedHeading, 'category');
      // }, 600);
    }
  }

  scrollRight(): void {
    this.menuItems.nativeElement.scrollBy({
      left: 150,
      behavior: 'smooth',
    });
  }

  downloadCatalogue(): void {
    window.open(
      `https://lalivraison.ma/assets/catalogues/${this.restaurant.catalogue}`,
      '_blank'
    );
  }

  generateHeadingId(heading: any): string {
    if (this.isFamily) { 
      return `${heading.cat_name}*${heading.familyname}`; }
    return heading.cat_name;
  }

  ejectHeadingId(heading: string, element: 'family' | 'category'): string {
    if (element === 'family') { return heading.split('*')[1]; }
    if (element === 'category') { return heading.split('*')[0]; }
  }

  openFamilySubMenu(selectedHeading: string, state: boolean): void {
    this.families = this.families?.map((family) => {
      if (family.name === selectedHeading) {
        return {
          ...family,
          open: state ? state : !family.open,
          family: true,
        };
      }
      return {
        ...family,
        open: false,
        family: true,
      };
    });
    const openFamily = this.families?.filter((family) => family.open);
    if (openFamily?.length) {
      this.faimilyCategories = openFamily[0].categories;
    } else {
      this.faimilyCategories = [];
    }
  }

  scrollLeft(): void {
    this.menuItems.nativeElement.scrollBy({
      left: -150,
      behavior: 'smooth',
    });
  }

  isDelivery(): boolean {
    return this.basketService.getService() === 'Delivery';
  }

  getDeliveryPrice(): number {
    return this.basketService.basket.deliveryPrice;
  }

  getMinimumPrice(): number {
    return this.basketService.basket.minimumPrice;
  }

  minimumIsRequired(): void {
    this.snackBar.open(
      'Le minimum de commande est',
      `${this.getMinimumPrice()} DH`,
      {
        duration: 2000,
      }
    );
  }

  isReachedMinimum(): boolean {
    return this.basketService.isReachedMinimum();
  }


  validateBasket(): void {
    const nameOfRestaurant = this.route.snapshot.queryParamMap.get('name');
   if(this.restaurant.ferme && !this.restaurant.pre_commande){
     this.openSnackBar("Le restaurant est fermé temporairement","restaurant-is-close",2000);
     return;
   }
   if(this.isDelivery()){
      if (!this.isReachedMinimum()) {
        if(this.basketService.isMagicDelivryActiveInRestauarant) this.openPopupInformUserAboutWizardDeliveryWithRedirection();
        else return this.minimumIsRequired();
      }
      else this.router.navigate(['/checkout'], { queryParams: {name: nameOfRestaurant} });
   }
   else this.router.navigate(['/checkout'], { queryParams: {name: nameOfRestaurant} });
  }

 
  incrementItemCount(item: Item, index: number): void {
    this.basketService.incrementItemCount(item, index);
    this.basketService.switchServiceAutomaticallyBetweenMagicAndDelivery(this.restaurantStreets);
  }

  decrementItemCount(item: Item , index: number): void {
    this.basketService.decrementItemCount(item, index);
    this.basketService.switchServiceAutomaticallyBetweenMagicAndDelivery(this.restaurantStreets);
  }

  removeItem(item: Item, index: number): void {
    this.basketService.removeItem(item, index);
    this.basketService.switchServiceAutomaticallyBetweenMagicAndDelivery(this.restaurantStreets);
  }

  showDialogDetails(selectedItem: Item): void {
    selectedItem["isReadOnly"]=false
    this.dialog.open(BasketItemDialogComponent, {
      height: window.innerWidth < 500 ? '85%' : '740px',
      width: window.innerWidth < 500 ? '85%' : '400px',
      autoFocus: false,
      data: {
        item: selectedItem,
      },
      panelClass:"popupNeedRaduis"
    });
  }

  openLocationDialog(): void {
    this.locationPopupService.open();
  }

  isMagicDelivery(){
    return this.basketService.isServiceMagicDelivery();
  }

  setService(service: string): void {
    if(
      (service == "Delivery" && this.basketService.basket.hasDelivry)
      || 
      (service == "Takeaway" && this.basketService.basket.hasTakeway)
    )
    this.basketService.setService(service);
  }

  disableButton(): boolean {
    return !this.basketService.count();
  }

  onServiceChange(service: string): void {
    this.setService(service);
    this.onGettingRestaurantStreets(null);
  }

  handle(event: any): void {
    this.visibleHeading = event;
  }

  isVisible(headingName): boolean {
    if (this.visibleHeading === headingName) {
      this.viewportScroller.scrollToAnchor(headingName);
      return true;
    }
    return false;
  }

  highlightTitle(headingName: string): boolean {
    return headingName === this.visibleHeading;
  }

  ngDoCheck(): void {
    const activeElement = window.document.getElementById(
      `${this.visibleHeading}ID`
    );
  }

  getRestaurantPhoneLink(): string {
    return `tel:+212${this.restaurant.num_telephone}`;
  }

  handleFranchideRestauarant(): void {
   const city = this.route.snapshot.queryParamMap.get('city');
   const district = this.route.snapshot.queryParamMap.get('district');
   if (!city || !district) {
    this.httpService.getRestaurantFranchises(this.restaurant.id)
    .subscribe(result => {      
      this.franchise = result;
      if(this.basketService.getLocation().isEmpty()) {
        this.openLocationDialog();
      }
      else {
        this.redirectionToFranchiseRestauarantPage();
      }
    });
  } else {
    this.httpService.getRestaurantFranchises(this.restaurant.id)
    .subscribe(result => {
      this.franchise = result;
      const quartier = this.franchise[city]?.quartiers?.find(
        quartierElement => quartierElement.nom_quartier.includes(district));
      if(!quartier && this.basketService.getService()!="Takeaway"){                
        this.isRestaurantDoesNotDeliverToUserLocation();
        this.openPopupInformUser();
      }
      else this.redirectionToFranchiseRestauarantPage();
    });
  }
  }

  redirectionToFranchiseRestauarantPage(){
    const city = this.route.snapshot.queryParams["city"];
    const district = this.route.snapshot.queryParams["district"];
    if(this.basketService.getLocation().isEmpty()){
      this.basketService.getLocation().city = city;
      this.basketService.getLocation().district = district;
      this.basketService.setHasDelivry(true);
      return;
    }
    let quartier = null;      
    const choosedStreet = this.franchise[this.basketService.getLocation().city]?.quartiers?.find(
      quartierElement => quartierElement.nom_quartier.includes(this.basketService.getLocation().district));
    if(choosedStreet) quartier = choosedStreet;
    if(quartier) {
      this.basketService.setHasDelivry(true);
      const restaurantUrl = quartier.url_unique;
      const location = this.basketService.getLocation();
      if(city==location.city && district==location.district)  return;
      this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/restaurant'],
        { queryParams: {name: restaurantUrl, city: this.basketService.getLocation().city,
            district: this.basketService.getLocation().district}});
    });
    }
    else {
      this.isRestaurantDoesNotDeliverToUserLocation();
      this.openPopupInformUser();
    }
  }

  private fetchData(): void {
    const id = this.route.snapshot.queryParamMap.get('name');
    this.httpService
    .getRestaurant(id, '11')
    .pipe(
        catchError((err) => {
          this.router.navigate(['/']);
          return throwError(err);
        })
    )
    .subscribe((restaurant) => this.checkingIfMagicDeliveryIsActive(restaurant));
  }

  checkingIfMagicDeliveryIsActive(restaurant){
    this.basketService.isMagicDelivryActiveInRestauarant = restaurant["isMagicDeliveredActive"];
    if(restaurant["isMagicDeliveredActive"]){
      this.restaurantService.getAndSetMagicDeliveryAmount(restaurant.id)
      .pipe(tap(amount=> {
        this.onGettingRestaurantData(restaurant)
      }))
      .subscribe();
    }
    else this.onGettingRestaurantData(restaurant);
  }

  onGettingRestaurantData(restaurant){

    this.route.queryParamMap.subscribe(params => {
      this.searchField = params.get('search')
      const filter = (term, obj) => {
        const res = [];
        for (let i = 0; i < obj.categories.length; i++) {
          const newProds = obj.categories[i].produits.filter((prod) => {
            if (prod.designation.toLowerCase().includes(term.toLowerCase())) {
              return true;
            }
          });
          if (newProds.length > 0) {
            res.push({ ...obj.categories[i], produits: newProds });
          }
        }
      
        return res;
      };
      if(this.searchField) this.searchResult = filter(this.searchField, restaurant);
      else this.searchResult = filter("", restaurant);
      
      this.restaurant = restaurant 
      this.restaurantCategories = this.searchResult
    })
    
      if(!this.restaurant) return;
      this.modernLoaderPopup.close();
      this.basketService.resetBasket( this.restaurant.id);
      if (this.restaurant.franchise) {
        let observableRef = this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject().asObservable()
          .subscribe( data => {
            if(data) {
              this.handleFranchideRestauarant();
            }
          }); 
          this.__subscriptionsArray.push(observableRef);
      }
      this.basketService.setServiceCharge(this.restaurant.frais_service);
      this.basketService.setIsOnlinePaymentAllowed(this.restaurant.cb);
      this.basketService.setIsCashPaymentAllowed(this.restaurant.espece);
      this.basketService.setHasTakeway(this.restaurant.emporter);
      this.restaurantService.addRestaurantNumber(this.restaurant.num_telephone);
      this.updateRestaurantOpeningState(
           this.restaurant.ferme,
           this.restaurant.heure_ouverture,
           this.restaurant.heure_fermeture
      );
      if (this.checkIfFamily(this.restaurant?.categories)) {
        this.formatRestaurantCategories(this.restaurant.categories);
        this.isFamily = true;
      }
      let index = 0;
      this.allHeadings = this.restaurant.categories.map((heading) => {
        return { name: heading.cat_name, index: index++, ratio: 0 };
      });
      this.visibleHeading = this.restaurant.categories[0].cat_name; 
      this.httpService
      .getRestaurantStreets(this.restaurant.id)
      .subscribe((restaurantStreets) => {
        //this.onGettingRestaurantStreets(restaurantStreets);
        if(this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedAttribute()) 
          this.onGettingRestaurantStreets(restaurantStreets);
        else{
          let observableRef =  this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject().asObservable()
          .subscribe( data => {
          if(data) this.onGettingRestaurantStreets(restaurantStreets)
          });
          this.__subscriptionsArray.push(observableRef);
        }
      });
  }

  onGettingRestaurantStreets(restaurantStreets){
    this.restaurantStreets = restaurantStreets || this.restaurantStreets;
    if (
        this.basketService.getLocation().city &&
        this.basketService.getLocation().district
    ) {
      const street = this.restaurantStreets.quartier.find(
          ({nom_quartier}) => nom_quartier.includes(this.basketService.getLocation().district)
      );
        if(street){
          this.basketService.setHasDelivry(true);
          this.basketService.basket.minimumPrice = Number(street.min_commande);
          if(this.basketService.isServiceDelivery()) this.basketService.basket.deliveryPrice = Number(
            street.frais_de_livraison
            );
          else if(this.basketService.isServiceMagicDelivery()){
                if(!this.basketService.isReachedMinimum()) 
                  this.basketService.setDeliveryPrice(this.basketService.magicDeliveryAmount); 
                else{
                  this.setService("Delivery");
                  this.basketService.basket.deliveryPrice = Number(
                    street.frais_de_livraison
                    );
                }              
          }

          this.basketService.setMinimumPrice(street.min_commande);
          if(
            this.__sharedDataService.wasPreviousPageTheMap() &&
            !this.basketService.isServiceMagicDelivery()
            ) 
            this.basketService.setService("Delivery");
          
        }
        else {
          if(!this.restaurant.franchise){
            this.isRestaurantDoesNotDeliverToUserLocation();
            if(!this.basketService.getService() ||  !this.basketService.isServiceTakeAway()) this.openPopupInformUser();
          }
        }
    }
  }

  saveUtmInSessionStorage(){
    sessionStorage.setItem('utm', this.utm)
  }

  redirectUserToMapPage(){
    this.router.navigate(["map"]);
  }

  redirectUserToHomePage(){
    this.router.navigate([""]);
  }

  WhereTheUserWannaScrollTo(nextCategory):string{
    let indexOfCurrentCategory = this.restaurant.categories.findIndex(e => e.cat_name==this.visibleHeading.split("*")[0]);
    let indexOfNextCategory = this.restaurant.categories.findIndex(e => e.cat_name==nextCategory.split("*")[0]);
    if(indexOfNextCategory<indexOfCurrentCategory) return "top";
    else return "bottom";
  }

  getTotalPriceAfterapplyingReduction(){
    return this.basketService.getTotalPriceAfterapplyingReduction();
  }

  openSnackBar(message: string, className:string, duration:number, action?: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = [className];
    config.duration = duration;
    config.horizontalPosition = "center";
    this.snackBar.open(message, action ? "Action Label" : undefined, config);
  }

  openPopupInformUser(){
    let text = "Malheuresement, nous ne livrons pas dans votre quartier";
    if(!this.basketService.basket.hasTakeway) text+=", et la commande à  emporter n'est pas disponible."
    const data={
      imgSrc:"deliver-user.png",
      text,
      btn1Text:"Modifiez votre adresse",
      btn2Text:`${this.basketService.basket.hasTakeway ? "Commande à emporter" : "Changez de restaurant"}`,
      btn1Fun:()=>{
        this.redirectUserToMapPage();
      },
      btn2Fun:()=>{
        if(this.basketService.basket.hasTakeway) this.setService("Takeaway");
        else this.redirectUserToHomePage();
      },
      subjectToUnsubscribeFromObservables:this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet
    };
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }

  isRestaurantDoesNotDeliverToUserLocation(){
    if(this.basketService.isServiceDelivery()) this.basketService.setService(null);
    this.basketService.setHasDelivry(false);
  }

  openPopupInformUserAboutWizardDeliveryWithoutRedirection(){
    this.restaurantPageService.openPopupInformUserAboutWizardDeliveryWithoutRedirection(
      this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet
      );
  }

  openPopupInformUserAboutWizardDeliveryWithRedirection(){
    this.restaurantPageService.openPopupInformUserAboutWizardDeliveryWithRedirection(
      this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet
      );
  }

  


  ngOnDestroy(){
    this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet.next("random value");
    this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet.complete();
    this.__subscriptionsArray.forEach(subElement=>subElement.unsubscribe());
  }

}
