<div 
class="command-container">

 <!--  <div class="command-container-first-level">
        <img [src]="command.logo" class="command-container-first-level-command-img">
        <span 
        [ngStyle]="{backgroundColor:getStatusBackgrounColor(command.status)}" 
        class="command-container-first-level-command-status"> 
        {{command.status}} 
        </span>
    </div>

    <div class="command-container-second-level">
        <span class="command-container-second-level-command-date"> {{command.created_at}} </span>
        <span class="command-container-second-level-command-price"> {{command.prix_total}} DH</span>
    </div>
-->

<div class="command-container-first-column">
    <img [src]="command.logo" class="command-container-first-column-restaurant-img">
</div>

<div class="command-container-second-column">
    <span class="command-container-second-column-first-element"> {{command.restaurant_name}} </span>
    <span 
    [ngStyle]="{'color':getStatusColor()}"
    class="command-container-second-column-second-element"> {{command.status}} </span>
    <span class="command-container-second-column-third-element"> {{command.created_at}} </span>
    <span class="command-container-second-column-fourth-element">
         <span>
            {{command.prix_total}} Dh
         </span>
         <button 
         [ngStyle]="{'background':getStatusColor()}"
         (click)="redirectionToSuiviDeCommandePage()"
         class="command-container-second-column-fourth-element-btn-details">
            Voir le details
        </button>
        </span>
</div>




</div>
