import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MakeanorderService {

  constructor(
    private http: HttpClient,
  ) { }

  getRestaurantsTables(urlUnique: string): Observable<any[]> {
    const url = `${environment.apiUrlLacaisse}getTablesurl.php`;
    let params:HttpParams = new HttpParams().append("url_unique", urlUnique)
    return this.http.get<string[]>(url,{params});
  }
 
}