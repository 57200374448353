<div class="selected-heading-item__container">
  <!-- <img [src]="headingItem.image.endsWith('jpg') ? headingItem.image : '../../../assets/images/dish.jpeg'" -->
  <div *ngIf="
          headingItem.quantite_stock==0 
          || headingItem.declinaisons.length <=1 && headingItem.quantite_stock <= sharedData.getTotalOfAnItemInTheBasket(headingItem.id_produit) && headingItem.quantite_stock != -1
          || headingItem.declinaisons.length >1 && sharedData.getStockOfDeclinationWithTheBiggestStock(headingItem) <= sharedData.getTotalOfAnItemInTheBasket(headingItem.id_produit) && headingItem.quantite_stock != -1"
       class="sold-out">
       Rupture de stock
  </div>

  <div class="selected-heading-item__container-promo"
      *ngIf="headingItem.prixpromo != 0"
  >
    -{{ 100 - (100 / headingItem.prix  * headingItem.prixpromo)  |number:'0.0-1' }}%
  </div>

  <img
    [src]="headingItem.image"
    class="selected-heading-item__upper-zone"
    (click)="showItemDetails()"
  />


  <div class="selected-heading-item__lower-zone">
    <div class="selected-heading-item__lower-zone-name">
      {{ headingItem.designation | uppercase }}
    </div>
    <div class="selected-heading-item__lower-zone-price">
      <span class="selected-heading-item__price" style="display: flex; align-self: center">
        <div 
          *ngIf="headingItem.prixpromo != 0 && headingItem.prixpromo != headingItem.prix"
        >
          {{ getPriceWithPromo(headingItem) }} 
            <span *ngIf="headingItem.prix !== null">Dhs &nbsp;</span>
        </div>
        
        <div
          [ngClass]="{'promoPriceActive': headingItem.prixpromo != 0 && headingItem.prixpromo != headingItem.prix}"
        >
            {{ getTheLowestPriceOfHeadingItem(headingItem) }}
            <span *ngIf="headingItem.prix !== null">Dhs</span>
        </div>
      </span>
      <button 
        type="button"   
        class="selected-heading-item__add-item" (click)="addToBasket()" 
        [disabled]="headingItem.quantite_stock==0 
                 || headingItem.declinaisons.length <=1 && headingItem.quantite_stock <= sharedData.getTotalOfAnItemInTheBasket(headingItem.id_produit) && headingItem.quantite_stock != -1
                 || headingItem.declinaisons.length >1 && sharedData.getStockOfDeclinationWithTheBiggestStock(headingItem) <= sharedData.getTotalOfAnItemInTheBasket(headingItem.id_produit) && headingItem.quantite_stock != -1" 
      >
        <mat-icon [ngClass]="{
          'disabled': 
                    headingItem.quantite_stock==0 
                 || headingItem.declinaisons.length <=1 && headingItem.quantite_stock <= sharedData.getTotalOfAnItemInTheBasket(headingItem.id_produit) && headingItem.quantite_stock != -1
                 || headingItem.declinaisons.length >1 && sharedData.getStockOfDeclinationWithTheBiggestStock(headingItem) <= sharedData.getTotalOfAnItemInTheBasket(headingItem.id_produit) && headingItem.quantite_stock != -1
        }"
          >add_circle</mat-icon> 
      </button>
    </div>
  </div>
</div>
