<div class="signup">
    <div class="signup-welcome">
        S'inscrire
    </div>
    <div class="signup__container">
        <div class="signup__header">
            <div class="signup__header-msg">Bienvenue sur THE BOX</div>
            <div class="signup__header-description">Renseignez vos informations pour créer votre compte</div>
        </div>
        <form [formGroup]="__formGroup" class="signup__form" #formDirective="ngForm">
            <div class="signup__form-fullName">
                    <mat-form-field class="signup__form-fullName-firstName">
                        <mat-label class="signup__form-fullName-firstName-margin">
                          Saisissez votre prenom</mat-label
                        >
                        <input
                          formControlName="prenom"
                          class="signup__form-fullName-firstName-field-margin"
                          type="text"
                          matInput
                        />
                        <mat-error *ngIf="__formGroup.get('prenom').invalid">
                          {{ getErrorMessage("prenom") }}
                        </mat-error>
                        <mat-icon matSuffix>person</mat-icon>
                      </mat-form-field>
        
                      <mat-form-field class="signup__form-fullName-lastName">
                        <mat-label class="signup__form-fullName-lastName-margin">
                          Saisissez votre nom</mat-label
                        >
                        <input
                          formControlName="nom"
                          class="signup__form-fullName-lastName-field-margin"
                          type="text"
                          matInput
                        />
                        <mat-error *ngIf="__formGroup.get('nom').invalid">
                            {{ getErrorMessage("nom") }}
                        </mat-error>
                        <mat-icon matSuffix>person</mat-icon>
                      </mat-form-field>
        
            </div>
        
            <div class="signup__form-second-element">
                <mat-form-field class="signup__form-second-element-email">
                    <mat-label class="signup__form-second-element-email-margin">
                      Saisissez votre E-mail</mat-label
                    >
                    <input
                      formControlName="email"
                      class="signup__form-second-element-email-field-margin"
                      type="text"
                      matInput
                    />
                    <mat-error *ngIf="__formGroup.get('email').invalid">
                        {{ getErrorMessage("email") }}
                    </mat-error>
                    <mat-icon matSuffix>alternate_email</mat-icon>
                  </mat-form-field>
            
                  <mat-form-field class="signup__form-second-element-phone">
                    <mat-label class="signup__form-second-element-phone-margin">
                      Saisissez votre téléphone</mat-label
                    >
                    <input
                      formControlName="telephone"
                      class="signup__form-second-element-phone-field-margin"
                      type="tel"
                      matInput
                    />
                    <mat-error *ngIf="__formGroup.get('telephone').invalid">
                        {{ getErrorMessage("telephone") }}
                    </mat-error>
                    <mat-icon matSuffix>phone</mat-icon>
                  </mat-form-field>
            </div>
        
            <div class="signup__form-third-element">
                <mat-form-field class="signup__form-third-element-password">
                    <mat-label class="signup__form-third-element-password-margin">
                      Saisissez votre mot de passe</mat-label
                    >
                    <input
                      (keyup)="checkIfPassworsAreEquals()"
                      #password
                      formControlName="password"
                      class="signup__form-third-element-password-field-margin"
                      type="password"
                      matInput
                    />
                    <mat-error *ngIf="__formGroup.get('password').invalid">
                        {{ getErrorMessage("password") }}
                    </mat-error>
                    <mat-icon
                    (click)="showThePassword(password,'password')" 
                    *ngIf="!isTheAppShowingPassword"
                    style="cursor: pointer;" 
                     matSuffix>remove_red_eye</mat-icon>
                     <mat-icon
                     (click)="hideThePassword(password,'password')"
                      *ngIf="isTheAppShowingPassword"
                      style="cursor: pointer;"  
                      matSuffix>
                      visibility_off</mat-icon> 
                  </mat-form-field>
            
                  <mat-form-field class="signup__form-third-element-confirmPassword">
                    <mat-label class="signup__form-third-element-confirmPassword-margin">
                      Confirmez votre mot de passe</mat-label
                    >
                    <input
                      #confirmPassword 
                      formControlName="confirmPassword"
                      class="signup__form-third-element-confirmPassword-field-margin"
                      type="password"
                      matInput
                    />
                    <mat-error>
                        {{ getErrorMessage("confirmPassword") }}
                    </mat-error>
                    <mat-icon
                    (click)="showThePassword(confirmPassword,'confirmPassword')" 
                    *ngIf="!isTheAppShowingConfirmPassword"
                    style="cursor: pointer;" 
                     matSuffix>remove_red_eye</mat-icon>
                     <mat-icon
                     (click)="hideThePassword(confirmPassword,'confirmPassword')"
                      *ngIf="isTheAppShowingConfirmPassword"
                      style="cursor: pointer;"  
                      matSuffix>
                      visibility_off</mat-icon> 
                  </mat-form-field>
            </div>
        
            <div class="signup__form-button-container">
                <button
                [hidden]="loading" 
                (click)="onSubmit()"
                class="signup__form-button-container-btn"
                [disabled]="!__formGroup.valid"
                >
                    S'inscrire 
                    <mat-icon>touch_app</mat-icon>
                </button>
                <app-loading [hidden]="!loading"></app-loading>
            </div>

                <div class="signup__form-join-us">
                    <div class="signup__form-join-us-text">
                        Vous avez déjà un compte ?
                    </div>

                    <div class="signup__form-join-us-link">
                        <a style="cursor: pointer;" (click)="redirectMeToSignin()">Se connecter</a>
                    </div>
                </div>

        </form>
        </div>
        
</div>


<div class="footer">
  <app-about-zone></app-about-zone>
</div>