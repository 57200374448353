<div class="container-command-details">

    <div class="container-command-details-cancel">
       <div class="container-command-details-cancel-text">
        < Retour
       </div>
    </div>

    <div class="container-command-details-header">
       <div class="container-command-details-header-text">
        Ma commande
       </div>
    </div>

    <div class="container-command-details-items-container">
       <div 
       *ngFor="let item of commandDetails?.items"
       class="container-command-details-items-container-item">

        <div class="container-command-details-items-container-item-picture">

        </div>

        <div class="container-command-details-items-container-item-count">
            {{item.count}} x
        </div>

        <div class="container-command-details-items-container-item-title">
            {{item.title}}
        </div>

        <div class="container-command-details-items-container-item-price">
            {{item.price}} DH
        </div>
       </div> 
    </div>

    <div class="container-command-details-services-container">
        <div class="container-command-details-services-container-service-charges">
            <div class="container-command-details-services-container-service-charges-picture">
                <span class="container-command-details-services-container-service-charges-picture-logo">
                    <mat-icon>motorcycle</mat-icon>
                </span>
            </div>
            <div class="container-command-details-services-container-service-charges-title">
                Frais de livraison
            </div>
            <div class="container-command-details-services-container-service-charges-price">
                {{ commandDetails.frais_livraison }} DH
            </div>
        </div>

        <div class="container-command-details-services-container-delivry-charges">
            <div class="container-command-details-services-container-delivry-charges-picture">
                <span class="container-command-details-services-container-delivry-charges-picture-logo">
                    <mat-icon>style</mat-icon>
                </span>
            </div>
            <div class="container-command-details-services-container-delivry-charges-title">
                Frais de service
            </div>
            <div class="container-command-details-services-container-delivry-charges-price">
                {{ commandDetails.frais_service }} DH
            </div>
        </div>
     </div>

     <div class="container-command-details-total">
        <div class="container-command-details-total-container">
            <div class="container-command-details-total-container-text">
                Total
            </div>
            <div class="container-command-details-total-container-price">
                155 DH
            </div>
        </div>
        <div class="container-command-details-total-hr"></div>
     </div>

</div>

<div class="footer">
    <app-about-zone></app-about-zone>
</div>