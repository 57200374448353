import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { pluck, takeUntil, tap } from 'rxjs/operators';
import { PopupConfirmUserChoiceComponent } from '../components/checkout-page/popup-confirm-user-choice/popup-confirm-user-choice.component';
import { PopupModernLoaderComponent } from '../components/popup-modern-loader/popup-modern-loader.component';
import { BankCardsService } from './bank-cards.service';
import { BasketService } from './basket.service';
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { RestaurantService } from './restaurant.service';
import { SharedDataService } from './shared-data.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ThrowStmt } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService implements Resolve<Promise<any> | null>{

  constructor(
    private __httpClientWithTokenService:HttpClientWithTokenService,
    private basketService:BasketService,
    private router:Router,
    private __bankCardService:BankCardsService,
    private __basketService:BasketService,
    private __sharedDataService:SharedDataService,
    private __dialog:MatDialog,
    private __restaurantService:RestaurantService,
    private __authService:AuthService
  ) 
  { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<any> | null{
    if(this.basketService.count()==0) {
      this.router.navigate([""]);
      return null;
    }
    else{
      return new Promise(
        (resolve,reject)=>{
          Promise.all([
            this.getUserInfos(),
            this.getUserActivateCardsNumber(),
            this.checkCommandValidity(),
            this.getMagicDeliveryAmount(),
            this.getLoyaltyReduction()
          ])
          .then(response=>{
            resolve(response)
          });
        }
      );
    }
  }

  private getUserInfos():Promise<any>{
    let uuid = this.decodeToken()["uuiduser"];
    let url:string = `${environment.apiUrl}user/profile.php/${uuid}`;
    let params = {};
    return new Promise(
      (resolve,reject)=>{
        this.__httpClientWithTokenService.get(url,params)
        .subscribe(
          data=>resolve(data)
        )
      }
    )
  }

  checkCommandValidity():Promise<any>{
    return new Promise((resolve,reject) => {
      const url = `${environment.apiUrl}checkout/checkUserLocation`;
      const params = {restaurantId:null,city:null, street:null};
      this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
      .pipe(
        tap(res => {
          if(res){
            /*
            if(this.basketService.getLocation().isEmpty()){
              this.router.navigate(["map"]);
              resolve("");
            }
            */
            if(this.basketService.getLocation().isEmpty()){
              resolve("");
              return;
            }
            params.restaurantId = this.basketService.basket.items[0].restaurant_id;
            params.city = this.basketService.location.city;
            params.street = this.basketService.location.district;
            this.__httpClientWithTokenService.get(url,params)
            .pipe(tap(_res => {
              resolve(_res);
            }))
            .subscribe();
          }
        })
      )
      .subscribe();
    });
  }

  private getUserActivateCardsNumber():Promise<any>{
    return new Promise((resolve,reject)=>{
      this.__bankCardService.getUserCards()
      .then(response => {
        if(response.cards) resolve(response.cards.filter(e => e.etat == 1).length);
        else resolve([]);
      });
    })
  }

  private getToken(){
    let userInfos = null;
    if(localStorage.getItem("user_infos")) userInfos = JSON.parse(localStorage.getItem("user_infos"));
    else return null;
    return userInfos["token"];
  }

  private decodeToken(){
    let token = this.getToken();
    if(!token) return null;
    return JSON.parse(atob(token.split('.')[1]));
  }

  createSessionToHshwoSuccessPopupForSucessefulPayment(commandeId){
    if(commandeId) sessionStorage.setItem("commandeId",commandeId);
  }

  createSessionItemForRestaurantName(restaurantName){
    if(restaurantName) sessionStorage.setItem("restaurantName",restaurantName);
  }

  createNecessarySessionForOnlinePayement(commandeId){
    this.createSessionToHshwoSuccessPopupForSucessefulPayment(commandeId);
  }

  validatePromoCode(promoCode:string):Observable<any>{
    let url = `${environment.apiUrl}promo/validateur`;;
    let body = {
      code:promoCode,
      restaurant_id: this.__basketService.basket.items[0].restaurant_id
    }
    return this.__httpClientWithTokenService.post(url,body);
  }
  
  openPopupConfirmUserChoice({imgSrc,text,btn1Text,btn2Text,btn1Fun,btn2Fun,subjectToUnsubscribeFromObservables}){
    const popupConfigs = {
      panelClass:'popup-confirm-user-choice',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data:{
        imgSrc,
        text,
        btn1Text,
        btn2Text
      },
      disableClose:true
    }
   const dialogRef =  this.__dialog.open(PopupConfirmUserChoiceComponent,popupConfigs);
   this.onPopupConfirmUserChoiceBtn1GetClicked(dialogRef,btn1Fun,subjectToUnsubscribeFromObservables);
   this.onPopupConfirmUserChoiceBtn2GetClicked(dialogRef,btn2Fun,subjectToUnsubscribeFromObservables);
  }

  onPopupConfirmUserChoiceBtn1GetClicked(
    dialogRef:MatDialogRef<PopupConfirmUserChoiceComponent>,
    fun,
    subjectToUnsubscribeFromObservables:Subject<any>
    ){
    dialogRef.componentInstance.emitValueWhenBtn1GetClicked
    .pipe(
      takeUntil(subjectToUnsubscribeFromObservables),
      tap( data => {
        if(data) {
        fun();
        dialogRef.close()
        }
      })
    )
    .subscribe();
  }

  onPopupConfirmUserChoiceBtn2GetClicked(
    dialogRef:MatDialogRef<PopupConfirmUserChoiceComponent>,
    fun,
    subjectToUnsubscribeFromObservables:Subject<any>
    ){
    dialogRef.componentInstance.emitValueWhenBtn2GetClicked
    .pipe(
      takeUntil(subjectToUnsubscribeFromObservables),
      tap( data => {
        if(data){
          fun();
          dialogRef.close();
        }
      })
    )
    .subscribe();
  }

  getMagicDeliveryAmount():Promise<any>{
    return new Promise((resolve,reject)=>{
      this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
      .pipe(tap(data => {
        if(data){
          this.__restaurantService.getMagicDeliveryAmount(this.basketService.getRestaurantId())
          .pipe(tap(data => {
            this.basketService.magicDeliveryAmount = data.frais_livraison;
            this.basketService.isMagicDelivryActiveInRestauarant = data.frais_livraison>=0 ? true : false;
            resolve(data);
          }))
          .subscribe(); 
        }
      }))
      .subscribe();
    })
  }

  openModernPopupLoader(){
    const popupConfigs = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose:true
    }
    return this.__dialog.open(PopupModernLoaderComponent,popupConfigs);
  }

  getLoyaltyReduction():Promise<any>{
    return new Promise((resolve,reject)=>{
      this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
      .pipe(tap(data => {
        if(data){
          if(!this.__authService.isTheUserLoggedIn()) resolve(0);
          else{
            const url = `${environment.apiUrl}fidelite/check`
            const params = new HttpParams().append("restaurantId",this.__basketService.getRestaurantId());
            this.__httpClientWithTokenService.get(url,params)
            .pipe(
              pluck("prc_fidelite"),
              tap(reduction => {
                this.basketService.basket.promoCodeInfos.reduction = +reduction;
                this.basketService.montantFidelite =+ reduction
                resolve(reduction)
              })
            )
            .subscribe()
          }
        }
      }))
      .subscribe();
    })
  }

  getLoyaltyReductionUsingUniqueUrl(uniqueUrl):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
      .pipe(tap(data => {
        if(data){
          if(!this.__authService.isTheUserLoggedIn()) resolve(0);
          else{
            const url = `${environment.apiUrl}fidelite/check`
            const params = new HttpParams().append("restaurantId", uniqueUrl);
            this.__httpClientWithTokenService.get(url,params)
            .pipe(
              pluck("prc_fidelite"),
              tap(reduction => {
                this.basketService.basket.promoCodeInfos.reduction = +reduction;
                this.basketService.montantFidelite =+ reduction;
                resolve(reduction)
              })
            )
            .subscribe()
          }
        }
      }))
      .subscribe();
    })
  }

}
