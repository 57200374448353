import { SearchRestaurantComponent } from './components/search-restaurant/search-restaurant.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BankCardsComponent } from './components/bank-cards/bank-cards.component';
import { BasketPageComponent } from './components/basket-page/basket-page.component';
import { CheckoutPageComponent } from './components/checkout-page/checkout-page.component';
import { DelivrerPageComponent } from './components/delivrer-page/delivrer-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ListComponent } from './components/list/list.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { OrderDetailsPageComponent } from './components/order-details-page/order-details-page.component';
import { PartnerPageComponent } from './components/partner-page/partner-page.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RestaurantPageComponent } from './components/restaurant-page/restaurant-page.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { BankCardsService } from './services/bank-cards.service';
import { CheckoutService } from './services/checkout.service';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { UserProfileService } from './services/user-profile.service';
import { PayementFailedComponent } from './components/payement-failed/payement-failed.component';
import { SavingCardFailedComponent } from './components/saving-card-failed/saving-card-failed.component';
import { ModifyUserBankCardComponent } from './components/modify-user-bank-card/modify-user-bank-card.component';
import {ModifyBankCardService} from 'src/app/services/modify-bank-card.service';
import { CommandsComponent } from './components/commands/commands.component';
import { CommandsService } from './services/commands.service';
import { PromotionRestaurantsComponent } from './components/promotion-restaurants/promotion-restaurants.component';
import { HomePageService } from 'src/app/services/home-page.service';
import { MapComponent } from './components/map/map.component';
import { AddressListService } from './services/address-list.service';
import { CheckoutAddressDetailsComponent }  from './components/checkout-page/checkout-address-details/checkout-address-details.component';
import { BasketService } from './services/basket.service';
import { RestaurantPageService } from './services/restaurant-page.service';
import { PopupSuccessfulPaymentComponent } from './components/popup-successful-payment/popup-successful-payment.component';
import { PopupFailedPaymentComponent } from './components/popup-failed-payment/popup-failed-payment.component';
import { MakeAnOrderComponent } from './components/make-an-order/make-an-order.component';
import { StoresPageComponent } from './components/stores-page/stores-page.component';
import { RestaurantsComponent } from './components/restaurants/restaurants.component';
import { RestaurantsPageComponent } from './components/restaurants-page/restaurants-page.component';
import { PastriesComponent } from './components/pastries/pastries.component';
import { SpecialNordComponent } from './components/special-nord/special-nord.component';
import { RefundComponent } from './components/refund/refund.component';
import { AuthGoogleComponent } from './components/auth-google/auth-google.component';

const routes:Routes = [
  { path: "home", 
    component: HomePageComponent,
    canActivate:[AuthGuardService], 
  },

  { path: "", 
    redirectTo: '/restaurant?name=ghostkitchen',
    pathMatch: 'full',
    canActivate:[AuthGuardService], 
  },

  {
    path: "refund",
    component: RefundComponent
  },

  { path: "boutiques", 
    component: StoresPageComponent,
  },

  { path: "patisseries", 
    component: PastriesComponent,
  },

  { path: "restaurants", 
    component: RestaurantsPageComponent,
  },

  { path: "makeanorder", 
    component: MakeAnOrderComponent
  },

  { path: "successful-payment", 
    component: PopupSuccessfulPaymentComponent 
  },

  { path: "failed-payment", 
    component: PopupFailedPaymentComponent 
  },
/*
  { path: "commandelivre", 
    component: PopupLivreComponent
  },
*/

  { path: "carte-bancaire", 
    component: BankCardsComponent, 
    canActivate:[AuthGuardService], 
    resolve:{uls:BankCardsService}
  },
  

  { path: "echec-enregistrement-carte", 
    component: SavingCardFailedComponent, 
    canActivate:[AuthGuardService], 
  },

  { path: "s'inscrire", 
    component: SignupComponent, 
    canActivate:[AuthGuardService]
  },

  { path:"reinitialiser-mot-de-passe",
    component:ResetPasswordComponent,
    canActivate:[AuthGuardService]
  },

  { path: "se-connecter", 
    component: SigninComponent,
    canActivate:[AuthGuardService]
  },

  { path: "google-auth", 
    component: AuthGoogleComponent
  },
   
  { path:"user-profile/:uuid", 
    component:UserProfileComponent,
    canActivate:[AuthGuardService],
    resolve:{uls:UserProfileService},
  },

  { path: "restaurant", 
    component: RestaurantPageComponent,
    resolve:[RestaurantPageService],
    canActivate:[AuthGuardService]
  },
  
  //Search page restaurant path
  { path: "search/:searchFieldValue", 
    component: SearchRestaurantComponent,
    resolve:[RestaurantPageService]
  },

  
  
  

  { path: "map", 
    component: MapComponent,
    canActivate:[AuthGuardService],
    resolve:{userAddresses:AddressListService},
  },

  { path: "menu", 
    component: ListComponent
  },
  
  { path: "commandes", 
    component: CommandsComponent,
    resolve:{uls:CommandsService},
    canActivate:[AuthGuardService],
  },

  /*
  { path: "commande/:id", 
    component: CommandDetailsComponent,
    resolve:{uls:CommandDetailsService}
  },
  */

  { path: "nouveau-mot-de-passe", 
    component: NewPasswordComponent, 
    canActivate:[AuthGuardService] 
  },

  { path: "checkout", 
    component: CheckoutPageComponent, 
    canActivate:[AuthGuardService],
    resolve:{uls:CheckoutService},
  },

  { path: "checkout/adresse", 
    component: CheckoutAddressDetailsComponent, 
    canActivate:[AuthGuardService],
  },

  { path: "suivi-commande/:id", 
    component: OrderDetailsPageComponent, 
    canActivate:[AuthGuardService] 
  },

  { path: "panier/:restaurantName", 
    component: BasketPageComponent,
    resolve:{data:BasketService}
  },

  { path: "modifier-carte-bancaire", 
    component: ModifyUserBankCardComponent,
    resolve:{uls:ModifyBankCardService}
  },

  { path: "devenir-livreur", 
    component: DelivrerPageComponent 
  },

  { path: "devenir-partenaire", 
    component: PartnerPageComponent 
  },


  { path: "echec-de-paiement", 
    component: PayementFailedComponent, 
    canActivate:[AuthGuardService],
  },

  
  { path: "promotion", 
    component: PromotionRestaurantsComponent, 
  },
  

  { path: ":id", 
    component: HomePageComponent 
  },

  { path: "**", 
    component: HomePageComponent 
  },
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, -200],
    })
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
