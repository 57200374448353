import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Item} from '../../../models/item';
import {BasketService} from '../../../services/basket.service';
import {MatDialog} from '@angular/material/dialog';
import {BasketItemDialogComponent} from './basket-item-dialog/basket-item-dialog.component';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-basket-page-item',
  templateUrl: './basket-page-item.component.html',
  styleUrls: ['./basket-page-item.component.scss']
})
export class BasketPageItemComponent implements OnInit{

  @Input()
  item: Item;

  @Input()
  index: number;

  @Output() onUpdateItem:EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog, private basketService: BasketService, public sharedData: SharedDataService) {
    
  }
  ngOnInit(){
    
  }

  incrementItemCount(item: Item): void {
    this.basketService.incrementItemCount(item, this.index);
    this.onUpdateItem.emit();
  }

  decrementItemCount(item: Item): void {
    this.basketService.decrementItemCount(item, this.index);
    this.onUpdateItem.emit();
  }

  removeItem(item: Item): void {
    this.basketService.removeItem(item, this.index);
    this.onUpdateItem.emit();
  }

 

  showDialogDetails(): void {
    this.dialog.open(BasketItemDialogComponent, {
      height: window.innerWidth < 500 ? '85%' : '740px',
      width: window.innerWidth < 500 ? '85%' : '400px',
      autoFocus: false,
      data: {
        item: this.item
      },
      panelClass:".popupNeedRaduis"
    });
  }
}
