import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-commande-livre',
  templateUrl: './popup-commande-livre.component.html',
  styleUrls: ['./popup-commande-livre.component.scss']
})
export class PopupCommandeLivreComponent implements OnInit {

  commandeId = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.commandeId = this.data
  }

}
