<div
  class="order-details-page__container"
  *ngIf="orderStatus"
  [ngClass]="{'fixHeight': isCommandDelivredByOurOwnDeliveryMan!=null ? true : false}"
>
  <div 
    class="order-details-page__info-zone"
    *ngIf="!isCommandDelivredByOurOwnDeliveryMan"
    >
    <div class="order-details-page__info-zone-title">
      <div class="order-details-page__info-zone-title--text">
        Suivi de commande
      </div>
      <img
        [routerLink]="['/restaurant']"
        [queryParams]="{name: orderStatus.infos.url_unique}"
        [src]="orderStatus.infos.logo"
        class="order-details-page__info-zone-title--img"
      />
    </div>

    <div
      *ngIf="
               (orderStatus.infos.state == 1 || orderStatus.infos.state == 4)  
            && orderStatus.times.duree_estimee 
            && !theOrderIsPreorder()
            "
      class="order-details-page__info-zone-estimation-duration"
    >
      <div class="order-details-page__info-zone-estimation-duration--content">
        <span class="order-details-page__info-zone-estimation-duration--content-text">Temps d'arrivée estimé</span>
        <span class="order-details-page__info-zone-estimation-duration--content-time">{{ getArrivalTime() }} - {{ getLateArrivalTime() }}</span>
      </div>
    </div>

    <div
      class="order-details-page__info-zone-driver"
      *ngIf="  orderStatus.infos.state == 1 
            && orderStatus.times.duree_estimee 
            && driversInfos
            && !theOrderIsPreorder()
            "
    >
      <img 
        class="order-details-page__info-zone-driver--img"
        src="../../../assets/images/driver-img.png" alt="">

      <div class="order-details-page__info-zone-driver--driver-infos">
        <span class="order-details-page__info-zone-driver--driver-infos_name">{{ driversInfos.first_name }}</span>
        <span class="order-details-page__info-zone-driver--driver-infos_text">Prend en charge votre commande</span>
        <a
          [href]="getDeliveryManPhoneLink()" 
          class="order-details-page__info-zone-driver--driver-infos_call-buttom">Appeler {{ driversInfos.first_name }}</a>
      </div>
      
    </div>

    <div class="order-details-page__info-zone-pre-order"
        *ngIf="theOrderIsPreorder()
          && (orderStatus.infos.state != 4 && orderStatus.infos.state != 2 && orderStatus.infos.state != 3 )"
        >
      <div class="order-details-page__info-zone-pre-order--information">
        <img class="order-details-page__info-zone-pre-order--information---img" src="../../../assets/images/Information-icon.webp" alt="">
        <div class="order-details-page__info-zone-pre-order--information---content">
          Le suivi de commande débutera le {{ preOrderDate }} entre {{ preOrderCreneau }}h.
        </div>
      </div>

      <div class="order-details-page__info-zone-pre-order--message">
        Votre précommande <br> a bien été prise en compte.
      </div>

      <img class="order-details-page__info-zone-pre-order--img" src="../../../assets/images/pre-commande.png" alt="">
  

    </div>
    <div class="order-details-page__info-zone-image-stepper-container"
         *ngIf="
                !theOrderIsPreorder()
                || 
                (
                  theOrderIsPreorder() && 
                  ( orderStatus.infos.state == 4 || orderStatus.infos.state == 2 || orderStatus.infos.state == 3 )
                )"
    >
      <div
        class="order-details-page__info-zone-image"
        [ngClass]="{
          'order-details-page__info-zone-image-takeaway':
            orderStatus.service.type === 'TAKEAWAY' || orderStatus.service.type === 'PREORDER-TAKEAWAY'
         }"
      >
      </div>
      <app-stepper
        [state]="orderStatus.infos.state"
        [status]="orderStatus.infos.status"
        [times]="orderStatus.times"
        [serviceType]="orderStatus.service.type"
        class="order-details-page__info-zone-stepper"
      >
      </app-stepper>
    </div>

    <div
      id="call-restaurant-btn"
      class="order-details-page__info-zone-order-address-zone"
    >

    <button
      mat-raised-button
      class="checkout-page__info-zone-button"
      color="primary"
      style="
        margin-bottom: 5px;
        background: #FFF;
        border-color: #ffc107;
        color:#ffc107;
        border:1px solid #ffc107;
        border-radius: 50px;
        box-shadow: rgb(26 77 160 / 12%) 0px 30px 40px -10px;
      "
      (click)="showPopupPayementMod()"
      *ngIf="orderStatus.infos.state == -1" 
  >
    <span
      style="
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      PAYER MA COMMANDE
      
</span>
  </button>

    <button
    mat-raised-button
    class="checkout-page__info-zone-button"
    color="primary"
    style="
      margin-bottom: 5px;
      background: #FFF;
      border:1px solid #dc3545;
      color:#dc3545;
      border-radius: 50px;
      box-shadow: rgb(26 77 160 / 12%) 0px 30px 40px -10px;
    "
    (click)="cancelOrder()"
    *ngIf="orderStatus.infos.state<=0" 
  >
    <span
      style="
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
    <span *ngIf="!isLoadingCancelOrderResponse"> ANNULER MA COMMANDE </span>
    <span 
    style="
    padding-left: 23px;
    padding-right: 23px;
    "
    *ngIf="isLoadingCancelOrderResponse">Chargement ...</span>
      
</span>
  </button>


      <button
        mat-raised-button
        class="checkout-page__info-zone-button"
        color="primary"
        style="
          border-radius: 50px;
          box-shadow: rgb(26 77 160 / 12%) 0px 30px 40px -10px;
        "
      >
        <a
          [href]="phoneNumber"
          style="
            color: white;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          APPELER LE RESTAURANT
          <img
            src="../../../assets/images/telephone-of-old-design.svg"
            alt=""
            width="17px"
            style="margin-left: 10px"
          />
        </a>
      </button>
    </div>

    <div class="order-details-page__info-zone-order-status"
        *ngIf="(orderType != 'PREORDER-TAKEAWAY' && orderType != 'PREORDER-DELIVERY') 
        || (orderType === 'PREORDER-TAKEAWAY' && orderType === 'PREORDER-DELIVERY') && orderStatus.infos.status == 4"
    >
      Votre commande est
      <span class="order-details-page__info-zone-order-status-text">
        {{ orderStatus.infos.status }}
      </span>
    </div>

    <div
      id="desktop-phone-number"
      *ngIf="orderStatus.infos.phone"
      class="order-details-page__info-zone-order-status-need-flex"
    >
      <span class="order-details-page__info-zone-order-status-need-flex-msg">
        Vous souhaitez nous contacter ?
      </span>
      <span
        class="order-details-page__info-zone-order-status-text order_details_page_style"
      >
        {{ orderStatus.infos.phone }}
      </span>

      <div class="order-details-page__info-zone-order-status-need-flex-buttons">
        <span
        (click)="showPopupPayementMod()"
        *ngIf="orderStatus.infos.state == -1"
        class="order-details-page__info-zone-order-status-text"
        style="
          padding: 10px;
          font-size: 14px;
          border-radius: 20px;
          background: #FFF;
          border-color: #ffc107;
          color:#ffc107;
          border:1px solid #ffc107;
          cursor: pointer;
        "
      >
        PAYER MA COMMANDE
      </span>

      <span
        (click)="cancelOrder()"
        *ngIf="orderStatus.infos.state<=0"
        class="order-details-page__info-zone-order-status-text"
        style="
          padding: 10px;
          font-size: 14px;
          border-radius: 20px;
          background: #FFF;
          border:1px solid #dc3545;
          color:#dc3545;
          cursor: pointer;
          min-width: 130px;
          text-align: center;
        "
      >
        <span *ngIf="!isLoadingCancelOrderResponse"> ANNULER MA COMMANDE </span>
        <span *ngIf="isLoadingCancelOrderResponse">Chargement ...</span>
      </span>
      </div>

    </div>
    <div 
    *ngIf="orderStatus.infos.status=='en attente de confirmation'" 
    class="order-details-page__info-zone-order-status-cancel_order">
      <!--<button 
      class='btn btn-danger'
      (click)="cancelOrder()"
      > Annuler ma commande
    </button>-->
    </div>

    <div
      *ngIf="orderStatus.service.type === 'DELIVERY' || orderStatus.service.type === 'PREORDER-DELIVERY'"
      class="order-details-page__info-zone-order-address-zone"
    >
      Adresse de livraison :
      <span class="order-details-page__info-zone-order-address-zone-text">
        {{ orderStatus.infos.address }}
      </span>
    </div>
    <div
      *ngIf="orderStatus.service.type === 'TAKEAWAY' || orderStatus.service.type === 'PREORDER-TAKEAWAY'"
      class="order-details-page__info-zone-order-address-zone"
    >
      Adresse du restaurant :
      <span class="order-details-page__info-zone-order-address-zone-text">
        {{ orderStatus.infos.adresse_resto }}
      </span>
    </div>

    <div class="order-details-page__info-zone-order-delivery-msg">
      {{ orderStatus.infos.message_livraison }}
    </div>
  </div>

  <div 
    class="order-details-page__info-zone-top-info"
    *ngIf="(orderStatus.infos.state == 4) && isCommandDelivredByOurOwnDeliveryMan"
  >
    <div class="order-details-page__info-zone-top-info--home-button"
         (click)="openList()"
    >
      <div class="order-details-page__info-zone-top-info--home-button---menu-icon"></div>
    </div>

    <div class="order-details-page__info-zone-top-info--delivery-man" >

      <div class="order-details-page__info-zone-top-info--delivery-man---img"> 
      </div>

      <div class="order-details-page__info-zone-top-info--delivery-man---text">
        <div class="order-details-page__info-zone-top-info--delivery-man---text----text-top">Appeler le livreur</div>
        <div class="order-details-page__info-zone-top-info--delivery-man---text----line"></div>
        <div class="order-details-page__info-zone-top-info--delivery-man---text----text-bottom">{{ driverInfos.first_name }}  {{ driverInfos.last_name }}</div>
      </div>

    </div>

    <div class="order-details-page__info-zone-top-info--call-button"
        (click)="callDeliveryMan()"
    >
      <div class="order-details-page__info-zone-top-info--call-button---phone-icon"></div>
    </div>
  </div>

  <div
    class="order-details-page__info-zone-bottom-info"
    *ngIf="(orderStatus.infos.state == 4) && isCommandDelivredByOurOwnDeliveryMan"
  >
    <div
        class="order-details-page__info-zone-bottom-info--estimation-duration"
      >
        <div 
          class="order-details-page__info-zone-bottom-info--estimation-duration---text"
        >
          Arrivée estimée
        </div>
        <div
          class="order-details-page__info-zone-bottom-info--estimation-duration---line"
        >
        </div>
        <div class="order-details-page__info-zone-bottom-info--estimation-duration---duree">
          {{ getArrivalTime() }} - {{ getLateArrivalTime() }}
        </div>   
    </div>

    <div
      class="order-details-page__info-zone-bottom-info--total-order"
    >
      <div 
        class="order-details-page__info-zone-bottom-info--total-order---text"
      >
        Total
      </div>
      <div
        class="order-details-page__info-zone-bottom-info--total-order---line"
      >
      </div>
      <div class="order-details-page__info-zone-bottom-info--total-order---price">
        {{ newTotalPrice | number : '1.2-2' }} Dhs
      </div>   
    </div>
  </div>

  <app-order-google-map
    *ngIf="orderStatus.infos.state==4 && isCommandDelivredByOurOwnDeliveryMan"
    class="order-details-page__map-zone"
    [mapInfos]="mapInfos"
  >
  </app-order-google-map>
 <!--
  <div 
    class="order-details-page__delivery-man--mobile"
    *ngIf="orderStatus && orderStatus.infos.state==4 && isCommandDelivredByOurOwnDeliveryMan && driverInfos"
    (click)="callDeliveryMan()"
  >

  <div class="order-details-page__delivery-man--mobile__first-level">
    <img 
      [src]="driverInfos.image" 
      alt=""
      class="order-details-page__delivery-man--mobile__first-level-img"
    >

    <div class="order-details-page__delivery-man--mobile__first-level-full-name">
      {{ driverInfos.first_name }}  {{ driverInfos.last_name }}
    </div>
  </div>

  <div class="order-details-page__delivery-man--mobile__second-level">
    
  </div>

  <div class="order-details-page__delivery-man--mobile__third-level">
    <button 
      type="button"
      class="order-details-page__delivery-man--mobile__third-level-phone-btn"
    >
      Appeler le livreur
      <img 
      src="../../../assets/images/phone-call.png" 
      class="order-details-page__delivery-man--mobile__third-level-phone-btn-icon"
      >
    </button>
  </div>
  </div>
  -->
  <div class="order-details-page__order-zone">
    <div class="order-details-page__order-zone-frame">
      <div class="order-details-page__order-zone-header">
        <div class="order-details-page__order-zone-header-text">
          Numero de commande:
        </div>
        <div class="order-details-page__order-zone-header-text">
          {{ orderStatus.infos.id }}
        </div>
      </div>
      <div class="order-details-page__order-zone-frame-items">
        <div
          *ngFor="let item of orderStatus.items"
          class="order-details-page__order-zone-frame-item"
          (click)="showDialogDetails(item)"
        >
          <img
            class="order-details-page__order-zone-frame-item-image"
            [src]="item.image"
          />
          <div class="order-details-page__order-zone-frame-item-details">
            <span
              class="order-details-page__order-zone-frame-item-details-name"
              >{{ item.name }}</span
            >
            <p class="checkout-page__order-zone-frame-item-details-small-p" >{{item.remark}}</p>

            <div class="order-details-page__order-zone-frame-item-details-text">
              <span>{{ item.count }}&nbsp;&nbsp;</span>x
              <span
                class="
                  order-details-page__order-zone-frame-item-details-text-price
                "
              >
                &nbsp;&nbsp;{{ item.unitPrice }} Dhs
              </span>
            </div>
          </div>
        </div>

        <div class="order-details-page__order-zone-frame-bill">

          <div class="order-details-page__order-zone-frame-bill-line">
            <div>Sous-total</div>
            <div class="order-details-page__order-zone-frame-bill-line-price">
              {{ subtotal | number : '1.2-2'}} Dhs
            </div>
          </div>

          <div class="order-details-page__order-zone-frame-bill-line" 
               style="color: red"
               *ngIf="oldTotalPrice > 0" 
          >
            <div>Réduction</div>
            <div
              class="order-details-page__order-zone-frame-bill-line-price"  
            >
              {{  orderStatus.infos.reduction | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div class="order-details-page__order-zone-frame-bill-line" 
               style="color: red"
               *ngIf="totalWithPromo() - total() != 0"
          >
            <div>Réduction</div>
            <div
              class="order-details-page__order-zone-frame-bill-line-price"  
            >
              {{ totalWithPromo() - total() | number : '1.2-2'  }} Dhs
            </div>
          </div>
          
          <div
            *ngIf="orderStatus.service.type === 'DELIVERY' || orderStatus.service.type === 'PREORDER-DELIVERY'"
            class="order-details-page__order-zone-frame-bill-line"
          >
            <div>Frais de livraison</div>
            <div class="order-details-page__order-zone-frame-bill-line-price">
              {{ orderStatus.service.fees | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div class="order-details-page__order-zone-frame-bill-line">
            <div>Frais de service</div>
            <div class="order-details-page__order-zone-frame-bill-line-price">
              <!--{{ orderStatus?.serviceCharge }} Dhs-->
              {{ orderStatus.service.frais_service | number : '1.2-2' }} Dhs
            </div>
          </div>

          <hr
            class="order-details-page__order-zone-frame-bill-line-separator"
          />
          <div class="order-details-page__order-zone-frame-bill-line">
            <div>Total</div>
            <div 
             *ngIf="oldTotalPrice > 0"            
             class="order-details-page__order-zone-frame-bill-line-old-price">
              {{ oldTotalPrice | number : '1.2-2' }} Dhs
            </div>
            <div
              *ngIf="newTotalPrice >= 0"
              class="order-details-page__order-zone-frame-bill-line-new-price">
              {{ newTotalPrice | number : '1.2-2' }} Dhs
            </div>
            <div
              *ngIf="newTotalPrice < 0"
              class="order-details-page__order-zone-frame-bill-line-new-price">
              {{ 0 | number : '1.2-2' }} Dhs
            </div>
          </div>
          <div class="order-details-page__order-zone-frame-bill-line">
            <div>Paiement</div>
            <div class="order-details-page__order-zone-frame-bill-line-price">
              {{ orderStatus.service.moyen_paiement }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../../assets/images/shape10.png"
      class="order-details-page__order-zone-footer"
      style="margin-top: -8px"
    />
  </div>
</div>

<div
  class="order-details-page__delivery-man--desktop"
  *ngIf="orderStatus && orderStatus.infos.state==4 && isCommandDelivredByOurOwnDeliveryMan && driverInfos"
>
  <div 
  class="order-details-page__delivery-man--desktop-container"
  *ngIf="driverInfos"
  (click)="callDeliveryMan()"
>
  <div class="order-details-page__delivery-man--desktop-container__first-level">
    <img 
      [src]="driverInfos.image" 
      class="order-details-page__delivery-man--desktop-container__first-level-img"
    >
    <div class="order-details-page__delivery-man--desktop-container__first-level-full-name">
      {{ driverInfos.first_name }}  {{ driverInfos.last_name }}
    </div>
  </div>

  <div class="order-details-page__delivery-man--desktop-container__second-level"></div>

  <div class="order-details-page__delivery-man--desktop-container__third-level">
    <button class="order-details-page__delivery-man--desktop-container__third-level-phone-btn">
      Appeler le livreur
      <img 
        src="../../../assets/images/phone-call.png" 
        class="order-details-page__delivery-man--desktop-container__third-level-phone-btn-icon"
        >
    </button>
  </div>
</div>
</div>
<!--
  <ng-template #loaderComponent>
  <mat-spinner
    style="margin: auto; margin-bottom: 30vh; margin-top: 30vh"
    strokeWidth="6"
    diameter="60"
  >
  </mat-spinner>
</ng-template>
-->
<app-about-zone class="order-details-page__about-zone"></app-about-zone>

<app-hide-crisp-client *ngIf="!isCommandAffectedToDeliveryMan"></app-hide-crisp-client>

<app-display-crisp-client  *ngIf="isCommandAffectedToDeliveryMan"></app-display-crisp-client>