import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-popup-payement-choice',
  templateUrl: './popup-payement-choice.component.html',
  styleUrls: ['./popup-payement-choice.component.scss']
})
export class PopupPayementChoiceComponent implements OnInit, OnDestroy {
  onPayementModButtonClicked:Subject<any> = new Subject();
  constructor() { }

  ngOnInit(): void {
  }


  EspeceMod(){
    this.onPayementModButtonClicked.next("espece");
  }

  bankCardMod(){
    this.onPayementModButtonClicked.next("cb");
  }

  ngOnDestroy(){
    this.onPayementModButtonClicked.unsubscribe();
  }
  

}
