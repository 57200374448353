
<div class="modify-bank-card">
    <div class="modify-bank-card-container">
        <app-return-to-previous-page class="modify-bank-card-container-btn-return"></app-return-to-previous-page>
        <div class="modify-bank-card-container-header">
           <div style="text-align:center;">Votre carte est 
               <span 
               [ngStyle]="{backgroundColor: getBankCardStatusBackGroundColor(bankCard.etat)}"
               class="modify-bank-card-container-header-status"> 
               {{bankCard?.etat == '1' ? 'Activé' : 'Désactivé' }}
                </span>
           </div>
        </div>
        <form class="modify-bank-card-container-label" [formGroup]="__formGroup">
            <mat-form-field class="modify-bank-card-container-label-input">
                <mat-label class="modify-bank-card-container-label-margin">
                    Saisissez votre libellé
                </mat-label>
                <input
                    formControlName="label"
                    class="modify-bank-card-container-label-field-margin"
                    type="text"
                    matInput
                />
                <mat-error> {{ getErrorMessage("label") }} </mat-error>
                <mat-icon matSuffix>label</mat-icon>
            </mat-form-field>

            <button
            (click)="modifyLabel()"
            *ngIf="!loadingForModifyingLabel"
            [disabled]="__formGroup.invalid"
            class="modify-bank-card-container-label-btn"
            >Modifier mon libellé
                <mat-icon class="modify-bank-card-container-label-btn-icon">label</mat-icon>
            </button>  
            <span *ngIf="loadingForModifyingLabel" class="modify-bank-card-container-label-loading">
                <app-loading></app-loading> 
            </span>
        </form>

        <div class="modify-bank-card-container-action">
            <button
            (click)="activatebankCard()"
            *ngIf="bankCard.etat==0 && !loadingForModifyingStatus"
            class="modify-bank-card-container-action-btn-activate"
            >Activer ma carte
                <mat-icon class="modify-bank-card-container-action-btn-activate-icon">credit_card</mat-icon>
            </button> 

            <button
            (click)="deactivatebankCard()"
            *ngIf="bankCard.etat==1 && !loadingForModifyingStatus"
            class="modify-bank-card-container-action-btn-deactivate"
            >Désactiver ma carte
                <mat-icon class="modify-bank-card-container-action-btn-deactivate-icon">credit_card</mat-icon>
            </button>  

            <span *ngIf="loadingForModifyingStatus" class="modify-bank-card-container-action-loading">
                <app-loading></app-loading> 
            </span>
        </div>
    </div>
</div>

<div class="footer">
    <app-about-zone></app-about-zone>
</div>

