<!--<mat-spinner
  style="margin: auto"
  strokeWidth="6"
  diameter="60"
  *ngIf="!restaurant"
>
</mat-spinner>
-->
<div class="restaurant-page__container" *ngIf="restaurant">
  <app-location-popup
    [restaurantStreets]="restaurantStreets?.quartier"
    [city]="restaurantStreets?.ville"
    [franchise]="franchise"
    [restaurantId]="restaurant.id"
  ></app-location-popup>
  
  <div class="restaurant-page__presentation">
  <!--  <div *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"> -->
    <div class="restaurant-page__presentation-images-container"
         *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
    >  
      <img
        [src]="restaurant.background"
        class="restaurant-page__presentation-image"
      />
      <img
        [src]="restaurant.image"
        class="restaurant-page__presentation-sub-image"
      />
    </div>

    <div class="restaurant-page__presentation-restaurant-infos"
        *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
    >
      <div>
        <span class="restaurant-page__presentation-rest aurant-name">
          {{ restaurant.designation }}
        </span>
        <span class="restaurant-page__presentation-restaurant-speciality">{{
          restaurant.category
        }}</span>
      </div>
      <span class="restaurant-page__presentation-restaurant-phone">{{
        restaurant.num_telephone
      }}</span>
      <button
        class="restaurant-page__presentation-restaurant-show-details-button"
        mat-button
        (click)="showRestaurantDescription()"
      >
        <mat-icon
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
          "
        >
          {{
            restaurantDescriptionOpened === true
              ? "arrow_drop_up"
              : "arrow_drop_down"
          }}
        </mat-icon>
      </button>
    </div>

    <div class="restaurant-page__presentation-restaurant-infos"
        *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
    >
      <span class="restaurant-page__presentation-restaurant-infos-text">
        {{ restaurant.ville }}
      </span>

      <div>
        <div
          *ngIf="restaurant.catalogue !== '0'"
          class="restaurant-page__presentation-restaurant-catalogue"
          (click)="downloadCatalogue()"
        >
          <img
            class="restaurant-page__presentation-restaurant-catalogue-icon"
            src="../../../assets/images/catalogue.svg"
            width="15px"
          />
          <span class="restaurant-page__presentation-restaurant-catalogue-text">
            Telecharger le menu
          </span>
        </div>
        <div class="restaurant-page__presentation-restaurant-time">
          <mat-icon class="restaurant-page__presentation-restaurant-time-icon"
            >access_time</mat-icon
          >
          <span class="restaurant-page__presentation-restaurant-time-text">
            {{ restaurant?.heure_ouverture }} -
            {{ restaurant?.heure_fermeture }}
          </span>
        </div>
      </div>
    </div>

    <div  *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage">
      <div class="restaurantStatus" *ngIf="!restaurant.ferme; else restaurantClosed">
        <div
          class="restaurant-page__presentation-restaurant-status--open"
          *ngIf="!restaurantPageService.estimationDuration"
        >
        {{ restaurant.message_fermeture }}
        </div>
        <div
          class="restaurant-page__presentation-restaurant-status--estimationDuration"
          *ngIf="restaurantPageService.estimationDuration"
        >
        <img style="width: 44px;
                    margin-left: 10px;
                    margin-right: 10px;
                    " 
            src="../../../assets/images/estimationDuration.png" alt="">
        Délai de livraison estimé {{ restaurantPageService.estimationDuration }}
        </div>
        <div class="actions-section">
          <a
            id="call-restaurant-page-btn"
            [href]="getRestaurantPhoneLink()"
            class="restaurant-page__presentation-restaurant-status--phone"
            [ngStyle]="{'height': (restaurantPageService.estimationDuration) ? '60px' : '29px',
                        'background': (restaurantPageService.estimationDuration) ? '#CEA224':'#393541'}"
          >
            <img
              src="../../../assets/images/telephone-of-old-design.svg"
              alt=""
              class="phone-icon"
            />
          </a>
        </div>
      </div>
      <ng-template #restaurantClosed>
        <div class="restaurant-page__presentation-restaurant-status--closed">
          {{ restaurant.message_fermeture }}
        </div>
      </ng-template>
    </div>
  
    <div
    class="restaurant-page__presentation-restaurant-status--delivery"
    *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
    >
    {{ restaurant.message_livraison }}
    </div>
    

    <div
      class="restaurant-page__presentation-restaurant-description-container"
      *ngIf="restaurant.description && restaurant.description.length && !this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
    >   
      <div
        class="restaurant-page__presentation-restaurant-description"
        [ngClass]="{
          'restaurant-page__presentation-restaurant-description-opened':
            restaurantDescriptionOpened === true
        }"
      >
        <span
          class="
            restaurant-page__presentation-restaurant-description-text-desktop
          "
        >
          {{ getDescription() }}
        </span>
        <span
          class="
            restaurant-page__presentation-restaurant-description-text-mobile
          "
        >
          {{ restaurant.description }}
        </span>
        <span
          *ngIf="restaurant.description.length > 100"
          (click)="seeMoreClicked = !seeMoreClicked"
          class="restaurant-page__presentation-restaurant-description-details"
        >
          {{ seeMoreClicked ? "Réduire" : "Plus" }}
        </span>
      </div>
    </div>
    <div class="restaurant-page__presentation-restaurant-time-mobile"
        *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
      >
      <mat-icon class="restaurant-page__presentation-restaurant-time-icon"
        >access_time</mat-icon
      >
      <span class="restaurant-page__presentation-restaurant-time-text">
        {{ restaurant.heure_ouverture }} - {{ restaurant.heure_fermeture }}
      </span>
    </div>

    <div class="restaurant-page__presentation-restaurant-menu-container"
        *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage"
      >
      <div class="restaurant-page__presentation-restaurant-menu">
        <div
          class="restaurant-page__presentation-restaurant-menu-scroll-button"
        >
          <button mat-icon-button (click)="scrollLeft(menuItems)">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <!-- -------Family-Block-------- -->
        <div
          #menuItems
          *ngIf="isFamily; else categoryMenu"
          class="restaurant-page__presentation-restaurant-menu"
        >
          <div
            class="restaurant-page__presentation-restaurant-menu-item"
            *ngFor="let heading of families"
            (click)="setSelectedHeading(heading.name)"
            [attr.id]="isFamily ? heading.name + 'ID' : heading.cat_name + 'ID'"
          >
            <img
              class="restaurant-page__presentation--img"
              src="{{ heading.categories[0].image_family }}"
              [ngClass]="{
                'restaurant-page__presentation-restaurant-menu-item-selected-family':
                  visibleFamilyHeading === heading.name
              }"
            />
            <span class="restaurant-page__presentation--title">{{ heading.categories[0].familyname }}</span>
          </div>
        </div>
        <!-- -------Family-Block-------- -->

        <!-- -------Category-Block-------- -->
        
        <ng-template #categoryMenu>
          <div #menuItems class="restaurant-page__presentation-restaurant-menu">
            <div
              class="restaurant-page__presentation-restaurant-menu-item"
              *ngFor="let heading of restaurant.categories"
              (click)="setSelectedHeading(heading.cat_name)"
              [attr.id]="heading.cat_name + 'ID'"
              [ngClass]="{
                'restaurant-page__presentation-restaurant-menu-item-selected':
                  visibleHeading === heading.cat_name
              }"
            >
              <span class="restaurant-page__presentation--name">
                {{ heading.cat_name }}
              </span>
            </div>
          </div>
        </ng-template>
        <!-- -------Category-Block-------- -->
        <div
          class="restaurant-page__presentation-restaurant-menu-scroll-button"
        >
          <button mat-icon-button (click)="scrollRight(menuItems)">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
<!--  </div>  -->

    <!-- ------sub-menu-start---- -->

    <div 
      *ngIf="!this.__sharedDataService.showSearchFieldToSearchInARestaurantPage && faimilyCategories.length > 0"
      class="restaurant-page__presentation-restaurant-sub-menu-container"
    >
      <div class="restaurant-page__presentation-restaurant-sub-menu">
        <div
          class="
            restaurant-page__presentation-restaurant-sub-menu-scroll-button
          "
        >
          <button mat-icon-button (click)="scrollLeft(menuItems)">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <!-- -------Category-Block-------- -->
        <div
          #menuItems
          class="restaurant-page__presentation-restaurant-sub-menu"
        >
          <div
            class="restaurant-page__presentation-restaurant-sub-menu-item"
            *ngFor="let heading of faimilyCategories"
            (click)="setSelectedHeading(generateHeadingId(heading))"
            [ngClass]="{
              'restaurant-page__presentation-restaurant-sub-menu-item-selected':
                visibleHeading === heading.cat_name
            }"
            [attr.id]="heading.cat_name + 'ID'"
          >
            <span class="restaurant-page__presentation--name">
              {{ heading.cat_name }}
            </span>
          </div>
        </div>
        <!-- -------Category-Block-------- -->
        <div
          class="
            restaurant-page__presentation-restaurant-sub-menu-scroll-button
          "
        >
          <button mat-icon-button (click)="scrollRight(menuItems)">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!-- ------sub-menu-end---- -->
    <div #x [ngClass]="{ 'marginIfSearchBarActive':this.__sharedDataService.showSearchFieldToSearchInARestaurantPage }" style="display: flex; flex-direction: column; align-items: center;">
      <div class="product-not-found" 
           *ngIf="this.restaurantCategories.length == 0"
      >
      </div>
      <app-selected-heading
        [ngClass]="{
          '--margin-top-meduim': isFamily
        }"
        class="restaurant-page__presentation-selected-heading"
        [id]="generateHeadingId(h)"
        *ngFor="let h of restaurantCategories"
        [highlightTitle]="highlightTitle(h.cat_name)"
        [heading]="h"
        [restaurantClosed]="restaurant.ferme"
        [preOrderStatus]="restaurant.pre_commande"
      >
      </app-selected-heading>
    </div>
  </div>
  
  <div class="restaurant-page__basket" 
       [ngStyle]="{ 'margin-top': this.__sharedDataService.showSearchFieldToSearchInARestaurantPage ? '125px' : '0px' }"
  >
    <div class="restaurant-page__basket-container">
      <div class="restaurant-page__basket-header">
        <div class="restaurant-page__basket-header-title">Votre Panier</div>
        <!--
        <div
          *ngIf="
            basketService.count() && !basketService.getLocation().isEmpty()
          "
          class="restaurant-page__basket-header-address"
        >
          <mat-icon
            style="
              font-weight: normal;
              font-size: 20px;
              margin-right: 10px;
              margin-top: -5px;
            "
            >location_on</mat-icon
          >
          <span
            >{{ basketService.getLocation().district }},
            {{ getCityName(basketService.getLocation().city) }}</span
          >
        </div>
        <div
          *ngIf="
            basketService.count() && !basketService.getLocation().isEmpty()
          "
          class="restaurant-page__basket-header-address-change"
          (click)="openLocationDialog()"
        >
          Changer
        </div>
      -->
      </div>

      <div class="restaurant-page__basket-items">
        <div
          *ngFor="let item of basketService.basket.items; let i = index"
          class="restaurant-page__basket-item"
          style="margin-top: 10px"
        >
          <div class="restaurant-page__basket-item-remove">
            <button
              (click)="removeItem(item, i)"
              mat-icon-button
              class="restaurant-page__basket-item-remove-button"
            >
              <mat-icon class="restaurant-page__basket-item-remove-button-icon"
                >close</mat-icon
              >
            </button>
          </div>
          <div class="restaurant-page__basket-item-body">
            <img
              class="restaurant-page__basket-item-body-image"
              (click)="showDialogDetails(item)"
              [src]="item.image"
            />
            <div class="restaurant-page__basket-item-body-details">
              <span class="restaurant-page__basket-item-body-details-name cursour" (click)="showDialogDetails(item)">
                {{ item.name }} 
                <span *ngIf="!item.compositions">
                  {{ item.declinations?.selectedValue?.label | lowercase }}
                </span>
              </span>

              <span *ngIf="item.remark" class="restaurant-page__basket-item-body-details-remark cursour italice-gray" (click)="showDialogDetails(item)">
                {{ item.remark | lowercase }}
              </span>

              <span *ngIf="!item.remark" class="restaurant-page__basket-item-body-details-remark cursour italice-gray" (click)="showDialogDetails(item)">
                Cliquez ici pour ajouter un commentaire
              </span>

              <div
                class="restaurant-page__basket-item-body-details-count-price"
              >
                <div class="restaurant-page__basket-item-body-details-count">
                  <mat-icon
                    (click)="decrementItemCount(item, i)"
                    class="restaurant-page__basket-item-body-details-icon"
                  >
                    remove</mat-icon
                  >
                  <span>&nbsp;&nbsp;{{ item.count }}&nbsp;&nbsp;</span>
                  <button
                    (click)="incrementItemCount(item, i)"
                    class="restaurant-page__basket-item-body-details-icon-addButton"
                    [disabled]="item.compositions && item.quantite_stock <= __sharedDataService.getTotalOfAnItemInTheBasket(item.id_produit) && item.quantite_stock != -1
                            || !item.compositions && item.declinations.selectedValue.quantite_stock <= __sharedDataService.getTotalOfAnItemInTheBasketByIdOfDeclination(item.id_prix) && item.quantite_stock != -1"
                    >
                    <mat-icon
                      [ngClass]="{
                        'disabled': 
                                item.compositions && item.quantite_stock <= __sharedDataService.getTotalOfAnItemInTheBasket(item.id_produit) && item.quantite_stock != -1
                            ||  !item.compositions && item.declinations.selectedValue.quantite_stock <= __sharedDataService.getTotalOfAnItemInTheBasketByIdOfDeclination(item.id_prix)&& item.quantite_stock != -1
                      }"
                      class="restaurant-page__basket-item-body-details-icon-add"
                    >
                      add
                    </mat-icon>
                  </button>
                </div>
                <span
                  *ngIf="!item.compositions && 
                        (item.declinations.selectedValue.prixpromo == 0 
                        || item.declinations.selectedValue.prixpromo == item.declinations.selectedValue.prix)"
                  class="restaurant-page__basket-item-body-details-price"
                >
                  {{ item.count * item.declinations?.selectedValue?.prix }} Dhs
                </span>
                <span
                  *ngIf="!item.compositions &&
                         item.declinations.selectedValue.prixpromo != item.declinations.selectedValue.prix &&
                         item.declinations.selectedValue.prixpromo != 0"
                  class="restaurant-page__basket-item-body-details-price"
                >
                  {{ item.count * item.declinations?.selectedValue?.prixpromo }} Dhs
                </span>
                <span
                  *ngIf="item.compositions"
                  class="restaurant-page__basket-item-body-details-price"
                >
                  {{ item.count * item.unitPrice }} Dhs
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="restaurant-page__basket-bill">
        <div
          class="restaurant-page__basket-bill-details-price"
          *ngIf="basketService.count()"
        >
          <div
            class="restaurant-page__basket-bill-details-price-line-with-margin"
          >
            <div>Sous-total</div>
            <div class="restaurant-page__basket-bill-details-price-line-value">
              {{ basketService.totalWithoutPromo() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div
            class="restaurant-page__basket-bill-details-price-line-with-margin"
            style="color: red;"
            *ngIf="(basketService.totalWithoutPromo() - basketService.total())>0"
          >
            <div>Réduction</div>
            <div class="restaurant-page__basket-bill-details-price-line-value"
                 style="color: red;">
              - {{ basketService.totalWithoutPromo() - basketService.total() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div 
            class="restaurant-page__basket-bill-details-price-line-with-margin" 
            *ngIf="basketService.getMontantFidelite() > 0"
            style="color: red;"
           
            >
            <div>Réduction</div>
            <div class="restaurant-page__basket-bill-details-price-line-value"
                 style="color: red;">
              - {{ basketService.getMontantFidelite() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div
            *ngIf="(isDelivery() || isMagicDelivery()) && getDeliveryPrice() !== undefined"
            class="restaurant-page__basket-bill-details-price-line-with-margin"
          >
            <div>Frais de livraison</div>
            <div class="restaurant-page__basket-bill-details-price-line-value">
              {{ getDeliveryPrice() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div
            class="restaurant-page__basket-bill-details-price-line-with-margin"
          >
            <div>Frais de service</div>
            <div class="restaurant-page__basket-bill-details-price-line-value">
              {{ basketService.getServiceCharge() | number : '1.2-2' }} Dhs
            </div>
          </div>

          <div
            *ngIf="basketService.basket.promoCodeInfos.reduction!=0 && basketService.getMontantFidelite() == 0"
            class="restaurant-page__basket-bill-details-price-line-with-margin"
          >
            <div>Réduction</div>
            <div class="restaurant-page__basket-bill-details-price-line-value-reduction">
              -{{ basketService.basket.promoCodeInfos.reduction | number : '1.2-2' }}%
            </div>
          </div>

          <hr
            class="restaurant-page__basket-bill-details-price-line-separator"
          />
          <div class="restaurant-page__basket-bill-details-price-line">
            <div>Total</div>

            <div
              *ngIf="basketService.basket.promoCodeInfos.reduction>0" 
              class="restaurant-page__basket-bill-details-price-line-value-old">
              {{ basketService.total() + basketService.getDeliveryPrice() + basketService.getServiceCharge() | number : '1.2-2' }} Dhs
            </div>

            <div class="restaurant-page__basket-bill-details-price-line-value-new">
              {{ getTotalPriceAfterapplyingReduction() | number : '1.2-2' }} Dhs
            </div>
          </div>
        </div>
        <div
          *ngIf="
          isDelivery() &&
          getDeliveryPrice() !== undefined &&
          !isReachedMinimum() &&
          basketService.count()
          "
          class="restaurant-page__basket-bill-not-minimum"
       >
        Le minimum de commande est {{ getMinimumPrice() }} Dhs
        </div>
        <app-magic-service  
            *ngIf="
            isDelivery() &&
            getDeliveryPrice() !== undefined &&
            !isReachedMinimum() &&
            basketService.count() &&
            basketService.isMagicDelivryActiveInRestauarant
            "
            class="restaurant-page__basket-bill-magic-service"
            (click)="openPopupInformUserAboutWizardDeliveryWithoutRedirection()"
          >
          </app-magic-service>
        <mat-button-toggle-group
          class="restaurant-page__basket-bill-toggle"
          #group="matButtonToggleGroup"
          [value]="basketService.getService()"
          (change)="onServiceChange(group.value)"
          *ngIf="basketService.count() && (basketService.basket.hasDelivry || basketService.basket.hasTakeway)"
        >
          <mat-button-toggle
            *ngIf="basketService.getService()=='magicDelivery'"
            [disabled]="!basketService.basket.hasDelivry"
            value="magicDelivery"
            class="restaurant-page__basket-bill-toggle-button"
          >
          Livraison
          </mat-button-toggle>
          <mat-button-toggle
            *ngIf="basketService.getService()!='magicDelivery'"
            [disabled]="!basketService.basket.hasDelivry"
            (click)="setService('Delivery')"
            value="Delivery"
            ngClass="restaurant-page__basket-bill-toggle-button"
            >Livraison</mat-button-toggle
          >
          <mat-button-toggle
            [disabled]="!basketService.basket.hasTakeway"
            (click)="setService('Takeaway')"
            value="Takeaway"
            class="restaurant-page__basket-bill-toggle-button"
            >Emporter</mat-button-toggle
          >
        </mat-button-toggle-group>

        <img
          src="../../../assets/images/panier_vide.png"
          style="width: 65%; margin: 20px"
          *ngIf="!basketService.count()"
        />
        <div
          *ngIf="!basketService.count()"
          class="basket-empty"
        >
          Votre panier est vide
        </div>
        <button
          mat-raised-button
          color="primary"
          (click)="validateBasket()"
          [disabled]="disableButton()"
          class="restaurant-page__basket-bill-button"
        >
          Valider mon panier
        </button>
        <div
          *ngIf="basketService.count()"
          style="
            color: red;
            margin-top: 10px;
            font-weight: 500;
            cursor: pointer;
          "
          (click)="basketService.resetBasket('')"
        >
          Vider le panier
        </div>
      </div>
    </div>
  </div>
  <app-about-zone class="restaurant-page__about-zone"> </app-about-zone>
</div>
