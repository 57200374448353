import { Injectable, OnInit } from '@angular/core';
import {BasketLocalstorage} from 'src/app/interfaces/basket-localstorage'

@Injectable({
  providedIn: 'root'
})
export class BasketLocalstorageService  {

  constructor() { 
    if(!this.isBasketExistInLocalStorage()) this.initializeLocalStorageWithEmptyBasket();
  }

  isBasketExistInLocalStorage():boolean{
    return localStorage.getItem("basket") == null ? false :  true;
  }

  updateBasketStoredInLocalStorage(basketLocalstorage:BasketLocalstorage){
    localStorage.setItem("basket", JSON.stringify(basketLocalstorage));
  }

  initializeLocalStorageWithEmptyBasket(){
    let obj:BasketLocalstorage = {
      itemsCount: 0,
      items: [],
      service: "Delivery",
      minimumPrice: 0,
      deliveryPrice: 0,
      serviceCharge: 0,
      restaurantOpen: false,
      hasTakeway: false,
      isOnlinePaymentAllowed: '',
      isCashPaymentAllowed: '',
      declinations: null,
      promoCodeInfos: {
        reduction: 0,
        promoCode: null,
        restaurantName: null,
        responseMessage: null,
        promoCodeType: null
      },
      hasDelivry:true
    }
    localStorage.setItem("basket",JSON.stringify(obj));    
  }

  getBasketStoredInLOcalStorage():BasketLocalstorage{
    const basket:BasketLocalstorage  = JSON.parse(localStorage.getItem("basket").toString());
    if(basket.items.length==0 && basket.service=="Takeaway") basket.service="Delivery";
    return basket;
  }

}
