import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export type MapInfos = {restaurantCoordinates:{lat,lng,icon?}, deliveryCoordinates:{lat,lng,icon?}, userCoordinates:{lat,lng,icon?}};

@Component({
  selector: 'app-order-google-map',
  templateUrl: './order-google-map.component.html',
  styleUrls: ['./order-google-map.component.scss']
})
export class OrderGoogleMapComponent implements OnInit, OnChanges {
  @Input("mapInfos") boundMapInfos:MapInfos;
  mapInfos:MapInfos;
  private map:any;
  private percent = 0;
  private readonly maximumPercent = 1;
  private readonly delay = 20; //milliseconds
  newPosition;

  styleArray = [
    { elementType: "geometry", stylers: [{ color: "#000000" }] },

    { elementType: "labels.text.stroke", stylers: [{ "visibility": "off" }] },

    { elementType: "labels.text.fill", stylers: [{ color: "#FFFFFF" }] },

    {

      featureType: "administrative.locality",

      elementType: "labels.text.fill",

      stylers: [{ color: "#FFFFFF" }],

    },

    {

      featureType: "poi",

      elementType: "labels.text.fill",

      stylers: [{ color: "#03544d" }],

    },{

      "featureType": "all",

      "elementType": "labels.icon",

      "stylers": [{

        "visibility": "off"

      }]

    },

    {

      featureType: "poi.park",

      elementType: "geometry",

      stylers: [{ color: "#263c3f" }],

    },

    {

      featureType: "poi.park",

      elementType: "labels.text.fill",

      stylers: [{ color: "#6b9a76" }],

    },

    {

      featureType: "road",

      elementType: "geometry",

      stylers: [{ color: "#38414e" }],

    },

    {

      featureType: "road",

      elementType: "geometry.stroke",

      stylers: [{ color: "#212a37" }],

    },

    {

      featureType: "road",

      elementType: "labels.text.fill",

      stylers: [{ color: "#9ca5b3" }],

    },

    {

      featureType: "road.highway",

      elementType: "geometry",

      stylers: [{ color: "#1f2835" }],

    },

    {

      featureType: "road.highway",

      elementType: "geometry.stroke",

      stylers: [{ color: "#1f2835" }],

    },

    {

      featureType: "road.highway",

      elementType: "labels.text.fill",

      stylers: [{ color: "#f3d19c" }],

    },

    {

      featureType: "transit",

      elementType: "geometry",

      stylers: [{ color: "#2f3948" }],

    },

    {

      featureType: "transit.station",

      elementType: "labels.text.fill",

      stylers: [{ color: "#d59563" }],

    },

    {

      featureType: "water",

      elementType: "geometry",

      stylers: [{ color: "#17263c" }],

    },

    {

      featureType: "water",

      elementType: "labels.text.fill",

      stylers: [{ color: "#515c6d" }],

    },

    {

      featureType: "water",

      elementType: "labels.text.stroke",

      stylers: [{ color: "#17263c" }],

    },
  ]

  heightOfBrowser;


  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {        
    if(changes.boundMapInfos && !changes.boundMapInfos.firstChange){
      this.newPosition = {
        lat:changes.boundMapInfos.currentValue.deliveryCoordinates.lat,
        lng:changes.boundMapInfos.currentValue.deliveryCoordinates.lng
      };
      if(!this.mapInfos) this.mapInfos = {...changes.boundMapInfos.previousValue};
      this.setMapMarkers();
      this.transition();
    }

  }

  ngOnInit(): void {
    this.heightOfBrowser = (window.innerHeight) + 'px'
    this.mapInfos = {...this.boundMapInfos};
    this.setMapMarkers();
  }


  setMapMarkers(){
    this.mapInfos.deliveryCoordinates.icon = {
      url: '../../../../assets/images/delivery-man-track.png',
      scaledSize: {
        width: 60,
        height: 60
      }
    }
    this.mapInfos.restaurantCoordinates.icon = {
      url:  '../../../../assets/images/restaurant-location.png',
      scaledSize: {
        width: 60,
        height: 60
      }
    }
    this.mapInfos.userCoordinates.icon  = {
      url:'../../../../assets/images/marker-icon.png',
      scaledSize: {
        width: 60,
        height: 60
      }
    }
  }

  mapReady(map:any){
    this.map = map;
    let mapOptions:any = {
      fullscreenControl: false,
    }
    const lat = this.mapInfos.deliveryCoordinates.lat;
    const lng = this.mapInfos.deliveryCoordinates.lng;
    map.setOptions(mapOptions);
    map.panTo({ lat, lng});
    map.setZoom(15);
  }

  transition(){
    this.percent=0;
    this.moveDeliveryMarker();
  }

  moveDeliveryMarker(){
    this.mapInfos.deliveryCoordinates.lat= this.mapInfos.deliveryCoordinates.lat + this.percent * ( this.newPosition.lat - this.mapInfos.deliveryCoordinates.lat );
    this.mapInfos.deliveryCoordinates.lng= this.mapInfos.deliveryCoordinates.lng + this.percent * ( this.newPosition.lng - this.mapInfos.deliveryCoordinates.lng );
    if(this.percent<1){
      this.percent+=0.01;
      setTimeout(this.moveDeliveryMarker.bind(this), this.delay);
    }    
  }
}
