<div
  style="
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  "
>
<!--  
<div *ngIf="oldVersionAlertOpen" class="old-version__container">
    <div class="old-version__text">
      Revenir vers l'ancienne version
      <a href="https://delivrini.com/"> Clique ici</a>
    </div>
    <img
      (click)="closeVersionAlert()"
      src="../assets/images/close-red.png"
      alt=""
      class="old-version__img"
    />
  </div>
-->
<!--
    *ngIf="isInMakeAnOrderPage"-->
  <app-header 
    *ngIf="!currentUrl.includes('makeanorder')"
    class="app__header" 
    [ngClass]="{'fixed': this.__sharedDataService.showSearchFieldToSearchInARestaurantPage}">
  </app-header>
  <router-outlet></router-outlet>
  <section class="basketForMobile">
      <div
        (click)="redirectToBasketPage()"
        *ngIf="currentUrl && currentUrl.includes('restaurant') && !currentUrl.includes('restaurants') && winodw.innerWidth < 500"
        class="basket-icon"
        id="basketCount"
      >
        <div class="cart">
          <img class="cart-image" src="./assets/images/cartfD.png" />
          <div class="cart-price">
            <div class="cart-title">PANIER</div>
            <div class="cart-number">{{ basketService.total() | number : '1.2-2' }} Dhs</div>
          </div>
          <span class="cart-count">{{ basketService.count() || 0 }}</span>
        </div>
      </div>
  </section>
</div>
