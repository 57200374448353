import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CouponCodeService {
  public intial: boolean = true;
  public loading: boolean = false;
  public sucess: boolean;
  public error: boolean;
  public message: string;
  public couponCode: string;

  constructor() {}

  apply(): void {
    this.loading = true;
    this.intial = false;
  }
}
