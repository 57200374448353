import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BasketService } from "../../services/basket.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { HttpService } from "../../services/http.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BasketItemDialogComponent } from "../basket-page/basket-page-item/basket-item-dialog/basket-item-dialog.component";
import { User } from 'src/app/interfaces/user';
import { Subject, Subscription } from "rxjs";
import { CheckoutService } from "src/app/services/checkout.service";
import { PopupInputsErrorsComponent } from "../popup-inputs-errors/popup-inputs-errors.component";
import { PopupConfirmSavingCardsComponent } from "../popup-confirm-saving-cards/popup-confirm-saving-cards.component";
import { PopupInformUserComponent } from "../popup-inform-user/popup-inform-user.component";
import { PopupPromoCodeComponent } from "../popup-promo-code/popup-promo-code.component";
import { PromotionRestaurantsService } from "src/app/services/promotion-restaurants.service";
import { PopupLoaderComponent } from "../popup-loader/popup-loader.component";
import { MapsService } from "src/app/services/maps.service";
import { first, last, pluck, takeUntil, tap } from "rxjs/operators";
import { PopupLocationErrorCheckoutPageComponent }
  from "../popup-location-error-checkout-page/popup-location-error-checkout-page.component";
import { SharedDataService } from "src/app/services/shared-data.service";
import { RestaurantPageService } from "src/app/services/restaurant-page.service";
import { PopupModernLoaderComponent } from "../popup-modern-loader/popup-modern-loader.component";
import { PopupRestaurantSilenceComponent } from "../popup-restaurant-silence/popup-restaurant-silence.component";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';


@Component({
  selector: "app-checkout-page",
  templateUrl: "./checkout-page.component.html",
  styleUrls: ["./checkout-page.component.scss"],
  providers: [[MapsService],
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
})
export class CheckoutPageComponent implements OnInit, AfterViewInit, OnDestroy {
  restaurantSilence: any;
  montantFidelite: any;
  formGroup: FormGroup;
  nameOfRestaurant: any;
  moneyBackMontant: any;
  acceptPrivacy: boolean = false;
  loading: boolean = false;
  estimationDuration: string
  user: User = null;
  private confirmSavingCardsObservable: Subscription;
  private userCardsCount: number = 0;
  isRestaurantDeliverToUserLocation: {
    isRestaurantDeliversInUserLocation: boolean,
    minimumOrder: number,
    deliveryCharges: number,
    closed:boolean,
    pre_commande:boolean
  } = null;
  @ViewChild("mapRef") private mapRefToScrollIntoIncompleteAddressElement: ElementRef;
  @ViewChild("preOrderRef") private preOrderDateRefToScrollIntoPreOrderInvalidMessages: ElementRef;
  private subjectToUnsubscribeFromObservables: Subject<any> = new Subject();

  @ViewChild('myDatePicker') picker: any;
  minDate = new Date();
  creneaux;
  preOrderDateIsSelected = true
  preOrderCreneauIsSelected = true

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public basketService: BasketService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private httpService: HttpService,
    public dialog: MatDialog,
    private __activatedRoute: ActivatedRoute,
    private __checkoutService: CheckoutService,
    private __promotionRestaurantService: PromotionRestaurantsService,
    private __sharedDataService:SharedDataService,
    private __restaurantPageService:RestaurantPageService
  ) { }

  closeDatePicker(){
    this.picker.open()
  }

  getCityName(city) {
    if (typeof city === "string") return city;
    return city?.name || "";
  }

  isDelivery() {
    return this.basketService.getService() === "Delivery";
  }

  ngOnInit(): void {
    this.montantFidelite =  this.basketService.montantFidelite
    this.nameOfRestaurant = this.route.snapshot.queryParamMap.get('name');
    if(this.nameOfRestaurant) this.getEstimationDuration()
    this.checkEmptyBasketAndLocation();
    this.applyPromoCodeAutomaticlly();
    this.initializeFormGroup({ nom: "", prenom: "", telephone: "", email: "" });
    this.__activatedRoute.data.subscribe(
      response => this.handleResponse(response.uls)
    );
  }


  getEstimationDuration(){
    if(!this.basketService.getLocation().isEmpty()){
      this.httpService.getEstimationDuration(this.basketService.getLocation().latitude, this.basketService.getLocation().longitude, this.nameOfRestaurant)
          .subscribe(estimationDuration => {
            this.estimationDuration = estimationDuration[0].duree
          })
    }
  }

  checkUserLocationValidity(index) {    
    if (!this.isRestaurantDeliverToUserLocation) return;
    if (this.isRestaurantDeliverToUserLocation.isRestaurantDeliversInUserLocation) {
      this.basketService.setMinimumPrice(this.isRestaurantDeliverToUserLocation.minimumOrder);
      if(!this.basketService.isServiceMagicDelivery())
        this.basketService.setDeliveryPrice(+this.isRestaurantDeliverToUserLocation.deliveryCharges);
      this.basketService.setHasDelivry(true);
      if(this.__sharedDataService.wasPreviousPageTheMap() && !this.basketService.isServiceMagicDelivery()){
        this.setService("Delivery");
      };
      if (!this.basketService.isReachedMinimum() && !this.basketService.isServiceTakeAway()) {
        //this.informUserThatMinimumCommandIsNotRichedAndRedirectUserToRestaurantPage();
        if(this.basketService.isMagicDelivryActiveInRestauarant){
          if(index){
            if(
              ( 
              this.__sharedDataService.getPreviousUrl() &&
              !this.__sharedDataService.getPreviousUrl().includes("restaurant") &&
              !this.__sharedDataService.getPreviousUrl().includes("checkout") &&
              !this.__sharedDataService.getPreviousUrl().includes("panier")
              ) ||
              !this.__sharedDataService.getPreviousUrl()
              ) 
            {
              this.openPopupConfirmUserChoiceToDeliverHisCommand();
            }
          }
          else this.openPopupConfirmUserChoiceToDeliverHisCommand();
        }
        else this.informUserThatMinimumCommandIsNotRichedAndRedirectUserToRestaurantPage();
      }
    }
    else{      
      this.basketService.setHasDelivry(false);
      if (!this.basketService.isServiceTakeAway()) this.informUserThatRestaurantDoesNotDeliverInHisLocation();
    }
  }

  openPopupConfirmUserChoiceToDeliverHisCommand(){
    this.__restaurantPageService.openPopupInformUserAboutWizardDeliveryWithoutRedirection(
      this.subjectToUnsubscribeFromObservables
    );
  }

  informUserThatRestaurantDoesNotDeliverInHisLocation() {
    let text = "Malheuresement, nous ne livrons pas dans votre quartier";
    if(!this.basketService.basket.hasTakeway) text+=", et la commande à  emporter n'est pas disponible."
    const data = {
      imgSrc:"deliver-user.png",
      text,
      btn1Text:"Modifiez votre adresse",
      btn2Text:`${this.basketService.basket.hasTakeway ? "Commande à emporter" : "Changez de restaurant"}`,
      btn1Fun:() => {
        this.redirectToMapPage();
      },
      btn2Fun:() => {
        if(this.basketService.basket.hasTakeway) this.setService("Takeaway");
        else this.redirectUserToHomePage();
      },
      subjectToUnsubscribeFromObservables:this.subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }

  openPopupLocationErrorCheckoutPageComponent(popupConfigs) {
    return this.dialog.open(PopupLocationErrorCheckoutPageComponent, popupConfigs);
  }

  redirectUserToRestaurantPage() {
    const obj = {
      name: this.basketService.basket.items[0].restaurant_name,
    }
    this.router.navigate(["restaurant"], { queryParams: obj })
  }

  informUserThatMinimumCommandIsNotRichedAndRedirectUserToRestaurantPage() {
    const data = {
      imgSrc: 'money.png',
      text: "Vous n'avez pas atteint le minimum de commande.",
      btn1Text: 'Modifiez votre adresse',
      btn2Text: 'Retournez à la page du restaurant',
      btn1Fun: () => this.redirectToMapPage(),
      btn2Fun: () => this.redirectUserToRestaurantPage(),
      subjectToUnsubscribeFromObservables: this.subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data)
    //this.openPopupConfirmUserChoice(data);
  }

  ngAfterViewInit(): void {

  }

  getErrorMessage(field: string): string {
    if (!this.formGroup.get(field).hasError("required")) {
      return "Ce champs est invalide";
    }
    return "";
  }

  conditionsAccepted(): boolean {
    const checkout = this.formGroup.getRawValue();
    if (checkout.moyen_paiement !== "0") {
      return this.acceptPrivacy;
    }
    return true;
  }

  conditionsPrivacyChanged(): void {
    this.acceptPrivacy = !this.acceptPrivacy;
  }

  submit() {
    if(
      (this.basketService.isServiceDelivery() || this.basketService.isServiceMagicDelivery()) && 
      this.basketService.getLocation().isMainAddressEmpty()
      ) 
      this.scrollTo(this.mapRefToScrollIntoIncompleteAddressElement);
    else if(this.areAddtionalAddressInfosEmptyAndServiceIsDelivery()) this.informUserThatHeHasIncompleteAddress();
    else {
      this.httpService
      .checkIfPick(this.basketService.basket.items[0].restaurant_id)
      .subscribe((result) => {
        this.restaurantSilence = result
        if (this.restaurantSilence.restaurant_silence == false ){
          this.informUserAboutPayment();
        }else{
          this.openPopupInformTheUserToTryAgainLater()
        }
      })
    }
    
    
  }

  areAddtionalAddressInfosEmptyAndServiceIsDelivery():boolean{
    return  (
            (this.basketService.isServiceDelivery() || this.basketService.isServiceMagicDelivery()) &&
            this.basketService.getLocation().areAddtionalAddressInfosEmpty()
            );
  }

  informUserThatHeHasIncompleteAddress(){
    const data = {
      imgSrc: "package.png",
      text: "Pour une meilleure expérience, nous vous recommandons de compléter votre adresse.",
      btn1Text: "Compléter mon adresse",
      btn2Text: "Continuer",
      btn1Fun: () => this.redirectUserToAddressDetailsPage(),
      btn2Fun: () => this.httpService
                          .checkIfPick(this.basketService.basket.items[0].restaurant_id)
                          .subscribe((result) => {
                            this.restaurantSilence = result
                            if (this.restaurantSilence.restaurant_silence == false ){
                              this.informUserAboutPayment();
                            }else{
                              this.openPopupInformTheUserToTryAgainLater()
                            }
                          }),
      subjectToUnsubscribeFromObservables: this.subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }


  informUserAboutPayment() {
    if (!this.formGroup.get("preOrderDate").value && this.formGroup.get("order_type").value == 'preOrder') {
      this.preOrderDateIsSelected = false
      this.scrollTo(this.preOrderDateRefToScrollIntoPreOrderInvalidMessages)
    }
    else if (!this.formGroup.get("creneau").value && this.formGroup.get("order_type").value == 'preOrder') {
      this.preOrderDateIsSelected = true
      this.preOrderCreneauIsSelected = false
      this.scrollTo(this.preOrderDateRefToScrollIntoPreOrderInvalidMessages)
    }
    else if (this.formGroup.get("moyen_paiement").value == "1" && this.userCardsCount == 0) {
      this.informUser().afterClosed().subscribe(data => this.validate());
    }
    else{
      this.basketService.getRestaurantStatus()
      .pipe(
        pluck("closed"),
        tap(closed => this.handleResponseOfRestaurantStatus(closed))
      )
      .subscribe()
    }
  }

  handleResponseOfRestaurantStatus(closed){
    if(closed && !this.isRestaurantDeliverToUserLocation.pre_commande) this.openSnackBar("Le restaurant est fermé temporairement","restaurant-is-close",2000);
    else this.validate();
  }

  validate(): void {
    //this.loading = true;
    const dialogRef = this.__checkoutService.openModernPopupLoader();
    const checkout = this.formGroup.getRawValue();
    checkout.items = this.basketService.basket.items.map((item) => {
      const returned = item;
      /*if (item.declinations && item.declinations.length) {
        returned.id_prix = item.declinations.selectedValue.id;
      }*/
      if (item.declinations && item.declinations != null) {
        returned.id_prix = item.declinations.selectedValue.id.toString();
      }
      return returned;
    });

    checkout.items = checkout.items.map((item) => {
      return {
        id_produit: item.id_produit,
        id_prix: item.id_prix,
        remark: item.remark,
        restaurant_id: item.restaurant_id,
        count: item.count,
        composants: item.compositions
          ? item.compositions.flatMap((composition) =>
            composition.composants
              .filter((composant) => composant.selected === true)
              .map((composant) => {
                return {
                  id_titre: composant.id_titre,
                  id_prix: composant.id_prix,
                  id_produit: composant.id_produit,
                  id_tpf: composant.id_tpf,
                  remark: item.remark,
                };
              })
          )
          : [],
      };
    });

    checkout.restaurant_id = checkout.items[0].restaurant_id;
    checkout.status = checkout.moyen_paiement === "0" ? 0 : -1;
    checkout.service = this.basketService.basket.service;
    checkout.frais_service = this.basketService.getServiceCharge();
    checkout.quartier = this.basketService.location.district;
    checkout.ville = this.basketService.location.city;
    const location = this.basketService.getLocation();
    checkout.adresse = `${location.mainAddress}${location.additionalAddressInfos ? ', ' + location.additionalAddressInfos : ''}`;
    checkout.latitude = location.latitude;
    checkout.longitude = location.longitude;
    checkout.point = 'ghostkitchen'
    checkout.utm = this.getUtmFromSessionStorage()



    if (checkout.status === 0) {
      this.httpService
        .postCheckout(checkout, checkout.status === 0 ? "espece" : "cb")
        .subscribe(({ commande }) => {
          this.basketService.resetBasket("");
          dialogRef.close();
          this.router.navigate([`/suivi-commande/${commande}`], { queryParams: {name: this.nameOfRestaurant} });
        }); 
    } else {
      this.httpService
        .postCheckout(checkout, checkout.status === 0 ? "espece" : "cb")
        .subscribe((result) => {
          this.createNecessarySessionForOnlinePayement(result.commande_id);
          dialogRef.close();
          window.location.href = result.commande;
        });
    }

  }

  getUtmFromSessionStorage(){
    return sessionStorage.getItem('utm')
  }

  getDeliveryPrice(): number {
    if(this.basketService.isServiceDelivery() || this.basketService.isServiceMagicDelivery()) return this.basketService.basket.deliveryPrice;
    else return 0;
  }

  private checkEmptyBasketAndLocation(): void {
    if (this.basketService.count() === 0) this.router.navigate(["/"]);
    this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      tap(res => {
        if(res){
          if(this.basketService.getLocation().isEmpty()){
            this.router.navigate(["map"]);
          }
        }
      })
    )
    .subscribe()
  }

  getOrderType(): string {
    if(this.isRestaurantDeliverToUserLocation && this.isRestaurantDeliverToUserLocation.closed && this.isRestaurantDeliverToUserLocation.pre_commande) return 'preOrder'
    else return 'immediate'
  }

  getSelectedPaymentMethod(): string {
    if( this.basketService.basket.isOnlinePaymentAllowed === '1' ){
      return '1';
    }
    else return '0'
    /*
    if (this.basketService.basket.isCashPaymentAllowed === '1') {
      return '0';
    }
    else if (this.basketService.basket.isCashPaymentAllowed === '0'
      && this.basketService.basket.isOnlinePaymentAllowed === '1') {
      return '1';
    }
    else {
      return '0';
    }
    */
  }

  showDialogDetails(item: any): void {
    item["isReadOnly"] = false
    this.dialog.open(BasketItemDialogComponent, {
      height: window.innerWidth < 500 ? "85%" : "740px",
      width: window.innerWidth < 500 ? "85%" : "400px",
      autoFocus: false,
      data: {
        item,
      },
      panelClass: "popupNeedRaduis"
    });
  }

  handleResponse(response) {
    if(response[2]){
      this.isRestaurantDeliverToUserLocation = response[2];
      if(this.isRestaurantDeliverToUserLocation.closed && !this.isRestaurantDeliverToUserLocation.pre_commande) this.redirectUserToRestaurantPage();
    }
    this.checkUserLocationValidity("First Time");
    this.handleMagicDeliveryAmountResponse(response[3].frais_livraison);
    this.user = response[0];
    this.userCardsCount = response[1];
    this.initializeFormGroup(this.user);
  }

  handleMagicDeliveryAmountResponse(magicDeliveryPrice){
    this.basketService.setMagicDeliveryAmount(magicDeliveryPrice);
    if(this.basketService.isServiceMagicDelivery()){
      if(this.basketService.isReachedMinimum()){
        this.basketService.setService("Delivery");
        if(this.isRestaurantDeliverToUserLocation)
          this.basketService.setDeliveryPrice(this.isRestaurantDeliverToUserLocation.deliveryCharges);
      }
      else this.basketService.setDeliveryPrice(magicDeliveryPrice); 
    }
  }

  initializeFormGroup({ nom, prenom, telephone, email }) {
    this.formGroup = this.fb.group({
      nom: [nom, [Validators.required, Validators.minLength(3)]],
      prenom: [prenom, [Validators.required, Validators.minLength(3), this.isFieldValid]],
      telephone: [
        telephone,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("0(6|7)[0-9]*$"),
        ],
      ],
      email: [
        email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            "^([a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+)@([a-zA-Z0-9-]+)(\\.[a-zA-Z0-9-]+)+$"
          ),
        ],
      ],
      quartier: this.isDelivery()
        ? [
          {
            value: '',
            disabled: true,
          },
          Validators.required,
        ]
        : [],
      ville: this.isDelivery()
        ? [
          {
            value: '',
            disabled: true,
          },
          [Validators.required, Validators.minLength(3)],
        ]
        : [],
      moyen_paiement: [this.getSelectedPaymentMethod(), Validators.required],
      note: [""],
      moneyBack: ["0"],
      moneyBackMontant: [""],
      order_type: [ this.getOrderType(), Validators.required],
      preOrderDate: ["", Validators.required],
      creneau: ["", Validators.required]
      });


    this.formGroup.get('preOrderDate').valueChanges.subscribe(res => { 
      this.formGroup.patchValue({creneau: ""})
      this.getCreneaux()
    })
  }


  getCreneaux(){
    const date: Date = this.formGroup.getRawValue().preOrderDate._d
    const restaurantId = this.basketService.basket.items[0]?.restaurant_id;
    if(date){
      this.httpService.getCrenaux(restaurantId, date.toLocaleDateString()).subscribe( creneauxTables => {
        this.creneaux = creneauxTables
      })
    }
  }


 

  createNecessarySessionForOnlinePayement(commandeId) {
    this.__checkoutService.createNecessarySessionForOnlinePayement(commandeId);
  }

  showPopupForFormError() {
    let field = "adresse";
    let msg = "Adresse : ";
    let errors = [];
    let fieldRef = this.formGroup.get(field);
    if (fieldRef.hasError("minlength")) { msg += "Ce champs doit comporter au moins 3 carractères."; errors.push(msg); }
    else if (fieldRef.hasError("required")) { msg += "Ce champs est requis."; errors.push(msg); }
    /*
    if(!this.conditionsAccepted()){
      msg = "Afin de poursuivre, merci de bien vouloir accepter les Termes et conditions";
      errors.push(msg);
    }
    */
    field = "prenom";
    msg = "Prénom : ";
    fieldRef = this.formGroup.get(field);
    if (fieldRef.hasError("minlength")) { msg += "Ce champs doit comporter au moins 3 carractères."; errors.push(msg); }
    else if (fieldRef.hasError("required")) { msg += "Ce champs est requis."; errors.push(msg); }
    this.openErrorFormDialog(errors);
  }

  isTheFormValid(): boolean {
    //return this.formGroup.valid && this.conditionsAccepted();
    return this.formGroup.valid;
  }

  openErrorFormDialog(errors) {
    this.dialog.open(PopupInputsErrorsComponent, {
      data: { errors }
    })
  }

  showPopupForSavingCards() {
    let errorMessage =
      "Vous serez désormais redirigé vers la plateforme du Centre Monétique Interbancaire afin d'enregistrer votre carte et procéder au paiement." +
      " Ainsi, vous n'aurez plus à re-saisir vos informations lors de vos prochaines commandes.";
    let dialogRef = this.dialog.open(PopupConfirmSavingCardsComponent,
      {
        data: { errorMessage },
      });
    this.handlePopupConfirmSavingCardsComponentReference(dialogRef);
  }

  handlePopupConfirmSavingCardsComponentReference(dialogRef: MatDialogRef<PopupConfirmSavingCardsComponent>) {
    this.confirmSavingCardsObservable = dialogRef.componentInstance.confirmSavingCards.asObservable()
      .subscribe(data => this.validate());
  }

  informUser(): MatDialogRef<PopupInformUserComponent> {
    let data = {
      header: "Information !",
      msg: `Vous avez désormais la possibilité d’enregistrer votre carte bancaire sur la plateforme du centre monétique interbancaire.<br>
      Ainsi, vous n'aurez plus à re-saisir vos informations lors de vos prochaines commandes.<br>
      Pour enregistrer votre carte allez dans Menu / Mes cartes bancaires.`,
      buttonClass: "warning"
    }
    let dialogConfigs = {
      panelClass: 'popup',
      data
    }
    return this.dialog.open(PopupInformUserComponent, dialogConfigs);
  }

  isPrenomReadOnly(): boolean {
    let rs: boolean = (this.user?.prenom && this.user.prenom != "") ? true : false;
    return rs;
  }

  isFieldValid(control: AbstractControl): { [key: string]: boolean } | null {
    return (control.value != undefined && control.value.trim() != "" && control.value.trim().length >= 3) ? null : { 'isNotValid': true }
  }

  openSnackBar(message: string, className: string, duration: number, action?: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = [className];
    config.duration = duration;
    config.horizontalPosition = "center";
    this.snackBar.open(message, action ? "Action Label" : undefined, config);
  }

  openPopupPromoCode() {
    let matDialogConfigs = {
      panelClass: "popup-promo-code",
      disableClose: true
    }
    let dialogRef = this.dialog.open(PopupPromoCodeComponent, matDialogConfigs);
    dialogRef.componentInstance.onCloseButtonClicked.asObservable()
      .pipe(takeUntil(this.subjectToUnsubscribeFromObservables))
      .subscribe(data => dialogRef.close());
    dialogRef.componentInstance.onPromoCodeBeingApplied.asObservable()
      .pipe(takeUntil(this.subjectToUnsubscribeFromObservables))
      .subscribe(data => this.handlePromoCodeSuccessefullResponse(dialogRef));
  }

  handlePromoCodeSuccessefullResponse(dialogRef: MatDialogRef<PopupPromoCodeComponent>) {
    let msg = "Félications, votre code promo a été appliqué avec succès";
    this.openSnackBar(msg, "promo-code-custom-class-success", 7000, "");
    dialogRef.close();
  }

  getTotalPriceAfterapplyingReduction() {
    return this.basketService.getTotalPriceAfterapplyingReduction();
  }

  applyPromoCodeAutomaticlly() {
    if (this.basketService.basket.promoCodeInfos.restaurantName && this.basketService?.basket?.promoCodeInfos?.reduction == 0) {
      let dialogRef = this.openPopupLoader();
      this.__checkoutService.validatePromoCode(this.basketService.basket.promoCodeInfos?.promoCode)
        .subscribe(
          data => this.handleSuccessefullResponseForApplyingPromoCodeAutomaticlly(data, dialogRef),
          error => this.handleFailedResponseForApplyingPromoCodeAutomaticlly(error, dialogRef)
        );
    }
  }

  handleSuccessefullResponseForApplyingPromoCodeAutomaticlly(response, dialogRef: MatDialogRef<PopupLoaderComponent>) {
    setTimeout(() => {
      dialogRef.close();
      let msg = "Félications, votre code promo a été appliqué avec succès";
      this.openSnackBar(msg, "promo-code-custom-class-success", 5000, "");
      this.basketService.basket.promoCodeInfos.reduction = response.reduction;
    }, 2000)
  }

  handleFailedResponseForApplyingPromoCodeAutomaticlly(error, dialogRef: MatDialogRef<PopupLoaderComponent>) {
    setTimeout(
      () => {
        dialogRef.close();
        let msg = this.__promotionRestaurantService.setPromoCodeResponseMsg(error.error.message)
        this.openSnackBar(msg, "promo-code-custom-class-error", 5000, "");
      },
      2000
    );
  }

  openPopupLoader(): MatDialogRef<PopupLoaderComponent> {
    let configs = {
      panelClass: "popup-loader",
      disableClose: true,
      data: {
        text: "On essaye d'appliquer le code promo"
      }
    }
    let dialogRef = this.dialog.open(PopupLoaderComponent, configs);
    return dialogRef;
  }

  openPopupInformTheUserToTryAgainLater(){
    let configs = {
      panelClass: "inform-the-user-to-try-again-later",
      disableClose: true    
    }
    let dialogRef = this.dialog.open(PopupRestaurantSilenceComponent, configs);
    return dialogRef;
  }

  redirectToMapPage(){
    this.router.navigate(["map"]);
  }

  redirectUserToAddressDetailsPage() {
    this.router.navigate(["adresse"], { relativeTo: this.__activatedRoute });
  }
  
  redirectUserToMapPage(){
    this.router.navigate(["map"]);
  }

  scrollTo(eRef: ElementRef) {
    eRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onServiceChange(service:string){
    this.setService(service);
    if(service=="Delivery"){
      this.__checkoutService.checkCommandValidity()
      .then(res => this.handleCommandValidityResponse(res));
    }
  }

  

  setService(service){
    this.basketService.setService(service);    
  }

  handleCommandValidityResponse(res){
    if(!res) return;
    this.isRestaurantDeliverToUserLocation = res;
    this.checkUserLocationValidity(null);
  }

  redirectUserToHomePage(){
    this.router.navigate([""]);
  }

  isMagicDelivery():boolean{
    return this.basketService.isServiceMagicDelivery();
  }

  moyenPaiementStatus():boolean{ 
    const checkout = this.formGroup.getRawValue();
    if (checkout.moyen_paiement == 0){
      return true
    }else return false
  }

  orderTypeStatus():boolean{ 
    const checkout = this.formGroup.getRawValue();
    if (checkout.order_type == "immediate"){
      return true
    }else return false
  }

  resetPreOrderDate(){
    const checkout = this.formGroup.getRawValue();
    if (checkout.order_type == "immediate"){
      this.formGroup.patchValue({preOrderDate: ""})
      this.formGroup.patchValue({creneau: ""})
      this.creneaux = null
    }else if (checkout.order_type == "preOrder"){
      this.formGroup.get('preOrderDate').valueChanges.subscribe(res => { 
      this.formGroup.patchValue({creneau: ""})
      this.getCreneaux()
      }) 
    }
  }

  amountToPay():boolean{ 
    const amountStatus = this.formGroup.getRawValue();
    if (amountStatus.moneyBack == "0"){
      return false
    }else return true
  }

  ngOnDestroy() {
    if (this.confirmSavingCardsObservable) this.confirmSavingCardsObservable.unsubscribe();
    this.subjectToUnsubscribeFromObservables.next("random value");
    this.subjectToUnsubscribeFromObservables.complete();
  }



}

function moneyBackMontant(arg0: { value: string; disabled: boolean; }, required: (control: AbstractControl) => import("@angular/forms").ValidationErrors): any {
  throw new Error("Function not implemented.");
}

