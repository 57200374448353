<div class="location-error__container">
    <h1 
        mat-dialog-title *ngIf="dialogInfos.header!=''"
        class="location-error__container-header"
        > 
        {{dialogInfos.header}} 
    </h1>
    <div 
        mat-dialog-content class="content" 
        [innerHtml]="dialogInfos.msg"
        class="location-error__container-msg"
    > </div>
    <div mat-dialog-actions align="center" class="location-error__container-btn-container">
        <button
            (click)="onButton1GetClicked()"
            mat-button mat-dialog-close 
            [ngClass]="dialogInfos.button1.buttonClass"
        >
            {{ dialogInfos.button1.buttonText }}
        </button>
        <button
            (click)="onButton2GetClicked()"
            mat-button mat-dialog-close 
            [ngClass]="dialogInfos.button2.buttonClass"
        >
            {{ dialogInfos.button2.buttonText }}
        </button>
    </div>
</div>