import {Injectable} from "@angular/core";
import {Item} from "../models/item";
import {Location} from "../models/location";
import {BasketLocalstorageService} from 'src/app/services/basket-localstorage.service';
import {MenuBasketLocalstorageService} from 'src/app/services/menu-basket-localstorage.service';
import { BasketLocalstorage } from "../interfaces/basket-localstorage";
import { MenuBasketLocalstorage } from "../interfaces/menu-basket-localstorage";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, Observable, Observer } from "rxjs";
import { HttpClientWithTokenService } from "./http-client-with-token.service";
import { HttpParams } from "@angular/common/http";
import { SharedDataService } from "./shared-data.service";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

export class BasketService  implements Resolve<Object>{
  
  basket:BasketLocalstorage = {
    itemsCount: 0,
    items: [],
    service : "Delivery",
    minimumPrice: 0,
    deliveryPrice: 0,
    serviceCharge: 0,
    restaurantOpen: false,
    hasTakeway: false,
    isOnlinePaymentAllowed: '',
    isCashPaymentAllowed: '',
    promoCodeInfos: {
      reduction: 0, promoCode: null, restaurantName: null, responseMessage: null, promoCodeType: null
    },
    declinations: null,
    hasDelivry:true,
  }

  montantFidelite: any;
  location:Location = null;
  magicDeliveryAmount:number = 0;
  isMagicDelivryActiveInRestauarant:boolean = false;

  constructor(
    private __basketLocalStorageService:BasketLocalstorageService,
    private __httpClientWithToken:HttpClientWithTokenService,
    private __sharedDataService:SharedDataService
  ) 
  {
    let basket =  this.__basketLocalStorageService.getBasketStoredInLOcalStorage();
    if(basket)  this.basket = basket;
    this.setLocation(null,null,null,null,null,null,null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object | Observable<Object> | Promise<Object> {
    
   return forkJoin([
      this.getRestaurantStatus(),
      this.getAndSetMagicDeliveryAmount()
    ]);
  }


  getRestaurantStatus():Observable<Object>{
    if(this.basket.items.length==0){
      return new Observable((observer:Observer<Object>) => {
        observer.next({closed:false});
      });
    }
    const restaurantId = this.basket.items[0].restaurant_id;
    const url = `${environment.apiUrl}cart/checkClosedPoint`;
    const params = new HttpParams().append("restaurantId",restaurantId);
    return this.__httpClientWithToken.get(url,params);
  }

  switchServiceAutomaticallyBetweenMagicAndDelivery(restaurantStreets){
    if(this.isServiceTakeAway()) return;
    if(this.isServiceMagicDelivery() && this.isReachedMinimum()) {      
      this.setService("Delivery");      
      this.settingMinimumPriceAndDeliveryPrice(this.getStreet(restaurantStreets));
    }
  }

  settingMinimumPriceAndDeliveryPrice(street){
    this.setMinimumPrice(Number(street.min_commande));
    if(this.isServiceDelivery()) this.setDeliveryPrice(Number(street.frais_de_livraison));
    else if(this.isServiceMagicDelivery()){
      if(this.isReachedMinimum()) this.switchServiceAutomaticallyBetweenMagicAndDelivery({quartier:[{...street}]});
      else this.setDeliveryPrice(this.magicDeliveryAmount);
    }
  }

  getStreet(restaurantStreets){
    const street = restaurantStreets.quartier.find(
      ({ nom_quartier }) =>  (
        this.getLocation().district 
        && 
        this.getLocation().district == nom_quartier
      )
    );
    return street;
  }

  incrementItemCount(item: any, index: number): void {
    const items = this.basket.items.filter(
      (element, i) => i === index && JSON.stringify(element) === JSON.stringify(item)
    );
    if (items.length) {
      ++items[0].count;
      this.setItemsCount(++this.basket.itemsCount);
      this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
    }
  }

  addItem(item: any): void {
    this.basket.items.push(item);  
    this.setItemsCount(this.basket.itemsCount+item.count);    
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  addRemarkToProdcut(item: any, remark?: string): void {
    this.basket.items.filter( _item => {
      if (_item.id === item.id) {
        item.remark = remark;
        this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
      }
    })
  }

  decrementItemCount(item: any, index: number): void {
    if (JSON.stringify(this.basket.items[index]) === JSON.stringify(item)) {
      if (this.basket.items[index].count > 1) {
        --this.basket.items[index].count;
        this.setItemsCount(--this.basket.itemsCount);
        this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
      }
      else this.removeItem(item, 0);
    }
  }

  removeItem(item: Item, index: number): void {
    if (index > -1) {
      this.basket.itemsCount -= this.basket.items[index].count;
      this.setItemsCount(this.basket.itemsCount);
      this.basket.items.splice(index, 1);
      this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
    }
  }

  count(): number {
    return this.basket.itemsCount;
  }

  total(): number {
    return this.basket.items
      .map((item) => item.unitPrice * item.count)
      .reduce((acc, val) => (acc += val), 0);
  }

  totalWithoutPromo(): number {
    return this.basket.items
      .map((item) => item.originalPrice * item.count)
      .reduce((acc, val) => (acc += val), 0);
  }

  getMontantFidelite(): number {
    return this.montantFidelite
  }

  

  getDeliveryPrice(): number {
    if(this.isServiceDelivery() || this.isServiceMagicDelivery())  return this.basket.deliveryPrice;
    return 0;
  }

  getServiceCharge():number{
    return this.basket.serviceCharge;
  }

  isReachedMinimum(): boolean {
    return this.total() >= this.basket.minimumPrice;
  }

  getService(): string {
    return this.basket.service;
  }

  setService(service: string): void {
    this.basket.service = service;    
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  resetBasket(id: string): void {
    if (this.basket.items.length == 0 || this.basket.items[0].restaurant_id !== id) {
      this.basket = {
        itemsCount: 0,
        items: [],
        service: "Delivery",
        minimumPrice: 0,
        deliveryPrice: 0,
        serviceCharge: 0,
        restaurantOpen: false,
        hasTakeway: false,
        isOnlinePaymentAllowed: '',
        isCashPaymentAllowed: '',
        declinations: null,
        promoCodeInfos: {
          reduction: 0,
          promoCode: null,
          restaurantName: null,
          responseMessage: null,
          promoCodeType: null
        },
        hasDelivry:true
      }
      this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
    }
  }

  setItemsCount(itemsCount:number){
    this.basket.itemsCount = itemsCount;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  setMinimumPrice(minimumPrice){    
    this.basket.minimumPrice=+minimumPrice;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  setDeliveryPrice(deliveryPrice){
    this.basket.deliveryPrice=+deliveryPrice;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);    
  }

  setServiceCharge(serviceCharge){
    this.basket.serviceCharge=+serviceCharge;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  setHasTakeway(hasTakeway){
    this.basket.hasTakeway = hasTakeway;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  setIsOnlinePaymentAllowed(isOnlinePaymentAllowed){
    this.basket.isOnlinePaymentAllowed = isOnlinePaymentAllowed;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  setIsCashPaymentAllowed(isCashPaymentAllowed){
    this.basket.isCashPaymentAllowed = isCashPaymentAllowed;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  getTotalPriceAfterapplyingReduction():number{
    let oldTotalPrice = this.total() + this.getDeliveryPrice() + this.getServiceCharge();
    let newTotalPrice = oldTotalPrice;
    if(this.basket.promoCodeInfos.reduction>0 && this.montantFidelite == 0) 
    {
      if(this.basket.promoCodeInfos.promoCodeType == 0){
        newTotalPrice = oldTotalPrice - (this.basket.promoCodeInfos.reduction);
      }else{
        newTotalPrice = oldTotalPrice - (this.total()*(this.basket.promoCodeInfos.reduction/100));
      } 
    }else  if(this.montantFidelite >= 0) {
      newTotalPrice = oldTotalPrice - this.montantFidelite;
    }

    if(newTotalPrice >= 0) return newTotalPrice
    else return 0
  }

  initializePromoCodeInfos(){
    this.basket.promoCodeInfos = {
      reduction:0, promoCode:null, restaurantName:null, responseMessage:null, promoCodeType: null
    };
  }

  setRestaurantNameAndPromoCodeForPromoCodeInfosObject(restaurantName, promoCode){
    if(!this.basket.promoCodeInfos.restaurantName) {
      this.basket.promoCodeInfos.restaurantName = restaurantName;
      this.basket.promoCodeInfos.promoCode = promoCode;
    }
    else if(this.basket.promoCodeInfos.restaurantName!=restaurantName) {
      this.initializePromoCodeInfos();
      this.basket.promoCodeInfos.restaurantName = restaurantName;
      this.basket.promoCodeInfos.promoCode = promoCode;
    }
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  getLocation(){
    return this.location;
  }

  setLocation(city,district,lat,long,mainAddress,additionalAdressInfos,id){
    this.location = new Location(city,district,lat,long,mainAddress,additionalAdressInfos,id);
  }

  setHasDelivry(value:boolean){    
    this.basket.hasDelivry = value;
    this.__basketLocalStorageService.updateBasketStoredInLocalStorage(this.basket);
  }

  isRestaurantHasDelivry():boolean{
    return this.basket.hasDelivry;
  }  

  isServiceDelivery():boolean{
    return this.getService()=="Delivery";
  }

  getRestaurantId():string{
    if(this.basket.items && this.basket.items.length==0) return null;
    const restaurant_id = this.basket.items[0].restaurant_id;
    return restaurant_id;
  }

  setCity(city){
    this.location.city = city;
  }

  setStreet(street){
    this.location.district = street;
  }

  setMagicDeliveryAmount(amount){
    this.magicDeliveryAmount = amount;
  }

  getAndSetMagicDeliveryAmount():Observable<any>{
    return new Observable((observer:Observer<any>) => {
    this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      tap(data =>{
        if(data){
          if(data.latitude || !this.location.isEmpty()){
            if(this.basket.items && this.basket.items.length>0){
              this.getMagicDeliveryAmount(this.basket.items[0].restaurant_id)
              .pipe(tap(data=>{
                this.magicDeliveryAmount = data.frais_livraison;
                this.isMagicDelivryActiveInRestauarant = data.frais_livraison>=0 ? true : false;
                observer.next(data.frais_livraison);
                observer.complete();
              }))
              .subscribe()
            }
          }
          else{
            this.magicDeliveryAmount = 0;
            observer.next(0);
            observer.complete();
          }
        }
      })
    )
    .subscribe()
    })
  }

  isServiceMagicDelivery():boolean{
    return this.basket.service == "magicDelivery";
  }

  isServiceTakeAway():boolean{
    return this.basket.service == "Takeaway";
  }

  getMagicDeliveryAmount(restaurantId):Observable<any>{
    const url = `${environment.apiUrl}magic/apply`;
    const params = new HttpParams().append("restaurantId",restaurantId).append("street",this.location.district);
    return this.__httpClientWithToken.get(url,params);
  }
}
