import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientWithTokenService {

  constructor(
    private __http:HttpClient
  ) { }

  get(url,params):Observable<any>{
    let headers:HttpHeaders  = this.createHeaders();
    return this.__http.get(url,{headers,params})
  }

  delete(url):Observable<any>{
    let headers:HttpHeaders  = this.createHeaders();
    return this.__http.delete(url,{headers})
  }

  post(url,body):Observable<any>{
    let headers:HttpHeaders  = this.createHeaders();
    return this.__http.post(url,body,{headers})
  }

  put(url,body):Observable<any>{
    let headers:HttpHeaders  = this.createHeaders();
    return this.__http.put(url,body,{headers})
  }

  private getToken(){
    let userInfos = null;
    if(localStorage.getItem("user_infos")) userInfos = JSON.parse(localStorage.getItem("user_infos"));
    else return null;
    return userInfos["token"];
  }

  private createHeaders(){
    let headers:HttpHeaders = new HttpHeaders({
      'Authorization' : `Bearer ${this.getToken()}`
    })
    return headers;
  }
}
