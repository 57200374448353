import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BasketService } from './basket.service';
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { HttpService } from './http.service';
import { SharedDataService } from './shared-data.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NewRestaurantsService {
  constructor(
    private __http:HttpClient,
    private __httpClientWithTokenService:HttpClientWithTokenService,
    private __authService:AuthService,
    private __basketService:BasketService,
    private __sharedDataService:SharedDataService,
    private __httpService:HttpService
  ) 
  { }

  afterLocateUserAutomaticllyGetNewRestaurants(category, ramadan: boolean):Observable<any[]>{
    const oservableThatReturnEmptyTable:Observable<any[]> = new Observable((observer:Observer<any[]>)=>{
      observer.next([]);
      observer.complete();
    });
    return this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      switchMap(response => {
        if(!response) return oservableThatReturnEmptyTable;
        return this.getNewRestaurants(category, ramadan);
      })
    )
  }


  getNewRestaurants(category, ramadan: boolean):Observable<any>{

    const currentUrl = window.location.href
    let url: string;

    if (ramadan) {

      if(currentUrl.includes('restaurants')){
        url = `${environment.apiUrl}restaurants/Ramadane_restaurants`;
      }else{
        url = `${environment.apiUrl}restaurants/Ramadane_boutiques`;
      }  
    } else {
      if(currentUrl.includes('restaurants')){
        url = `${environment.apiUrl}restaurants/New_restaurants`;
      }else{
        url = `${environment.apiUrl}restaurants/New_boutiques`;
      }   
    }
      const latitude = this.__basketService.getLocation().latitude;
      const longitude = this.__basketService.getLocation().longitude;
      let params:HttpParams = new HttpParams().append("category",category);
      if(latitude && longitude) {
        params = params.append("latitude",latitude+"").append("longitude",longitude+"");
      }
      return this.__http.get(url,{ params });
  }
}
