<div class="google-maps__container">
    <div 
        class="google-maps__container__marker-text"
        *ngIf="!disableChangingMap && displayMarkerText"
        >
        Placez le point exactement à l’emplacement où vous souhaitez vous faire livrer 
    </div>
    <img 
        src="../assets/images/marker-icon.png" 
        class="google-maps__container__marker-icon"
    >
    <img 
        *ngIf="!disableUseCurrenntLocationButton"
        src="../../../../assets/images/user-location.png" 
        class="google-maps__container__useMyLocation-icon"
        (click)="putMarkerOnUserLocation()"
    >
    <agm-map 
        *ngIf="!disableChangingMap"
        class="google-maps__container__agm-map"
        [latitude]="location.latitude" 
        [longitude]="location.longitude" 
        (mapReady)="mapReady($event)" 
        (centerChange)="centerChanged($event)"
        [zoom]="location.zoom"
        gestureHandling = "greedy"
        >
      <agm-marker
          class="google-maps__container__agm-map__agm-marker"
          [latitude]="location.latitude" 
          [longitude]="location.longitude" 
          [markerDraggable]="true"
          [opacity]="0"
          [agmFitBounds]="true"
       >
      </agm-marker>
    </agm-map>
    <agm-map 
        *ngIf="disableChangingMap"
        class="google-maps__container__agm-map"
        [latitude]="location.latitude" 
        [longitude]="location.longitude" 
        (mapReady)="mapReady($event)" 
        (centerChange)="centerChanged($event)"
        [zoom]="location.zoom"
        gestureHandling = "greedy"
        >
      <agm-marker
          class="google-maps__container__agm-map__agm-marker"
          [latitude]="location.latitude" 
          [longitude]="location.longitude" 
          [markerDraggable]="true"
          [opacity]="0"
          [agmFitBounds]="true"
       ></agm-marker>
    </agm-map>
  </div>