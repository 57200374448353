<div class="search-restaurants__container">
<div class="restaurants__container">
    <div class="restaurants__title">
      <span class="restaurants__title--text">Restaurants pour « &nbsp;<strong>{{ searchFieldValue }}</strong>&nbsp; »</span>
    </div>
    
    <div class="restaurants__body-container">
      
      <div class="restaurants__body" *ngIf="restaurants">
        <app-restaurant
          *ngFor="let restaurant of restaurants"
          [image]="restaurant.image"
          [city]="restaurant.city"
          [name]="restaurant.name | uppercase"
          [delivery]="restaurant.delivery"
          [takeAway]="restaurant.takeAway"
          [type]="restaurant.type"
          [categories]="restaurant.categories"
          [closed]="restaurant.ferme"
          class="restaurants__restaurant"
          (click)="redirectToRestaurantPage(restaurant.url_unique)"
        >
        </app-restaurant>
 
        <div class="restaurants__body" *ngIf="restaurants.length == 0 ">
          <img
          src="../../../assets/images/Localisation-not-found.png"
          style="height: 100%; width: 100%; border-radius: 13px; object-fit: fill"
          alt="images"
          />
        </div>
      </div>
    </div>
</div>
</div>