<div class="basket-page-item__container">
  <div (click)="showDialogDetails()" class="basket-page-item__upper-zone">
    <button
      mat-icon-button
      (click)="removeItem(item)"
      class="basket-page-item__upper-zone-button"
    >
      <mat-icon class="basket-page-item__upper-zone-button-icon"
        >close</mat-icon
      >
    </button>
    <img [src]="item.image" class="basket-page-item__upper-zone-image" />
  </div>

  <div class="basket-page-item__lower-zone">
    <div class="basket-page-item__lower-zone-name">
      {{ item.name | uppercase }}
      <span *ngIf="!item.compositions">
        {{ item.declinations?.selectedValue?.label | lowercase }}
      </span>
    </div>
    <div class="basket-page-item__lower-zone-price">
      <div class="basket-page-item__counter">
        <mat-icon
          (click)="decrementItemCount(item)"
          class="basket-page-item__counter-icon"
          >remove</mat-icon
        >
        <span>&nbsp;{{ item.count }}&nbsp;</span>
        <button 
          class="basket-page-item__counter-icon-buttomAdd" 
          [disabled]="item.compositions && item.quantite_stock <= sharedData.getTotalOfAnItemInTheBasket(item.id_produit) && item.quantite_stock != -1
                  || !item.compositions && item.declinations.selectedValue.quantite_stock <= sharedData.getTotalOfAnItemInTheBasketByIdOfDeclination(item.id_prix) && item.quantite_stock != -1"
          (click)="incrementItemCount(item)"
        >


          <mat-icon
          class="basket-page-item__counter-icon-add"
          [ngClass]="{
            'disabled':  item.compositions && item.quantite_stock <= sharedData.getTotalOfAnItemInTheBasket(item.id_produit) && item.quantite_stock != -1
                    ||  !item.compositions && item.declinations.selectedValue.quantite_stock <= sharedData.getTotalOfAnItemInTheBasketByIdOfDeclination(item.id_prix) && item.quantite_stock != -1
          }"
          >
            add
          </mat-icon>
        </button>
      </div>
      <span class="basket-page-item__price">
        {{ item.unitPrice | number : '1.2-2' }}<span *ngIf="item.unitPrice !== null"> Dhs</span>
      </span>
    </div>
  </div>
</div>
