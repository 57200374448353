import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { MatDialog } from "@angular/material/dialog";
import { Item } from 'src/app/models/item';
import { BasketService } from 'src/app/services/basket.service';
import { MenuBasketService } from 'src/app/services/menu-basket.service';
import { MakeanorderService } from 'src/app/services/makeanorder.service';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Table } from 'src/app/models/table';
import { LocalOrder } from 'src/app/models/local-order';



@Component({
  selector: 'app-make-an-order',
  templateUrl: './make-an-order.component.html',
  styleUrls: ['./make-an-order.component.scss']
})
export class MakeAnOrderComponent implements OnInit {

  //Variables of CountDown
  interval: any;
  public seconds: number = 3;
  public screenWidth: any;
  public screenHeight: any;
  public screenWidthInPixels: any;
  public screenHeightInPixels: any;

  public restaurantData;
  public restaurantCategories;
  public categorie= {
                      'produits':'vide'
                    };
  private restaurantClosed:boolean;
  ItemCompositionDetails = new Array();

  composantss = new Array()
  public menuHeight;
  public basketHeight;
  public showBasket = false;

  private table: Table;
  TableExists: boolean = false
  myControl = new FormControl('');
  options: Table[];
  filteredOptions: Observable<Table[]>;

  restaurantName: string;
  restaurantId: any;

  orderClicked = false
  orderConfirmed = false

  constructor(
    private router: Router,
    private httpService: HttpService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public basketService: BasketService,
    public menuBasketService: MenuBasketService,
    private makeAnOrderService: MakeanorderService
  ) { }


  ngOnInit(): void { 
      this.menuBasketService.resetBasket()
      this.getRestaurantsTables()
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight ;
      this.screenWidthInPixels = window.innerWidth + 'px'
      this.screenHeightInPixels = window.innerHeight + 'px'
      this.basketHeight = (this.screenHeight - 75 - 60 - 60) + 'px';
      this.menuHeight = (this.screenHeight - 60) + 'px'
      this.fetchData();
  }

  checkIfTheTableExists(option){
    if(this.options.find( table => table.id_table == option)) this.TableExists = true
    else this.TableExists = false
  }

  goToBasketPage(){
    this.showBasket = true
  }

  displayFn(table: Table): string {
    return table && table.id_table ? table.id_table : '';
  }

  private _filter(idTable: string): Table[] {
    const filterValue = idTable.toLowerCase();
    return this.options.filter(option => option.id_table.toLowerCase().includes(filterValue));
  }

  getRestaurantsTables(){
    const urlUnique = this.route.snapshot.queryParamMap.get('name');
    this.makeAnOrderService.getRestaurantsTables(urlUnique).subscribe( res => {
        this.options = res
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.name;
            return name ? this._filter(name as string) : this.options.slice();
          }),
        );
    } )
  }

  fetchData(){
    const restaurantUniqueUrl = this.route.snapshot.queryParamMap.get('name');
    this.httpService.getRestaurant(restaurantUniqueUrl, '11').subscribe( res => {
      this.restaurantData = res
      this.restaurantCategories = res.categories
      this.restaurantClosed = res.ferme
      this.fetchDataByCategorie('TEMPURA')
      }
      )
    }

    @HostListener('window:resize', ['$event'])
      onResize(event) {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        this.screenWidthInPixels = window.innerWidth + 'px'
        this.screenHeightInPixels = window.innerHeight + 'px'
        this.basketHeight = (this.screenHeight - 75 - 60 - 60) + 'px';
      }
   

  fetchDataByCategorie(categorie :string){
    for (let i = 0; i < this.restaurantCategories.length; i++) {
      if (this.restaurantCategories[i].cat_name == categorie){
        this.categorie = this.restaurantCategories[i]
      }
    }
    return this.categorie
  }

    getTheLowestPriceOfHeadingItem(item):number{
      if(item.declinaisons && item.declinaisons.length>1){
        let min = item.declinaisons[0].prix;
        for(let i=1;i<item.declinaisons.length;i++){
          if(item.declinaisons[i].prix<min) min=item.declinaisons[i].prix;
        }
        return min;
      }
      else return item.prix;
    }

    openSnackBar(message: string, action?: string) {
      let config = new MatSnackBarConfig();
      config.panelClass = ["custom-class"];
      config.duration = 2000;
      config.horizontalPosition = "center";
      this.snackBar.open(message, action ? "Action Label" : undefined, config);
    }

    getItemCompositionDetails(item: Item, index: number){
      const filter = (term, obj) => {
        const res = [];
        for (let i = 0; i < obj.compositions.length; i++) {
          const newComposants = obj.compositions[i].composants.filter((composant) => {
            if (composant.selected == true) {
              return true;
            }
          });
          if (newComposants.length > 0) {
            res.push({ ...obj.compositions[i], composants: newComposants });
          }
        }
      
        return res;
      };
      const result = filter(true, item);
      return result
    }

    getItemComposition(item: Item, index: number){
      if(item){
        var compositions :Record<string, any> = {};
        var composants = new Set<string>();
          item.compositions.forEach(compositions => {
            //test.compositionsTitle = compositions.titre
            compositions.composants.forEach(composant =>{
              if(composant.selected){
                composants.add(composant.name)
              }
            })
            compositions.compositionsComposant = composants
          });
          this.ItemCompositionDetails.push(compositions)
      }
        return this.ItemCompositionDetails
    }


    incrementItemCount(item: Item, index: number): void {
      this.menuBasketService.incrementItemCount(item, index);
    }

    decrementItemCount(item: Item , index: number): void {
      this.menuBasketService.decrementItemCount(item, index);
    }

    removeItem(item: Item, index: number): void {
      this.menuBasketService.removeItem(item, index);
    }

    reset(){
      this.menuBasketService.resetBasket()
      this.orderClicked = false
      this.stopTimer()
      this.seconds = 3
    }

    resetBasket(){
      this.menuBasketService.resetBasket()
    }
  
    addToBasket(product) {
      if (
        (product.declinaisons &&
          product.declinaisons.length > 1) ||
          product.formule.length
      ) {
        this.dialog.open(ItemDetailsComponent, {
          height: window.innerWidth < 500 ? "85%" : "740px",
          width: window.innerWidth < 500 ? "85%" : "400px",
          autoFocus: false,
          data: {
            item: product,
          },
          panelClass:"popupNeedRaduis"
        });    
      } else {      
        const item1 = new Item();
        item1.id = product.id_produit;
        item1.id_produit = product.id_produit;
        item1.id_prix = product.id_prix;
        item1.name = product.designation;
        item1.unitPrice = product.prix;
        item1.originalPrice = product.prix;
        item1.count = 1;
        item1.image = product.image;
        item1.description = product.description;
        item1.restaurant_id = product.restaurant_id;
        item1.restaurant_name = product.restaurant_name;
        item1.quantite_stock = product.quantite_stock
    
        //const declinations = this.headingItem.declinaisons;
        const declinations:{items:Item[],selectedValue:Item} = {items:product.declinaisons,selectedValue:null};      
        declinations.selectedValue = product.declinaisons[0];
        item1.declinations = declinations;
  
        if(product.prixpromo != 0 && product.prixpromo != product.prix){
          item1.unitPrice = declinations.selectedValue.prixpromo;
        }else{
          item1.unitPrice = declinations.selectedValue.prix;
        }
  
        item1.id = declinations.selectedValue.produit_id;
        this.menuBasketService.addItem(item1); 
        
        
  
        // Shake basket
        const BasketElementToShake = document.getElementById('basketCount')
        BasketElementToShake.classList.remove("shake")
        setTimeout(()=>{BasketElementToShake.classList.add("shake") }, 50)
        navigator.vibrate(800)
        
        /* Snack bar
        let config = new MatSnackBarConfig();
        config.duration = 1500;
        config.horizontalPosition = "center";
        config.verticalPosition = "top";
        this.snackBar.open(
          this.headingItem.designation.toUpperCase(),
          "Ajouté(e) au panier",
          config
        );
        */
        
      }
    }


    showItemDetails(product): void {
      /*
      if (this.restaurantClosed) {
        return this.openSnackBar("Le restaurant est fermé temporairement", "");
      }
      */
      this.dialog.open(ItemDetailsComponent, {
        height: window.innerWidth < 500 ? "85%" : "75vh", // 480px
        width: window.innerWidth < 500 ? "85%" : "400px",
        autoFocus: false,
        data: {
          item: {
            ...product,
          },
          restaurantClosed: false
  
        },
        panelClass:"popupNeedRaduis"
      });
    }

    validate(){
        this.orderClicked = true
        this.interval = setInterval(()=>{
                                          this.seconds--
                                        }, 1000)   
    }

    stopTimer(){
      clearInterval(this.interval);
    }

    confirmation(): void {
      this.orderConfirmed = true
      this.stopTimer()
      const checkout = {
        email: "",
        frais_service: 0,
        items:[],
        latitude: "0",
        longitude: "0",
        moyen_paiement: "0",
        nom: "",
        note: "",
        point: "lalivraison.ma",
        prenom: "",
        restaurant_id: "",
        service: "Delivery",
        status: 0,
        telephone: "",
        table: "",
      }

      checkout.items = this.menuBasketService.menuBasket.items.map((item) => {
        const returned = item;
        /*if (item.declinations && item.declinations.length) {
          returned.id_prix = item.declinations.selectedValue.id;
        }*/
        if (item.declinations && item.declinations != null) {
          returned.id_prix = item.declinations.selectedValue.id.toString();
        }
        return returned;
      });

      checkout.items = checkout.items.map((item) => {
        return {
          id_produit: item.id_produit,
          id_prix: item.id_prix,
          remark: item.remark,
          restaurant_id: item.restaurant_id,
          count: item.count,
          composants: item.compositions
            ? item.compositions.flatMap((composition) =>
              composition.composants
                .filter((composant) => composant.selected === true)
                .map((composant) => {
                  return {
                    id_titre: composant.id_titre,
                    id_prix: composant.id_prix,
                    id_produit: composant.id_produit,
                    id_tpf: composant.id_tpf,
                    remark: item.remark,
                  };
                })
            )
            : [],
        };
      });

      checkout.restaurant_id = checkout.items[0].restaurant_id;
      checkout.point = 'lalivraison.ma';
      checkout.table = this.myControl.value.id_table;

      this.httpService.postCheckoutForLocalOrders(checkout).subscribe( commande =>{
        this.menuBasketService.resetBasket()
        this.showBasket = false  
        this.orderClicked = false
        this.orderConfirmed = false
        this.seconds = 3
      })

    }

}
