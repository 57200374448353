import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {NewPasswordService} from 'src/app/services/new-password.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
   loading:boolean = false; 
   __formGroup:FormGroup;
   isTheAppShowingPassword:boolean = false;
   isTheAppShowingConfirmPassword:boolean = false;
   private uuid:string;
   @ViewChild("myForm") form:NgForm; 

  constructor(
    private __fb:FormBuilder,
    private __activtedRoute:ActivatedRoute,
    private __newPasswordService:NewPasswordService,
    private toaster:ToastrService,
    private __router:Router
  ) 
  { }

  ngOnInit(): void {
    this.initializeForm();
    this.uuid = this.__activtedRoute.snapshot.queryParamMap.get("uuid");    
  }

  initializeForm(){
    this.__formGroup = this.__fb.group({
      password:["",[Validators.required, Validators.minLength(6)]],
      confirmPassword:["",[Validators.required, Validators.minLength(6), this.checkPasswords]]
    })
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = this.__formGroup?.get('password')?.value;
    let confirmPass = this.__formGroup?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  getErrorMessage(field:string){
    if (this.__formGroup.get(field).hasError("notSame")) {
      return "Vous devez saisir le même mot de passe";
    }

    if (!this.__formGroup.get(field).hasError("required") ) {
      return "Ce champs est invalide";
    }

    return "";
  }

  hideThePassword(fieldRef,inputName){
    fieldRef.type="password"; 
    if(inputName=="password") this.isTheAppShowingPassword = false;
    else this.isTheAppShowingConfirmPassword = false;
  }
 
  showThePassword(fieldRef,inputName){
     fieldRef.type="text";
     if(inputName=="password") this.isTheAppShowingPassword = true;
     else this.isTheAppShowingConfirmPassword = true;
  }

  onSubmit(){
    this.loading=true;
    this.__newPasswordService.updatePassword(this.uuid,this.__formGroup.get("password").value)
    .subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(response){
    this.loading=false;
    let header = "Bravo !";
    let msg = "Votre mot de passe a bien été rénitialisé";
    this.showSuucessToaster(msg,header)
    .onHidden.subscribe(data => this.__router.navigate(["se-connecter"]));
    this.clearForm();
  }

  handleError(error){
    this.loading=false;
    let header = "Erreur !";
    let msg = "Il faut pas modifié le lien";
    this.showErrorToaster(msg,header)
  }

  showSuucessToaster(msg,header){
    return this.toaster.success(msg,header,{timeOut:2000})
  }

  showErrorToaster(msg,header){
    this.toaster.error(msg,header,{timeOut:6000})
  }

  clearForm(){
    this.form.resetForm();
    this.__formGroup.reset();
  }

}
