import { Injectable } from '@angular/core';
import { MenuBasketLocalstorage } from '../interfaces/menu-basket-localstorage';
import { Item } from '../models/item';
import { MenuBasketLocalstorageService } from './menu-basket-localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuBasketService {

  menuBasket:MenuBasketLocalstorage = {
    itemsCount: 0,
    items: [],
    service : "Delivery",
    minimumPrice: 0,
    deliveryPrice: 0,
    serviceCharge: 0,
    restaurantOpen: false,
    hasTakeway: false,
    isOnlinePaymentAllowed: '',
    isCashPaymentAllowed: '',
    promoCodeInfos: {
      reduction: 0, promoCode: null, restaurantName: null, responseMessage: null
    },
    declinations: null,
    hasDelivry:true
  }
  
  constructor(private __menuBasketLocalStorageService: MenuBasketLocalstorageService,) {
    let menuBasket =  this.__menuBasketLocalStorageService.getBasketStoredInLOcalStorage();
    if(menuBasket)  this.menuBasket = menuBasket;
   }

  setItemsCount(itemsCount:number){
    this.menuBasket.itemsCount = itemsCount;
    this.__menuBasketLocalStorageService.updateBasketStoredInLocalStorage(this.menuBasket);
  }

  addItem(item: any): void {
    this.menuBasket.items.push(item);   
    this.setItemsCount(this.menuBasket.itemsCount+item.count);   
    this.__menuBasketLocalStorageService.updateBasketStoredInLocalStorage(this.menuBasket);
  }

  incrementItemCount(item: any, index: number): void {
    const items = this.menuBasket.items.filter(
      (element, i) => i === index && JSON.stringify(element) === JSON.stringify(item)
    );
    if (items.length) {
      ++items[0].count;
      this.setItemsCount(++this.menuBasket.itemsCount);
      this.__menuBasketLocalStorageService.updateBasketStoredInLocalStorage(this.menuBasket);
    }
  }

  decrementItemCount(item: any, index: number): void {
    if (JSON.stringify(this.menuBasket.items[index]) === JSON.stringify(item)) {
      if (this.menuBasket.items[index].count > 1) {
        --this.menuBasket.items[index].count;
        this.setItemsCount(--this.menuBasket.itemsCount);
        this.__menuBasketLocalStorageService.updateBasketStoredInLocalStorage(this.menuBasket);
      }
      else this.removeItem(item, 0);
    }
  }

  removeItem(item: Item, index: number): void {
    if (index > -1) {
      this.menuBasket.itemsCount -= this.menuBasket.items[index].count;
      this.setItemsCount(this.menuBasket.itemsCount);
      this.menuBasket.items.splice(index, 1);
      this.__menuBasketLocalStorageService.updateBasketStoredInLocalStorage(this.menuBasket);
    }
  }

  totalWithoutPromo(): number {
    return this.menuBasket.items
      .map((item) => item.originalPrice * item.count)
      .reduce((acc, val) => (acc += val), 0);
  }

  resetBasket(): void {
      this.menuBasket = {
        itemsCount: 0,
        items: [],
        service: "Delivery",
        minimumPrice: 0,
        deliveryPrice: 0,
        serviceCharge: 0,
        restaurantOpen: false,
        hasTakeway: false,
        isOnlinePaymentAllowed: '',
        isCashPaymentAllowed: '',
        declinations: null,
        promoCodeInfos: {
          reduction: 0,
          promoCode: null,
          restaurantName: null,
          responseMessage: null
        },
        hasDelivry:true,
      }
      this.__menuBasketLocalStorageService.updateBasketStoredInLocalStorage(this.menuBasket);
      
  }

}
