<div class="stepper__container"> 
  <div  *ngIf="state == -1" class="stepper__step">
    <div class="stepper__step-circle-line-container">
      <div
        class="stepper__step-circle"
        [ngClass]="{ 'stepper__step-circle-filled': state == -1 }"
      >
        <img
          class="stepper__step-circle-image"
          src="../../../../assets/images/waiting-for-payement.png"
        />
      </div>
      <div
        class="stepper__line"
        [ngClass]="{ 'stepper__line-filled': state >=0 }"
      ></div>
    </div>
    <div
      class="stepper__step-text"
      [ngClass]="{ 'stepper__step-text-filled': state == -1 }"
    >
      <span style="white-space: pre">En attente de paiement</span>
      <span class="stepper__step-text-filled-hour-mobile" *ngIf="times">{{
        times.date_confirmation
      }}</span>
    </div>
    <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times"
      style="font-weight: normal"
    >
      {{ times.date_confirmation }}
    </div>
  </div>

  <div class="stepper__step">
    <div class="stepper__step-circle-line-container">
      <div
        class="stepper__step-circle"
        [ngClass]="{ 'stepper__step-circle-filled': state >= 0 }"
      >
        <img
          class="stepper__step-circle-image"
          src="../../../../assets/images/order_status_icon_stepper_validated.png"
        />
      </div>
      <div
        class="stepper__line"
        [ngClass]="{ 'stepper__line-filled': state > 0 }"
      ></div>
    </div>
    <div
      class="stepper__step-text"
      [ngClass]="{ 'stepper__step-text-filled': state >= 0 }"
    >
      <span style="white-space: pre">En attente</span>
      <span class="stepper__step-text-filled-hour-mobile" *ngIf="times">{{
        times.date_confirmation
      }}</span>
    </div>
    <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times"
      style="font-weight: normal"
    >
      {{ times.date_confirmation }}
    </div>
  </div>

  <div class="stepper__step" *ngIf="state === 3">
    <div class="stepper__step-circle-line-container">
      <div class="stepper__step-circle-filled">
        <img
          class="stepper__step-circle-image"
          src="../../../../assets/images/order_status_icon_stepper_validated.png"
        />
      </div>
    </div>
    <div class="stepper__step-text-filled">
      <span *ngIf="status!='Annulée';else CancelStatus" style="white-space: pre">Refusée</span>
      <ng-template #CancelStatus>
        <span  style="white-space: pre">Annulée</span>
      </ng-template>
      <span
        class="stepper__step-text-filled-hour-mobile"
        *ngIf="times && times.date_annuler"
      >
        {{ times.date_annuler }}</span
      >
    </div>
    <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times && times.date_annuler"
      style="font-weight: normal"
    >
      {{ times.date_annuler }}
    </div>
  </div>

  <div class="stepper__step" *ngIf="state !== 3">
    <div class="stepper__step-circle-line-container">
      <div
        class="stepper__step-circle"
        [ngClass]="{ 'stepper__step-circle-filled': state >= 1 }"
      >
        <img
          class="stepper__step-circle-image"
          src="../../../../assets/images/order_status_icon_stepper_ready.png"
        />
      </div>
      <div
        class="stepper__line"
        [ngClass]="{ 'stepper__line-filled': state > 1 }"
      ></div>
    </div>
    <div
      class="stepper__step-text"
      [ngClass]="{ 'stepper__step-text-filled': state >= 1 }"
    >
      <span style="white-space: pre">En cours de préparation</span>
      <span
        class="stepper__step-text-filled-hour-mobile"
        *ngIf="times && times.date_acceptation"
      >
        {{ times.date_acceptation }}
      </span>
    </div>
    <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times && times.date_acceptation"
      style="font-weight: normal"
    >
      {{ times.date_acceptation }}
    </div>
  </div>

  <div *ngIf="(serviceType === 'DELIVERY' || serviceType === 'PREORDER-DELIVERY') && state !== 3" class="stepper__step">
    <div class="stepper__step-circle-line-container">
      <div
        class="stepper__step-circle"
        [ngClass]="{
          'stepper__step-circle-filled': state === 2 || state === 4
        }"
      >
        <img
          src="../../../../assets/images/order_status_icon_stepper_sent.png"
          class="stepper__step-circle-image"
          style="margin-left: -2px; margin-top: -2px"
        />
      </div>
      <div
        class="stepper__line"
        [ngClass]="{ 'stepper__line-filled': state === 2 }"
      ></div>
    </div>
    <div
      class="stepper__step-text"
      [ngClass]="{ 'stepper__step-text-filled': state === 2 || state === 4 }"
    >
      <span style="white-space: pre">En cours de livraison</span>
      <span
        class="stepper__step-text-filled-hour-mobile"
        *ngIf="times && times.date_livreur"
      >
        {{ times.date_livreur }}
      </span>
    </div>
    <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times && times.date_livreur"
      style="font-weight: normal"
    >
      {{ times.date_livreur }}
    </div>
  </div>

  <div *ngIf="(serviceType === 'DELIVERY' || serviceType === 'PREORDER-DELIVERY') && state !== 3" class="stepper__step">
    <div class="stepper__step-circle-line-container">
      <div
        class="stepper__step-circle"
        [ngClass]="{ 'stepper__step-circle-filled': state === 2 }"
      >
        <img
          src="../../../../assets/images/order_status_icon_stepper_delivered.png"
          class="stepper__step-circle-image"
        />
      </div>
    </div>
    <div
      class="stepper__step-text"
      [ngClass]="{ 'stepper__step-text-filled': state === 2 }"
    >
      <span style="white-space: pre">Livrée</span>
      <span
        class="stepper__step-text-filled-hour-mobile"
        *ngIf="times && times.date_livre"
      >
        {{ times.date_livre }}
      </span>
    </div>
    <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times && times.date_livre"
      style="font-weight: normal"
    >
      {{ times.date_livre }}
    </div>
  </div>

  <div *ngIf="(serviceType === 'TAKEAWAY' || serviceType === 'PREORDER-TAKEAWAY') && state !== 3" class="stepper__step">
    <div class="stepper__step-circle-line-container">
      <div
        class="stepper__step-circle"
        [ngClass]="{ 'stepper__step-circle-filled': state === 2 }"
      >
        <img
          src="../../../../assets/images/order_status_icon_stepper_delivered.png"
          class="stepper__step-circle-image"
        />
      </div>
    </div>
    <div
      class="stepper__step-text"
      [ngClass]="{ 'stepper__step-text-filled': state === 2 }"
    >
      <span>Livrée</span>
      <span
        class="stepper__step-text-filled-hour-mobile"
        *ngIf="times && times.date_livre"
      >
        {{ times.date_livre }}
      </span>
    </div>
    <!-- <div
      class="stepper__step-text-filled-hour-desktop"
      *ngIf="times && times.date_livre"
      style="font-weight: normal"
    >
      {{ times.date_livre  }}
    </div> -->
  </div>
</div>
