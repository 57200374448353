import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderComponent } from "./components/header/header.component";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { SwiperComponent } from "./components/swiper/swiper.component";
import { CarouselCategoriesComponent } from "./components/carousel-categories/carousel-categories.component";
import { CarouselItemComponent } from "./components/carousel-categories/carousel-item/carousel-item.component";
import { JoinUsComponent } from "./components/join-us/join-us.component";
import { NewsletterComponent } from "./components/newsletter-zone/newsletter.component";
import { AboutZoneComponent } from "./components/about-zone/about-zone.component";
import { RestaurantsComponent } from "./components/restaurants/restaurants.component";
import { RestaurantComponent } from "./components/restaurants/restaurant/restaurant.component";
import { LocationChooserComponent } from "./components/location-chooser/location-chooser.component";
import { HomePageComponent } from "./components/home-page/home-page.component";
import { RestaurantPageComponent } from "./components/restaurant-page/restaurant-page.component";
import { SelectedHeadingComponent } from "./components/restaurant-page/selected-heading/selected-heading.component";
import { SelectedHeadingItemComponent } from "./components/restaurant-page/selected-heading/selected-heading-item/selected-heading-item.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { SelectedHeadingItemDialogComponent } from "./components/restaurant-page/selected-heading/selected-heading-item/selected-heading-item-dialog/selected-heading-item-dialog.component";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CheckoutPageComponent } from "./components/checkout-page/checkout-page.component";
import { OrderDetailsPageComponent } from "./components/order-details-page/order-details-page.component";
import { StepperComponent } from "./components/order-details-page/stepper/stepper.component";
import { BasketPageComponent } from "./components/basket-page/basket-page.component";
import { BasketPageItemComponent } from "./components/basket-page/basket-page-item/basket-page-item.component";
import { RouterModule, Routes } from "@angular/router";
import { BasketItemDialogComponent } from "./components/basket-page/basket-page-item/basket-item-dialog/basket-item-dialog.component";
import { MatSelectModule } from "@angular/material/select";
import { DelivrerPageComponent } from "./components/delivrer-page/delivrer-page.component";
import { PartnerPageComponent } from "./components/partner-page/partner-page.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DeferLoadDirective } from "./directives/defer-load.directive";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";
import { LocationPopupComponent } from "./components/location-popup/location-popup.component";
import { OrderItemDialogComponent } from "./components/order-details-page/order-item-dialog/order-item-dialog.component";
import { CouponCodeComponent } from './components/coupon-code/coupon-code.component';
import { ToastrModule } from "ngx-toastr";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SignupComponent } from './components/signup/signup.component';
import { SigninComponent } from './components/signin/signin.component';
import { CheckAccountComponent } from './components/check-account/check-account.component';
import { ListComponent } from './components/list/list.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { BankCardsComponent } from './components/bank-cards/bank-cards.component'
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CommandsComponent } from './components/commands/commands.component';
import { ConfirmUserChoiceComponent } from './components/confirm-user-choice/confirm-user-choice.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfirmCancelOrderComponent } from './components/confirm-cancel-order/confirm-cancel-order.component';
import { PayementFailedComponent } from './components/payement-failed/payement-failed.component';
import { PopupInputsErrorsComponent } from './components/popup-inputs-errors/popup-inputs-errors.component';
import { CommandComponent } from './components/commands/command/command.component';
import { CommandDetailsComponent } from './components/commands/command-details/command-details.component';
import { PopupConfirmSavingCardsComponent } from './components/popup-confirm-saving-cards/popup-confirm-saving-cards.component';
import { PopupPayementChoiceComponent } from './components/popup-payement-choice/popup-payement-choice.component';
import { PopupLoadingComponent } from './components/popup-loading/popup-loading.component';
import { SavingCardFailedComponent } from './components/saving-card-failed/saving-card-failed.component';
import { PopupInformUserComponent } from './components/popup-inform-user/popup-inform-user.component';
import { ModifyUserBankCardComponent } from './components/modify-user-bank-card/modify-user-bank-card.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NewRestaurantsComponent } from './components/new-restaurants/new-restaurants.component';
import { ReturnToPreviousPageComponent } from './components/return-to-previous-page/return-to-previous-page.component';
import { PopupPromoCodeComponent} from './components/popup-promo-code/popup-promo-code.component';
import { PromotionRestaurantComponent } from './components/promotion-restaurants/promotion-restaurant/promotion-restaurant.component';
import  { PromotionRestaurantsComponent } from './components/promotion-restaurants/promotion-restaurants.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { MapComponent } from './components/map/map.component'
import { AgmCoreModule } from "@agm/core";
import { MapsComponent } from './components/map/maps/maps.component';
import { AddressDetailsComponent } from './components/map/address-details/address-details.component';
import { CardContainerComponent } from './UI/card-container/card-container.component';
import { AddressListComponent } from './components/map/address-list/address-list.component';
import { GoogleMapsComponent } from './components/map/google-maps/google-maps.component';
import { PopupLocationErrorCheckoutPageComponent } from './components/popup-location-error-checkout-page/popup-location-error-checkout-page.component';
import { CheckoutAddressDetailsComponent } from './components/checkout-page/checkout-address-details/checkout-address-details.component';
import { PopupConfirmUserChoiceComponent } from './components/checkout-page/popup-confirm-user-choice/popup-confirm-user-choice.component';
import { MagicServiceComponent } from './components/magic-service/magic-service.component';
import { PopupModernLoaderComponent } from './components/popup-modern-loader/popup-modern-loader.component';
import { OrderGoogleMapComponent } from './components/order-details-page/order-google-map/order-google-map.component';
import { environment } from "src/environments/environment";
import { HideCrispClientComponent } from './components/order-details-page/hide-crisp-client/hide-crisp-client.component';
import { DisplayCrispClientComponent } from './components/order-details-page/display-crisp-client/display-crisp-client.component';
import { SearchRestaurantComponent } from './components/search-restaurant/search-restaurant.component';
import { PopupCommandeLivreComponent } from './components/popup-commande-livre/popup-commande-livre.component';
import { PopupLivreComponent } from './components/popup-livre/popup-livre.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { PopupSuccessfulPaymentComponent } from './components/popup-successful-payment/popup-successful-payment.component';
import { PopupFailedPaymentComponent } from './components/popup-failed-payment/popup-failed-payment.component';
import { MakeAnOrderComponent } from './components/make-an-order/make-an-order.component';
import { StoresPageComponent } from './components/stores-page/stores-page.component';
import { RestaurantsPageComponent } from './components/restaurants-page/restaurants-page.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { PastriesComponent } from './components/pastries/pastries.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SpecialNordComponent } from './components/special-nord/special-nord.component';
import { ItemDetailsComponent } from './components/make-an-order/item-details/item-details.component';
import { RotateYourIpadComponent } from './components/make-an-order/rotate-your-ipad/rotate-your-ipad.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { PopupRestaurantSilenceComponent } from './components/popup-restaurant-silence/popup-restaurant-silence.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { RefundComponent } from './components/refund/refund.component';
import { PopupInformUserAboutRefundComponent } from './components/popup-inform-user-about-refund/popup-inform-user-about-refund.component';
import { CoupDuMondeComponent } from './components/coup-du-monde/coup-du-monde.component';
import { OAuthModule } from "angular-oauth2-oidc";
import { AuthGoogleComponent } from './components/auth-google/auth-google.component';






@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SwiperComponent,
    CarouselCategoriesComponent,
    CarouselItemComponent,
    JoinUsComponent,
    NewsletterComponent,
    AboutZoneComponent,
    RestaurantsComponent,
    RestaurantComponent,
    LocationChooserComponent,
    HomePageComponent,
    RestaurantPageComponent,
    SelectedHeadingComponent,
    SelectedHeadingItemComponent,
    SelectedHeadingItemDialogComponent,
    CheckoutPageComponent,
    OrderDetailsPageComponent,
    StepperComponent,
    BasketPageComponent,
    BasketPageItemComponent,
    BasketItemDialogComponent,
    DelivrerPageComponent,
    PartnerPageComponent,
    DeferLoadDirective,
    LocationPopupComponent,
    OrderItemDialogComponent,
    CouponCodeComponent,
    SignupComponent,
    SigninComponent,
    CheckAccountComponent,
    ListComponent,
    UserProfileComponent,
    BankCardsComponent,
    ResetPasswordComponent,
    CommandsComponent,
    ConfirmUserChoiceComponent,
    NewPasswordComponent,
    LoadingComponent,
    ConfirmCancelOrderComponent,
    PayementFailedComponent,
    PopupInputsErrorsComponent,
    CommandComponent,
    CommandDetailsComponent,
    PopupConfirmSavingCardsComponent,
    PopupPayementChoiceComponent,
    PopupLoadingComponent,
    SavingCardFailedComponent,
    PopupInformUserComponent,
    ModifyUserBankCardComponent,
    NotFoundComponent,
    GoogleMapsComponent,
    NewRestaurantsComponent,
    ReturnToPreviousPageComponent,
    PopupPromoCodeComponent,
    PromotionRestaurantComponent,
    PromotionRestaurantsComponent,
    SearchFieldComponent,
    MapComponent,
    MapsComponent,
    AddressDetailsComponent,
    CardContainerComponent,
    AddressListComponent,
    PopupLocationErrorCheckoutPageComponent,
    CheckoutAddressDetailsComponent,
    PopupConfirmUserChoiceComponent,
    MagicServiceComponent,
    PopupModernLoaderComponent,
    OrderGoogleMapComponent,
    HideCrispClientComponent,
    DisplayCrispClientComponent,
    SearchRestaurantComponent,
    PopupCommandeLivreComponent,
    PopupLivreComponent,
    PromotionsComponent,
    PopupSuccessfulPaymentComponent,
    PopupFailedPaymentComponent,
    MakeAnOrderComponent,
    StoresPageComponent,
    RestaurantsPageComponent,
    PastriesComponent,
    SpecialNordComponent,
    ItemDetailsComponent,
    RotateYourIpadComponent,
    PopupRestaurantSilenceComponent,
    RefundComponent,
    PopupInformUserAboutRefundComponent,
    CoupDuMondeComponent,
    AuthGoogleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatNativeDateModule,
    NgxPageScrollCoreModule,
    ToastrModule.forRoot(),
    MatSlideToggleModule,
    OAuthModule.forRoot(),
    MatCarouselModule.forRoot(),
    AppRoutingModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    AgmCoreModule.forRoot({
      //Prod
      //apiKey: 'AIzaSyBUegovUfhPdDMry49eB0ypQIQSRSnyAJw',
      //apiKey:'AIzaSyD2laIvUMuRXW4dL5MTQTfMyMjUr0841tY',
      apiKey:environment.apiKey,
      language: 'fr',
      libraries: ['places'],
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
