
<div class="check__account">
    <div class="check__account__container">
        <form  class="check__account__container-verification" #formDirective="ngForm">
            <div class="check__account__container-verification-header">
                Vérification de votre code d'activation, veuillez patiener!
            </div>

            <span class="spinner" *ngIf="!stopLoading">
                <mat-spinner  mode="indeterminate" [diameter]="50"></mat-spinner>
            </span>

            <button 
            [disabled]="!activatedAccount"
            class="check__account__container-verification-button"
            (click)="redirectMeToSignin()"
            >
            Connexion
            <mat-icon>send</mat-icon>
            </button>

        </form>
    </div>
</div>