import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-inform-user-about-refund',
  templateUrl: './popup-inform-user-about-refund.component.html',
  styleUrls: ['./popup-inform-user-about-refund.component.scss']
})
export class PopupInformUserAboutRefundComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  close(){
    this.dialog.closeAll()
  }


}
