import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { time } from 'console';
import { Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';
import { MapService } from 'src/app/services/map.service';
import {MapsService} from '../../../services/maps.service'
import { GoogleMapsComponent } from '../google-maps/google-maps.component';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  providers:[MapsService]
})
export class MapsComponent implements OnInit,OnDestroy {
  fullscreenControl:boolean = false;
  @ViewChild('search',{static:true})
  public searchElementRef!: ElementRef;
  location:{latitude,longitude,userAddress,zoom};
  //private subjectToUnsubscribeFromObservables:Subject<any>;
  private map:any;
  @Output() onChoosePoint:EventEmitter<any> = new EventEmitter()
  @ViewChild(GoogleMapsComponent, {static:false}) private googleMpasComponent:GoogleMapsComponent;
  displayLocateMeButton:boolean = false;

  constructor(
    public __mapsService:MapsService,
    private __basketService:BasketService,
    private __mapService:MapService
  ){}

  ngOnInit(){
    this.__mapsService.loadMapsApi(this.searchElementRef);
    //this.searchElementRef.nativeElement.focus();
    if(navigator.geolocation) this.displayLocateMeButton = true;
  }

  mapReady(map:any){
   // map.fullscreenControl = this.fullscreenControl;
    map.setOptions({
      fullscreenControl: this.fullscreenControl,
      zoomControl: "true",
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      }
    });
    this.map = map;
  }

  centerChanged(coords:any){
    //onCenterChanged
    this.__mapsService.onCenterChanged(coords);
  }

  putMarkerOnUserLocation(){
    this.setMapZoom(13);
    this.getUserCurrentLocation();
  }

  getUserCurrentLocation(){
    if(window.navigator.geolocation){
      window.navigator.geolocation.getCurrentPosition(
        position => {
          setTimeout(()=>{
            this.location.latitude = position.coords.latitude;
            this.location.longitude = position.coords.longitude;
            this.setMapZoom(17);
          },500)
        },
        error => {}
      )
    }
  }

  setMapZoom(value){
    this.map.setZoom(value);
  }

  choosePoint(){
    const obj = {
      lat:this.googleMpasComponent.location.latitude,
      long:this.googleMpasComponent.location.longitude,
      //mainAddress:this.googleMpasComponent.location.userAddress
      mainAddress:this.searchElementRef.nativeElement.value
    }
    this.onChoosePoint.emit(obj);
    this.__mapService.goToNextStep();
  }

  goBack(){
    this.__mapService.goToPreviousStep();
  }

  locateUser(){
    this.__mapsService.locateUser();
  }

  ngOnDestroy(): void {
   
  }

}
