import { Injectable } from '@angular/core';
import { MenuBasketLocalstorage } from 'src/app/interfaces/menu-basket-localstorage';

@Injectable({
  providedIn: 'root'
})
export class MenuBasketLocalstorageService {

  constructor() { 
    if(!this.isBasketExistInLocalStorage()) this.initializeLocalStorageWithEmptyBasket();
  }

  isBasketExistInLocalStorage():boolean{
    return localStorage.getItem("menuBasket") == null ? false :  true;
  }

  updateBasketStoredInLocalStorage(basketLocalstorage:MenuBasketLocalstorage){
    localStorage.setItem("menuBasket", JSON.stringify(basketLocalstorage));
  }

  initializeLocalStorageWithEmptyBasket(){
    let obj:MenuBasketLocalstorage = {
      itemsCount: 0,
      items: [],
      service: "Delivery",
      minimumPrice: 0,
      deliveryPrice: 0,
      serviceCharge: 0,
      restaurantOpen: false,
      hasTakeway: false,
      isOnlinePaymentAllowed: '',
      isCashPaymentAllowed: '',
      declinations: null,
      promoCodeInfos: {
        reduction: 0,
        promoCode: null,
        restaurantName: null,
        responseMessage: null
      },
      hasDelivry:true
    }
    localStorage.setItem("menuBasket",JSON.stringify(obj));    
  }

  getBasketStoredInLOcalStorage():MenuBasketLocalstorage{
    const basket:MenuBasketLocalstorage  = JSON.parse(localStorage.getItem("menuBasket").toString());
    if(basket.items.length==0 && basket.service=="Takeaway") basket.service="Delivery";
    return basket;
  }

}
