import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AddressListService } from 'src/app/services/address-list.service';
import { BasketService } from 'src/app/services/basket.service';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-checkout-address-details',
  templateUrl: './checkout-address-details.component.html',
  styleUrls: ['./checkout-address-details.component.scss']
})
export class CheckoutAddressDetailsComponent implements OnInit {
  mainAddress:string = '';
  constructor(
    private __basketService:BasketService,
    private __location:Location,
    private __addressListService:AddressListService,
    private __checkoutService:CheckoutService
  ) 
  { }

  ngOnInit(): void {
    this.mainAddress = this.__basketService.getLocation().mainAddress;
  }

  setAddressInfos(address:{mainAddress,additionalAddressInfos,id}){
    const location = this.__basketService.getLocation();
    location.mainAddress = address.mainAddress;
    location.additionalAddressInfos = address.additionalAddressInfos;
    if(this.__basketService.location.id) this.updateUserAddress();
  }

  goBack(){
    this.__location.back();
  }

  updateUserAddress(){
    const dialogref = this.__checkoutService.openModernPopupLoader();
    this.__addressListService.updateAddress()
    .pipe(tap( _ => {
      dialogref.close();
      this.goBack();
    }))
    .subscribe();
  }
}
