<div class="popup">
    <h1 mat-dialog-title>Voulez-vous vraiment annuler votre commande ?</h1>
    <!--<div mat-dialog-content>Vous voulez vraiment annuler votre commande</div>-->
    <div mat-dialog-actions align="center">
    <button mat-button mat-dialog-close 
    class="cancel"
    >Non</button>
    <button mat-button mat-dialog-close 
    class="confirm"
    (click)="confirm()"
    >Oui</button>
    </div>
</div>