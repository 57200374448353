<div class="home-page__container">
  
  <div id="carouselExampleControls" class="carousel slide carouselWeb" data-ride="carousel">

    <div class="carousel-inner">
      <div class="carousel-item" data-bs-interval="1000"  *ngFor="let image of webImages, index as i" [ngClass]="{'active' : i == 0}">
        <img class="d-block w-100" [src]="image.image" alt="First slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only"></span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only"></span>
    </a>

  </div>

  <div id="carouselExampleControlsmobile" class="carousel slide carouselMobile" data-ride="carousel">

    <div class="carousel-inner">
      <div class="carousel-item" data-bs-interval="1000"  *ngFor="let image of mobileImages, index as i" [ngClass]="{'active' : i == 0}">
        <img class="d-block w-100" [src]="image.image" alt="First slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControlsmobile" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only"></span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControlsmobile" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only"></span>
    </a>

  </div>



    <app-carousel-categories
    [ngClass]="{'carouselCategoriesNeedMarginTop': __sharedDataService.showSearchFieldToSearchForARestaurant}"
    (selectedCategoryChange)="handleCategoryEmitted($event)"  
    [(selectedCategory)]="selectedCategory"
    class="home-page__carousel-categories"
    >
    </app-carousel-categories>
  
    
    
  <!--
    <app-promotion-restaurants
      [category]="selectedCategory"
      [city]="selectedCity"
      [street]="selectedStreet"
      class="home-page__promotion-restaurants"
    >
    </app-promotion-restaurants>
  -->
    
  <!--
    <app-new-restaurants 
      [category]="selectedCategory"
      [city]="selectedCity"
      [street]="selectedStreet"
      class="home-page__new-restaurants"> 
  
    </app-new-restaurants>
  -->
    <app-coup-du-monde
        title="Spécial Ramadan"
        [category]="selectedCategory"
        icon="promotions.png"
        class="home-page__new-restaurants">
    </app-coup-du-monde>

    <app-promotions
        title="Spécial Ramadan"
        [category]="selectedCategory"
        icon="promotions.png"
        class="home-page__new-restaurants">
    </app-promotions>
  
    <app-new-restaurants
        title="Nouveaux restaurants"
        icon="new-resturants-icon.png"
        [category]="selectedCategory"
        class="home-page__new-restaurants">
    </app-new-restaurants>
  <!--
    <app-restaurants
      [category]="selectedCategory"
      [city]="selectedCity"
      [street]="selectedStreet"
      class="home-page__restaurants"
    >
    </app-restaurants>
  -->
  <app-restaurants
      [category]="selectedCategory"
      class="home-page__restaurants"
    >
    </app-restaurants>
    <app-join-us class="home-page__join-us"></app-join-us>
    <app-newsletter class="home-page__newsletter-zone"></app-newsletter>
    <app-about-zone class="home-page__about-zone"></app-about-zone>
  </div>
  