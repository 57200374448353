import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { BasketLocalstorageService } from '../basket-localstorage.service';
import { SharedDataService } from '../shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(
    private __authService:AuthService,
    private __router:Router,
    private __basketLocalStorageService:BasketLocalstorageService,
    private __sharedDataService:SharedDataService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canTheUserAccessThisPage(state);
  }

  canTheUserAccessThisPage(state: RouterStateSnapshot):Promise<boolean>{
    return new Promise(
      (resolve,reject)=>{
        Promise.all([this.__authService.isTokenValid()])
        .then(
          response=>{
            let isTheTokenValid = response[0].isTokenValid;
            let isTokenExist = response[0].isTokenExist;
            if(isTokenExist && !isTheTokenValid){
              this.__authService.logout();
              this.__sharedDataService.setIsUserJustCreateSession(false);
              resolve(false);
              this.__router.navigate(["se-connecter"]);
            }

            else{
              if(this.isTheUserWannaAccessAPageWhichNotequiresAnyChecking(state)){
                resolve(true);
              }
              if(this.isTheUserWannaAccessHomePage(state)) {
                if(this.isBasketNotEmpty() && this.__sharedDataService.getIsUserJustCreateSession()) 
                  this.redirectionToRestaurantPage();
                else  resolve(true);
                this.__sharedDataService.setIsUserJustCreateSession(false);
              }
              else if(this.isTheUserWannaAccessRestaurantPage(state)) {
                this.__sharedDataService.setIsUserJustCreateSession(false);
                resolve(true);
              }
              else if(this.isTheUserWantsToAccessWhiteList(state)){
                if(!isTheTokenValid) {
                  resolve(true);
                }
                else{
                  resolve(false);
                  this.__router.navigate([""]);
                }
                this.__sharedDataService.setIsUserJustCreateSession(false);
              }
      
              else{
                if(!isTheTokenValid) {                  
                  resolve(false);
                  this.__router.navigate(["se-connecter"]);
                }
                else resolve(true);
                this.__sharedDataService.setIsUserJustCreateSession(false);
              }
            }
          }
        )
      }
    )
  }

  isTheUserWantsToAccessWhiteList(state: RouterStateSnapshot):boolean{ 
    if(state.url.includes("?")){
      if(
        state.url.split("?")[0] == "/nouveau-mot-de-passe"
        ||
        state.url.split("?")[0] ==  "/restaurant"
      ) 
      return true;
    }
    else{
      let whiteList:string[]  = [
        "/se-connecter","/s'inscrire","/reinitialiser-mot-de-passe",
      ];
      if(whiteList.includes(state.url)) return true;
      return false;
    }
  }

  isTheUserWannaAccessHomePage(state:RouterStateSnapshot):boolean{
    if(
      state.url=='/' || state.url=="/home" 
      || 
      (state.url.includes("?") && (state.url.split("?")[0]=="/" || state.url.split("?")[0]=="/home"))
    ) return true;
    return false;
  }

  redirectionToRestaurantPage(){
    let restaurantName:string = this.getRestaurantNameFromBasket();
    this.__router.navigate(["/restaurant"],{
     queryParams:{ name:restaurantName }
    });
  }

  getRestaurantNameFromBasket():string{
    return this.__basketLocalStorageService.getBasketStoredInLOcalStorage().items[0].restaurant_name;
  }

  isBasketNotEmpty():boolean{
    if( this.__basketLocalStorageService.isBasketExistInLocalStorage() 
        && 
        this.__basketLocalStorageService.getBasketStoredInLOcalStorage().items.length>0
      )
      return true;
      return false;
  }

  isTheUserWannaAccessRestaurantPage(state:RouterStateSnapshot):boolean{
    if( state.url.includes("?") && state.url.split("?")[0] == "/restaurant" )
    return true;
    return false;
  }

  isTheUserWannaAccessAPageWhichNotequiresAnyChecking(state:RouterStateSnapshot):boolean{
    let pages:string[] = ["/map"];
    return pages.includes(state.url);
  }


}
