import { 
  AfterViewInit, 
  Component, 
  Input,
  OnChanges, 
  OnDestroy, 
  OnInit, 
  SimpleChanges, 
  ViewChild
      } 
  from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BasketService } from 'src/app/services/basket.service';
import { HomePageService } from 'src/app/services/home-page.service';
import { CupDuMondeService } from '../../services/cup-du-monde.service'

@Component({
  selector: 'app-coup-du-monde',
  templateUrl: './coup-du-monde.component.html',
  styleUrls: ['./coup-du-monde.component.scss']
})
export class CoupDuMondeComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() restaurants = []
  @ViewChild("carouselRef",{static:true})  carouselRef;
  @Input() category:string;
  @Input() title: string;
  @Input() icon: string;

  /*
  @Input() city:any;
  @Input() street:any;
  */

  constructor(
    private cupDuMondeService: CupDuMondeService,
    private __basketService:BasketService,
    private __router:Router,
    private __homePageService:HomePageService
  ) { }


  ngOnInit(): void {
    //this.getNewRestaurants();
  }

  ngAfterViewInit(): void {
   
  }

  scrollLeft(itemsContainer: HTMLElement): void {
    itemsContainer.scrollBy({
      left: -150,
      behavior: "smooth",
    });
  }

  scrollRight(itemsContainer: HTMLElement): void {
    itemsContainer.scrollBy({
      left: 150,
      behavior: "smooth",
    });
  }


  addClassToCarousel(){
    this.carouselRef.nativeElement.classList.add("new-restaurants-containerWithClassSPaceEvenly");
  }

  getMarginRight(index){
    return index == (this.restaurants.length - 1) ? "0px" : "15px" 
  }

  getNewRestaurants(){
    const ramadan = this.title.toLowerCase().includes('ramadan');
    this.cupDuMondeService.afterLocateUserAutomaticllyGetNewRestaurants(this.category, ramadan)
    .subscribe(data => {
      this.restaurants = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getNewRestaurants();
  }

  redirectToRestaurantPage(restaurantName){
    /*
    if(this.__basketService.promoCodeInfos.restaurantName && restaurantName!= this.__basketService.promoCodeInfos.restaurantName) 
      this.__basketService.initializePromoCodeInfos();
    */
   this.__router.navigate(["restaurant"],{queryParams:{name:restaurantName}});
  }



  ngOnDestroy(): void {
   
  }


}
