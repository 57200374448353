
<div class="signin">
    <div class="signin__container">
        <div class="signin__container-header">
            <!--
            <div class="signin__container-header-msg">
                Bienvenue
            </div>
            -->
            <div class="signin__container-header-signin_with_google">
                <div id="buttonDiv"></div> 
            </div>

            <div class="signin__container-header-methods_separator">
                <div class="signin__container-header-methods_separator-left_bar"></div>
                <div class="signin__container-header-methods_separator-text">Ou</div>
                <div class="signin__container-header-methods_separator-right_bar"></div>
            </div>

            <div class="signin__container-header-description">
                Saisissez vos identifiants pour vous connecter
            </div>
        </div>
        <form class="signin__container__form" [formGroup]="__formGroup">

            <div class="signin__container__form-login">
                <mat-form-field class="signin__container__form-login-username">
                    <mat-label class="signin__container__form-login-username-margin">
                      E-mail ou Numéro de téléphone
                    </mat-label>
                    <input
                      formControlName="username"
                      class="signin__container__form-login-username-field-margin"
                      type="text"
                      matInput
                    />
                    <mat-error >
                        {{ getErrorMessage("username") }}
                    </mat-error>
                    <mat-icon matSuffix>person</mat-icon>
                  </mat-form-field>

                  <mat-form-field class="signin__container__form-login-password">
                    <mat-label class="signin__container__form-login-password-margin">
                      Mot de passe</mat-label
                    >
                    <input
                      #password
                      formControlName="password"  
                      class="signin__container__form-login-password-field-margin"
                      type="password"
                      matInput
                    />
                    <mat-error >
                        {{ getErrorMessage("password") }}
                    </mat-error>
                    <mat-icon
                    (click)="showThePassword(password)" 
                    *ngIf="!isTheAppShowingPassword"
                    style="cursor: pointer;" 
                     matSuffix>remove_red_eye</mat-icon>
                     <mat-icon
                     (click)="hideThePassword(password)"
                      *ngIf="isTheAppShowingPassword"
                      style="cursor: pointer;"  
                      matSuffix>visibility_off</mat-icon> 
                  </mat-form-field>

            </div>

            <div class="signin__container__form-button-container">
                <button
                [hidden]="loading" 
                (click)="signinUser()"
                [disabled]="__formGroup.invalid"
                class="signin__container__form-button-container-btn"
                >Se Connecter 
                    <mat-icon>touch_app</mat-icon>
                </button>
            </div>

            <div class="signin__container__form-footer">
                <div class="signin__container__form-footer-join-us">
                    <div class="signin__container__form-footer-join-us-text">
                        Vous n'avez pas de compte ?
                    </div>

                    <div class="signin__container__form-footer-join-us-link">
                        <a style="cursor: pointer;" (click)="redirectMeToSignupPage()">Inscrivez-vous</a>
                    </div>
                </div>

                <div class="signin__container__form-footer-forget-password">
                    <div class="signin__container__form-footer-forget-password-text">
                        Mot de passe oublié ?
                    </div>
                    <div class="signin__container__form-footer-forget-password-link">
                        <a style="cursor: pointer;" (click)="redirectMeToResetPasswordPage()">Cliquez-ici</a>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>

<div class="footer">
    <app-about-zone></app-about-zone>
</div>
