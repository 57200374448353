import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-command-details',
  templateUrl: './command-details.component.html',
  styleUrls: ['./command-details.component.scss']
})
export class CommandDetailsComponent implements OnInit {
  commandDetails:{items,frais_service,frais_livraison,total} = null;
  constructor(
    private __activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.__activatedRoute.data.subscribe(response => this.handleGetCommadsResponse(response.uls));
  }

  handleGetCommadsResponse(response){
    this.commandDetails = response;
  }

}
