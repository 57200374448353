import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BasketService } from 'src/app/services/basket.service';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent implements OnInit {
  __formGroup:FormGroup;
  @Output() onAddAdress:EventEmitter<any> = new EventEmitter();
  @Input() mainAddress:string = '';
  @Input() additionalAddressInfos:string = '';

  constructor(
    private __formBuilder:FormBuilder,
    private __basketService:BasketService
  ) { }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  initializeFormGroup(){
    this.__formGroup = this.__formBuilder.group({
      "address":[this.mainAddress || '',Validators.required],
      "additionalInformation":[this.additionalAddressInfos || '']
    });
  }

  getErrorMessage(fieldName:string){
    const errorsObject = {
      address:"Ce champs est requis",
    };
    return errorsObject[fieldName];
  }

  onFormSubmited(){
    if(this.__formGroup.invalid) return;
    const obj = {
      mainAddress:this.__formGroup.get("address").value,
      additionalAddressInfos:this.__formGroup.get("additionalInformation").value
    }
    this.onAddAdress.emit(obj);
  }

}
