import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignupService {
  
  constructor(
    private __http:HttpClient
  ) { }

  signupUser(user:{nom:string,prenom:string,telephone:string,email:string,password:string}):Observable<any>{
    return this.__http
    .post(
      `${environment.apiUrl}register/sign_up`,
      user
    )
  }
}
