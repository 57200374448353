import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CheckAccountService {

  constructor(
    private __http:HttpClient
  ) 
  { }

  verifyCode(user:string,code:string){
    return this.__http
    .get(`${environment.apiUrl}login/verify?user=${user}&code=${code}`)
  }
}
