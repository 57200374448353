import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BankCardsService } from 'src/app/services/bank-cards.service';

@Component({
  selector: 'app-modify-user-bank-card',
  templateUrl: './modify-user-bank-card.component.html',
  styleUrls: ['./modify-user-bank-card.component.scss']
})
export class ModifyUserBankCardComponent implements OnInit {
  bankCard:{label, etat, token, nom_client, expiry_date} ;
  __formGroup:FormGroup = null;
  loadingForModifyingLabel:boolean = false;
  loadingForModifyingStatus:boolean = false;
  private oldLabel;
  onLabelModified:Subject<any> = new Subject();

  constructor(
    private __formBuilder:FormBuilder,
    private __bankCardsService:BankCardsService,
    private __activatedRoute:ActivatedRoute,
    private __router:Router
  ) { }

  ngOnInit(): void {
    this.__activatedRoute.data.subscribe(data => {
      this.bankCard = data.uls || null;
      if(!this.bankCard["etat"]) this.__router.navigate(["carte-bancaire"]);
    });
    this.oldLabel = this.bankCard.label;
    this.__formGroup = this.__formBuilder.group({
      label:[this.bankCard.label, [Validators.minLength(3), Validators.required,this.checkIsLabelModified]]
    })
  }

  getBankCardStatusBackGroundColor(status:string):string{
    let backGroundColor = "#000";
    if(status=="0") backGroundColor = "#dc3545";
    return backGroundColor;
  }

  getErrorMessage(field:string):string{
    let fieldRef = this.__formGroup.get(field);
    let errorMessage = "";
    if(fieldRef.hasError("required")) errorMessage = "Ce champs est requis";
    else if (fieldRef.hasError("minlength")) errorMessage = "Ce champs doit comporter au moins 3 carractères."
    else if (fieldRef.hasError("same")) errorMessage = "Vous avez rien modifié.";
    return errorMessage;
  }

  modifyLabel(){
    this.loadingForModifyingLabel = true;
    let obj = {
      newLabel:this.__formGroup.get("label").value,
      oldLabel:this.oldLabel, 
      tokencmi:this.bankCard.token, 
      owner:this.bankCard.nom_client, 
      expire:this.bankCard.expiry_date
    }
    this.__bankCardsService.updateLabel(obj)
    .subscribe(data=>this.handleModifyLabelResponse(data));
  }

  activatebankCard(){
    this.loadingForModifyingStatus = true;
    this.__bankCardsService.activateCard(this.bankCard)
    .subscribe(
      data => this.handleRsponseForActivationCardAction()
    )
  }

  deactivatebankCard(){
    this.loadingForModifyingStatus = true;
    this.__bankCardsService.deactivateCard(this.bankCard)
    .subscribe(
      data => this.handleRsponseForDeactivationCardAction()
    )
  }

  handleRsponseForActivationCardAction(){
    this.loadingForModifyingStatus = false;
    this.bankCard.etat="1";
  }

  handleRsponseForDeactivationCardAction(){
    this.loadingForModifyingStatus = false;
    this.bankCard.etat="0";
  }

  handleModifyLabelResponse(response){
    this.loadingForModifyingLabel = false;
    this.onLabelModified.next(this.__formGroup.get("label").value);
    this.oldLabel = this.__formGroup.get("label").value;
    let queryParams = {label:this.bankCard.label,tokencmi:this.bankCard.token};
    this.__router.navigate(["modifier-carte-bancaire"],{queryParams});
  }

  checkIsLabelModified: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let newLabel = this.__formGroup?.get('label')?.value;
    return newLabel === this.oldLabel ? {same: true } : null;
  }

}
