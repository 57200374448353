import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { BasketService } from "../../services/basket.service";
import { LocationPopupService } from "src/app/services/location-popup.service";
import { HttpService } from "src/app/services/http.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { SharedDataService } from "src/app/services/shared-data.service";
import { MatDialog } from "@angular/material/dialog";
import { CheckoutService } from "src/app/services/checkout.service";
import { pluck, tap } from "rxjs/operators";
import { RestaurantPageService } from "src/app/services/restaurant-page.service";

@Component({
  selector: "app-basket-page",
  templateUrl: "./basket-page.component.html",
  styleUrls: ["./basket-page.component.scss"],
})
export class BasketPageComponent implements OnInit, OnDestroy {
  restaurantStreets: any = {
    quartier: [],
    ville: "",
  };
  restaurantId;
  private subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet:Subject<any>;
  isRestaurantClosed:boolean = true;
  preOrderStatus: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public basketService: BasketService,
    private locationPopupService: LocationPopupService,
    private httpService: HttpService,
    private snackBar: MatSnackBar,
    private __sharedDataService:SharedDataService,
    private __dialog:MatDialog,
    private __checkoutService:CheckoutService,
    private __activatedRoute:ActivatedRoute,
    private __restaurantPageService:RestaurantPageService
  ) {
    this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet = new Subject();
  }

  getCityName(city) {
    if (typeof city === "string") return city;
    return city?.name;
  }
  openLocationDialog() {
    this.locationPopupService.open();
  }

  ngOnInit(): void {
    this.__activatedRoute.data.pipe(
      pluck("data"),
      tap(data => {
        if(data[0].pre_commande && data[0].closed){
          this.isRestaurantClosed = false
        }else{
          this.isRestaurantClosed = data[0].closed;
        }
        this.basketService.magicDeliveryAmount = data[1];
      })
    )
    .subscribe();
    if (this.basketService.count()) {
      const restaurantId = this.basketService.basket.items[0]?.restaurant_id;
      this.restaurantId = restaurantId
      this.httpService
        .getRestaurantStreets(restaurantId)
        .subscribe((restaurantStreets) => {
          this.restaurantStreets = restaurantStreets;
          this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
          .pipe()
          .subscribe(
            data => {if(data) this.checkingIfWeDeliverInUserStreet();}
          )
        });
    }
  }

  checkingIfWeDeliverInUserStreet(){    
    if(this.basketService.getLocation().isEmpty()) {
      this.basketService.setDeliveryPrice(0);
      this.basketService.setMinimumPrice(0);
      this.basketService.setHasDelivry(true);
      if(!this.basketService.getService()) this.basketService.setService("Delivery");
      return;
    }
    const street = this.getStreet();
    if(street) this.restaurantDeliversInUserLocation(street);
    else this.restaurantDoesNotDeliverInUserLocation();

  }

  getStreet(){
    return this.basketService.getStreet(this.restaurantStreets);
  }

  restaurantDoesNotDeliverInUserLocation(){
    this.basketService.setHasDelivry(false);
    this.basketService.setDeliveryPrice(0);
    this.basketService.setMinimumPrice(0);
    if(!this.basketService.isServiceTakeAway()) this.openPopupInformUser();
  }

  restaurantDeliversInUserLocation(street){    
    this.basketService.setHasDelivry(true);
    this.settingMinimumPriceAndDeliveryPrice(street);
    if(
      this.__sharedDataService.wasPreviousPageTheMap() && 
      !this.basketService.isServiceMagicDelivery()
      ) 
      this.basketService.setService("Delivery");
  }

  switchService(){
    this.basketService.switchServiceAutomaticallyBetweenMagicAndDelivery(this.restaurantStreets);
  }

  
  openPopupInformUser(){
    let text = "Malheuresement, nous ne livrons pas dans votre quartier";
    if(!this.basketService.basket.hasTakeway) text+=", et la commande à  emporter n'est pas disponible."
    const data = {
      imgSrc:"deliver-user.png",
      text,
      btn1Text:"Modifiez votre adresse",
      btn2Text:`${this.basketService.basket.hasTakeway ? "Commande à emporter" : "Changez de restaurant"}`,
      btn1Fun:() => {
        this.redirectUserToMapPage();
      },
      btn2Fun:() => {
        if(this.basketService.basket.hasTakeway) this.setService("Takeaway");
        else this.redirectUserToHomePage();
      },
      subjectToUnsubscribeFromObservables:this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet
    }
    this.__checkoutService.openPopupConfirmUserChoice(data)
  }

  settingMinimumPriceAndDeliveryPrice(street){
    this.basketService.settingMinimumPriceAndDeliveryPrice(street);
  }

  back(): void {
    this.location.back();
  }

  isDelivery(): boolean {
    return this.basketService.getService() === "Delivery";
  }

  isMagicDelivery():boolean{
    return this.basketService.isServiceMagicDelivery();
  }

  getDeliveryPrice(): number {
    return this.basketService.getDeliveryPrice();
  }

  getMinimumPrice(): number {
    return this.basketService.basket.minimumPrice;
  }

  minimumIsRequired(): void {
    this.snackBar.open(
      "Le minimum de commande est",
      `${this.getMinimumPrice()} DH`,
      {
        duration: 2000,
      }
    );
  }

  isReachedMinimum(): boolean {
    return this.basketService.isReachedMinimum();
  }

  validate(): void {
    const nameOfRestaurant = this.route.snapshot.paramMap.get('restaurantName');
   if(this.isRestaurantClosed){
    this.openSnackBar("Le restaurant est fermé temporairement","restaurant-is-close",2000);
    return;
   }
    if(this.isDelivery()){
      //if (!this.isReachedMinimum()) return ;
      if (!this.isReachedMinimum()) {
        if(this.basketService.isMagicDelivryActiveInRestauarant) this.openPopupInformUserAboutWizardDeliveryWithRedirection();
        else return this.minimumIsRequired();
      }
      else this.router.navigate(['/checkout'], { queryParams: {name: nameOfRestaurant} });
    }
    else this.router.navigate(['/checkout'], { queryParams: {name: nameOfRestaurant} });
  }

  openSnackBar(message: string, className:string, duration:number, action?: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = [className];
    config.duration = duration;
    config.horizontalPosition = "center";
    this.snackBar.open(message, action ? "Action Label" : undefined, config);
  }

  disableButton(): boolean {
    return !this.basketService.count();
  }

  setService(service: string): void {
    if(
      (service == "Delivery" && this.basketService.basket.hasDelivry)
      || 
      (service == "Takeaway" && this.basketService.basket.hasTakeway)
    )
    this.basketService.setService(service);
  }

  onServiceChange(service: string): void {
    this.setService(service);
    this.checkingIfWeDeliverInUserStreet();
  }

  getTotalPriceAfterapplyingReduction(){
    return this.basketService.getTotalPriceAfterapplyingReduction();
  }

  redirectUserToMapPage(){
    this.router.navigate(["map"]);
  }

  redirectUserToHomePage(){
    this.router.navigate([""]);
  }

  openPopupInformUserAboutWizardDeliveryWithoutRedirection(){
    this.__restaurantPageService.openPopupInformUserAboutWizardDeliveryWithoutRedirection(
      this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet
    );
  }

  openPopupInformUserAboutWizardDeliveryWithRedirection(){
    this.__restaurantPageService.openPopupInformUserAboutWizardDeliveryWithRedirection(
      this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet
    );
  }

  ngOnDestroy(){
    this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet.next("random value");
    this.subjectToUnsubscribeFromAllCustomsObservablesInThiComponenet.complete();
  }

}
