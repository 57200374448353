<div class="confirm-user-choice-container">
    <h1>Veuillez choisir votre mode de paiement</h1>
    <div mat-dialog-actions align="center">
    <button mat-button mat-dialog-close 
    class="espece"
    (click)="EspeceMod()"
    >Espéce</button>
    <button mat-button mat-dialog-close 
    class="bankCard"
    (click)="bankCardMod()"
    >Carte bancaire</button>
    </div>
</div>