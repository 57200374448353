<div class="popup-promo-code">
    <div class="popup-promo-code-header">
        <span class="popup-promo-code-header-text">
            Code promo
        </span>
        <img 
            (click)="closePopup()"
            class="popup-promo-code-header-img"
            src="../../../assets/images/x-button.png" alt=""
        >
    </div>

    <div class="popup-promo-code-container">
        <mat-form-field class="popup-promo-code-container-input">
            <mat-label class="popup-promo-code-container-input-label">
              Saisissez votre code promo
            </mat-label
            >
            <input
              [(ngModel)]="promoCodeValue"
              class="popup-promo-code-container-input-field"
              type="text"
              matInput
            />
          </mat-form-field>

          <button
            (click)="onSubmit()"
            [disabled]="isLoadingResponseForPromoCode || promoCodeValue==''"
            class="popup-promo-code-container-submit"
          >

          <span 
            class="popup-promo-code-container-submit-text" 
            *ngIf="!isLoadingResponseForPromoCode"
            > 
            Valider mon code promo 
          </span>
 
          <mat-spinner 
            *ngIf="isLoadingResponseForPromoCode"
            class="spinner popup-promo-code-container-submit-spinner spinner" 
            diameter="25"
            >
          </mat-spinner>
          </button>

          <div
            *ngIf="ErrorMessage!=null" 
            class="popup-promo-code-container-error">
              <mat-icon class="popup-promo-code-container-error-icon">
                  error
              </mat-icon>

              <span 
                class="popup-promo-code-container-error-text"
              >
                {{ getErrorMessage() }}
              </span>
          </div>
    </div>
</div>