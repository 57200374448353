import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  private headers:HttpHeaders = new HttpHeaders({
  })
  body:Object = {};

  constructor(
    private __http:HttpClient
  ) { 

  }

  resetPAssword(email:string):Observable<any>{
    this.body = {email};
    return this.__http.post(`${environment.apiUrl}password/reset`,this.body,{headers:this.headers});
  }

  getToken(){
    return localStorage.getItem("user_token");
  }
}
