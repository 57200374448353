import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('ListAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class UserProfileComponent implements OnInit {

  @ViewChild("formDirective",{static:true}) formDirective:NgForm
  isTheAppShowingPassword:boolean = false;
  isTheAppShowingConfirmPassword:boolean = false;
  __formGroup:FormGroup;
  isHeWantsToModifyPassword:boolean = false;
  disableButtons:boolean = false;

  constructor(
    private router:Router,
    private toastr:ToastrService,
    private __formBuilder:FormBuilder,
    private __activtedRoute:ActivatedRoute,
    private __userProfileService:UserProfileService
  ) 
  { }

  ngOnInit(): void {
    this.__activtedRoute.data
    .subscribe(
      response => this.initializeOurFormGroup(response.uls)
    )
  }



  getErrorMessage(field: string): string {
    if (this.__formGroup.get(field).hasError("notSame")) {
      return "Vous devez saisir le même mot de passe";
    }

    if (!this.__formGroup.get(field).hasError("required") ) {
      return "Ce champs est invalide";
    }

    return "";
  }

  handleResponse(response, user){
    if(this.isHeWantsToModifyPassword) this.ChangeUserChoice();
    this.__userProfileService.setTheUserObjectThatMakeUsToCheckIfTheUserModifiedHisProfile(user);
    this.disableButtons = false;
    this.toastr.clear();
    let msg = "Votre profile a bien été modifié";
    this.showSuccessToaster(msg,"Bravo !");
  }

  handleError(error){
    this.showSuccessToaster(error.message,"Erreur");
  }

  showErrorToaster(msg,header){
    this.toastr.clear();
     return this.toastr.error(msg,header,{
       timeOut:10000,
      })
  } 

  showSuccessToaster(msg,header){
    this.toastr.clear();
    return this.toastr.success(msg,header,{
      timeOut:5000,
      closeButton:true
    })
  }

  redirectMeToSignin(){
    this.router.navigate(["se-connecter"])
  }

  hideThePassword(fieldRef,inputName){
    fieldRef.type="password"; 
    if(inputName=="password") this.isTheAppShowingPassword = false;
    else this.isTheAppShowingConfirmPassword = false;
   }
 
  showThePassword(fieldRef,inputName){
     fieldRef.type="text";
     if(inputName=="password") this.isTheAppShowingPassword = true;
     else this.isTheAppShowingConfirmPassword = true;
   }

  initializeOurFormGroup(user:{nom,prenom,telephone,email,adresse}){
    this.__formGroup =  this.__formBuilder.group(
      {
        nom: [user.nom, [Validators.required, Validators.minLength(3)]],
        prenom: [user.prenom, [Validators.required, Validators.minLength(3)]],
        telephone: [
          user.telephone,
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("0(6|7)[0-9]*$"),
          ],
        ],
        email: [
          user.email,
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^([a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+)@([a-zA-Z0-9-]+)(\\.[a-zA-Z0-9-]+)+$"
            ),
          ],
        ],
        password:["",[Validators.minLength(6)]],
        confirmPassword:["",[Validators.minLength(6), this.checkPasswords]],
        adresse:[user.adresse,[Validators.required, Validators.minLength(3)]],
      }
    )
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = this.__formGroup?.get('password')?.value;
    let confirmPass = this.__formGroup?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  isPasswordEqualsToConfirmPassword():boolean{
    let pass = this.__formGroup?.get('password')?.value;
    let confirmPass = this.__formGroup?.get('confirmPassword')?.value
    return pass === confirmPass
  }

  onSubmit(){
    let user = {
      nom:this.__formGroup.get('nom').value,
      prenom:this.__formGroup.get('prenom').value,
      telephone:this.__formGroup.get('telephone').value,
      email:this.__formGroup.get('email').value,
      adresse:this.__formGroup.get('adresse').value,
      password:this.__formGroup.get('password').value,
    }
    if(this.__userProfileService.checkIfTheUserModifiedHisProfile(user)){
      this.disableButtons = true;
      this.__userProfileService.modifyUser(user)
      .subscribe(
        response=>this.handleResponse(response,user),
        error=>{}
      )
    }
    else {
      let msg = "Vous avez rien modifié";
      let header =  "Pas de modification";
      this.showErrorToaster(msg,header);
    }
  }
  
  ChangeUserChoice(){
    if(this.isHeWantsToModifyPassword) this.resetPasswordAndConfirmPAssword();
    this.isHeWantsToModifyPassword = !this.isHeWantsToModifyPassword;
  }

  resetPasswordAndConfirmPAssword(){
    this.__formGroup.get("password").setValue("");
    this.__formGroup.get("confirmPassword").setValue("");
  }

}
