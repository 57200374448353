import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router, Scroll} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedDataService } from "src/app/services/shared-data.service";

@Component({
  selector: 'app-special-nord',
  templateUrl: './special-nord.component.html',
  styleUrls: ['./special-nord.component.scss']
})
export class SpecialNordComponent implements OnInit {

  selectedCategory = "Tout";
  //selectedCity: string = null;
  //selectedStreet: string = null;
  searchFiledValue:string = '';
  utm: string="";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toaster:ToastrService,
    public __sharedDataService:SharedDataService,
    ) {}

  ngOnInit(): void {
    this.utm = this.route.snapshot.queryParamMap.get('utm');
    if(this.utm) this.saveUtmInSessionStorage()
    this.toaster.clear();
    const uniqueId = this.route.snapshot.paramMap.get('id');
    if (uniqueId) {
      this.router.navigate(['/restaurant'], {queryParams: {name: uniqueId}});
    }
  }

  saveUtmInSessionStorage(){
    sessionStorage.setItem('utm', this.utm)
  }

  /*
  handleCityEmitted($event: string): void {
    this.selectedCity = $event;
  }
  */

  /*
  handleStreetEmitted($event: string): void {
    this.selectedStreet = $event;
  }
  */

  handleCategoryEmitted($event: string): void {
    this.selectedCategory = $event;
  }


}
