import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rotate-your-ipad',
  templateUrl: './rotate-your-ipad.component.html',
  styleUrls: ['./rotate-your-ipad.component.scss']
})
export class RotateYourIpadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
