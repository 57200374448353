import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
  ) 
  { 
  }

  shouldShowTheUserASuccessPopup(commande_id,status):boolean{
    this.removeItemFromSession("restaurantName");
    let commandeId = sessionStorage.getItem("commandeId") || null;
    if(commandeId &&  commandeId==commande_id && status!=-1) {
      this.removeItemFromSession("commandeId")
      return true;
    }

    else {
      this.removeItemFromSession("commandeId")
      return false;
    }
  }

 

  removeItemFromSession(item){
    sessionStorage.removeItem(item);
  }




}
