import { 
  Component, 
  Input, 
  OnInit, 
} 
from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';
import { MapsService } from 'src/app/services/maps.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
  fullscreenControl:boolean = false;
  location:{latitude,longitude,userAddress,zoom};
  private subjectToUnsubscribeFromObservables:Subject<any>;
  private map:any;
  @Input() disableUseCurrenntLocationButton:boolean = false;
  @Input() disableChangingMap:boolean = false;
  displayMarkerText:boolean = true;

  constructor(
    private __mapsService:MapsService,
    private __sharedDataService:SharedDataService,
    private __basketService:BasketService
  ) { }

  ngOnInit(): void {    
    if(this.disableChangingMap) this.initializeLocationFromBasketLocation();
    else this.initializeLocationWithUpdatedLocation();
  }

  mapReady(map:any){
    this.map = map;
    map.addListener("dragstart",()=>{
      this.displayMarkerText = false;
    });
    map.addListener("dragend",()=>{
      this.displayMarkerText = true;
    });
    // map.fullscreenControl = this.fullscreenControl;
    let mapOptions:any = {
      fullscreenControl: this.fullscreenControl,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
    }
    if(this.disableChangingMap){
      mapOptions = {
        ...mapOptions,
        draggable: false, 
        keyboardShortcuts:false,
        zoomControl:false,
        //I added styles to make the map look in grey color
        styles:[
          {
            featureType: "all",
            elementType: "all",
            stylers: [
              { saturation: -100 } 
            ]
          }
      ]
      }
    }

    map.setOptions(mapOptions);
   }
 
   centerChanged(coords:any){     
     if(this.disableChangingMap) return;
     if(this.__mapsService.hideMapAndChoiceButton) this.__mapsService.hideMapAndChoiceButton = false;
     //onCenterChanged
     this.__mapsService.onCenterChanged(coords);
   }
 
   putMarkerOnUserLocation(){
     this.__mapsService.makeSearchFieldEmpty();
     this.setMapZoom(13);
     this.getUserCurrentLocation();
   }
 
   getUserCurrentLocation(){
     if(window.navigator.geolocation){
       window.navigator.geolocation.getCurrentPosition(
         position => {
           setTimeout(()=>{
             this.location.latitude = position.coords.latitude;
             this.location.longitude = position.coords.longitude;
             this.setMapZoom(17);
           },500)
         },
         error => {}
       )
     }
   }
 
   setMapZoom(value){
     this.map.setZoom(value);
   }

   initializeLocationWithUpdatedLocation(){
    this.subjectToUnsubscribeFromObservables = new Subject();
    this.__mapsService.onLocationChanges
    .pipe(
      takeUntil(this.subjectToUnsubscribeFromObservables),
      tap(response => this.location = response)
    )
    .subscribe();
   }

   initializeLocationFromBasketLocation(){
     this.location = this.__mapsService.getDefaultLocation();
     this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
     .pipe(
       tap(response => {
         const {latitude,longitude} = this.__basketService.getLocation();
         if(response) this.location = {
          latitude :+latitude,
          longitude:+longitude,
           zoom:17,
           userAddress:null
         };
       })
     )
     .subscribe();
   }

 
   ngOnDestroy(): void {
    if(this.subjectToUnsubscribeFromObservables){
      this.subjectToUnsubscribeFromObservables.next("random value");
      this.subjectToUnsubscribeFromObservables.complete();
    }
   }

}
