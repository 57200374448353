import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Refund } from 'src/app/models/refund';
import { RatingService } from '../../services/rating.service'
import { PopupInformUserAboutRefundComponent } from '../popup-inform-user-about-refund/popup-inform-user-about-refund.component';

@Component({
  selector: 'app-popup-livre',
  templateUrl: './popup-livre.component.html',
  styleUrls: ['./popup-livre.component.scss']
})
export class PopupLivreComponent implements OnInit {

  screenHeightInPixels = window.innerHeight + 'px'
  
  badRatingForTheRestaurant: boolean = false

  spinnerStatus: boolean = false

  body = {
    "deliveryMan": null,
    "restaurant": null,
    "commandeId": this.data.commandeId,
    "productQuality": null,
    "missingProducts": null,
    "differentProductsReceived": null,
    "otherProblem": null
   }

  

  productQuality: Refund = {
    reason: 'Qualité du produit',
    completed: false,
    subtasks: [
      {name: 'Produit froid', completed: false},
      {name: 'Qualité défectueuse', completed: false},
      {name: 'Produit mal emballé', completed: false},
    ],
  };

  missingProducts: Refund = {
    reason: 'Produits manquants',
    completed: false,
    comment: ''
  }

  differentProductsReceived : Refund = {
    reason: 'Produits reçus différents',
    completed: false,
    comment: ''
  }

  otherProblem: Refund = {
    reason: 'Autre problème',
    completed: false,
    comment: ''
  }

  allComplete: boolean = false;

  
  deliveryManeRate = false
  restaurantRate = false

  deliveryGoodClicked = false
  deliveryBadClicked = false

  restaurantGoodClicked = false
  restaurantbadClicked = false

  commandeId = ""

  constructor(  @Inject(MAT_DIALOG_DATA) public data:any, 
                private raiting: RatingService, 
                private router: ActivatedRoute,
                private dialog: MatDialog,
                private snackBar: MatSnackBar
              ) { }


  ngOnInit(): void {
  }

  badDeliveryMan(){
    this.deliveryBadClicked = !this.deliveryBadClicked
    this.deliveryGoodClicked = false
    if(this.deliveryBadClicked){
      this.body['deliveryMan'] = "bad"
    }else{ this.body['deliveryMan'] = null }
  }

  goodDeliveryMan(){
    this.deliveryGoodClicked = !this.deliveryGoodClicked
    this.deliveryBadClicked = false
    if(this.deliveryGoodClicked){
      this.body['deliveryMan'] = "good"
    }else{ this.body['deliveryMan'] = null }
  }

  badRestaurant(){
    this.restaurantbadClicked = !this.restaurantbadClicked
    this.restaurantGoodClicked = false
    if(this.restaurantbadClicked){
      this.body['restaurant'] = "bad"
    }else{ this.body['restaurant'] = null }
  }

  goodRestaurant(){
    this.restaurantGoodClicked = !this.restaurantGoodClicked
    this.restaurantbadClicked = false
    if(this.restaurantGoodClicked){
      this.body['restaurant'] = "good"
    }else{ this.body['restaurant'] = null }
  }

  raitingSubmit(){
    if(this.body['restaurant'] == "bad"){
      this.badRatingForTheRestaurant = true
    }else{
      this.raiting.ratingRestaurantAndDeliveryMan(this.body).subscribe(res =>{
        this.snackBar.open("Merci pour votre feedback. A bientôt !", "Fermer", {duration: 2000, verticalPosition: 'top', horizontalPosition: 'center'})
        this.dialog.closeAll();
      })
    }
  }

  feedbackSubmit(){
    this.spinnerStatus = true

    this.body.productQuality = this.productQuality
    this.body.missingProducts = this.missingProducts
    this.body.differentProductsReceived = this.differentProductsReceived,
    this.body.otherProblem = this.otherProblem
    
    this.raiting.ratingRestaurantAndDeliveryMan(this.body).subscribe(res =>{
      this.openPopupInformTheUserAboutRefund()
      })
    
  }

  openPopupInformTheUserAboutRefund(){
    let configs = {
      panelClass: "inform-the-user-about-refund",
      disableClose: true    
    }
    let dialogRef = this.dialog.open(PopupInformUserAboutRefundComponent, configs);
    return dialogRef;
  }

  updateAllComplete() {
    this.allComplete = this.productQuality.subtasks != null && this.productQuality.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.productQuality.subtasks == null) {
      return false;
    }
    return this.productQuality.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.productQuality.subtasks == null) {
      return;
    }
    this.productQuality.subtasks.forEach(t => (t.completed = completed));
  }

  setMissingProducts(completed: boolean){
    this.missingProducts.completed = completed
  }

  setDifferentProductsReceived(completed: boolean){
    this.differentProductsReceived.completed = completed
  }

  setOtherProblem(completed: boolean){
    this.otherProblem.completed = completed
  }

}
