<div class="reset-password">
    <form
    #myForm="ngForm"
    [formGroup]="__formGroup"
    class="reset-password__container">
        <div class="reset-password__container-header">
            Bonjour! pour réinitialiser le mot de passe veuillez saisir votre adresse email
        </div>
        <mat-form-field
            class="reset-password__container-email"
          >
            <mat-label class="checkout-page__info-zone-text-field-margin">
                Saisissez votre adresse email
            </mat-label
            >
            <input
              formControlName="email"
              class="checkout-page__info-zone-text-field-margin"
              type="text"
              matInput
            />
            <mat-icon matSuffix>alternate_email</mat-icon>
        </mat-form-field>
        <button
            [hidden]="loading"
            (click)="resetPassword()"
            [disabled]="__formGroup.invalid"
            class="reset-password__container-reset-btn"
            >Envoyez moi un email
                <mat-icon class="reset-password__container-reset-btn-icon">touch_app</mat-icon>
        </button>
        <app-loading [hidden]="!loading"></app-loading>
    </form>
    <div class="footer" style="width: 100%;">
        <app-about-zone></app-about-zone>
    </div>
</div>



