import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {LocationPopupService} from "../../services/location-popup.service";
import {BasketService} from "../../services/basket.service";
import {HttpService} from "../../services/http.service";
import {Router} from "@angular/router";
import { Location } from "src/app/models/location";

@Component({
  selector: "app-location-popup",
  templateUrl: "./location-popup.component.html",
  styleUrls: ["./location-popup.component.scss"],
})
export class LocationPopupComponent implements OnInit, OnChanges {

  constructor(
    private locationPopupService: LocationPopupService,
    private basketService: BasketService,
    private httpService: HttpService,
    private router: Router,
  ) {
    this.location = new Location(null,null,null,null,null,null,null);
  }

  @Input() restaurantStreets: any;
  @Input() city: any;
  @Input() franchise: any;
  @Input() restaurantId: string;
  selectedStreet: string;
  isOpen: boolean;
  geolocation: Geolocation;
  noLocationIsAvailable:boolean = false;
  location:Location;

  ngOnInit(): void {
    this.location.district = null;
    this.city = this.basketService.getLocation().city;
    this.location.city = this.city;    
    this.geolocation = window.navigator.geolocation;
    this.locationPopupService.stateUpdate.subscribe((state) => {
      this.location.city = this.city;
      this.selectedStreet = this.basketService.getLocation().district;
      this.location.district = this.selectedStreet;
      if (state === "open") this.isOpen = true;
      else this.isOpen = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (this.franchise && changes.city && changes.city.currentValue) {
        const street = this.getStreets()[0];
       if(street)  this.selectedStreet = street.nom_quartier;
      }
    }, 100);
  }

  close(): void {
    this.isOpen = false;
    this.locationPopupService.close();
    this.noLocationIsAvailable =  false;
    this.location = new Location(null,null,null,null,null,null,null);
  }

  onCityChange(value): void {
    //this.city = value;
    //this.selectedStreet = this.getStreets()[0].nom_quartier;
    this.location.city = value;
  }

  onLocationChange(value): void {
    const street = this.restaurantStreets.find(
      ({ nom_quartier }) => value.includes(nom_quartier)
    );
    if (street) {
      //this.basketService.setMinimumPrice(Number(street.min_commande));
      //this.basketService.setDeliveryPrice(Number(street.frais_de_livraison));
      //this.selectedStreet = value;
      //this.noLocationIsAvailable = false;
      this.location.district = value;
    }
  }

  updateLocation(): void {
    this.city = this.location.city;
    this.selectedStreet = this.location.district;
    const street = this.restaurantStreets.find(
      ({ nom_quartier }) =>  this.location.district && this.location.district.includes(nom_quartier)
    );
   if(street) {
    this.basketService.setMinimumPrice(Number(street.min_commande));
    this.basketService.setDeliveryPrice(Number(street.frais_de_livraison));
    
   }

    if (this.city && this.selectedStreet) {

      this.basketService.setLocation(this.city,this.selectedStreet,null,null,null,null,null);
      this.close();
      if (this.franchise) {
        const restaurantUrl = this.franchise[this.city].quartiers.find(
            quartier => quartier.nom_quartier.includes(this.selectedStreet)).url_unique;
            this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/restaurant'],
              { queryParams: {name: restaurantUrl, city: this.city.trim(),
                  district: this.selectedStreet}});
        });
      }
    }
  }

  getLocation(): void {
    if (this.geolocation) {
      this.geolocation.getCurrentPosition(({ coords }) => {  
        this.httpService
          .getGeolocationInfoRestaurant(
            coords.latitude,
            coords.longitude,
            this.restaurantId
          )
          .subscribe(({ street, ville }) => {
            this.basketService.getLocation().city = ville[0];
            this.basketService.getLocation().district = street;
            this.selectedStreet = street;
            this.onLocationChange(street);
            this.close();
          },
          error=>{
            if(error.status==404){
              this.noLocationIsAvailable=true
            }
          });
      });
    }
  }

  getFranchiseCities(): string[] {
    return Object.keys(this.franchise);
  }

  getStreets(): any[] {    
    if (!this.franchise) {
      return this.restaurantStreets;
    } else {
      //return this.franchise[this.city].quartiers;
      return this.franchise[this.location.city]?.quartiers || []
    }
  }
}
