import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {BasketService} from "../../services/basket.service";
import {HttpService} from "../../services/http.service";

@Component({
  selector: "app-location-chooser",
  templateUrl: "./location-chooser.component.html",
  styleUrls: ["./location-chooser.component.scss"],
})
export class LocationChooserComponent implements OnInit, OnDestroy {
  @Output() emittedCity: EventEmitter<any> = new EventEmitter<any>();
  @Output() emittedStreet: EventEmitter<any> = new EventEmitter<any>();

  city: any = { name: "" };
  district: string;
  geolocation: Geolocation;
  cities: any[] = [];

  constructor(
    public dialog: MatDialog,
    private basketService: BasketService,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.geolocation = window.navigator.geolocation;
    //this.basketService.getLocation().city = undefined;
    
    //this.basketService.getLocation().district = undefined;
    //this.reinitializeUserLocation();
    this.httpService.getCities().subscribe((cities: any[]) => {
      this.cities = cities.map((city) => {
        const returnedCity = city;
        city.page = 0;
        return returnedCity;
      });
    });
  }

  getCities(): string[] {
    return this.cities;
  }

  getDistricts(): string[] {
    if (this.city) {
      return this.cities
        .find((city) => city.name === this.city.name)
        ?.districts.map((district) => district.name);
    }
  }

  handleCityChange(): void {
    this.basketService.getLocation().city = this.city;

    this.emittedCity.emit(this.city);
    this.emittedStreet.emit("Tout");
  }

  handleStreetChange(): void {
    this.emittedStreet.emit("working");
  }

  handleDistrictChange(): void {
    this.basketService.getLocation().district = this.district;
    this.emittedStreet.emit(this.district);
  }

  getLocation(): void {
    /*if (this.geolocation) {
      this.geolocation.getCurrentPosition(
        ({ coords }) => {
          this.httpService
            .getGeolocationInfo(coords.latitude, coords.longitude)
            .subscribe(({ street, ville }) => {
              this.basketService.getLocation().city = ville[0];
              this.basketService.getLocation().district = street;
              this.emittedCity.emit(ville[0]);
              this.emittedStreet.emit(street);
              this.city = this.getCities().find(
                (city) => (city as any).name === ville[0].name
              );
              this.district = street;
            });
        },
        (error) => {
          window.alert(error.message);
        }
      );
    }*/
  }

  reinitializeUserLocation(){
    this.basketService.getLocation().city = undefined;
    this.basketService.getLocation().district = undefined;
  }

  ngOnDestroy(): void {
    //this.reinitializeUserLocation();
  }

}
