<div class="countainer">
    <img src="../../../assets/images/like.png" alt="">
    <span>
        Merci d’avoir partagé votre feed-back. Vous recevrez un e-mail relatif à votre demande incluant un coupon de réduction le cas échéant.
    </span>
    <span>
        A très vite !
    </span>
    <span 
        class="close"
        (click)="close()"
    >
        Fermer
    </span>
</div>
