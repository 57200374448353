import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { PromotionRestaurantsService } from 'src/app/services/promotion-restaurants.service';

@Component({
  selector: 'app-popup-promo-code',
  templateUrl: './popup-promo-code.component.html',
  styleUrls: ['./popup-promo-code.component.scss']
})
export class PopupPromoCodeComponent implements OnInit {
  isLoadingResponseForPromoCode:boolean = false;
  onCloseButtonClicked:Subject<boolean> = new Subject();
  onPromoCodeBeingApplied:Subject<boolean> = new Subject();
  promoCodeValue:string = "";
  ErrorMessage:string = null;

  constructor(
    private __checkoutService:CheckoutService,
    private __basketService:BasketService,
    private __PromotinRestaurantService:PromotionRestaurantsService
  ) { }

  ngOnInit(): void {

  }

  closePopup(){
    this.onCloseButtonClicked.next(true);
  }

  onSubmit(){
    this.ErrorMessage = null;
    this.isLoadingResponseForPromoCode = true;
    this.__checkoutService.validatePromoCode(this.promoCodeValue)
    .subscribe(
      data => this.handleSuccessefullResponse(data),
      error => this.handleError(error)
    )
  }

  handleSuccessefullResponse(response){
    this.isLoadingResponseForPromoCode = false;
    this.__basketService.basket.promoCodeInfos.promoCodeType = response.type;
    this.__basketService.basket.promoCodeInfos.promoCode = this.promoCodeValue;
    this.__basketService.basket.promoCodeInfos.reduction = response.reduction;
    this.onPromoCodeBeingApplied.next(true);
  }

  handleError(error){
    this.isLoadingResponseForPromoCode = false;
    this.ErrorMessage = this.__PromotinRestaurantService.setPromoCodeResponseMsg(error["error"]["message"]);
  }

  getErrorMessage(){
    return this.ErrorMessage;
  }

}
