import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-saving-card-failed',
  templateUrl: './saving-card-failed.component.html',
  styleUrls: ['./saving-card-failed.component.scss']
})
export class SavingCardFailedComponent implements OnInit {

  constructor(
    private __router:Router
  ) { }

  ngOnInit(): void {
  }

  goBackToBankCardsPage(){
    this.__router.navigate(["carte-bancaire"]);
  }

}
