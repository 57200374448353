import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SigninService {


  constructor(
    private __http:HttpClient
  ) { }

  signinUser(user:{username:string,password:string}):Observable<any>{
    return this.__http
    .post(
      `${environment.apiUrl}login/sign_in`,
      user
    )
  }

  checkIfUserExist(user:{email:string}):Observable<any>{
    return this.__http
    .post(
      `${environment.apiUrl}register/check_with_google`,
      user
    )
  }

  signUp(user:{nom:string,prenom:string,telephone:string,email:string}):Observable<any>{
    return this.__http
    .post(                           
      `${environment.apiUrl}register/sign_up_with_google`,
      user
    )
  }

}
