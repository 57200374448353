import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss']
})
export class CommandComponent implements OnInit {
  @Input()
  command:{prix_total, status, created_at, index, uuid, logo, restaurant_name};

  constructor(
    private __router:Router
  ) { }

  ngOnInit(): void {
  }

  getStatusColor(){
    let backgroundColor = "";
    switch(this.command.status){
      case "Refusée":
        backgroundColor="#393541";
        break;
      case "Annulée":
        backgroundColor="#393541";
        break;
      case "En cours":
        backgroundColor="#393541";
        break;
      case "En attente de confirmation":
        backgroundColor="#393541";
        break;
      case "En cours de préparation":
        backgroundColor="#393541";
        break;
      case "En cours de livraison":
        backgroundColor="#393541";
        break;
      case "En attente de paiement":
        backgroundColor="#393541";
        break;
      default : 
        backgroundColor="#393541";
    }
    return backgroundColor;
  }

  redirectionToSuiviDeCommandePage(){
    this.__router.navigate(["suivi-commande",this.command.uuid]);
  }

}
