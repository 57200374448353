import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocationPopupService {
  state: boolean = false;
  stateUpdate: EventEmitter<any> = new EventEmitter();

  constructor() {}

  open(): void {
    this.stateUpdate.emit("open");
    this.state = true;
  }
  close(): void {
    this.stateUpdate.emit("close");
    this.state = false;
  }
  isOpen(): boolean {
    return this.state;
  }
}
