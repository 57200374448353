<div class="countainer">
    <img src="../../../assets/images/oops.png" alt="">
    <span>
        En raison d’une forte demande, nous vous prions de réessayer dans quelques instants.
    </span>
    <span>
        Nous vous remercions pour votre compréhension et vous disons à tout de suite !
    </span>
    <span 
        class="close"
        (click)="close()"
    >
        Fermer
    </span>
</div>
