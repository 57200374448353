<div class="home-page__container">
  

    <app-swiper class="home-page__swiper"></app-swiper>
    <!--
      <app-location-chooser
      (emittedCity)="handleCityEmitted($event)"
      (emittedStreet)="handleStreetEmitted($event)"
      class="home-page__location-chooser"
    >
    </app-location-chooser> 
    -->
    <!--{{selectedCity | json}}-->
  
    <app-carousel-categories
    [ngClass]="{'carouselCategoriesNeedMarginTop': __sharedDataService.showSearchFieldToSearchForARestaurant}"
    (selectedCategoryChange)="handleCategoryEmitted($event)"  
    [(selectedCategory)]="selectedCategory"
    class="home-page__carousel-categories"
    >
    </app-carousel-categories>
  
    
  <!--
    <app-promotion-restaurants
      [category]="selectedCategory"
      [city]="selectedCity"
      [street]="selectedStreet"
      class="home-page__promotion-restaurants"
    >
    </app-promotion-restaurants>
  -->
    
  <!--
    <app-new-restaurants 
      [category]="selectedCategory"
      [city]="selectedCity"
      [street]="selectedStreet"
      class="home-page__new-restaurants"> 
  
    </app-new-restaurants>
  -->

  <!--
    <app-promotions
        title="Spécial Ramadan"
        [category]="selectedCategory"
        icon="promotions.png"
        class="home-page__new-restaurants">
    </app-promotions>
    
    <app-new-restaurants
        title="Nouveaux restaurants"
        icon="new-resturants-icon.png"
        [category]="selectedCategory"
        class="home-page__new-restaurants">
    </app-new-restaurants>-->
  <!--
    <app-restaurants
      [category]="selectedCategory"
      [city]="selectedCity"
      [street]="selectedStreet"
      class="home-page__restaurants"
    >
    </app-restaurants>
  -->
  <app-restaurants
      [category]="selectedCategory"
      class="home-page__restaurants"
    >
    </app-restaurants>
    <app-join-us class="home-page__join-us"></app-join-us>
    <app-newsletter class="home-page__newsletter-zone"></app-newsletter>
    <app-about-zone class="home-page__about-zone"></app-about-zone>
  </div>
  