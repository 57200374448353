import { 
    AfterViewInit, 
    Component, 
    ElementRef, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    ViewChild 
  } 
from "@angular/core";
import { BasketService } from "../../services/basket.service";
import { RestaurantService } from "../../services/restaurant.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {AuthService} from 'src/app/services/auth.service';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmUserChoiceComponent } from "../confirm-user-choice/confirm-user-choice.component";
import { Location } from "@angular/common";
import { filter, takeUntil, tap } from "rxjs/operators";
import {SharedDataService} from 'src/app/services/shared-data.service';
import { Subject, Subscription } from "rxjs";
import { HomePageService } from "src/app/services/home-page.service";
import { PopupLoaderComponent } from "../popup-loader/popup-loader.component";
import { PopupInformUserComponent } from "../popup-inform-user/popup-inform-user.component";
import { MapService } from "src/app/services/map.service";
import { CheckoutService } from "src/app/services/checkout.service";
import { HttpService } from "src/app/services/http.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

export class HeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  searchFieldValue = ""
  restaurantName = ""
  search = ""
  city = ""
  district = ""
  menuState: boolean = false;
  window: Window = window;
  currentPath:string;
  currentUrl:string;
  phoneLink: string;
  isInHomePage:boolean = false;
  isInSearchRestaurantsPage:boolean = false;
  isInRestaurantsPage: boolean = false;
  isInARestaurantPage:boolean = false;
  isListOpened:boolean = false;
  isLoggedIn:boolean = false;
  private dialogRefToPopupLOcationLoader:MatDialogRef<PopupLoaderComponent> = null;
  private subjectToUnsubscribeFromObservables:Subject<any>;
  
  @ViewChild("justifyContentShouldBeStart", { static:true }) justifyContentShouldBeStart:ElementRef;
  @ViewChild("textAlignShouldBeLeft", { static:true }) textAlignShouldBeLeft:ElementRef;
  @ViewChild("leftPart", { static:true }) leftPart:ElementRef; 

  constructor(
    public basketService: BasketService,
    public restaurantService: RestaurantService,
    private router: Router,
    private route: ActivatedRoute,
    private __authService:AuthService,
    private dialog: MatDialog,
    private location:Location,
    private __sharedDataService:SharedDataService,
    private __homePageService:HomePageService,
    private __mapService:MapService,
    private ___checkoutService:CheckoutService,
    private httpService: HttpService
  ) {

    router.events.subscribe((events)=> {
      this.currentUrl = this.router.url.split("/")[1]
      if(this.currentUrl.includes("restaurant?name=")) this.__sharedDataService.isInARestaurantPage = true
      else this.__sharedDataService.isInARestaurantPage = false
      this.isInARestaurantPage = this.__sharedDataService.isInARestaurantPage
    })

    router.events.subscribe((events)=> {
      this.currentUrl = this.router.url.split("/")[1]
      if(this.currentUrl.includes("restaurants")){
        this.__sharedDataService.isInRestaurantsPage = true
        this.isInRestaurantsPage =  this.__sharedDataService.isInRestaurantsPage
      }else{
        this.__sharedDataService.isInRestaurantsPage = false
        this.isInRestaurantsPage = this.__sharedDataService.isInRestaurantsPage
      }
    })

    router.events.subscribe((events)=> {
      this.currentUrl = this.router.url.split("/")[1]
      if(this.currentUrl=="search"){    
        this.__sharedDataService.isInSearchRestaurantsPage = true
        this.isInSearchRestaurantsPage = this.__sharedDataService.isInSearchRestaurantsPage;
      }
      else{
        this.__sharedDataService.isInSearchRestaurantsPage = false
        this.isInSearchRestaurantsPage = this.__sharedDataService.isInSearchRestaurantsPage;
      }
    })

    router.events.subscribe((events) => {
      this.currentUrl = this.router.url.split("/")[1];
      if(this.currentUrl=="menu") this.isListOpened = true;
      this.phoneLink = `tel:+212${this.restaurantService.getRestaurantNumber()}`;
    });
    this.subjectToUnsubscribeFromObservables = new Subject();
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.restaurantName = params.get('name')
      this.search = params.get('search')
      this.city = params.get('city')
      this.district = params.get('district')
    })
    this.locateUser();
    this.phoneLink = `tel:+212${this.restaurantService.getRestaurantNumber()}`;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(
      takeUntil(this.subjectToUnsubscribeFromObservables)
    )
    .subscribe(e=>{
      if(e["url"]!="/home" && e["url"]!="/"){
        this.isInHomePage = false
        this.__sharedDataService.isInHomePage = false
      }
      else if(!this.isInHomePage){
        this.isInHomePage=true;
        this.__sharedDataService.isInHomePage = true
      }
      if(e["url"]!="/menu") { this.isListOpened=false; this.removeClasses(); }
      else this.addClasses();
    });
    this.__authService.getIsLoggedInSubject()
    .pipe(
      takeUntil(this.subjectToUnsubscribeFromObservables)
    )
    .subscribe(data=>this.isLoggedIn=data);
  }

  ngOnChanges(): void {
    this.phoneLink = `tel:+212${this.restaurantService.getRestaurantNumber()}`;
  }

  openMenu() {
    this.menuState = !this.menuState;
  }

  getRestaurantPhone() {
    return `tel:+212${this.restaurantService.getRestaurantNumber()}`;
  }

  openList(){
    this.isListOpened = true;
    this.router.navigate(["menu"]);
    this.addClasses();
  }

  closeList(){
      this.removeClasses();
      this.isListOpened = false;
      this.location.back();
  }

  redirectMeToSignin(){
    this.router.navigate(["se-connecter"]);
    this.isListOpened=false;
  }

  redirectMeToBankCArd(){
    this.router.navigate(["carte-bancaire"]);
    this.isListOpened=false;
  }

  redirectMeToSignup(){
    this.router.navigate(["s'inscrire"]);
    this.isListOpened=false;
  }

  openDialog() {
    let dialogRef = this.dialog.open(ConfirmUserChoiceComponent, { panelClass: 'my-custom-dialog-class' });
    return dialogRef;
  }

  addClasses(){
    if(this.leftPart) this.leftPart.nativeElement.classList.add("removeLeftPart");
    if(this.justifyContentShouldBeStart) this.justifyContentShouldBeStart.nativeElement.classList.add("justifyContentShouldBeStart");
    if(this.textAlignShouldBeLeft) this.textAlignShouldBeLeft.nativeElement.classList.add("textAlignShouldBeLeft");
  }

  removeClasses(){
    if(this.leftPart) this.leftPart.nativeElement.classList.remove("removeLeftPart");
    if(this.justifyContentShouldBeStart) this.justifyContentShouldBeStart.nativeElement.classList.remove("justifyContentShouldBeStart");
    if(this.textAlignShouldBeLeft) this.textAlignShouldBeLeft.nativeElement.classList.remove("textAlignShouldBeLeft");
  }
  toggleSearchFieldOfRestaurants(){
    this.__sharedDataService.showSearchFieldToSearchForARestaurant = !this.__sharedDataService.showSearchFieldToSearchForARestaurant
    if(this.__sharedDataService.isInSearchRestaurantsPage) this.router.navigateByUrl('/home')
  }

  toggleSearchFieldOfRestaurantPage(){
    this.__sharedDataService.showSearchFieldToSearchInARestaurantPage = !this.__sharedDataService.showSearchFieldToSearchInARestaurantPage
  }

  clearSearch(){
    if(!this.city && !this.district) this.router.navigate(['/restaurant'], {queryParams: { name : this.restaurantName, search: "" }})
    if(this.city && this.district) this.router.navigate(['/restaurant'], {queryParams: { name : this.restaurantName, city : this.city, district: this.district, search: "" }})
  }


  openPopupLoader():MatDialogRef<PopupLoaderComponent>{
    let configs = {
      panelClass:"popup-loader",
      disableClose:true,
      data:{
        text:"Attendez, on essaye de vous localiser automatiquement"
      }
    };
    let dialogRef = this.dialog.open(PopupLoaderComponent,configs);
    return dialogRef;
  }

  openPopupInformUser(text){
    /*let dialogConfigs = {
      data:{
        header:"Oops !",
        msg,
        buttonClass:"green"
      },
      disableClose:true
    }
    this.dialog.open(PopupInformUserComponent,dialogConfigs);
    */
    const data = {
      imgSrc:"map-location.png",
      text,
      btn1Text:"Continuer",
      btn2Text:"",
      btn1Fun:() => {
        
      },
      btn2Fun:() => {
        
      },
      subjectToUnsubscribeFromObservables:this.subjectToUnsubscribeFromObservables
    }
   this.___checkoutService.openPopupConfirmUserChoice(data)
  }

  handleResponseOfMapServiceForUserCurrentLocation(msg){
    this.__mapService.subjectToInformHeaderWithResultOgGettingCurrentUserLocation.complete();
    if(!this.basketService.getService()){
     if(this.basketService.basket.hasDelivry) this.basketService.setService("Delivery");
     else if(this.basketService.basket.hasTakeway) this.basketService.setService("Takeaway");
    }
    if(msg!=null) this.handleNavigatorGeolocationFailedResponse(msg);
    else this.handleNavigatorGeolocationSucessefullResponse();
  }

  locateUser(){
    this.currentPath = window.location.href.split('/')[3]
    if(!(this.currentPath == 'suivi-commande')){
    //had ster rah mohim
    this.dialogRefToPopupLOcationLoader = this.openPopupLoader();
    this.dialogRefToPopupLOcationLoader.componentInstance.onCancelButtongetClicked
    .pipe(tap(res => res && this.stopLocatingUserAutomaticlly())) 
    .subscribe();
    this.__mapService.subjectToInformHeaderWithResultOgGettingCurrentUserLocation
    .pipe(
      takeUntil(this.subjectToUnsubscribeFromObservables),
      tap(msg => this.handleResponseOfMapServiceForUserCurrentLocation(msg))
    )
    .subscribe();
    this.__mapService.locateUser();
    }else{
      this.__mapService.locateUser();
    }
    
  }

  handleNavigatorGeolocationSucessefullResponse(){
    if(this.dialogRefToPopupLOcationLoader) this.dialogRefToPopupLOcationLoader.close();
  }

  stopLocatingUserAutomaticlly(){
    
  }

  handleNavigatorGeolocationFailedResponse(msg){
    if(this.dialogRefToPopupLOcationLoader) this.dialogRefToPopupLOcationLoader.close();
    this.basketService.setHasDelivry(true);
    if(!this.basketService.getService()) this.basketService.setService("Delivery");
    if(this.basketService.basket.minimumPrice!=0) this.basketService.setMinimumPrice(0);
    this.openPopupInformUser(msg);
  }

  redirectToMapPage(){
    this.router.navigate(["map"]);
  }

  getMainAddress():string{
    let mainAddress  = this.basketService.getLocation().mainAddress.substring(0,20);
    if(this.basketService.getLocation().mainAddress.length>20) mainAddress+="...";
    return mainAddress;
  }

  ngOnDestroy(){
   this.subjectToUnsubscribeFromObservables.next("random value");
   this.subjectToUnsubscribeFromObservables.complete();
  }

}
