import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HoldingDataService {

  constructor() { }
  serviceCharge:number = 0;
}
