<div class="search-field">
    <div class="search-field-container">
        <input type="text"
            id="inputTag"
            class="search-field-container-input"
            [(ngModel)]="searchFieldValue"
            (focus)="onFocus()"
            (keyup)="onKeyUp()"
            (blur)="onBlur()"
            (input)="search()"
        >
        <img src="../../../assets/images/search-for-input.png"
             class="search-field-container-icon" 
             #imgRef  
        >
        <span
            class="search-field-container-placeholder"
            #placeholderRef
        >Recherche</span>
    </div>
</div>

