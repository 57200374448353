<div class="errors-container">
    <h1 class="errors-container-header">Les champs suivants sont invalides !</h1>

    <div class="errors-container-list">
        <div 
        class="errors-container-list-item"
        *ngFor="let msg of errors;index as i"
        >
        {{i+1}}- {{msg}}
        </div>
    </div>

    <div mat-dialog-actions align="center">
    <button 
    mat-button mat-dialog-close 
    class="errors-container-button"
    >
    Ok
    </button>
    </div>
</div>