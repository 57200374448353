import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {SignupService} from 'src/app/services/signup.service';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  __formGroup: FormGroup;
  isTheAppShowingPassword:boolean = false;
  isTheAppShowingConfirmPassword:boolean = false;
  loading:boolean = false;

  constructor(
    private __formBuilder:FormBuilder,
    private __signupService:SignupService,
    private toastr:ToastrService,
    private router:Router,
    private __authService:AuthService
  ) 
  { }

  @ViewChild('confirmPassword') confirmPasswordRef: ElementRef;
  @ViewChild('formDirective') formDirective:NgForm;

  ngOnInit(): void {
    this.toastr.clear();
    this.initializeOurFormGroup();
  }

  getErrorMessage(field: string): string {
    if (this.__formGroup.get(field).hasError("notSame")) {
      return "Vous devez saisir le même mot de passe";
    }

    if (!this.__formGroup.get(field).hasError("required") ) {
      return "Ce champs est invalide";
    }

    return "";
  }

  initializeOurFormGroup(){
    this.__formGroup =  this.__formBuilder.group(
      {
        nom: ["", [Validators.required, Validators.minLength(3), this.isFieldValidateMinLength(3)]],
        prenom: ["", [Validators.required, Validators.minLength(3), this.isFieldValidateMinLength(3)]],
        telephone: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("0(6|7)[0-9]*$"),
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^([a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+)@([a-zA-Z0-9-]+)(\\.[a-zA-Z0-9-]+)+$"
            ),
          ],
        ],
        password:["",[Validators.required, Validators.minLength(6), this.isFieldValidateMinLength(6)]],
        confirmPassword:["",[Validators.required, Validators.minLength(6), this.checkPasswords]]
      }
    )
  }

  onSubmit(){
    this.loading=true;
    let user:{nom:string,prenom:string,telephone:string,email:string,password:string} = {
      nom:this.__formGroup.get("nom").value.trim(),
      prenom:this.__formGroup.get("prenom").value.trim(),
      telephone:this.__formGroup.get("telephone").value,
      email:this.__formGroup.get("email").value.trim(),
      password:this.__formGroup.get("password").value
    }
    this.__signupService.signupUser(user)
    .subscribe(
      data=>this.handleResponse(data),
      error=>this.handleError(error)
    )
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = this.__formGroup?.get('password')?.value;
    let confirmPass = this.__formGroup?.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }


  isFieldValidateMinLength(minLength:number):ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  
        (control.value!=null && control.value.trim().length>=minLength) ? 
         null : {isFieldDoesNotValidateMinLength:true}
  }


  handleResponse(response){
    this.loading=false;
    this.toastr.clear();
    this.formDirective.resetForm();
    let msg = "Votre compte a été créé avec succès";
    this.showSuccessToaster(msg,"Félicitation !")
    .onHidden.subscribe(data=>{this.__authService.login(response);});
  }

  handleError(error){
    this.loading=false;
    this.toastr.clear();
    let msg = "Ce numéro de téléphone ou cet E-mail a déjà été utilisé pour la création d'un autre compte";
    if(error.status==409)  this.showErrorToaster(msg,"Compte déjà existant")
  }

  showErrorToaster(msg,header){
     return this.toastr.error(msg,header,{
       timeOut:10000,
      })
  } 

  showSuccessToaster(msg,header){
    return this.toastr.success(msg,header,{
      timeOut:1500,
      closeButton:true
    })
  }

  redirectMeToSignin(){
    this.router.navigate(["se-connecter"])
  }

  hideThePassword(fieldRef,inputName){
    fieldRef.type="password"; 
    if(inputName=="password") this.isTheAppShowingPassword = false;
    else this.isTheAppShowingConfirmPassword = false;
  }
 
  showThePassword(fieldRef,inputName){
     fieldRef.type="text";
     if(inputName=="password") this.isTheAppShowingPassword = true;
     else this.isTheAppShowingConfirmPassword = true;
  }

  redirectMeToHomePage(){
     this.router.navigate([""]);
  }

  checkIfPassworsAreEquals(){
    let password = this.__formGroup.get("password").value;
    let confirmPassword = this.__formGroup.get("confirmPassword").value;
    if(password != confirmPassword) this.__formGroup.get("confirmPassword").setErrors({notSame:true});
    else this.__formGroup.get("confirmPassword").setErrors({notSame:null});
    this.__formGroup.get("confirmPassword").updateValueAndValidity();
  }

}
