import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Refund} from '../../models/refund';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {

  @ViewChild('otherProblem') otherProblemElem: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  productQuality: Refund = {
    reason: 'Qualité du produit',
    completed: false,
    subtasks: [
      {name: 'Produit froid', completed: false},
      {name: 'Qualité défectueuse', completed: false},
      {name: 'Produit mal emballé', completed: false},
    ],
  };

  missingProducts: Refund = {
    reason: 'Produits manquants',
    completed: false,
    comment: ''
  }

  differentProductsReceived : Refund = {
    reason: 'Produits reçus différents',
    completed: false,
    comment: ''
  }

  otherProblem: Refund = {
    reason: 'Autre problème',
    completed: false,
    comment: ''
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.productQuality.subtasks != null && this.productQuality.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.productQuality.subtasks == null) {
      return false;
    }
    return this.productQuality.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.productQuality.subtasks == null) {
      return;
    }
    this.productQuality.subtasks.forEach(t => (t.completed = completed));
  }

  setMissingProducts(completed: boolean){
    this.missingProducts.completed = completed
  }

  setDifferentProductsReceived(completed: boolean){
    this.differentProductsReceived.completed = completed
  }

  setOtherProblem(completed: boolean){
    this.otherProblem.completed = completed
  }

}
