import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payement-failed',
  templateUrl: './payement-failed.component.html',
  styleUrls: ['./payement-failed.component.scss']
})
export class PayementFailedComponent implements OnInit {
  restaurantName:string;

  constructor(
    private __router:Router
  ) { }

  ngOnInit(): void {
    this.restaurantName = this.getItemFromSession("restaurantName");
    if(this.restaurantName) this.removeItemFromSession("restaurantName");
    else this.redirectToHomePage();
  }

  getItemFromSession(item){
    return sessionStorage.getItem(item)
  }

  removeItemFromSession(item){
    sessionStorage.removeItem(item);
  }

  redirectToHomePage(){
    this.__router.navigate([""]);
  }

  goBackToTheRestaurantPage(){
    this.__router.navigate(["restaurant"],{queryParams:{name:this.restaurantName}});
  }
}
