import { Injectable } from '@angular/core';
import { BasketLocalstorageService } from './basket-localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class HomePageService{
  isUserAccessHomePageForFirstTimeInThisSession:boolean = true;
  constructor(
    private __basketLocalStorageService:BasketLocalstorageService,
  ) { }
 

  checkingTheVersionOfUserBasket(){
    const isSesionExist = sessionStorage.getItem("basketUpdated");
    if(!isSesionExist){
      this.__basketLocalStorageService.initializeLocalStorageWithEmptyBasket();
      sessionStorage.setItem("basketUpdated","$$*$$");
    }
  }

}
