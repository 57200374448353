import { ChangeDetectorRef, Component, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MapsService } from 'src/app/services/maps.service';
import { MapService } from  'src/app/services/map.service'
import { MatDialog } from '@angular/material/dialog';
import { AddressListComponent } from './address-list/address-list.component';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AddressListService } from 'src/app/services/address-list.service';
import { BasketService } from 'src/app/services/basket.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Location } from '@angular/common';
import { MapsComponent } from './maps/maps.component';

type addressType = {lat:number, long:number, mainAddress:string, additionalAddressInfos:string,city:string,street:string, id};

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  userAddresses:addressType[] = [];
  address:addressType= null;
  @ViewChild("appMapsRef") appMapsRef:MapsComponent;

  constructor(
    public __mapService:MapService,
    private __activatedRoute:ActivatedRoute,
    private __addressListService:AddressListService,
    private __basketService:BasketService,
    private __sharedDataService:SharedDataService,
    private __checkoutService:CheckoutService,
    private __location:Location
  )
  {}

  ngOnInit(): void {    
    this.__activatedRoute.data.pipe(
      tap(data => this.handleResponseForGettingUserAddresses(data.userAddresses))
    )
    .subscribe();
  }

  handleResponseForGettingUserAddresses(userAddresses){
    this.userAddresses = userAddresses;
    if(this.userAddresses.length == 0) this.__mapService.goToNextStep();
  }

  onChoicePoint(){
    this.__mapService.goToNextStep();
  }

  goToPreviousStep(){
    this.__mapService.goToPreviousStep();
  }

  initializeAddressObject(){
    this.address = {
      lat:null,
      long:null,
      mainAddress:null,
      additionalAddressInfos:null,
      city:null,
      street:null,
      id:null
    }
  }

  setGeolocatioInfos({lat,long,mainAddress}){    
    if(this.address == null) this.initializeAddressObject();
    const fixedLat = lat.toFixed(6);
    const fixedLong = long.toFixed(6)
    this.address = {
      ...this.address,
      lat:fixedLat,
      long:fixedLong,
      mainAddress
    }
    this.__addressListService.setCityAndDistrictFromGeolocation(fixedLat,fixedLong)
    .pipe(
      tap(({ street, ville }) => {        
        this.address.city = ville[0]?.name;
        this.address.street = street;
      })
    )
    .subscribe({
      error:()=>{
        this.address.city = "random value";
        this.address.street = "random value";
      }
    });
  }

  setAddressInfos({mainAddress,additionalAddressInfos}){
    const dialogRef = this.__checkoutService.openModernPopupLoader();
    this.address.mainAddress = mainAddress;
    this.address.additionalAddressInfos = additionalAddressInfos;
    this.userAddresses.push(this.address);
    this.__addressListService.addUserAddress(this.address)
    .pipe(tap(response => {
      this.address.id = response.id;
      this.setLocationFromAddressInfos();
      dialogRef.close();
      this.__location.back();
    }))
    .subscribe();
  }

  setLocationFromAddressInfos(){
    const location = this.__basketService.location;
    location.mainAddress = this.address.mainAddress;
    location.additionalAddressInfos = this.address.additionalAddressInfos;
    location.latitude = this.address.lat;
    location.longitude = this.address.long;
    location.city = this.address.city;
    location.district = this.address.street;
    location.id = this.address.id;
  }

  ngOnDestroy(){
    this.__mapService.initializeStep();
  }

}
