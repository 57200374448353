// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*apiUrl:'https://r7-lalivraisonv2-api.lalivraison.ma/',  API R7 */
  apiUrl: 'https://lalivraisonv2-api.lalivraison.ma/', // lalivraison
  apiUrlLacaisse: 'https://api-legacy.lacaisse.ma/', //Lacaisse.ma
  apiKey:'AIzaSyD2laIvUMuRXW4dL5MTQTfMyMjUr0841tY'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
