import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/services/basket.service';

@Component({
  selector: 'app-promotion-restaurant',
  templateUrl: './promotion-restaurant.component.html',
  styleUrls: ['./promotion-restaurant.component.scss']
})
export class PromotionRestaurantComponent implements OnInit {
  @Input() restaurant:{name, image, codePromo, valeurPromo, url_unique};

  constructor(
    private __basketService:BasketService,
    private __router:Router
  ) { }

  ngOnInit(): void {

  }

  redirectToRestaurantPage(){
    this.__basketService.setRestaurantNameAndPromoCodeForPromoCodeInfosObject(this.restaurant.url_unique, this.restaurant.codePromo);
    this.__router.navigate(["restaurant"],{queryParams:{"name":this.restaurant.url_unique}});
  }
}
