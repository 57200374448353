<div class="restaurant__container">

  <span class="restaurant__container-remise" *ngIf="remise > 0">
   
  </span>

  <span class="restaurant__container-text" *ngIf="remise > 0">
   -{{ remise }}%
  </span>

  <span *ngIf="closed && !preOrder" class="restaurant-closed__container">
    <span class="restaurant-closed__text"> Fermé </span>
  </span>
  <span *ngIf="closed && preOrder" class="restaurant-pre-order__container">
    <span class="restaurant-pre-order__text"> Précommander </span>
  </span>
  <span *ngIf="estimationDuration && !closed" class="restaurant__estimation-duration">
    <img class="restaurant__estimation-duration--timer" src="../../../../assets/images/timer.png" alt="">
    <span class="restaurant__estimation-duration--text">{{ estimationDuration }}</span>
  </span>
  <img [src]="image" class="restaurant__image" />
  <div class="restaurant__body">
    <div
      class="restaurant__body--upper"
    >
      <div class="restaurant__name"><span>{{ name }}</span></div>
      <div class="restaurant__delivery-takeaway">
        <span [class.restaurant__no-takeaway-delivery]="delivery !== true"
          >Livraison</span
        >&nbsp;
        <img
          style="height: 15px; width: 15px"
          [src]="
            delivery === true
              ? '../../../assets/images/restaurants/ok.png'
              : '../../../assets/images/restaurants/ko.png'
          "
        />&nbsp;
        <span [class.restaurant__no-takeaway-delivery]="takeAway !== true"
          >Emporter</span
        >&nbsp;
        <img
          style="height: 15px; width: 15px"
          [src]="
            takeAway === true
              ? '../../../assets/images/restaurants/ok.png'
              : '../../../assets/images/restaurants/ko.png'
          "
        />
      </div>
    </div>
    <div class="restaurant__body--down">
      <div class="down__first" style="font-size: 13px; margin-bottom: -3px;">{{ type.toUpperCase() }}</div>
      <div class="down__seconde" style="font-size: 13px">
        <span *ngFor="let cat of categories; let i = index">
          <span>{{ i > 0 ? " - " + cat : cat }}</span>
        </span>
      </div>
      <!--<br>-->
    </div>
    <div class="down__city">
      {{ city }}
    </div>
  </div>
</div>
