<div 
    (click)="redirectToRestaurantPage()"
    class="promotion-restaurant">
    <div class="promotion-restaurant-container">
        <div 
        class="promotion-restaurant-container-restauarant-background-level">
            <img 
                class="promotion-restaurant-container-restauarant-background-level-restaurant-img"
                [src]="restaurant.image" 
                alt=""
            >
            <div class="promotion-restaurant-container-restauarant-background-level-restaurant-infos">
                <div class="promotion-restaurant-container-restauarant-background-level-restaurant-infos-header">
                    <div class="promotion-restaurant-container-restauarant-background-level-restaurant-infos-header-text">
                        Les promos
                    </div>
                    <img 
                        src="../../../../assets/images/lalivraisontextlogo.png"
                        class="promotion-restaurant-container-restauarant-background-level-restaurant-infos-header-logo"
                    >      
                </div>
                <div class="promotion-restaurant-container-restauarant-background-level-restaurant-infos-reduction-value">
                    -{{restaurant.valeurPromo}}%
                </div>
                <img 
                    class="promotion-restaurant-container-restauarant-background-level-restaurant-infos-redirection-icon"
                    src="../../../../assets/images/go-back.png" alt="">
                
            </div>
        </div>

        <div  class="promotion-restaurant-container-footer-level">
            <div class="promotion-restaurant-container-footer-level-promoCode-text">
                Code promo : {{restaurant.codePromo}}
            </div>

            <button 
                (click)="redirectToRestaurantPage()"
                class="promotion-restaurant-container-footer-level-btn"
            >
                Profiter de l'offre
            </button>
        </div>

    </div>
</div>
