<div class="popup">
    <h1 mat-dialog-title *ngIf="dialogInfos.header!=''"> {{dialogInfos.header}} </h1>
    <div mat-dialog-content class="content" [innerHtml]="dialogInfos.msg"> </div>
    <div mat-dialog-actions align="center">
    <button
        mat-button mat-dialog-close 
        [ngClass]="dialogInfos.buttonClass"
    >
     Ok
    </button>
    </div>
</div>