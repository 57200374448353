<div class="track-order__container"
     [ngStyle]="{'height': heightOfBrowser}"
>
    <!--
    <div class="track-order__container__header">
       Suivez votre commande
    </div>
    -->
    <agm-map 
        class="track-order__container__agm-map"
        (mapReady)="mapReady($event)"
        [fitBounds]="true" 
        [styles]="styleArray"
    >
        <agm-marker
            class="track-order__container__agm-map__marker-user-location"
            [opacity]="1"
            [latitude]="mapInfos.userCoordinates.lat"
            [longitude]="mapInfos.userCoordinates.lng"
            [iconUrl]="mapInfos.userCoordinates.icon"
            [agmFitBounds]="true"
        >
        </agm-marker>

        <agm-marker
            class="track-order__container__agm-map__marker-delivery-man"
            [opacity]="1"
            [latitude]="mapInfos.deliveryCoordinates.lat"
            [longitude]="mapInfos.deliveryCoordinates.lng"
            [iconUrl]="mapInfos.deliveryCoordinates.icon"
            [agmFitBounds]="true"
        >
        </agm-marker>

        <agm-marker
            [latitude]="mapInfos.restaurantCoordinates.lat"
            [longitude]="mapInfos.restaurantCoordinates.lng"
            class="track-order__container__agm-map__marker-restaurant-location"
            [opacity]="1"
            [iconUrl]="mapInfos.restaurantCoordinates.icon"
            [agmFitBounds]="true"
        >
        </agm-marker>  
    </agm-map>
</div>