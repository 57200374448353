import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-confirm-cancel-order',
  templateUrl: './confirm-cancel-order.component.html',
  styleUrls: ['./confirm-cancel-order.component.scss']
})
export class ConfirmCancelOrderComponent implements OnInit {

  private oderId= null;
  onConfirm = new Subject();
  onConfirmButtonClicked:Subject<any> = new Subject();

  constructor(
    private __httpService:HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
   this.oderId =  this.data.orderId;
  }

  confirm(){
    this.onConfirmButtonClicked.next("onButtonConfirmClicked");
    this.__httpService.cancelOrder(this.oderId).subscribe(
      data=>this.onConfirm.next(data)
    );
  }

  

}
