<div 
  class="maps"
  >
    <input 
      type="text" 
      class="maps__search-bar"
      (keydown.enter)="$event.preventDefault()"
      placeholder="Entrez votre adresse ou un emplacement à proximité pour mieux vous localiser" 
      autocorrect="off" 
      autocapitalize="off" 
      spellcheck="off" 
      type="text" 
      #search
      [ngClass]="{'center-search-bar':__mapsService.hideMapAndChoiceButton}"
      [(ngModel)]="__mapsService.searchFieldValue"
    />
    <img 
      src="../../../../assets/images/earth.gif" 
      class="maps__earth-icon"
      *ngIf="__mapsService.hideMapAndChoiceButton"
    >
    <img 
      src="../../../../assets/images/send-icon.png" 
      alt=""
      class="maps__go-back"
      (click)="goBack()"
      *ngIf="__mapsService.hideMapAndChoiceButton"
    >
    <button
      *ngIf="__mapsService.hideMapAndChoiceButton && displayLocateMeButton"
      class="maps__locate-user-btn"
      (click)="locateUser()"
    >
      Utiliser ma position
      <img 
        src="../../../../assets/images/send-simple-green.png" 
        alt=""
        class="maps__locate-user-btn-icon"
        >
    </button>


    <app-google-maps 
      *ngIf="!__mapsService.hideMapAndChoiceButton"      
      class="maps__google-maps"
      >
    </app-google-maps>
<!--
    <div
      *ngIf="!__mapsService.hideMapAndChoiceButton"     
      class="maps__description"
    >
      Placez le drapeau exactement sur votre position pour aider le coursier à trouver votre adresse.
    </div>
-->
    <button
      *ngIf="!__mapsService.hideMapAndChoiceButton"      
      class="maps__btn"
      [disabled]="disableChoicePoint"
      (click)="choosePoint()"
    >
      Me faire livrer ici
    </button>
  </div>