<div class="popup-loader-box">
    <div class="popup-loader-box-container">
        <img class="popup-loader-box-container-spinner" src="../../../assets/images/spinerGif.gif" alt="">
        <div class="popup-loader-box-container-text">
            {{text}}
        </div>
        <button class="popup-loader-box-container-btn" (click)="stopProcess()">
            Annuler
        </button>
    </div>
</div>
