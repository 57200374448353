import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router, Scroll} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "src/app/services/http.service";
import { SharedDataService } from "src/app/services/shared-data.service";

@Component({
  selector: 'app-restaurants-page',
  templateUrl: './restaurants-page.component.html',
  styleUrls: ['./restaurants-page.component.scss']
})
export class RestaurantsPageComponent implements OnInit {

  selectedCategory = "Tout";
  //selectedCity: string = null;
  //selectedStreet: string = null;
  searchFiledValue:string = '';
  utm: string="";

  webImages = [];
  mobileImages = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toaster:ToastrService,
    public __sharedDataService:SharedDataService,
    private http: HttpService
    ) {}

  ngOnInit(): void {
    this.http.getSwiperImages().subscribe(result => {
      this.webImages = result.web
      this.mobileImages = result.mobile
    })

    this.__sharedDataService.isInRestaurantsPage = true

    this.utm = this.route.snapshot.queryParamMap.get('utm');
    if(this.utm) this.saveUtmInSessionStorage()
    this.toaster.clear();
    const uniqueId = this.route.snapshot.paramMap.get('id');
    if (uniqueId) {
      this.router.navigate(['/restaurant'], {queryParams: {name: uniqueId}});
    }
  }

  saveUtmInSessionStorage(){
    sessionStorage.setItem('utm', this.utm)
  }

  /*
  handleCityEmitted($event: string): void {
    this.selectedCity = $event;
  }
  */

  /*
  handleStreetEmitted($event: string): void {
    this.selectedStreet = $event;
  }
  */

  handleCategoryEmitted($event: string): void {
    this.selectedCategory = $event;
  }

}
