<div class="step0" *ngIf="__mapService.step==0">
    <app-address-list 
        class="step0__address_list"
        [userAddresses]="userAddresses"
    >
    </app-address-list>
</div>
<!--
<div 
    class="step1" 
    *ngIf="__mapService.step==1"
    [ngClass]="{'showingOnlySearchInput':appMapsRef.__mapsService.hideMapAndChoiceButton}"
    >
    <div 
        class="step1__map"
        [ngClass]="{'showingOnlySearchInput':appMapsRef.__mapsService.hideMapAndChoiceButton}"
        >
        <app-card-container
            class="step1__map__card"
            headerContent="Choisir une adresse de livraison"
            [ngClass]="{'showingOnlySearchInput':appMapsRef.__mapsService.hideMapAndChoiceButton}"
        >
            <div class="step1__map__card__container">
                <app-maps
                    #appMapsRef
                    class="step1__map__card__container__maps"
                    (onChoosePoint)="setGeolocatioInfos($event)"
                    [ngClass]="{'showingOnlySearchInput':appMapsRef.__mapsService.hideMapAndChoiceButton}"
                 >
                </app-maps>
             </div>
        </app-card-container>
    </div>
</div>
-->

<div 
    class="step1" 
    *ngIf="__mapService.step==1"
>
    <app-maps
        #appMapsRef
        class="step1__google-maps"
        (onChoosePoint)="setGeolocatioInfos($event)"
    >
    </app-maps>
</div>

<div class="step2" *ngIf="__mapService.step==2">
    <div class="step2__container">
        <app-address-details
            class="step2__container__address-details"
            (onAddAdress)="setAddressInfos($event)"
            [mainAddress]="address.mainAddress"
            (additionalAddressInfos)="address.additionalAddressInfos"
            >
        </app-address-details>
    </div>
</div>

<!--<app-about-zone></app-about-zone>-->
