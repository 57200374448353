import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, Observer} from "rxjs";
import { HttpClientWithTokenService } from "./http-client-with-token.service";
import { BasketService } from "./basket.service";
import { SharedDataService } from "./shared-data.service";
import { switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private __httpClientWithTokenService:HttpClientWithTokenService,
    private __basketService: BasketService,
    private __sharedDataService:SharedDataService
    ) {}

  private headers:HttpHeaders = new HttpHeaders({
    'Authorization' : `Bearer ${this.getToken()}`
  })

  getSwiperImages(): Observable<any> {
    return this.http.get<string[]>(`${environment.apiUrl}banners/read`);
  }

  getCategories(): Observable<any[]> {
    const currentUrl = window.location.href
    let url: string;
    if(currentUrl.includes('restaurants') || currentUrl.includes('specialnord')){
      url = `${environment.apiUrl}categories/read`;
    }else{
      url = `${environment.apiUrl}categories/readboutique`;
    } 
    return this.http.get<string[]>(url);
  }

  getCities(): Observable<any[]> {
    return this.http.get<string[]>(`${environment.apiUrl}locations/read`);
  }


  afterLocateUserAutomaticlllyGetResturants(category: string,page: number):Observable<any[]>
  {
    const oservableThatReturnEmptyTable:Observable<any[]> = new Observable((observer:Observer<any[]>)=>{
      observer.next([]);
    });
    return this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      switchMap(response => {
        if(!response) return oservableThatReturnEmptyTable;
        return this.getRestaurants(category,page);
      })
    )
  }

  afterLocateUserAutomaticlllyGetResturantsForSearchRestaurant(name: string):Observable<any[]>
  {
    const oservableThatReturnEmptyTable:Observable<any[]> = new Observable((observer:Observer<any[]>)=>{
      observer.next([]);
    });
    return this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      switchMap(response => {
        if(!response) return oservableThatReturnEmptyTable;
        return this.getRestaurantsForSearchRestaurant(name);
      })
    )
  }

  getRestaurantsForSearchRestaurant(name: string): Observable<any[]> {
    const url = `${environment.apiUrl}search/restaurants`;
    let params:HttpParams = new HttpParams()
    if(!this.__basketService.getLocation().isEmpty()){
      params = params.append("latitude",this.__basketService.getLocation().latitude+"")
                     .append("longitude",this.__basketService.getLocation().longitude+"")
                     .append("name", name);
    }else{
      params = params.append("name", name);
    }
    return this.http.get<object[]>(url,{params});
  }
  
  getRestaurants(category: string,page: number): Observable<any[]> {
    const currentUrl = window.location.href
    if(currentUrl.includes('restaurants')){
      var url = `${environment.apiUrl}restaurants/read`;
    }else if(currentUrl.includes('boutiques')){
      var url = `${environment.apiUrl}restaurants/Boutiqueread`;
    }else if(currentUrl.includes('patisseries')){
      var url = `${environment.apiUrl}restaurants/Patisserieread`
    }else{
      var url = `${environment.apiUrl}restaurants/Nordread`
    }
    let params:HttpParams = new HttpParams()
    .append("category",category)
    .append("page",page.toString())
    if(!this.__basketService.getLocation().isEmpty()){
      params = params.append("latitude",this.__basketService.getLocation().latitude+"")
                     .append("longitude",this.__basketService.getLocation().longitude+"");
    }
    return this.http.get<string[]>(url,{params});
  }

  
  getRestaurant(restaurantId: string, districtId: string): Observable<any> {
   /*
    let params:HttpParams = new HttpParams().append("restaurant_id",restaurantId)
    .append("promoCode",this.__basketService.basket.promoCodeInfos.promoCode);
    */
    
    let params:HttpParams = new HttpParams()
    .append("restaurant_id",restaurantId)
    .append("promoCode",null)
    if(!this.__basketService.getLocation().isEmpty()){
      params = params.append("latitude",this.__basketService.getLocation().latitude+"")
                     .append("longitude",this.__basketService.getLocation().longitude+"");
    }                         
    return this.http.get<any>(`${environment.apiUrl}Restaurant_Menu/read`,{params});
  }

  getRestaurantFranchises(restaurantId: string): Observable<any> {
    return this.http.get<any>(
        `https://delivrini.com/api/fee_franchise/read?restaurant_id=${restaurantId}`
    );
  }

  postNewsLetter(email: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}newsletter/create`, email);
  }

  getOrderStatus(orderId: string): Observable<any[]> {
    return this.__httpClientWithTokenService.get(`${environment.apiUrl}suivicommande/read?id_commande=${orderId}`,{});
  }

  cancelOrder(orderId){
    return this.__httpClientWithTokenService.get(`${environment.apiUrl}suivicommande/cancelorder?id_commande=${orderId}`,{});
  }

  getOrderStatusSummary(orderId: string): Observable<any[]> {
    return this.__httpClientWithTokenService.get(`${environment.apiUrl}suivicommande/summary?id_commande=${orderId}`,{});
  }

  postPartner(partner: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}dpartenaire/create`, partner);
  }

  postDelivrer(delivrer: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}dlivreur/create`, delivrer);
  }

  checkIfPick(restaurantId){
    return this.http.get(`${environment.apiUrl}checkout/checkIfPick?restaurant_id=${restaurantId}`);
  }

  postCheckout(checkout: any, type: string): Observable<any> {
    checkout["promoCode"] = this.__basketService.basket.promoCodeInfos.promoCode || null;
    if (type === "espece") {
      return this.__httpClientWithTokenService.post(`${environment.apiUrl}checkout/espece`, checkout);
    }
    return this.__httpClientWithTokenService.post(`${environment.apiUrl}checkout/cb`, checkout);
  }

  postCheckoutForLocalOrders(checkout: any): Observable<any> {
      return this.__httpClientWithTokenService.post(`${environment.apiUrl}checkout/SurPlace`, checkout);
  }

  getRestaurantStreets(restaurant_id: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}fee/read?restaurant_id=${restaurant_id}`
    );
  }



  postPaymentCb(body): Observable<any> {
    return this.http.post(`${environment.apiUrl}paiementcb/paiementrequest`, body, {
      headers: new HttpHeaders({
        Accept: "text/html",
        "Content-Type": "application/json",
      }),
      responseType: "text",
    });
  }
  

  checkOrderStatus(order_id: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}check_commande/read?id_commande=${order_id}`
    );
  }

  getGeolocationInfo(lat: number, lng: number,restaurantName:string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}getlocalisation/melocaliser?lat=${lat}&lng=${lng}&restaurantName=${restaurantName}`
    );
  }

  getEstimationDuration(lat: number, lng: number,restaurantName:string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}restaurants/getDureeEstimee?latitude=${lat}&longitude=${lng}&url_unique=${restaurantName}`
    );  
  }


  getGeolocationInfoRestaurant(
    lat: number,
    lng: number,
    restaurantId: string
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}getlocalisation/melocaliser_menu?lat=${lat}&lng=${lng}&restaurant_id=${restaurantId}`
    );
  }

  getToken(){
    return localStorage.getItem("user_token");
  }

  getNewRestaurantsForThisMonth():Observable<any>{
    return this.http.get<string[]>(`${environment.apiUrl}restaurants/New_restaurants`);
  }

  getCommandStatus(commandId){
    let url = `${environment.apiUrl}check_commande/cb?id_commande=${commandId}`;
    let params:HttpParams = new HttpParams().append("id_commande",commandId);
    return this.__httpClientWithTokenService.get(url,params);
  }

  getCrenaux(restaurantId, date){
    let params:HttpParams = new HttpParams()
    .append("id_caisse",restaurantId)
    .append("date",date)

    return this.http.get<any>(`${environment.apiUrl}getCrenaux`,{params});
  }

}
