import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: "app-order-item-dialog",
  templateUrl: "./order-item-dialog.component.html",
  styleUrls: ["./order-item-dialog.component.scss"],
})
export class OrderItemDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

}
