<div
    [hidden]="restaurants.length==0"
    class="new-restaurants">
    <div class="new-restaurants-header">
         <img src="../../../assets/images/promotions.png" alt="" class="new-restaurants-header-img">
         <div class="new-restaurants-header-text">
            <!-- {{title}} -->
            <ul>
                <li>P</li>
                <li>R</li>
                <li>O</li>
                <li>M</li>
                <li>O</li>
                <li>T</li>
                <li>I</li>
                <li>O</li>
                <li>N</li>
                <li>S</li>
            </ul>
        </div> 
    </div>
    <div class="new-restaurants-carousel">
        <button
            [ngClass]="{ 'hideMe':  restaurants.length<=2}"
            class="new-restaurants-carousel-left-btn"
            style="z-index: 99"
            mat-icon-button
            (click)="scrollLeft(carouselRef)"
        >
        <mat-icon class="carousel__items-swiper">keyboard_arrow_left</mat-icon>
       </button>
    
        <div class="new-restaurants-carousel-container" #carouselRef>
            <div 
            *ngFor="let restaurant of restaurants;index as i"
            [ngStyle]="{'margin-right': getMarginRight(i)}"
            class="new-restaurants-carousel-container-item">
                <app-restaurant
                [image]="restaurant.image"
                [city]="restaurant.city"
                [name]="restaurant.name | uppercase"
                [delivery]="restaurant.delivery"
                [takeAway]="restaurant.takeAway"
                [type]="restaurant.type"
                [categories]="restaurant.categories"
                [closed]="restaurant.ferme"
                [preOrder]="restaurant.pre_commande"
                
                class="restaurants__restaurant"
                (click)="redirectToRestaurantPage(restaurant.url_unique)"
                >
                </app-restaurant>
                <span class="new-restaurants-carousel-container-item-new-background">
                    
                </span>

                <span class="new-restaurants-carousel-container-item-new-text">
                    -{{ restaurant.promo }}%
                </span>
            </div>
        </div>

        <button
        class="new-restaurants-carousel-right-btn"
        style="z-index: 99"
        mat-icon-button
        (click)="scrollRight(carouselRef)"
        [ngClass]="{ 'hideMe':  restaurants.length<=2}"
    >
    <mat-icon class="carousel__items-swiper">keyboard_arrow_right</mat-icon>
  </button>
    </div>
</div>
