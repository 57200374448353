import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NewPasswordService {

  constructor(
    private __http:HttpClient,
  )
   { }

  updatePassword(uuiduser:string,password:string):Observable<any>{
    let body:Object = {uuiduser,password}; 
    return this.__http.post(`${environment.apiUrl}password/new_password`,body);
  } 
}
