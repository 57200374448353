<div 
class="magic-service"
>
<p class="magic-service__text">Premium Delivery</p>
<img 
  class="magic-service__icon"
  src="../../../assets/images/key.png" 
  alt=""
>
</div>
