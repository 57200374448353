<div class="confirm-user-choice-container">
    <h1>Êtes vous sûr de vouloir vous déconnecter ?</h1>
    <div mat-dialog-actions align="center">
    <button mat-button mat-dialog-close 
    class="cancel"
    >Annuler</button>
    <button mat-button mat-dialog-close 
    class="confirm"
    (click)="confirm()"
    >Oui</button>
    </div>
</div>