<link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<section class="successful-payment">

    <span class="spacebetween">
        <img style="width: 180px;" src="../../../assets/images/check.svg" alt="">
    </span>

    <div class="titre" style="text-transform:uppercase">
        Succès
    </div>
    
    <div class="text">
        Nous avons bien reçu votre paiement !
    </div>

    <!--
    <div class="redirect" (click)="redirect()">
        Continue
    </div>
    -->

</section>