import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {SigninService} from 'src/app/services/signin.service'
import {AuthService} from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import jwt_decode from 'jwt-decode';
declare var google:any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, AfterViewInit {

  __formGroup:FormGroup;
  isTheAppShowingPassword:boolean  = false;
  loading:boolean = false;

  signInWithGoogleWidth = 350

  constructor(
    private __formBuilder:FormBuilder,
    private __signinService:SigninService,
    private router:Router,
    private toastr:ToastrService,
    private __authService:AuthService,
  ) 
  { 

    if(window.innerWidth <= 425){
      this.signInWithGoogleWidth = parseInt(((window.innerWidth / 100) * 91).toFixed(0));
    }else{
      this.signInWithGoogleWidth = 400
    }
  }
  ngAfterViewInit(): void { 
    google.accounts.id.initialize({
      client_id: "686407160421-obkla4nr8hnqnij6k551k7f4llb162sv.apps.googleusercontent.com",
      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { type: "standard", theme: "outline", size: "large", shape: "circle", width: this.signInWithGoogleWidth}
      //{ theme: "outline",width: "350.043", shape:"square"}  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog 
  }

  ngOnInit(): void {
    this.toastr.clear()
    this.initializeOurFormGroup();
  }

  handleCredentialResponse(response: any){
    if(response.credential){
      var credential: any = jwt_decode(response.credential)
      var signInForm = {
        "email":"",
        "firstName":"",
        "lastName":""
      }
      signInForm.email = credential.email
      signInForm.firstName = credential.given_name
      signInForm.lastName = credential.family_name

      window.sessionStorage.setItem('signInDetails', JSON.stringify(signInForm))
      window.location.href = '/google-auth'
    }
    
  }

  


  initializeOurFormGroup(){
    this.__formGroup = this.__formBuilder.group(
      {
        username:["",
        [
        Validators.required,
        Validators.minLength(4),
        this.isFieldValidateMinLength(3)
        ]
        ],
        password:["",
        [
          Validators.required,
          Validators.minLength(6),
          this.isFieldValidateMinLength(6)
        ]
        ],
      }
    )
  }

  getErrorMessage(field:string){
    if(this.__formGroup.get(field).invalid) return "Ce champs est invalide";
    return "";
  }

  signinUser(){
    this.loading = true;
    let user = {username:this.__formGroup.get("username").value,password:this.__formGroup.get("password").value}
    this.__signinService.signinUser(user)
    .subscribe(
      response=>this.handleResponse(response),
      error=>this.handleError(error)
    )
  }

  handleResponse(response){
    this.loading=false;
    this.__authService.login(response);
  }

  handleError(error){
    this.loading=false;
    this.toastr.clear();
    if(error.status==404) this.showErrorMessage("Les informations sont érronnées","Erreur ! ");
    else this.showErrorMessage("Veuillez activer votre compte","Compte Désactivé");
  }

  redirectMeToSignupPage(){
    this.router.navigate(["s'inscrire"])
  }
  
  hideThePassword(fieldRef){
   fieldRef.type="password"; 
   this.isTheAppShowingPassword = false;
  }

  showThePassword(fieldRef){
    fieldRef.type="text";
    this.isTheAppShowingPassword = true;
  }

  showErrorMessage(msg,header){
    this.toastr.error(msg,header,{
      timeOut:10000,
    })
  }

  redirectMeToResetPasswordPage(){
    this.router.navigate(["reinitialiser-mot-de-passe"]);
  }

  isFieldValidateMinLength(minLength:number):ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  
         (control.value!=null && control.value.trim().length>=minLength) ? 
         null : {isFieldDoesNotValidateMinLength:true}
  }

}
