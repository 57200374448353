import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { 
    ActivatedRouteSnapshot, 
    Resolve, 
    Router, 
    RouterStateSnapshot 
  } from '@angular/router';
import { Observable, Observer, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BasketService } from './basket.service';
import { CheckoutService } from './checkout.service';
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { HttpService } from './http.service';
import { MapService } from './map.service';
import { SharedDataService } from './shared-data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressListService implements Resolve<Object[]>, OnDestroy{
  subjectToUnsubscribeFromObservables:Subject<any>;
  constructor(
    private __httpService:HttpService,
    private __basketService:BasketService,
    private __location:Location,
    private __httpClientWithToken:HttpClientWithTokenService,
    private __checkoutService:CheckoutService,
    private __router:Router,
    private __mapService:MapService,
    private __sharedDataService:SharedDataService,
    private __snackBar:MatSnackBar
  ) 
  {
    this.subjectToUnsubscribeFromObservables = new Subject();
   }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Object[] | Observable<Object[]> | Promise<Object[]> {
    return this.getUserAddresses();
  }

  getUserAddresses():Observable<any>{
    const params = {};
    const url = `${environment.apiUrl}address/getaddress`;
    return this.__httpClientWithToken.get(url,{});
  }

  setCityAndDistrictFromGeolocation(lat:number,long:number):Observable<any>{
    const restaurantName = this.__mapService.getRestaurantName(this.__sharedDataService.getPreviousUrl());
    return this.__httpService.getGeolocationInfo(lat,long,restaurantName)
  }

  addUserAddress(address):Observable<any>{
    const url = `${environment.apiUrl}address/addaddress`;
    const params = address;
    return this.__httpClientWithToken.post(url,params);
  }

  getSelectedAddress(addresses:any[]):Object{
    const selectedAddress = addresses.find(address => {
      const rst = this.isGivenAddressEqualsToBasketService(address);
      return rst;
    });
    return selectedAddress;
  }

  private isGivenAddressEqualsToBasketService(
    address:{lat:number,long:number,additionalAddressInfos:string,mainAddress:string}
    ):boolean{
    const basketAddress = this.__basketService.getLocation();
    return (
      address.lat == basketAddress.latitude && 
      address.long == basketAddress.longitude &&
      address.additionalAddressInfos == basketAddress.additionalAddressInfos && 
      address.mainAddress == basketAddress.mainAddress
    );
  }

  informUserThatWeDidNotFindAnyStreet() {
    let text = "Malheuresement, nous ne livrons pas dans votre quartier";
    const data = {
      imgSrc:"deliver-user.png",
      text,
      btn1Text:"Modifiez votre adresse",
      btn2Text:"Afficher tous les restaurants",
      btn1Fun:() => {
       
      },
      btn2Fun:() => {
       this.__basketService.setLocation(
         null,
         null,
         null,
         null,
         null,
         null,
         null
       );
       this.__router.navigate([""]);
      },
      subjectToUnsubscribeFromObservables:this.subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }

  deleteAddress(id,userAddresses:any[]):Observable<[]>{
    const url = `${environment.apiUrl}address/deleteAddress.php/${id}`;
    return this.__httpClientWithToken.delete(url)
    .pipe(
      map((response) => {
        const index  = userAddresses.findIndex(u => u.id == id);
        userAddresses.splice(index,1);
        this.onDeleteAddresseSucessefully();
        return userAddresses;
      }),
      catchError(error => {
        return new Observable((observer:Observer<any>)=>{
          observer.next(userAddresses);
          observer.complete();
        });
      })
    );
  }

  onDeleteAddresseSucessefully(){
    let config = new MatSnackBarConfig();
    config.duration = 1500;
    config.horizontalPosition = "center";
    config.verticalPosition = "top";
    this.__snackBar.open(
      "Votre adresse a bien été supprimé",
      "",
      config
    );    
  }

  updateAddress():Observable<any>{
    const url = `${environment.apiUrl}address/updateAddress.php/${this.__basketService.location.id}`;
    const additionnel = this.__basketService.location.additionalAddressInfos;
    const address = this.__basketService.location.mainAddress;
    const body = JSON.stringify({
      additionnel,
      address
    });
    return this.__httpClientWithToken.put(url,body);
  }

  ngOnDestroy(){

  }

}
