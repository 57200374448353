<div class="user-profile__container">
    <div class="user-profile__container-header">
       MES INFORMATIONS
    </div>
    <form
    [formGroup]="__formGroup"
    class="user-profile__container-form" 
    #formDirective="ngForm">
        <div class="user-profile__container-form-fullName">
                <mat-form-field class="user-profile__container-form-fullName-firstName">
                    <mat-label class="user-profile__container-form-fullName-firstName-margin">
                      Saisissez votre prenom</mat-label
                    >
                    <input
                      formControlName="prenom"
                      class="checkout-page__info-zone-text-field-margin"
                      type="text"
                      matInput
                    />
                    <mat-error *ngIf="__formGroup.get('prenom').invalid">
                        {{ getErrorMessage("prenom") }}
                      </mat-error>
                    <mat-icon matSuffix>person</mat-icon>
                  </mat-form-field>
    
                  <mat-form-field class="user-profile__container-form-fullName-lastName">
                    <mat-label class="user-profile__container-form-fullName-lastName-margin">
                      Saisissez votre nom</mat-label
                    >
                    <input
                      formControlName="nom"
                      class="checkout-page__info-zone-text-field-margin"
                      type="text"
                      matInput
                    />
                    <mat-error *ngIf="__formGroup.get('nom').invalid">
                        {{ getErrorMessage("nom") }}
                    </mat-error>
                    <mat-icon matSuffix>person</mat-icon>
                  </mat-form-field>
    
        </div>
    
        <div class="user-profile__container-form-second-element">
            <mat-form-field class="user-profile__container-form-second-element-email">
                <mat-label class="user-profile__container-form-second-element-email-margin">
                  Saisissez votre email</mat-label
                >
                <input
                  readonly
                  formControlName="email"
                  class="checkout-page__info-zone-text-field-margin"
                  type="text"
                  matInput
                />
                <mat-error *ngIf="__formGroup.get('email').invalid">
                    {{ getErrorMessage("email") }}
                </mat-error>
                <mat-icon matSuffix>alternate_email</mat-icon>
              </mat-form-field>
        
              <mat-form-field class="user-profile__container-form-second-element-phone">
                <mat-label class="user-profile__container-form-second-element-phone-margin">
                  Saisissez votre téléphone</mat-label
                >
                <input
                 readonly
                 formControlName="telephone"  
                  class="checkout-page__info-zone-text-field-margin"
                  type="text"
                  matInput
                />
                <mat-error *ngIf="__formGroup.get('telephone').invalid">
                    {{ getErrorMessage("telephone") }}
                </mat-error>
                <mat-icon matSuffix>phone</mat-icon>
              </mat-form-field>
        </div>
    
        <div class="user-profile__container-form-third-element">

            <mat-form-field class="user-profile__container-form-third-element-adress">
                <mat-label class="user-profile__container-form-third-element-adress-margin">
                    Saisissez votre adresse
                </mat-label>
                <textarea 
                #address
                formControlName="adresse"
                matInput >
                </textarea>
                <mat-error *ngIf="__formGroup.get('adresse').invalid">
                    {{ getErrorMessage("adresse") }}
                </mat-error>
              </mat-form-field>
        </div>

        <div class="user-profile__container-form-fourth-element">
            <button
            [disabled]="disableButtons"
            [@ListAnimation]
            (click)="ChangeUserChoice()"
            [hidden]="isHeWantsToModifyPassword" 
            class="user-profile__container-form-fourth-element-btn-modify-password"
            >
                Modifier mon  mot de passe 
                <mat-icon class="user-profile__container-form-fourth-element-btn-modify-password-icon">
                    https
                </mat-icon>
            </button>

            <button
            [disabled]="disableButtons"
            [@ListAnimation]
            (click)="ChangeUserChoice()" 
            [hidden]="!isHeWantsToModifyPassword" 
            class="user-profile__container-form-fourth-element-btn-do-not-modify-password"
            >
                Ne pas modifier mon  mot de passe 
                <mat-icon class="user-profile__container-form-fourth-element-btn-do-not-modify-password-icon">
                    clear
                </mat-icon>
            </button>

        </div>

        <div
        class="user-profile__container-form-fifth-element" 
        *ngIf="isHeWantsToModifyPassword"
        [@ListAnimation]
        >

            <mat-form-field class="user-profile__container-form-fifth-element-password">
                <mat-label class="user-profile__container-form-fifth-element-password-margin">
                  Saisissez votre mot de passe</mat-label
                >
                <input
                  #password
                  formControlName="password"
                  class="checkout-page__info-zone-text-field-margin"
                  type="password"
                  matInput
                />
                <mat-error *ngIf="__formGroup.get('password').invalid">
                    {{ getErrorMessage("password") }}
                </mat-error>
                <mat-icon
                (click)="showThePassword(password,'password')" 
                *ngIf="!isTheAppShowingPassword"
                style="cursor: pointer;" 
                 matSuffix>remove_red_eye
                </mat-icon>

                 <mat-icon
                 (click)="hideThePassword(password,'password')"
                  *ngIf="isTheAppShowingPassword"
                  style="cursor: pointer;"  
                  matSuffix>
                  visibility_off
                </mat-icon>
              </mat-form-field>

              <mat-form-field class="user-profile__container-form-fifth-element-confirmPassword">
                <mat-label class="user-profile__container-form-fifth-element-confirmPassword-margin">
                  Confirmez votre mot de passe</mat-label
                >
                <input
                 formControlName="confirmPassword"
                  #confirmPassword 
                  class="checkout-page__info-zone-text-field-margin"
                  type="password"
                  matInput
                />
                <mat-error *ngIf="__formGroup.get('confirmPassword').invalid">
                    {{ getErrorMessage("confirmPassword") }}
                </mat-error>
                <mat-icon
                 (click)="showThePassword(confirmPassword,'confirmPassword')" 
                 *ngIf="!isTheAppShowingConfirmPassword"
                 style="cursor: pointer;" 
                 matSuffix>
                remove_red_eye
                </mat-icon>
                 <mat-icon
                  (click)="hideThePassword(confirmPassword,'confirmPassword')"
                  *ngIf="isTheAppShowingConfirmPassword"
                  style="cursor: pointer;"  
                  matSuffix>
                  visibility_off
                </mat-icon>  
              </mat-form-field>
        </div>
    
        <div class="user-profile__container-form-buttons-container">
            <button
            [hidden]="disableButtons" 
            (click)="onSubmit()"
            [disabled]="!__formGroup.valid || !isPasswordEqualsToConfirmPassword()"
            class="user-profile__container-form-buttons-container-btn"
            >
                Modifier mes informations 
                <mat-icon>touch_app</mat-icon>
            </button>

            <button
            *ngIf="disableButtons" 
            style="position: relative;"
            class="user-profile__container-form-buttons-container-btn-loading"
            >
                Chargement
                <mat-spinner  mode="indeterminate" [diameter]="25"></mat-spinner>
            </button>
            
        </div>

    </form>

</div>


<div class="footer">
    <app-about-zone></app-about-zone>
</div>
