import { PopupCommandeLivreComponent } from './../popup-commande-livre/popup-commande-livre.component';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {BasketService} from "../../services/basket.service";
import {HttpService} from "../../services/http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {RestaurantService} from "../../services/restaurant.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import {BasketItemDialogComponent} from "../basket-page/basket-page-item/basket-item-dialog/basket-item-dialog.component";
import { ConfirmCancelOrderComponent } from "../confirm-cancel-order/confirm-cancel-order.component";
import {OrderService} from "src/app/services/order.service";
import { PopupPayementChoiceComponent } from "../popup-payement-choice/popup-payement-choice.component";
import { PopupLoadingComponent } from "../popup-loading/popup-loading.component";
import { CommandsService } from "src/app/services/commands.service";
import { PopupInformUserComponent } from "../popup-inform-user/popup-inform-user.component";
import { PopupModernLoaderComponent } from "../popup-modern-loader/popup-modern-loader.component";
import { CheckoutService } from "src/app/services/checkout.service";
import { MapInfos } from "./order-google-map/order-google-map.component";
import { PopupLivreComponent } from '../popup-livre/popup-livre.component';

@Component({
  selector: "app-order-details-page",
  templateUrl: "./order-details-page.component.html",
  styleUrls: ["./order-details-page.component.scss"],
})
export class OrderDetailsPageComponent implements OnInit, OnDestroy {
  orderStatus: any;
  isRated: any;
  ischat: any;
  driversInfos: any;
  private interval;
  phoneNumber: string;
  private commandeId;
  onPayementModButtonClickedObservable:Subscription = null;
  isLoadingCancelOrderResponse:boolean = false;
  newTotalPrice:number = 0;
  oldTotalPrice:number = 0;
  subtotal: number = 0;
  reduction: number = 0

  orderType;
  preOrderDate;
  preOrderCreneau;      

  private modernLoaderPopup:MatDialogRef<PopupModernLoaderComponent>;
  mapInfos:MapInfos = {
    restaurantCoordinates:{lat:33.5353502,lng:-7.6102811},
    deliveryCoordinates:{lat:33.5320412,lng:-7.6190787},
    userCoordinates:{lat:33.5281497,lng:-7.6384593}
  }
  isCommandDelivredByOurOwnDeliveryMan = false;
  driverInfos:{first_name:string,last_name:string,image:String,phone:string} = null;
  isCommandAffectedToDeliveryMan:boolean = false;

  constructor(
    public basketService: BasketService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    public restaurantService: RestaurantService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private __orderService:OrderService,
    private __commandsService:CommandsService,
    private __checkoutService:CheckoutService
  ) {}

  ngOnInit(): void {
    this.modernLoaderPopup = this.__checkoutService.openModernPopupLoader();
    this.commandeId = this.route.snapshot.paramMap.get("id");
    if (!this.commandeId) {
      this.router.navigate(["/"]);
    } 

    this.httpService
      .getOrderStatus(this.commandeId)
      .pipe(
        catchError((err) => {
          this.router.navigate(["/"]);
          return throwError(err);
        })
      )
      .subscribe((orderStatus: any) => {
        //this.shouldOpenSucccessPayementDialog();
        this.httpService.getCommandStatus(this.commandeId)
        .subscribe(data => {
          if(orderStatus.infos.state == -1) {
            if(!data) this.showPopupFailedPayement();
            else  this.orderStatus.infos.state = 0;
          }
        })
        if(orderStatus.service.moyen_paiement == "Carte bancaire") {
          this.shouldOpenSucccessPayementDialog(orderStatus.infos.state);
        }
        this.orderStatus = orderStatus;   
        this.orderType = orderStatus.service.type  
        this.preOrderDate = orderStatus.service.preOrderDate
        this.preOrderCreneau = orderStatus.service.creneau
        this.modernLoaderPopup.close();
        this.newTotalPrice = orderStatus.infos.prix_total + orderStatus.service.fees + orderStatus.service.frais_service;
        this.reduction =  this.total() - this.totalWithPromo()
        this.subtotal = orderStatus.infos.prix_total + this.reduction;
        this.handleReduction();
        this.restaurantService.addRestaurantNumber(orderStatus.infos.phone);
        this.phoneNumber = `tel:+212${orderStatus.infos.phone}`;
        this.getSummary()
        this.interval = setInterval(() => {
          this.getSummary()
        }, 5000);
      });
  }

  getSummary(){
    this.httpService
            .getOrderStatusSummary(this.commandeId)
            .subscribe((orderStatusRefresh: any) => {
              this.isRated = orderStatusRefresh.isRated
              this.ischat = orderStatusRefresh.ischat
              this.driversInfos = orderStatusRefresh.info_driver
              this.isCommandAffectedToDeliveryMan = orderStatusRefresh.ischat;              
              this.orderStatus.times = orderStatusRefresh.times;
              this.orderStatus.infos.status = orderStatusRefresh.status;
              this.orderStatus.infos.state = orderStatusRefresh.state;
              //Ajouter ici popup livre
              //if (this.orderStatus.infos.state == 1) this.showPopupOrderDelivered()
              if( this.orderStatus.infos.state==4 && orderStatusRefresh.location_driver){
                const obj = {
                  deliveryCoordinates:{lat: +orderStatusRefresh.location_driver.latitude, lng:+orderStatusRefresh.location_driver.longtiude},
                  userCoordinates:{lat: +orderStatusRefresh.location_client.latitude, lng:+orderStatusRefresh.location_client.longtiude},
                  restaurantCoordinates:{lat: +orderStatusRefresh.location_restaurant.latitude, lng:+orderStatusRefresh.location_restaurant.longtiude},
                  
                }
                this.isCommandDelivredByOurOwnDeliveryMan = true;
                //  Setting map coordinates to use them in map
                this.mapInfos = {...obj};  
                this.driverInfos = orderStatusRefresh.info_driver;
              }
              else this.isCommandDelivredByOurOwnDeliveryMan = false;
              if (
                this.orderStatus.infos.state === 3 ||
                this.orderStatus.infos.state === 2 
              ) {
                clearInterval(this.interval);
              }
              
              // notation
              if (this.orderStatus.infos.state === 2 && !this.isRated && this.ischat ){
                let dialogRef = this.dialog.open(PopupLivreComponent, {
                  maxWidth: '100vw',
                  maxHeight: '100vh',
                  height: '100%',
                  width: '100%',
                  panelClass: 'custom-dialog-container',
                  data: {
                    driverInfos: this.driversInfos,
                    infos: this.orderStatus.infos,
                    commandeId : this.commandeId
                  }
                });
              }
            });
  }

  showDialogDetails(item: any): void {
    item["isReadOnly"]=true    
    this.dialog.open(BasketItemDialogComponent, {
      height: window.innerWidth < 500 ? "85%" : "740px",
      width: window.innerWidth < 500 ? "85%" : "400px",
      autoFocus: false,
      data: {
        item,
      },
      panelClass:"popupNeedRaduis"
    });
  }

  openList(){
    this.router.navigate(["menu"]);
  }

  cancelOrder(){
    if(!this.isLoadingCancelOrderResponse){
      let dialogRef = this.openDialog(this.commandeId);
      dialogRef.componentInstance.onConfirmButtonClicked.asObservable()
      .subscribe(data => this.isLoadingCancelOrderResponse=true);

      dialogRef.componentInstance.onConfirm.asObservable()
      .subscribe(data => this.handleCancelOrderResponse(data));
    }
  }

  getDeliveryManPhoneLink(): string {
    return `tel:+212${this.driversInfos.phone}`;
  }

  showErrorToaster(msg){
    this.toastr.error(msg,"",{timeOut:10000})
  } 

  showSuccessToaster(msg){
    this.toastr.success(msg,"",{timeOut:10000})
  } 

  openDialog(orderId){
    return this.dialog.open(
      ConfirmCancelOrderComponent,
      {
        data:{orderId},
        panelClass:"popup",
      }
      )
  }

  handleCancelOrderResponse(data){
    this.isLoadingCancelOrderResponse = false;
    this.toastr.clear()
    if(data["annuler"]){
    this.showSuccessToaster(data["message"])
    this.httpService
    .getOrderStatusSummary(this.commandeId)
    .subscribe((orderStatusRefresh: any) => {
    this.orderStatus.times = orderStatusRefresh.times;
    this.orderStatus.infos.status = orderStatusRefresh.status;
    this.orderStatus.infos.state = orderStatusRefresh.state;
    if (
    this.orderStatus.infos.state === 3 ||
    this.orderStatus.infos.state === 2
    ) {
    clearInterval(this.interval);
    }
    });
    }
    else this.showErrorToaster(data["message"]);
  }


  shouldOpenSucccessPayementDialog(status){
    let response:boolean = this.__orderService.shouldShowTheUserASuccessPopup(this.commandeId,status);
    if(response){
      let data = {
        header:"Félicitations !",
        msg:"Votre paiement a été effectué avec succès",
        buttonClass:"green"
        }
      let dialogConfigs = { 
        panelClass: 'popup',
        data
       }
      this.dialog.open(PopupInformUserComponent,dialogConfigs);
    }
  }

  showPopupPayementMod(){
    if(this.orderStatus.infos.fermer) this.showUserPopupRestaurantIsClose();
    else{
    let dialogRef = this.dialog.open(PopupPayementChoiceComponent)
    this.handlePopupPayementChoiceRef(dialogRef);
    }
  }

  handlePopupPayementChoiceRef(dialogRef:MatDialogRef<PopupPayementChoiceComponent>){
    let popupConfigs = {
      width:"100%",
      minWidth:"100%",
      height:"100%",
      minHeight:"100%",
    }
    this.onPayementModButtonClickedObservable = dialogRef.componentInstance.onPayementModButtonClicked.asObservable()
    .subscribe(data => {
      let dialogRef = this.openAnyDialog(PopupLoadingComponent, popupConfigs);
      this.updatePayementMod(dialogRef,data);
    });
  }

  updatePayementMod(dialogRef:MatDialogRef<PopupLoadingComponent>,payementMod){
    this.__commandsService.updatePayementModForSprecificCommand(this.commandeId,payementMod)
    .subscribe(
      data => this.handleUpdatePayementModForSprecificCommandResponse(dialogRef,data)
    );
  }

  openAnyDialog(componenent,popupConfigs):MatDialogRef<any>{
    let dialogRef = this.dialog.open(componenent,popupConfigs);
    return dialogRef;
  }

  handleUpdatePayementModForSprecificCommandResponse(dialogRef:MatDialogRef<PopupLoadingComponent>,response:Object){
    dialogRef.close();
    if( "commande" in response){
      window.location.href = response["commande"];
    }
    else {
      this.orderStatus.times = response["times"];
      this.orderStatus.infos.status = response["infos"]["status"];
      this.orderStatus.infos.state = response["state"];
      this.orderStatus.service.moyen_paiement = response["service"]["moyen_paiement"];
      if (
        this.orderStatus.infos.state === 3 ||
        this.orderStatus.infos.state === 2
      ) {
        clearInterval(this.interval);
      }
    }
    
  }

  showPopupFailedPayement(){
    let data = {
      header:"Erreur !",
      msg:"Echec de paiement de votre commande, veuillez la payer",
      buttonClass:"danger"
    };
    let dialogConfigs = {
      data
    };
    this.openAnyDialog(PopupInformUserComponent,dialogConfigs);
  }

// Popup si la commande est livré travail
  showPopupOrderDelivered(){
    this.dialog.open(PopupCommandeLivreComponent, {
      panelClass: 'fullscreen-dialog',
      height: '100vh',
      width: '100%'
    })
  }

  showUserPopupRestaurantIsClose(){
    let data = {
      header:"Restaurant est fermé !",
      msg:"Vous ne pouvez pas payer votre commande maintenant",
      buttonClass:"warning"
      }
    let dialogConfigs = { 
      panelClass: 'popup',
      data
     }
    this.dialog.open(PopupInformUserComponent,dialogConfigs);
  }

  getTotalPriceBefroApplyingReduction():number{
    if(this.orderStatus["infos"]["reduction"] && this.orderStatus["infos"]["reduction"] != 0)
      return this.newTotalPrice + this.orderStatus["infos"]["reduction"];
  }

  handleReduction(){
    if
    ((this.orderStatus["infos"]["reduction"] && this.orderStatus["infos"]["reduction"] != 0)) 
    {      
      this.oldTotalPrice = this.newTotalPrice;
      this.newTotalPrice = this.getTotalPriceBefroApplyingReduction();
    }
  }

  callDeliveryMan(){
    window.location.replace("tel:"+this.driverInfos.phone);
  }

  /*
  total(): number {
    return this.orderStatus
      .map((item) => item.unitPrice * item.count)
      .reduce((acc, val) => (acc += val), 0);
  }
  */

  total(): number {
    return this.orderStatus.items
      .map((item) => item.unitPrice * item.count)
      .reduce((acc, val) => (acc += val), 0);
  }

  
  totalWithPromo(): number {
    return this.orderStatus.items
      .map((item) => {
        if(item.promoPrice == 0 ) return item.unitPrice * item.count
        else return item.promoPrice * item.count
      })
      .reduce((acc, val) => (acc += val), 0);
  }

  getArrivalTime(){
    let arrivalTime
    if(this.orderStatus.times.duree_estimee){
      const estimationDuration = this.orderStatus.times.duree_estimee
      const durations = estimationDuration.split('-')
      arrivalTime = durations[0].slice(0,5)
    }
    return arrivalTime
  }

  getLateArrivalTime(){
    let lateArrivalTime
    if(this.orderStatus.times.duree_estimee){
      const estimationDuration = this.orderStatus.times.duree_estimee
      const durations = estimationDuration.split('-')
      lateArrivalTime = durations[1].slice(1,6)
    }
    return lateArrivalTime
  }

  theOrderIsPreorder(){
    if(this.orderType == 'PREORDER-TAKEAWAY' || this.orderType == 'PREORDER-DELIVERY') return true
    else return false
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if(this.onPayementModButtonClickedObservable) this.onPayementModButtonClickedObservable.unsubscribe();
  }




}
