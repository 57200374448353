import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {ResetPasswordService} from 'src/app/services/reset-password.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  __formGroup:FormGroup;
  loading:boolean = false;
  @ViewChild("myForm",{static:true}) form:NgForm;

  constructor(
    private __resetPasswordService:ResetPasswordService,
    private __fb:FormBuilder,
    private toaster:ToastrService,
    private dialog:MatDialog,
    private __router:Router
  ) 
  { 

  }
  ngOnInit(): void {
      this;this.initializeFormGroup();
    }

  resetPassword(){
    this.loading = true;
    this.__resetPasswordService.resetPAssword(this.__formGroup.get("email").value)
    .subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      );
  }

  initializeFormGroup(){
    this.__formGroup = this.__fb.group({
      email:["",[Validators.required,Validators.email]]
    });
  }

  handleResponse(response){
    this.loading=false;
    this.toaster.clear();
    let msg = "Un email a bien été envoyé, veuillez vérifier votre boite email";
    let header = "Bravo !";
    this.showSuccessToaster(msg,header)
    .onHidden.subscribe(data=>this.__router.navigate(["se-connecter"]))
    this.clearForm();
  }

  handleError(response){
    this.loading=false;
    this.toaster.clear();
    let msg = "On a pas pu trouvé cette adresse email";
    let header = "Error !";
    this.showErrorToaster(msg,header);
  }

  showSuccessToaster(msg,header){
    return this.toaster.success(msg,header,{
      timeOut:4000
    });
  }

  showErrorToaster(msg,header){
    this.toaster.error(msg,header,{
      timeOut:6000
    });
  }

  clearForm(){
    this.form.resetForm();
    this.__formGroup.reset();
  }
}
