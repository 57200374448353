<div class="confirm-user-choice">
    <div class="confirm-user-choice__container">
        <div class="confirm-user-choice__container-level-1">
        <img 
            src="../../../../assets/images/{{data.imgSrc}}"
            class="confirm-user-choice__container-level-1-img"
        >
        <div class="confirm-user-choice__container-level-1-text">
            {{ data.text }}
         </div>
        </div>
        <div class="confirm-user-choice__container-level-2">
            <div class="confirm-user-choice__container-level-2-btn-container">
                <button 
                    (click)=" onBtn1GetClicked()"
                    class="confirm-user-choice__container-level-2-btn-container-btn1"
                >
                    {{ data.btn1Text }}
                </button>
                <button 
                    *ngIf="data.btn2Text"
                    (click)="onBtn2GetClicked()"
                    class="confirm-user-choice__container-level-2-btn-container-btn2"
                >
                {{ data.btn2Text }}
                </button>
            </div>
        </div>
    </div>
</div>