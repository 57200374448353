import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PromotionRestaurantsService {

  constructor(
    private __httpClient:HttpClient
  ) { }

  getAllRestaurantsThatAreDiscounting(category:string, city:any, street:any):Observable<any>{
    let params:HttpParams = new HttpParams().append("street",street).append("city",city).append("category",category);
    return this.__httpClient.get(`${environment.apiUrl}restaurants/Promotion_restaurants`,{params})
  }

  setPromoCodeResponseMsg(responseMsg){
    let msg;
    switch(responseMsg){
      case "inexistant":
        msg = "Code promo inexistant";
        break;
      case "utilisé":
        msg = "Code promo déja utilisé sur ce compte";
        break;
      case "validé":
        msg = "Félications, votre code promo a été appliqué avec succès";
        break;
      default:
        msg = "Code promo expiré"
    }
    return msg;
  }
}
