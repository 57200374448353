
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { BasketService } from './basket.service';
import { CheckoutService } from './checkout.service';
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { HttpService } from './http.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantPageService implements Resolve<any | Promise<any>>{
  
  estimationDuration: any;

  constructor(
    private __sharedDataService:SharedDataService,
    private __httpservice:HttpService,
    private __basketService:BasketService,
    private __router:Router,
    private __checkoutService:CheckoutService,
    private __httpClientWithTokenService:HttpClientWithTokenService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):any | Promise<any> {
    return this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .pipe(
      tap(data =>{
        if(this.__basketService.location.latitude && this.__basketService.location.longitude){
          this.getTheClosestStreetToUserLocation("/restaurant?name="+route.queryParams["name"],data,this.__basketService.location.latitude,this.__basketService.location.longitude,route)
        }
        else if(data && data.latitude && data.longitude){
          this.getTheClosestStreetToUserLocation("/restaurant?name="+route.queryParams["name"],data,null,null,route)
        }
      })
    )
    .subscribe();
  }

  getTheClosestStreetToUserLocation(url,data,latitude,longitude,route: ActivatedRouteSnapshot){
    //let restaurantName = this.__mapService.getRestaurantName(url);
    const restaurantName = route.queryParams["name"]    
    if(latitude && longitude){
      this.__httpservice.getEstimationDuration(latitude,longitude,restaurantName).subscribe(
        res => this.estimationDuration = res[0].duree
      )
      this.__httpservice.getGeolocationInfo(latitude,longitude,restaurantName)
    .pipe(
      tap(data => {
        this.handleGeolocationResponse(data);
      })
    )
    .subscribe({
      error:()=>{}
    });
    }
    else this.__httpservice.getGeolocationInfo(data.latitude,data.longitude,restaurantName)
    .pipe(
      tap(data => {
        this.handleGeolocationResponse(data);
      })
    )
    .subscribe();
  }
  
  handleGeolocationResponse(data){
    let street = data["street"];
    let city = data["ville"][0].name;
    this.__basketService.getLocation().city = city;
    this.__basketService.getLocation().district = street;
  }

  openPopupInformUserAboutWizardDeliveryWithoutRedirection(subjectToUnsubscribeFromObservables){
    let text = `Minimum de commande non atteint ? Pas de panique, notre option Wizard vous permet de vous faire livrer votre commande pour seulement ${this.__basketService.magicDeliveryAmount} MAD`;
    const data = {
      imgSrc:"delivery-man.png",
      text,
      btn1Text:"Oui, je valide l'option",
      btn2Text:"Non, je complète mon panier ",
      btn1Fun:() => {
        //this.redirectToCheckoutPage();
        this.__basketService.setService("magicDelivery");
        this.__basketService.setDeliveryPrice(this.__basketService.magicDeliveryAmount);
      },
      btn2Fun:() => {
        this.redirectToRestaurantPageOrBasketPage();
      },
      subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }

  openPopupInformUserAboutWizardDeliveryWithRedirection(subjectToUnsubscribeFromObservables){
    let text = `Minimum de commande non atteint ? Pas de panique, notre option Wizard vous permet de vous faire livrer votre commande pour seulement ${this.__basketService.magicDeliveryAmount} MAD`;
    const data = {
      imgSrc:"delivery-man.png",
      text,
      btn1Text:"Oui, je valide l'option",
      btn2Text:"Non, je complète mon panier ",
      btn1Fun:() => {
        this.redirectToCheckoutPage();
        this.__basketService.setService("magicDelivery");
        this.__basketService.setDeliveryPrice(this.__basketService.magicDeliveryAmount);
      },
      btn2Fun:() => {
        this.redirectToRestaurantPageOrBasketPage();
      },
      subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data);
  }

  redirectToCheckoutPage(){
    this.__router.navigate(["checkout"]);
  }

  redirectToRestaurantPageOrBasketPage(){
    const restaurantName = this.__basketService.basket.items[0].restaurant_name;
    if(window.innerWidth < 500) this.__router.navigate(["panier",restaurantName]);
    else this.__router.navigate(["restaurant"],{queryParams:{name:restaurantName}});
  }

}
