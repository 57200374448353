import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SigninService } from 'src/app/services/signin.service';
import { updateFor } from 'typescript';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-google',
  templateUrl: './auth-google.component.html',
  styleUrls: ['./auth-google.component.scss']
})
export class AuthGoogleComponent implements OnInit {

  formGroup: FormGroup;

  profile = {
    "email":"",
    "firstName":"",
    "lastName":""
  }

  loading = true;


  constructor( private router: Router, 
               private signinService:SigninService, 
               private authService:AuthService,
               private formBuilder:FormBuilder,
               private toastr:ToastrService,
               ) {
    this.profile = JSON.parse(window.sessionStorage.getItem('signInDetails'))
    this.checkIfUserExistByEmail()
  } 

  ngOnInit(): void {
    this.toastr.clear();
    this.initializeOurFormGroup();
  }

  initializeOurFormGroup(){
    this.formGroup =  this.formBuilder.group(
      {
        nom: [this.profile.lastName, [Validators.required, Validators.minLength(3)]],
        prenom: [this.profile.firstName, [Validators.required, Validators.minLength(3)]],
        telephone: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("0(6|7)[0-9]*$"),
          ],
        ],
        email: [
          this.profile.email,
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^([a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+)@([a-zA-Z0-9-]+)(\\.[a-zA-Z0-9-]+)+$"
            ),
          ],
        ]
      }
    )
  }

  getErrorMessage(field: string): string {
    if (this.formGroup.get(field).hasError("notSame")) {
      return "Vous devez saisir le même mot de passe";
    }

    if (!this.formGroup.get(field).hasError("required") ) {
      return "Ce champs est invalide";
    }

    return "";
  }

  showErrorToaster(msg,header){
    return this.toastr.error(msg,header,{
      positionClass: 'toast-top-left',
      timeOut:5000,
     })
 } 

 showSuccessToaster(msg,header){
   return this.toastr.success(msg,header,{
     timeOut:1500,
     positionClass: 'toast-top-left',
     closeButton:true
   })
 }

  checkIfUserExistByEmail(){
    let user = {email: this.profile.email}
    this.signinService.checkIfUserExist(user).subscribe(
        response=>this.handleResponse(response),
        error=>this.handleError(error)
    )
  }

  handleResponse(response){
    let msg = "Votre compte a été créé avec succès";
    this.showSuccessToaster(msg,"Félicitation !")
    setTimeout(()=>{
      this.authService.login(response)
      this.toastr.clear();
    }, 1000)
    
    
  }


  handleError(error){
    this.toastr.clear();
    if(error.status == 406){
      let msg = "Ce numéro de téléphone déjà utilisé pour la création d'un autre compte";
      this.showErrorToaster(msg,"Compte déjà existant")
    }else if(error.status == 409){
      //new account
      this.loading = false
    }
  }

  signup(){
    if(this.formGroup.invalid){

    }else{
      let user:{nom:string,prenom:string,telephone:string,email:string} = {
        nom:this.formGroup.get("nom").value.trim(),
        prenom:this.formGroup.get("prenom").value.trim(),
        telephone:this.formGroup.get("telephone").value,
        email:this.formGroup.get("email").value.trim()
      }
      this.signinService.signUp(user).subscribe(
          response=>this.handleResponse(response),
          error=>this.handleError(error)
      )
    }
    
  }

 

}

