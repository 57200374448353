<div class="coupon-code">
  <div class="counpon-code__container">
    <div class="input">
      <label for="coupon-code-input" class="input__label">
        Enter your coupon code:
      </label>
      <input
        [disabled]="active"
        id="coupon-code-input"
        type="text"
        class="input__feild"
        placeholder="Entrer le code promo"
        [(ngModel)]="couponCode"
      />
      <span
        (click)="applyCouponCode()"
        *ngIf="displayVerifyButton() && !active"
        class="input__verify-btn"
        >Appliquer</span
      >
      <img
        *ngIf="couponCodeService.loading"
        src="../../../assets/images/coupon-spinner.svg"
        alt=""
        class="input__loader"
      />
      <!-- <img src="" alt="" class="input__wrong" /> -->
    </div>
  </div>
</div>
