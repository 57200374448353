import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BasketService } from './basket.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedIn:boolean  = false;
  public subject:any = new BehaviorSubject(false);
  private headers:HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private __http:HttpClient,
    private __basketService:BasketService,
    private __router:Router,
  ) 
  { 
    this.subject.subscribe(data => {
      if(data == true){
        setTimeout(()=>{
          let token = this.decodeToken();
          if(token){
            this.getOrderNotRated().subscribe(result =>{
              if(result["commande"] != "No commande found")this.__router.navigateByUrl("/suivi-commande/"+result["commande"])
            })
          } 
        }, 1000)
      }
      this.isLoggedIn=data;      
    });
    this.isTokenValid()
    .then((data:{isTokenExist:boolean,isTokenValid:boolean})=>{
      this.subject.next(data.isTokenValid);
    });
  }

  logout(){
    this.subject.next(false);
    this.removeUserInfos();
  }

  login(response){    
    this.subject.next(true);
    this.setTokenAndUserInfos(response);
    this.redirectUserToTheRightPage();
  }

  isTheUserLoggedIn():boolean{
    return this.isLoggedIn;
  }

  getIsLoggedInSubject():Observable<boolean>{
    return this.subject.asObservable();
  }

  isTokenValid():Promise<{isTokenExist:boolean,isTokenValid:boolean}>{
    return new Promise(
      (resolve,rejected) => {
      let token = this.decodeToken();
      if(!token) resolve({isTokenExist:false,isTokenValid:false});
      this.headers = this.headers.set('Authorization',`Bearer ${this.getToken()}`)
      this.__http
      .get(`${environment.apiUrl}login/verify_token?uuiduser=${token["uuiduser"]}`,{headers:this.headers})
      .subscribe(
        (data:boolean) => {
          resolve({isTokenExist:true,isTokenValid:data["status"]});
        }
      )
      }
    )
  }

  getOrderNotRated(){ 
    let token = this.decodeToken();
    if (token){
      this.headers = this.headers.set('Authorization',`Bearer ${this.getToken()}`)
      return this.__http.get(`${environment.apiUrl}feedback/rating`,{headers:this.headers})
    }else{
      return
    }     
  }

  decodeToken():string{
    let userInfos = null;
    if(localStorage.getItem("user_infos")) userInfos = JSON.parse(localStorage.getItem("user_infos"));
    else return null;
    let token = userInfos["token"];
    if(!token) return null;
    return JSON.parse(atob(token.split('.')[1]));
  }

  getToken(){
    let userInfos =  JSON.parse(localStorage.getItem("user_infos"));
    return userInfos["token"];
  }

  getAddress(){
    let userInfos =  JSON.parse(localStorage.getItem("user_infos"));
    return userInfos["adresse"];
  }

  getUserId(){
    let userInfos =  JSON.parse(localStorage.getItem("user_infos"));
    return userInfos["iduser"];
  }

  removeUserInfos(){
    localStorage.removeItem("user_infos");
  }

  setTokenAndUserInfos({token}){
    localStorage.setItem("user_infos",JSON.stringify({token}));
  }

  redirectUserToTheRightPage(){
    let route = "";
    let queryParams = {}
    if(this.__basketService.count() > 0 ) {
      if(
        this.__basketService.basket.items &&
        !this.__basketService.isReachedMinimum()
        ) {
        route = "restaurant";
        queryParams = {name:this.__basketService.basket.items[0].restaurant_name}
      }
      else route="checkout";
    }
    else
    {
      const lastRestaurantVisited = sessionStorage.getItem("lastRestaurantVisited");
      if(lastRestaurantVisited){
        route = "restaurant";
        queryParams = {name:lastRestaurantVisited};
        sessionStorage.removeItem("lastRestaurantVisited");
      }
    }
    this.__router.navigate([route],{queryParams})
  }  

  setIsLoggedIn(isLoggedIn){
    this.isLoggedIn = isLoggedIn;
  }

}
