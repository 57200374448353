<div class="location-chooser__container">
  <button
    id="location-button"
    class="location-chooser__button"
    mat-raised-button
    color="primary"
    (click)="getLocation()"
    style="display: flex; align-items: center; justify-content: center"
  >
    <mat-icon style="margin-right: 5px" matSuffix>location_on</mat-icon>
    <span id="location-button__text"> Localisation</span>
  </button>

  <mat-form-field class="location-chooser__input-field" appearance="standard">
    <mat-icon matSuffix style="border-top: 3px transparent solid !important"
      >location_city</mat-icon
    >
    <mat-label style="">Ville</mat-label>
    <mat-select [(value)]="city" (selectionChange)="handleCityChange()">
      <mat-option *ngFor="let c of getCities()" [value]="c">{{
        c.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="location-chooser__input-field" appearance="standard">
    <mat-icon matSuffix style="">home</mat-icon>
    <mat-label>Quartier</mat-label>
    <mat-select [(value)]="district" (selectionChange)="handleDistrictChange()">
      <mat-option *ngFor="let d of getDistricts()" [value]="d">{{
        d
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
