import { Router } from '@angular/router';
import { ActivatedRoute, Route } from '@angular/router';
import { Component, Input, OnInit, Testability, ViewChild } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  searchFieldValue= ""; // Search a restaurant
  searchItemValue="" // search an element in a restaurant page
  restaurantName ="";
  city = "";
  district = "";
  currentUrl="";

  @ViewChild("placeholderRef", {static:true}) placeholderRef:HTMLInputElement;
  @ViewChild("imgRef", {static:true}) imgRef:HTMLInputElement;

  constructor( private route: ActivatedRoute,
               private router: Router,
               private sharedDataService: SharedDataService
    ) { }

  ngOnInit(): void {

    const test = document.getElementById("inputTag");
    setTimeout(() => {
        test.focus();
    }, 300);
    


    this.route.params.subscribe(params => {
      this.searchFieldValue = params.searchFieldValue
    })
    this.route.queryParamMap.subscribe(params => {
      this.restaurantName = params.get('name')
      this.searchItemValue = params.get('search')
      this.city = params.get('city')
      this.district = params.get('district')
    })
  }

  onFocus(){
    this.imgRef["nativeElement"].classList.remove("returnToDefaultStyle");
    this.imgRef["nativeElement"].classList.add("doAnimation");
    this.placeholderRef["nativeElement"].classList.add("hideThisElement");
  }

  
  onKeyUp(){
   if(this.searchFieldValue == ''){
   }
  }


  onBlur(){
    if(this.sharedDataService.isInHomePage && !this.searchItemValue) this.sharedDataService.showSearchFieldToSearchForARestaurant = false
    if(this.sharedDataService.isInARestaurantPage && !this.searchItemValue) this.sharedDataService.showSearchFieldToSearchInARestaurantPage = false

  }

  removeClassess(){
    this.imgRef["nativeElement"].classList.remove("doAnimation");
    this.placeholderRef["nativeElement"].classList.remove("hideThisElement");
  }
  search():void{
    if(!this.sharedDataService.isInARestaurantPage){
      if(this.searchFieldValue)
      this.router.navigateByUrl('/search/'+ this.searchFieldValue)
      else
      this.router.navigateByUrl('/restaurants')
    }else{
      if(!this.city && !this.district){
        this.router.navigate(['/restaurant'], {queryParams: { name : this.restaurantName, search: this.searchFieldValue }})
      }else{
        this.router.navigate(['/restaurant'], {queryParams: { name : this.restaurantName, city : this.city, district: this.district, search: this.searchFieldValue }})
      }
    }
  }
  

  

  





}
