<div class="refund-countainer">

    <div class="refund-countainer_text-top">
      Merci de nous indiquer la raison de votre insatisfaction.
    </div>

    <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="someComplete() || allComplete"
                        (change)="setAll($event.checked)">
            {{productQuality.reason}}
          </mat-checkbox>
        </span>
        <span class="example-list-section">
          <ul>
            <li *ngFor="let subtask of productQuality.subtasks">
              <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete()">
                {{subtask.name}}
              </mat-checkbox>
            </li>
          </ul>
        </span>
      </section>

      <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="missingProducts.completed"
                        (change)="setMissingProducts($event.checked)">
            {{missingProducts.reason}}
          </mat-checkbox>
        </span>
        <div class="refund-countainer_reason-comment">
            <textarea 
                *ngIf="missingProducts.completed"
                [(ngModel)]="missingProducts.comment" 
                rows="4" 
                maxlength="200"
                >
            </textarea>
        </div>
      </section>
    
      <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="differentProductsReceived.completed"
                        (change)="setDifferentProductsReceived($event.checked)">
            {{differentProductsReceived.reason}}
          </mat-checkbox>
        </span>
        <div class="refund-countainer_reason-comment">
            <textarea 
                *ngIf="differentProductsReceived.completed"
                [(ngModel)]="differentProductsReceived.comment" 
                rows="4" 
                maxlength="200"
            >
            </textarea>
        </div>
      </section>
    
      <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="otherProblem.completed"
                        (change)="setOtherProblem($event.checked)">
            {{otherProblem.reason}}
          </mat-checkbox>
        </span>
        <div class="refund-countainer_reason-comment">
            <textarea 
                #otherProblem
                *ngIf="otherProblem.completed"
                [(ngModel)]="otherProblem.comment" 
                rows="4" 
                maxlength="200"
                >
            </textarea>
        </div>
      </section>

      <div class="refund-countainer_text-bottom">
        Valider
      </div>
    
</div>



  