<div class="delivrer-page__container">
  <div class="delivrer-page__container-frame">

    <div class="delivrer-page__title">
      <span>Devenir livreur</span>
      <img class="delivrer-page__title-image"
           src="../../../assets/images/order_icon_1.png">
    </div>

    <div class="delivrer-page__zones" *ngIf="formGroup">
      <form [formGroup]="formGroup" class="delivrer-page__zones-left">

        <div class="delivrer-page__frame-info-left-last">
          <div class="delivrer-page__frame-info-image-zone">
            <img class="delivrer-page__frame-info-image-zone-image"
                 src="../../../assets/images/8.png">
          </div>
          <div class="delivrer-page__frame-info-text-zone">
            <div class="delivrer-page__frame-info-text-zone-title">Rémunération attractive</div>
            <div class="delivrer-page__frame-info-text-zone-description">
              Profitez d’un revenu rapide et élevé. Pleins d’avantages vous attendent !
            </div>
          </div>
        </div>

        <div class="delivrer-page__frame-info-mobile" style="margin-bottom: 40px; margin-top: 0">
          <div class="delivrer-page__frame-info-image-zone">
            <img class="delivrer-page__frame-info-image-zone-image"
                 src="../../../assets/images/7.png">
          </div>
          <div class="delivrer-page__frame-info-text-zone">
            <div class="delivrer-page__frame-info-text-zone-title">
              Horaire flexible
            </div>
            <div class="delivrer-page__frame-info-text-zone-description">
              Optez pour un travail indépendant, choisissez où et quand livrer !
            </div>
          </div>
        </div>

        <mat-form-field class="delivrer-page__text-field">
          <mat-label class="delivrer-page__text-field-margin"> Saisissez votre nom
          </mat-label>
          <input formControlName="nom" class="delivrer-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('nom').invalid">
            {{getErrorMessage('nom')}}
          </mat-error>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field class="delivrer-page__text-field">
          <mat-label class="delivrer-page__text-field-margin"> Saisissez votre prénom
          </mat-label>
          <input formControlName="prenom" class="delivrer-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('prenom').invalid">
            {{getErrorMessage('prenom')}}
          </mat-error>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field class="delivrer-page__text-field">
          <mat-label class="delivrer-page__text-field-margin"> Saisissez votre téléphone
          </mat-label>
          <input formControlName="telephone" class="delivrer-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('telephone').invalid">
            {{getErrorMessage('telephone')}}
          </mat-error>
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>

        <mat-form-field class="delivrer-page__text-field">
          <mat-label class="delivrer-page__text-field-margin"> Saisissez votre age
          </mat-label>
          <input formControlName="age" class="delivrer-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('age').invalid">
            {{getErrorMessage('age')}}
          </mat-error>
          <mat-icon matSuffix>psychology</mat-icon>
        </mat-form-field>

        <mat-form-field class="delivrer-page__text-field">
          <mat-label class="delivrer-page__text-field-margin"> Saisissez votre ville</mat-label>
          <input formControlName="ville" class="delivrer-page__text-field-margin" type="text" matInput>
          <mat-error *ngIf="formGroup.get('ville').invalid">
            {{getErrorMessage('ville')}}
          </mat-error>
          <mat-icon matSuffix>location_city</mat-icon>
        </mat-form-field>

        <mat-form-field class="delivrer-page__select-zone" appearance="standard">
          <mat-icon matSuffix style="">delivery_dining</mat-icon>
          <mat-label class="delivrer-page__select-zone-text">Avez-vous un scooter</mat-label>
          <mat-select formControlName="moto" class="delivrer-page__select-zone-select-value">
            <mat-option value="Oui">Oui</mat-option>
            <mat-option value="Oui">Non</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="delivrer-page__select-zone" appearance="standard">
          <mat-icon matSuffix>assignment_ind</mat-icon>
          <mat-label class="delivrer-page__select-zone-text">Avez-vous un permis de conduire
          </mat-label>
          <mat-select formControlName="permis" class="delivrer-page__select-zone-select-value">
            <mat-option value="Oui">Oui</mat-option>
            <mat-option value="Non">Non</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="delivrer-page__select-zone" appearance="standard">
          <mat-icon matSuffix style="">school</mat-icon>
          <mat-label class="delivrer-page__select-zone-text">Êtes-vous un étudiant</mat-label>
          <mat-select formControlName="etudiant" class="delivrer-page__select-zone-select-value">
            <mat-option value="Oui">Oui</mat-option>
            <mat-option value="Non">Non</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="delivrer-page__select-zone" appearance="standard">
          <mat-icon matSuffix style="">smartphone</mat-icon>
          <mat-label class="delivrer-page__select-zone-text">Avez- vous un smartphone</mat-label>
          <mat-select formControlName="mobile" class="delivrer-page__select-zone-select-value">
            <mat-option value="IPhone">IPhone</mat-option>
            <mat-option value="Android">Android</mat-option>
          </mat-select>
        </mat-form-field>

      </form>
      <div class="delivrer-page__zones-right">

        <div class="delivrer-page__frame-info-right" style="margin-bottom: 40px;">
          <div class="delivrer-page__frame-info-image-zone">
            <img class="delivrer-page__frame-info-image-zone-image"
                 src="../../../assets/images/7.png">
          </div>
          <div class="delivrer-page__frame-info-text-zone">
            <div class="delivrer-page__frame-info-text-zone-title">Horaire flexible</div>
            <div class="delivrer-page__frame-info-text-zone-description">
              Optez pour un travail indépendant, choisissez où et quand livrer !
            </div>
          </div>
        </div>

        <img style="width: 90%; margin-top: 120px" src="../../../assets/images/store_illustration_1.png">

      </div>
    </div>
    <button class="delivrer-page__button"
            mat-raised-button
            [disabled]="formGroup.invalid"
            (click)="validate()"
            color="primary">
      Rejoindre
    </button>
  </div>

</div>
<app-about-zone class="delivrer-page__about-zone"></app-about-zone>
