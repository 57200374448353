<div class="basket-page__padding-maker">
  <app-location-popup
    [restaurantStreets]="restaurantStreets.quartier"
    [city]="restaurantStreets.ville"
    [restaurantId]="restaurantId"
  ></app-location-popup>
  <div class="basket-page__container">
    <div class="basket-page__header">
      <div class="basket-page__header-container">
        <button
          mat-button
          (click)="back()"
          style="
            height: 50px;
            width: 20px;
            margin-left: -20px;
            border-radius: 8px;
          "
        >
          <mat-icon
            style="
              color: white;
              font-weight: bold;
              margin-top: -4px;
              margin-left: 8px;
            "
            >arrow_back_ios</mat-icon
          >
        </button>

        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            font-family: Lato;
          "
        >
          <div>Votre Panier</div>
        <!-- 
          <div
            *ngIf="
              basketService.count() && !basketService.getLocation().isEmpty()
            "
            style="
              margin-top: 10px;
              font-size: 15px;
              font-weight: normal;
              display: flex;
              align-items: center;
            "
          >
            <mat-icon
              style="font-weight: normal; font-size: 20px; margin-right: 2px"
              >location_on</mat-icon
            >
            <span
              >{{ basketService.getLocation().district }},
              {{ getCityName(basketService.getLocation().city) }}</span
            >
          </div>
          <div
            *ngIf="
              basketService.count() && !basketService.getLocation().isEmpty()
            "
            style="
              color: black;
              font-weight: normal;
              text-decoration: underline;
              cursor: pointer;
              font-size: 12px;
              margin-top: -5px;
            "
            (click)="openLocationDialog()"
          >
            Changer
          </div>
        -->
        </div>

        <div class="basket-page__header-container__count-tab">
          <span style="margin-top: 15px">{{ basketService.count() }}</span>
        </div>
      </div>
    </div>
    <div class="basket-page__content">
      <div class="basket-page__content-items">
        <app-basket-page-item
          *ngFor="let item of basketService.basket.items; let i = index"
          class="basket-page__content-item"
          [item]="item"
          [index]="i"
          (onUpdateItem)="switchService()"
        >
        </app-basket-page-item>
      </div>

      <div
        class="basket-page__content-details-price"
        *ngIf="basketService.count()"
      >
        <div class="basket-page__content-details-price-line">
          <div>Sous-total</div>
          <div class="basket-page__content-details-price-line-value">
            {{ basketService.totalWithoutPromo() | number : '1.2-2' }} Dhs
          </div>
        </div>
      
        <div 
          class="basket-page__content-details-price-line" 
          style="color: red;"
          *ngIf="(basketService.totalWithoutPromo() - basketService.total())>0"
          >
          <div>Réduction</div>
          <div class="basket-page__content-details-price-line-value">
            - {{ basketService.totalWithoutPromo() - basketService.total() | number : '1.2-2' }} Dhs
          </div>
        </div>
        <div 
            class="basket-page__content-details-price-line" 
            style="color: red;"
            *ngIf="this.basketService.getMontantFidelite()>0"
            >
            <div>Réduction</div>
            <div class="basket-page__content-details-price-line-value">
              - {{ this.basketService.getMontantFidelite() | number : '1.2-2' }} Dhs
            </div>
          </div>
        <div
          *ngIf="(isDelivery() || isMagicDelivery()) && getDeliveryPrice() !== undefined"
          class="basket-page__content-details-price-line"
        >
          <div>Frais de livraison</div>
          <div class="basket-page__content-details-price-line-value">
            {{ getDeliveryPrice() | number : '1.2-2'}} Dhs
          </div>
        </div>

        <div
        class="basket-page__content-details-price-line"
        >
          <div>Frais de service</div>
          <div class="basket-page__content-details-price-line-value">
            {{ basketService.basket.serviceCharge | number : '1.2-2'}} Dhs
        </div>
        </div>

        <div
        *ngIf="basketService.basket?.promoCodeInfos?.reduction!=0 && this.basketService.getMontantFidelite() == 0"
        class="basket-page__content-details-price-line"
        >
          <div>Réduction</div>
          <div class="basket-page__content-details-reduction-line-value">
            -{{ basketService.basket?.promoCodeInfos?.reduction | number : '1.2-2'}}%
        </div>
        </div>

        <hr class="basket-page__content-details-price-line-separator" />
        <div class="basket-page__content-details-price-line">
          <div>Total</div>
          <div 
          *ngIf="basketService.basket?.promoCodeInfos?.reduction>0"
          class="basket-page__content-details-price-line-old-value">
            {{ basketService.total() + getDeliveryPrice() + basketService.getServiceCharge() | number : '1.2-2' }} Dhs
          </div>
          <div class="basket-page__content-details-price-line-new-value">
            {{ getTotalPriceAfterapplyingReduction() | number : '1.2-2' }} Dhs
          </div>
        </div>
      </div>
      <div
        *ngIf="
          isDelivery() &&
          getDeliveryPrice() !== undefined &&
          !isReachedMinimum() &&
          basketService.count()
        "
        class="basket-page__content-details-price-not-minimum"
      >
        Le minimum de commande est {{ getMinimumPrice() }} Dhs
      </div>
      <app-magic-service
        *ngIf="
        isDelivery() &&
        getDeliveryPrice() !== undefined &&
        !isReachedMinimum() &&
        basketService.count() &&
        basketService.isMagicDelivryActiveInRestauarant
        " 
        class="basket-page__content-details-price-magic-service"
        (click)="openPopupInformUserAboutWizardDeliveryWithoutRedirection()"
      >
      </app-magic-service>
      <mat-button-toggle-group
        class="basket-page__content-toggle"
        #group="matButtonToggleGroup"
        [value]="basketService.getService()"
        (change)="onServiceChange(group.value)"
        *ngIf="basketService.count()"
      >
        <mat-button-toggle
        *ngIf="basketService.getService()=='magicDelivery'"
        [disabled]="!basketService.basket.hasDelivry"
        value="magicDelivery"
        class="basket-page__content-toggle-button-left"
        >
        Livraison
        </mat-button-toggle>

        <mat-button-toggle
          *ngIf="basketService.getService()!='magicDelivery'"
          [disabled]="!basketService.basket.hasDelivry"
          (click)="setService('Delivery')"
          value="Delivery"
          class="basket-page__content-toggle-button-left"
          >Livraison</mat-button-toggle
        >
        <mat-button-toggle
          [disabled]="!basketService.basket.hasTakeway"
          (click)="setService('Takeaway')"
          value="Takeaway"
          class="basket-page__content-toggle-button-right"
          >Emporter</mat-button-toggle
        >
      </mat-button-toggle-group>

      <img
        src="../../../assets/images/panier_vide.png"
        class="basket-page__content-image"
        *ngIf="!basketService.count()"
      />
      <div
        *ngIf="!basketService.count()"
        class="basket-page__content-image-text"
      >
        Votre panier est vide
      </div>

      <button
        mat-raised-button
        (click)="validate()"
        [disabled]="disableButton()"
        color="primary"
        class="basket-page__content-button"
      >
       Valider mon panier
      </button>
      <div
        *ngIf="basketService.count()"
        style="color: red; margin-top: 10px; font-weight: 500; cursor: pointer"
        (click)="basketService.resetBasket('')"
      >
        Vider le panier
      </div>
    </div>
  </div>
  <app-about-zone class="basket-page__about-zone"></app-about-zone>
</div>
