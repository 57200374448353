import { Injectable, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, pairwise, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService implements  OnDestroy{
  showSearchFieldToSearchForARestaurant:boolean = false;
  showSearchFieldToSearchInARestaurantPage:boolean = false;


  isInHomePage:boolean = false;
  isInARestaurantPage:boolean = false;
  isInSearchRestaurantsPage: boolean = false;
  isInRestaurantsPage: boolean = false
  private isUserJustCreateSession:boolean = true;
  private isLocateUserAutomaticllyWorkFinishedSubject:BehaviorSubject<{latitude:string,longitude:string}>;
  private isLocateUserAutomaticllyWorkFinishedAttribute:boolean = false;
  private subjectToUnsubscribeFromCustomObservables:Subject<any>;
  private previousUrl:string = null;
  private currentUrl:string = null;
  private nbrItemInBasket;
  private basket
  private itemsOfBasket


  constructor(
    private __router:Router,
  ) { 
    this.subjectToUnsubscribeFromCustomObservables = new Subject();
    this.isLocateUserAutomaticllyWorkFinishedSubject = new BehaviorSubject(null);
    this.handleUserNavigations();
  }

  getStockOfDeclinationWithTheBiggestStock(headingItem){
    var totalStockOfAllDeclinations = 0
    if(headingItem){
      headingItem.declinaisons.forEach(itemOfDeclination => {
        totalStockOfAllDeclinations = totalStockOfAllDeclinations + itemOfDeclination.quantite_stock
      });
    }
    return totalStockOfAllDeclinations
  }

  getStockOfDeclinationWithTheBiggestStockFromBasket(itemId){
    var totalStockOfAllDeclinations
    var declinaisons = []
    var basket = JSON.parse(localStorage.basket)
    var itemsOfBasket = basket.items
    itemsOfBasket.forEach(item => {
      totalStockOfAllDeclinations = 0
      if(item.id_produit == itemId){
      declinaisons = [item.declinations]
      declinaisons.forEach(declination => {
        if(declination){
          declination.items.forEach(itemOfDeclination => {
            totalStockOfAllDeclinations = totalStockOfAllDeclinations + itemOfDeclination.quantite_stock
          });
        }
      });
      }
    })
    return totalStockOfAllDeclinations
  }

  getTotalOfAnItemInTheBasket(itemId){
    this.nbrItemInBasket = 0
    this.basket = JSON.parse(localStorage.basket)
    this.itemsOfBasket = this.basket.items
    this.itemsOfBasket.forEach(item => {
      if (item.id_produit == itemId){
        this.nbrItemInBasket = this.nbrItemInBasket + item.count
      }
    });
    return this.nbrItemInBasket
  }

  getTotalOfAnItemInTheBasketByIdOfDeclination(itemId){
    this.nbrItemInBasket = 0
    this.basket = JSON.parse(localStorage.basket)
    this.itemsOfBasket = this.basket.items
    this.itemsOfBasket.forEach(item => {
      if (item.id_prix == itemId){
        this.nbrItemInBasket = this.nbrItemInBasket + item.count
      }
    });
    return this.nbrItemInBasket
  }

  setIsUserJustCreateSession(value){
    this.isUserJustCreateSession = value;
  }

  getIsUserJustCreateSession():boolean{
    return this.isUserJustCreateSession;
  }

  getIsLocateUserAutomaticllyWorkFinishedSubject():BehaviorSubject<{latitude:string,longitude:string}>{
    return this.isLocateUserAutomaticllyWorkFinishedSubject;
  }

  getIsLocateUserAutomaticllyWorkFinishedAttribute():boolean{
    return this.isLocateUserAutomaticllyWorkFinishedAttribute;
  }

  setIsLocateUserAutomaticllyWorkFinishedAttribute(value){
    this.isLocateUserAutomaticllyWorkFinishedAttribute = value;
  }

  getPreviousUrl():string{
    return this.previousUrl;
  }

  wasPreviousPageTheMap():boolean{
    return this.previousUrl == "/map"
  }

  handleUserNavigations(){
    this.__router.events
    .pipe(
      takeUntil(this.subjectToUnsubscribeFromCustomObservables),
      filter((evt: any) => evt instanceof RoutesRecognized), 
      pairwise()
    )
    .subscribe((events: RoutesRecognized[]) => {
      this.currentUrl = events[1].urlAfterRedirects;
      this.previousUrl = events[0].urlAfterRedirects;
    });
  }

  ngOnDestroy(){
    this.subjectToUnsubscribeFromCustomObservables.next("random value");
    this.subjectToUnsubscribeFromCustomObservables.complete();
  }

}
