import { Component, OnInit, Input } from "@angular/core";
import { CouponCodeService } from "../../services/coupon-code.service";

@Component({
  selector: "app-coupon-code",
  templateUrl: "./coupon-code.component.html",
  styleUrls: ["./coupon-code.component.scss"],
})
export class CouponCodeComponent implements OnInit {
  public couponCode: string;

  @Input()
  active: boolean;
  @Input()
  orderData: boolean;

  constructor(public couponCodeService: CouponCodeService) {}

  ngOnInit(): void {}

  displayVerifyButton(): boolean {
    return !!this.couponCode && this.couponCodeService.intial;
  }

  applyCouponCode(): void {
    this.couponCodeService.apply();
  }
}
