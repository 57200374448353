<div class="card">
    <div class="card-header">
        <img 
            src="../../../assets/images/left-arrow.png"
            class="card-header-icon"
            (click)="onIconBeClicked()"
            >
        {{headerContent}}
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>