import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {BasketService} from '../../../../services/basket.service';
import {BasketPageItemComponent} from "./../../basket-page-item/basket-page-item.component";

@Component({
  selector: "app-basket-item-dialog",
  templateUrl: "./basket-item-dialog.component.html",
  styleUrls: ["./basket-item-dialog.component.scss"],
})
export class BasketItemDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private basketService: BasketService,
    public dialogRef: MatDialogRef<BasketPageItemComponent>,

  ) {}

  @Input() remark: any;

  ngOnInit(): void {
    this.remark = this.data.item.remark;
  }

  navigateToRestaurant(): void {
    this.router.navigate(['/restaurant'], {queryParams: {name: this.data.item.url_unique}});
  }

  addRemark(): void {
    let currentProduct = this.data.item;
    let remarkContent = this.remark;
    this.basketService.addRemarkToProdcut(currentProduct, remarkContent);
    this.dialogRef.close();
  }

  onRemarkChange(value): void {
    this.remark = value;
  }

}
