<div class="new-password-container">
    <div class="new-password-container-header">
        Réinitialisation de votre mot de passe
    </div>
    <form 
    #myForm="ngForm"
    [formGroup]="__formGroup" 
    class="new-password-container-form">
        <mat-form-field class="new-password-container-form-password">
            <mat-label class="new-password-container-form-password-margin">
              Saisissez votre nouveau mot de passe</mat-label
            >
            <input
            #password
            formControlName="password" 
            class="new-password-container-form-password-field"
            type="password"
            matInput
            />
            <mat-error>
                {{ getErrorMessage("password") }}
            </mat-error>
            <mat-icon
            (click)="showThePassword(password,'password')" 
            *ngIf="!isTheAppShowingPassword"
            style="cursor: pointer;" 
             matSuffix>
             remove_red_eye
            </mat-icon>
             <mat-icon
             (click)="hideThePassword(password,'password')"
              *ngIf="isTheAppShowingPassword"
              style="cursor: pointer;"  
              matSuffix>
              visibility_off
            </mat-icon> 
        </mat-form-field>

        <mat-form-field class="new-password-container-form-confirm-password">
            <mat-label class="new-password-container-form-confirm-password-margin">
              Confirmez votre nouveau mot de passe</mat-label
            >
            <input
            #confirmPassword
            formControlName="confirmPassword"
            class="new-password-container-form-confirm-password-field"
            type="password"
            matInput
            />
            <mat-error>
                {{ getErrorMessage("confirmPassword") }}
            </mat-error>
            <mat-icon
            (click)="showThePassword(confirmPassword,'confirmPassword')" 
            *ngIf="!isTheAppShowingConfirmPassword"
            style="cursor: pointer;" 
             matSuffix>
             remove_red_eye
            </mat-icon>
            <mat-icon
             (click)="hideThePassword(confirmPassword,'confirmPassword')"
              *ngIf="isTheAppShowingConfirmPassword"
              style="cursor: pointer;"  
              matSuffix>
              visibility_off
            </mat-icon> 
        </mat-form-field>
    </form>

    <div class="new-password-container-buttons">
        <button
        [hidden]="loading"
        (click)="onSubmit()"
        [disabled]="__formGroup.invalid" 
        class="new-password-container-buttons-btn"
        >
        Réinitialiser
            <mat-icon>touch_app</mat-icon>
        </button>
        <app-loading [hidden]="!loading"></app-loading>
    </div>
</div>

<div class="footer">
    <app-about-zone></app-about-zone>
</div>