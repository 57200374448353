import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.scss'],
})
export class CardContainerComponent implements OnInit {
  @Input() headerContent='';
  constructor(
    private __mapservice:MapService
  ) { }

  ngOnInit(): void {
  }

  onIconBeClicked(){
   this.__mapservice.goToPreviousStep();
  }

}
