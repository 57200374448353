import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {HttpClientWithTokenService} from "src/app/services/http-client-with-token.service"
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankCardsService implements Resolve<any>{

  private headers:HttpHeaders = new HttpHeaders({
    'Authorization':`Bearer ${this.getToken()}`
  }) 

  constructor(
    //private __http:HttpClient,
    private __httpClientWithToken:HttpClientWithTokenService
  )
   { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise((resolve,reject)=>{
      this.getUserCards()
      .then(response => resolve(response));
    });
  }

  getUserCards():Promise<any>{
    let uuiduser = this.decodeToken()["uuiduser"];
    return new Promise((resolve,reject)=>{
      this.__httpClientWithToken.get(`${environment.apiUrl}cards/read.php/${uuiduser}`,{})
      .subscribe(data=>resolve(data));

    })
  }

  getToken():string{
    let userInfos = null;
    if(localStorage.getItem("user_infos")) userInfos = JSON.parse(localStorage.getItem("user_infos"));
    else return null;
    return userInfos["token"];
  }

  decodeToken(){
    let token = this.getToken();
    if(!token) return null;
    return JSON.parse(atob(token.split('.')[1]));
  }

  addCard(){
    let token = this.decodeToken();
    let uuid = null;
    if(token) uuid = token["uuiduser"];
    let params:HttpParams = new HttpParams();
    params = params.append("uuiduser",uuid);
    this.__httpClientWithToken.get(`${environment.apiUrl}cardStoring/callCardStoring`,params)
    .subscribe(data=>this.redirectToExternalUrl(data["url"]));
  }

  redirectToExternalUrl(url:string){
    window.location.href = url;
  }

  deactivateCard({token,label}):Observable<any>{
    let params:HttpParams = new HttpParams();
    params = params.append("tokencmi",token).append("label",label);
    let url = `${environment.apiUrl}cards/disablecard`;
    return this.__httpClientWithToken.get(url,params)
  }

  activateCard({token,label}):Observable<any>{
    let params:HttpParams = new HttpParams();
    params = params.append("tokencmi",token).append("label",label);
    let url = `${environment.apiUrl}cards/enablecard`;
    return this.__httpClientWithToken.get(url,params)
  }

  updateLabel({newLabel,oldLabel,tokencmi,owner,expire}):Observable<any>{
    let url =`${environment.apiUrl}cards/updatecard`;
    let params:HttpParams = new HttpParams();
    params = params.append( "newlabel",newLabel).append("oldlabel",oldLabel)
    .append("tokencmi",tokencmi).append("owner",owner)
    .append("expire",expire);
    return this.__httpClientWithToken.get(url,params);
  }




}
