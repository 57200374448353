import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm-user-choice',
  templateUrl: './confirm-user-choice.component.html',
  styleUrls: ['./confirm-user-choice.component.scss'],
})
export class ConfirmUserChoiceComponent implements OnInit {

  constructor(
    private router:Router,
    private __authService:AuthService
  ) { }

  ngOnInit(): void {
  }

  confirm(){
    this.__authService.logout();
    this.router.navigate([""]);
  }

}
