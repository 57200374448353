import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import {CheckAccountService} from 'src/app/services/check-account.service'
@Component({
  selector: 'app-check-account',
  templateUrl: './check-account.component.html',
  styleUrls: ['./check-account.component.scss']
})
export class CheckAccountComponent implements OnInit {

  activatedAccount:boolean =  false;
  stopLoading:boolean =  false;
  constructor(
    private __checkAccountService : CheckAccountService,
    private __activatedRoute:ActivatedRoute,
    private __toastr:ToastrService,
    private router:Router
  ) 
  { }

  ngOnInit(): void {
    this.__toastr.clear();
    let obj  = this.getParams();
    this.__checkAccountService.verifyCode(obj.user,obj.code)
    .subscribe(
      response => this.handleResponse(response),
      error => this.handleError(error)
    )
  }

  getParams():{user:string,code:string}{
    let user = this.__activatedRoute.snapshot.queryParamMap.get("user")
    let code = this.__activatedRoute.snapshot.queryParamMap.get("code")
    return {user,code}
  }

  handleResponse(response){
    this.__toastr.clear();
    this.activatedAccount = true;
    this.stopLoading = true;
    this.showSuccessMessage("Bravo !","Votre compte a bien été activé")
    .onHidden.subscribe(
      response => this.redirectMeToSignin()
    )
  }

  handleError(error){
    this.__toastr.clear();
    this.stopLoading = true;
    if(error.status==406) {
      this.showErrorMessage("Erreur !","Votre compte est deja activé")
      .onHidden.subscribe(
        response => this.redirectMeToSignin()
      )
    }
    else this.showErrorMessage("Erreur !","Les informations sont érronées")
        .onHidden.subscribe(
          response => this.redirectMeToSignin()
        )
  }

  showErrorMessage(header,msg){
    return this.__toastr.error(msg,header,{
      timeOut:10000
    })
  }

  showSuccessMessage(header,msg){
    return this.__toastr.success(msg,header,{
      timeOut:6000
    })
  }

  redirectMeToSignin(){
    this.router.navigate(["se-connecter"]);
  }

}
