import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ModifyBankCardService implements Resolve<any>{

  constructor(
    private __httpClientWithTokenService:HttpClientWithTokenService
  ) { }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let tokencmi = route.queryParamMap.get("tokencmi")
    let label = route.queryParamMap.get("label")
    return new Promise((resolve,reject)=>{
      this.getBankCardDetails(tokencmi,label).then(data=>resolve(data));
    });
  }

  getBankCardDetails(token,label):Promise<any>{
    return new Promise((resolve,reject)=>{
      let url =`${environment.apiUrl}cards/info_cards`;
      let params = new HttpParams().append("tokencmi",token).append("label",label);
      this.__httpClientWithTokenService.get(url,params)
      .subscribe(data=>resolve(data));
    })
  }
}
