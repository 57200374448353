<section 
    class="countainer" 
    *ngIf="!badRatingForTheRestaurant"
    [ngStyle]="{'height': screenHeightInPixels}"
>
    <div class="rating-container">
        <div class="rating-text">
                Evaluez votre expérience
                <span style="color:#870D54">Lalivraison.ma</span>
        </div>

        <div class="rating-restaurant">
            <div class="first-line">
                <div class="bad"
                    (click)="badRestaurant()"
                >
                    <img *ngIf="!restaurantbadClicked" src="../../../assets/images/Bad.png" alt="">
                    <img *ngIf="restaurantbadClicked" src="../../../assets/images/bad-clicked.png" alt="">
                </div>
                <div class="restaurant-img">
                    <img [src]="data.infos.logo" alt="">
                </div>
                <div class="good"
                    (click)="goodRestaurant()"
                >
                    <img *ngIf="!restaurantGoodClicked" src="../../../assets/images/Good.png" alt="">
                    <img *ngIf="restaurantGoodClicked" src="../../../assets/images/good-clicked.png" alt="">
                </div>
            </div>
            <!--
            <div class="second-line">
                <div class="restaurant-name"><h2>{{ data.infos.url_unique }}</h2></div> 
            </div>
            -->
        </div>

        <div class="rating-delivery">
            <div class="first-line">
                <div class="bad"
                     (click)="badDeliveryMan()"
                >
                    <img *ngIf="!deliveryBadClicked" src="../../../assets/images/Bad.png" alt="">
                    <img *ngIf="deliveryBadClicked" src="../../../assets/images/bad-clicked.png" alt="">
                </div>
                <div class="delivery-img">
                    <img 
                        src="https://lalivraison.ma/livreur.png"
                         alt=""
                    >
                    <!-- [src]="data.driverInfos.image"  -->
                </div>
                
                <div class="good"
                     (click)="goodDeliveryMan()"
                >
                    <img *ngIf="!deliveryGoodClicked" src="../../../assets/images/Good.png" alt="">
                    <img *ngIf="deliveryGoodClicked" src="../../../assets/images/good-clicked.png" alt="">
                </div>
            </div>
            <div class="second-line">
                <div class="delivery-name">
                    <h2>{{ data.driverInfos.first_name }} {{ data.driverInfos.last_name }} </h2>
                </div>
            </div>
        </div>
        
        <button  
            mat-button 
            [disabled]="!body.deliveryMan || !body.restaurant"
            class="rating-submit" 
            (click)='raitingSubmit()'>VALIDER
        </button>
    </div>
</section>

<!-- Refund -->

<div class="refund-countainer" *ngIf="badRatingForTheRestaurant" [ngStyle]="{'min-height': screenHeightInPixels}">

    <img src="../../../assets/images/logo-header.png" class="refund-countainer_logo" alt="">

    <div class="refund-countainer_text-top">
      Merci de nous indiquer la raison de votre insatisfaction.
    </div>

    <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="someComplete() || allComplete"
                        (change)="setAll($event.checked)">
            {{productQuality.reason}}
          </mat-checkbox>
        </span>
        <span class="example-list-section">
          <ul>
            <li *ngFor="let subtask of productQuality.subtasks">
              <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete()">
                {{subtask.name}}
              </mat-checkbox>
            </li>
          </ul>
        </span>
      </section>

      <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="missingProducts.completed"
                        (change)="setMissingProducts($event.checked)">
            {{missingProducts.reason}}
          </mat-checkbox>
        </span>
        <div class="refund-countainer_reason-comment">
            <textarea 
                *ngIf="missingProducts.completed"
                [(ngModel)]="missingProducts.comment" 
                rows="4" 
                maxlength="200"
                >
            </textarea>
        </div>
      </section>
    
      <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="differentProductsReceived.completed"
                        (change)="setDifferentProductsReceived($event.checked)">
            {{differentProductsReceived.reason}}
          </mat-checkbox>
        </span>
        <div class="refund-countainer_reason-comment">
            <textarea 
                *ngIf="differentProductsReceived.completed"
                [(ngModel)]="differentProductsReceived.comment" 
                rows="4" 
                maxlength="200"
            >
            </textarea>
        </div>
      </section>
    
      <section class="refund-countainer_reason">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="otherProblem.completed"
                        (change)="setOtherProblem($event.checked)">
            {{otherProblem.reason}}
          </mat-checkbox>
        </span>
        <div class="refund-countainer_reason-comment">
            <textarea 
                *ngIf="otherProblem.completed"
                [(ngModel)]="otherProblem.comment" 
                rows="4" 
                maxlength="200"
                >
            </textarea>
        </div>
      </section>

      <div class="refund-countainer_text-bottom" (click)="feedbackSubmit()">
        <span *ngIf="!spinnerStatus">Valider</span>
        <mat-spinner *ngIf="spinnerStatus" [diameter]="23"></mat-spinner>
      </div>
    
</div>



  