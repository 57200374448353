import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BasketService } from './basket.service';
import { CheckoutService } from './checkout.service';
import { HttpService } from './http.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn:'root'
})

export class MapService {
  step:number = 0;
  subjectToInformHeaderWithResultOgGettingCurrentUserLocation:Subject<any>;
  subjectToUnsubscribeFromObservables:Subject<any>;
  private theUrlOfPagesThatWeHaveToGetGeolocationInfosOndependIntoRestauarntId = [];

  constructor(
    private __location:Location,
    private __basketService:BasketService,
    private __httpService:HttpService,
    private __sharedDataService:SharedDataService,
    private __router:Router,
    private __activatedRoute:ActivatedRoute,
    private __checkoutService:CheckoutService
  ) 
  { 
    this.subjectToInformHeaderWithResultOgGettingCurrentUserLocation = new Subject();
    this.theUrlOfPagesThatWeHaveToGetGeolocationInfosOndependIntoRestauarntId  = [
      "restaurant",
      "checkout",
      "panier",
    ];
    this.subjectToUnsubscribeFromObservables = new Subject();
  }
  

  goToPreviousStep(){
    if(this.step==0) this.__location.back();
    else this.step--;
  }

  goToNextStep(){
    this.step++;
  }


  locateUser(){
    this.getUserLocation();
  }

  getUserLocation(){
    const navigatorLocationOptions = {
      enableHighAccuracy: true,
      timeout: 7000,
      maximumAge: 70000
    };
    
    const timeoutIndentifier = setTimeout(() => {
      if(this.__basketService.getLocation().isEmpty()) this.handleNavigatorGeolocationFailedResponse(null);
    },7000);

    if(navigator && navigator.geolocation){
      navigator.geolocation.getCurrentPosition(
        position => {
          clearTimeout(timeoutIndentifier);
          this.handleNavigatorGeolocationSucessefullResponse(position);
        },
        error => {
          clearTimeout(timeoutIndentifier);
          this.handleNavigatorGeolocationFailedResponse(error);
        },
        navigatorLocationOptions
      );
    }
    else {
      clearTimeout(timeoutIndentifier);
      this.handleNavigatorGeolocationFailedResponse(null);
    }
  }

  handleNavigatorGeolocationSucessefullResponse(position){
    let restaurantName:string = null;
    const index = this.theUrlOfPagesThatWeHaveToGetGeolocationInfosOndependIntoRestauarntId
    .findIndex(pageUrl => this.__router.url.includes(pageUrl) || this.__location.isCurrentPathEqualTo("/checkout"));
    if(index>=0){
      restaurantName = this.getRestaurantName(this.__router.url);
    }
    this.__httpService.getGeolocationInfo(position.coords.latitude,position.coords.longitude,restaurantName)
    .subscribe(
      data => this.handleGeolocationInfoResponse(data,position),
      error => {
        this.informUserThatWeDoNotDeliverInHisLocation();
        this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
        .next({latitude:null,longitude:null});
        let msg =  null;
        this.subjectToInformHeaderWithResultOgGettingCurrentUserLocation.next(msg);
      }
      );
  }

  getRestaurantName(pageUrl:string):string{
    let restaurantName:string = null;
    if(!pageUrl)  return restaurantName;
    if(pageUrl.includes("panier")){
      const array = pageUrl.split("/");
      restaurantName = array[array.length-1];
    }
    else if(pageUrl.includes("restaurant") && !pageUrl.includes("restaurants")){
      restaurantName = this.__activatedRoute.snapshot.queryParams["name"];
      if(!restaurantName){
        if(this.__sharedDataService.getPreviousUrl()) restaurantName = this.__sharedDataService.getPreviousUrl().split("name=")[1].split("&")[0];
        if(!restaurantName && pageUrl.includes("name")){
          restaurantName = pageUrl.split("name=")[1];
        }
      }
    }
    else if(pageUrl.includes("checkout") || this.__location.isCurrentPathEqualTo("/checkout")){
      if(this.__basketService.basket.items.length>0){
        restaurantName = this.__basketService.basket.items[0].restaurant_name;
      }
    }
    return restaurantName;
  }

  handleGeolocationInfoResponse(data,position){
    let street = data["street"];
    let city = data["ville"][0].name;
    this.__basketService.setLocation(city,street,position.coords.latitude,position.coords.longitude,null,null,null);
    this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .next({latitude:position.coords.latitude,longitude:position.coords.longitude});
    this.subjectToInformHeaderWithResultOgGettingCurrentUserLocation.next(null);
  }

  handleNavigatorGeolocationFailedResponse(error){
    this.__sharedDataService.getIsLocateUserAutomaticllyWorkFinishedSubject()
    .next({latitude:null,longitude:null});
    let msg =  this.getGeolocationErrorMessage(error);
    this.subjectToInformHeaderWithResultOgGettingCurrentUserLocation.next(msg);
  }

  informUserThatWeDoNotDeliverInHisLocation(){
    let text = "Malheuresement, nous ne livrons pas dans votre quartier";
    const data = {
      imgSrc:"deliver-user.png",
      text,
      btn1Text:"Modifiez votre adresse",
      btn2Text:"",
      btn1Fun:() => {
        this.__basketService.setLocation(
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        this.__router.navigate(["map"]);
      },
      btn2Fun:() => {
       
      },
      subjectToUnsubscribeFromObservables:this.subjectToUnsubscribeFromObservables
    }
    this.__checkoutService.openPopupConfirmUserChoice(data); 
  }

  getGeolocationErrorMessage(error):string{
    enum msgsList{
      PERMISSION_DENIED = "Nous n’arrivons pas récupérer votre emplacement. Merci d’autoriser l’accès à votre localisation ou d’ajouter une adresse de livraison.",
      POSITION_UNAVAILABLE = "Nous n’arrivons pas récupérer votre emplacement. Merci d’autoriser l’accès à votre localisation ou d’ajouter une adresse de livraison.",
      TIMEOUT = "Nous n’arrivons pas récupérer votre emplacement. Merci d’autoriser l’accès à votre localisation ou d’ajouter une adresse de livraison.",
      UNKNOWN_ERROR = "Nous n’arrivons pas récupérer votre emplacement. Merci d’autoriser l’accès à votre localisation ou d’ajouter une adresse de livraison."
    }
    const defaultMessageError = "Pour une meilleure expérience, nous vous recommandons d’activer votre localisation ou d’ajouter une adresse de livraison.";
    let msg  = defaultMessageError;
    /*switch(error.code) {
      case error.PERMISSION_DENIED:
        msg = msgsList.PERMISSION_DENIED
        break;
      case error.POSITION_UNAVAILABLE:
        msg = msgsList.POSITION_UNAVAILABLE
        break;
      case error.TIMEOUT:
        msg = msgsList.TIMEOUT
        break;
      case error.UNKNOWN_ERROR:
        msg = msgsList.UNKNOWN_ERROR
        break;
    }
    */
    return msg;
  }

  initializeStep(){
    this.step = 0;
  }

}
