import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-popup-confirm-saving-cards',
  templateUrl: './popup-confirm-saving-cards.component.html',
  styleUrls: ['./popup-confirm-saving-cards.component.scss']
})
export class PopupConfirmSavingCardsComponent implements OnInit {
  errorMessage:string = "";
  confirmSavingCards = new Subject();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.errorMessage =  this.data.errorMessage;
  } 

  confirm(){
    this.confirmSavingCards.next("");
  }

}
