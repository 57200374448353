<div class="address-details">
    <app-card-container 
        class="address-details__card"
        headerContent='Details adresse'
        >
        <div class="address-details__container">
            <!--<div class="address-details__container-header">
                Address details
            </div>-->
            <form 
                [formGroup]="__formGroup" 
                class="address-details__container__form"
                (ngSubmit)="onFormSubmited()"
                >
                <mat-form-field class="address-details__container__form-matFormField">
                    <mat-label
                    class="address-details__container__form-matFormField-label"
                    >
                    Adresse (*requis)
                    </mat-label>
                    <input 
                        formControlName="address"
                        matInput 
                        value="" 
                        class="address-details__container__form-matFormField-input"
                        >
                    <mat-error *ngIf="__formGroup.invalid">
                        <span>{{getErrorMessage('address')}}</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="address-details__container__form-matFormField">
                    <mat-label
                    class="address-details__container__form-matFormField-label"
                    >
                    Information complémentaire (Résidence, Etage)
                </mat-label>
                    <input 
                        formControlName="additionalInformation"
                        matInput 
                        value="" 
                        class="address-details__container__form-matFormField-input"
                        >
                </mat-form-field>
                <button 
                    type="submit"
                    class="address-details__container__form-button"
                    >Valider</button>
            </form>
        </div>
    </app-card-container>
</div>
