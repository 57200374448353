<div class="menu-container" >
  <div 
  [@ListAnimation]
  class="menu-container-content">

  <div 
  (click)="redirectMeToHomePage()"
  class="menu-container-content-item">
   Accueil
    <mat-icon class="menu-container-content-item-icon" matSuffix>home</mat-icon>
  </div>

   <div 
   *ngIf="!isLoggedIn" 
   class="menu-container-content-item"
   (click)="redirectMeToSignin()"
   >
      Se connecter 
      <mat-icon class="menu-container-content-item-icon" matSuffix>perm_identity</mat-icon>
   </div>

    <div
    *ngIf="!isLoggedIn" 
    class="menu-container-content-item"
    (click)="redirectMeToSignup()"
    >
      S'inscrire 
      <mat-icon class="menu-container-content-item-icon" matSuffix>create</mat-icon>
    </div>

    <div 
    *ngIf="isLoggedIn" 
    (click)="redirectMeToUserProfile()"
    class="menu-container-content-item">
      Modifier mon profil
      <mat-icon class="menu-container-content-item-icon" matSuffix>person</mat-icon>
    </div>
    

    <div 
    *ngIf="isLoggedIn" 
    class="menu-container-content-item"
    (click)="redirectMeToMyCommands()">
      Mes commandes
      <mat-icon class="menu-container-content-item-icon" matSuffix>style</mat-icon>
    </div>

    <div 
    *ngIf="isLoggedIn" 
    class="menu-container-content-item"
    (click)="redirectMeToBankCArd()">
      Mes cartes bancaires 
      <mat-icon class="menu-container-content-item-icon" matSuffix>payment</mat-icon>
    </div>

    <div 
    *ngIf="isLoggedIn" 
    class="menu-container-content-item"
    (click)="logout()">
      Se déconnecter 
      <mat-icon class="menu-container-content-item-icon" matSuffix>https</mat-icon>
    </div>

  </div>
</div>