import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-to-previous-page',
  templateUrl: './return-to-previous-page.component.html',
  styleUrls: ['./return-to-previous-page.component.scss']
})
export class ReturnToPreviousPageComponent implements OnInit {

  constructor(
    private __location:Location
  ) { }

  ngOnInit(): void {
  }

  returnToPreviousPage(){
    this.__location.back();
  }

}
