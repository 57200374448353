<div 
    id="preloader"
>

<div *ngIf="loading" class="loader">
    <img src="../../../assets/images/loader-battery.gif" alt="">
</div>

<section 
    *ngIf="!loading"
    class="signup_section"
>

    <div class="signup_section-welcome-message">Bienvenue sur <span>THE BOX</span></div>
    <div class="signup_section-instruction">Renseignez votre numéro de téléphone pour compléter votre inscription</div>

    <form [formGroup]="formGroup" class="signup_section-signup_form" #formDirective="ngForm">

        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Prénom</mat-label>
            <input type="text" matInput formControlName="prenom"
                   placeholder="">
            <mat-hint></mat-hint>
            <mat-error *ngIf="this.formGroup.get('prenom').hasError('minlength') && !this.formGroup.get('prenom').hasError('required')">
              Le <strong>prénom</strong> doit avoir au moins 3 lettres
            </mat-error>
            <mat-error *ngIf="this.formGroup.get('prenom').hasError('required')">
                Veuillez saisir votre <strong>prénom</strong> !
            </mat-error>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="nom"
                   placeholder="">
            <mat-hint></mat-hint>
            <mat-error *ngIf="this.formGroup.get('nom').hasError('minlength') && !this.formGroup.get('nom').hasError('required')">
              Le <strong>nom</strong> doit avoir au moins 3 lettres
            </mat-error>
            <mat-error *ngIf="this.formGroup.get('nom').hasError('required')">
                Veuillez saisir votre <strong>nom</strong> !
            </mat-error>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>E-mail</mat-label>
            <input type="email" matInput formControlName="email" readonly
                   placeholder="Ex. hamza@example.com">
            <mat-hint></mat-hint>
            <mat-error *ngIf="this.formGroup.get('email').hasError('email') && !this.formGroup.get('email').hasError('required')">
                Veuillez saisir une <strong>adresse e-mail</strong> valide !
            </mat-error>
            <mat-error *ngIf="this.formGroup.get('email').hasError('required')">
                Veuillez saisir votre <strong>adresse e-mail</strong> !
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Numéro de téléphone</mat-label>
            <input type="text" matInput formControlName="telephone" 
                   placeholder="Ex. 06XXXXXXXX">
            
            <mat-error *ngIf="this.formGroup.get('telephone').hasError('required')">
                Veuillez saisir votre <strong>numéro de téléphone</strong> !
            </mat-error>

            <mat-error *ngIf="this.formGroup.get('telephone').hasError('pattern') && !this.formGroup.get('telephone').hasError('required')">
                <strong>Numéro de téléphone</strong> valide commence par 06 ou 07 !
            </mat-error>
    
            <mat-error *ngIf="(this.formGroup.get('telephone').hasError('minlength') || this.formGroup.get('telephone').hasError('maxlength')) &&!this.formGroup.get('telephone').hasError('pattern') && !this.formGroup.get('telephone').hasError('required')">
                <strong>Numéro de téléphone</strong> valide se compose de 10 chiffres ! 
            </mat-error>
        </mat-form-field>

        <button class="signup_section-submit-btn" mat-flat-button color="primary" (click)="signup()" >S'inscrire</button>
    
    </form>

</section>
    <div class="loading-spinner"></div>
 </div>
 

