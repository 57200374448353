<div class="address_list">
    <div  class="address_list__card">

        <div class="address_list__card__header">
            <img 
            src="../../../../assets/images/left-arrow.png"
            class="address_list__card__header-icon"
            (click)="goToPreviousPage()"
            >
            Adresses enregistrées
        </div>

        <div class="address_list__card__content">
            <div 
                class="address_list__card__content-item" 
                *ngFor="let userAddress of userAddresses;index as i"
                [ngClass]="{'selected-address':idOfSelectedAddress==userAddress.id}"
            >
                <div class="addressDetails" >
                    <div 
                    (click)="onChooseAnAddress(i)"
                    class="address_list__card__content-item-icon-container"
                    >
                        <img 
                        [src]="getSourceImageForItem(userAddress.id)" 
                        class="address_list__card__content-item-icon-container-element"
                        >
                    </div>
                    <div 
                        class="address_list__card__content-item-text-container"
                        (click)="onChooseAnAddress(i)"
                        >
                        <p class="address_list__card__content-item-text-container-element-address">
                            {{userAddress.mainAddress}}
                        </p>
                        <p class="address_list__card__content-item-text-container-element-additional-information">
                            {{userAddress.additionalAddressInfos}}
                        </p>
                    </div>

                    <div class="address_list__card__content-item-icon-container">
                        <mat-icon
                            (click)="hideTheMap(i)" 
                            *ngIf="isTheAppShowingMap[i]"
                            style="cursor: pointer;" 
                            class="address_list__card__content-item-icon-container-element"
                            matSuffix>remove_red_eye</mat-icon>
                            
                        <mat-icon
                            (click)="showTheMap(i)"
                            *ngIf="!isTheAppShowingMap[i]"
                            style="cursor: pointer;"  
                            class="address_list__card__content-item-icon-container-element"
                            matSuffix>visibility_off</mat-icon> 
                    </div>

                    <div
                        (click)="deleteAddress(userAddress.id)"
                        *ngIf="idOfSelectedAddress!=userAddress.id" 
                        class="address_list__card__content-item-icon-container"
                    >
                        <img 
                        src="../../../../assets/images/bin.png" 
                        class="address_list__card__content-item-icon-container-element address_list__card__content-item-icon-container-element-delete"
                    >
                    </div>  
                </div>   
                
                <div *ngIf="isTheAppShowingMap[i]"
                    class="addressMap"
                >
                    <agm-map 
                    [latitude]="userAddress.lat" 
                    [longitude]="userAddress.long"
                    [zoom]=17
                    >
                        <agm-marker [latitude]="userAddress.lat" [longitude]="userAddress.long"></agm-marker>
                    </agm-map>
                </div>
            </div>
           

            
            
        
            
            
        </div>
        <div class="address_list__card__footer">
            <button 
            (click)="goToNextStep()"
            class="address_list__card__footer-btn"
            >
            Ajouter une adresse
            <img class="address_list__card__footer-btn-img" src="../../../../assets/images/add.png" alt="">
        </button>
        </div>
    </div>
</div>
