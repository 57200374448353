import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-popup-inform-user',
  templateUrl: './popup-inform-user.component.html',
  styleUrls: ['./popup-inform-user.component.scss']
})
export class PopupInformUserComponent implements OnInit {
  dialogInfos:{header,msg,buttonClass};

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  ngOnInit(): void {
    this.dialogInfos = this.data;
  }

}
