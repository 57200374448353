import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appDeferLoad]",
})
export class DeferLoadDirective implements AfterViewInit {
  @Input() containerTitle: string;
  @Input() parent: ElementRef;

  @Output() public appDeferLoad: EventEmitter<string> = new EventEmitter();

  @Input() intersectionObserver: IntersectionObserver;

  constructor(private element: ElementRef) {}

  public ngAfterViewInit(): void {
  }

  private checkForIntersection = (
    entries: Array<IntersectionObserverEntry>
  ) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        //
        this.appDeferLoad.emit(this.containerTitle);
        // this.intersectionObserver.unobserve(this.element.nativeElement);
        // this.intersectionObserver.disconnect();
      }
    });
  };

  private checkIfIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting && entry.target === this.element.nativeElement;
  }
}
