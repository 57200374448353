import { trigger, state, style, transition, animate } from '@angular/animations';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmUserChoiceComponent } from '../confirm-user-choice/confirm-user-choice.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('ListAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(1200)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(900, style({opacity: 0})))
    ])
  ]
})
export class ListComponent implements OnInit, OnDestroy {
  isLoggedIn:boolean = false;
  private __subscribtions:Subscription[] = [];

  constructor(
    private router: Router,
    private __authService:AuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    let observableRef = this.__authService.getIsLoggedInSubject()
    .subscribe(data=>this.isLoggedIn=data);
    this.__subscribtions.push(observableRef);
  }

  decodeToken():string{
    let token = this.__authService.decodeToken();
    if(!token) return null;
    return token["uuiduser"];
  }

  redirectMeToUserProfile(){
    let uuid = this.decodeToken();
    if(uuid!=null) this.router.navigate(["user-profile",uuid])
  }

  redirectMeToSignin(){
    this.router.navigate(["se-connecter"]);
  }

  logout(){
    let dialogRef = this.openDialog();
    dialogRef.afterClosed().subscribe(data=>{
      if(!this.__authService.isTheUserLoggedIn()) this.redirectMeToHomePage()
    });
  }

  redirectMeToBankCArd(){
    this.router.navigate(["carte-bancaire"]);
  }

  redirectMeToSignup(){
    this.router.navigate(["s'inscrire"]);
  }

  openDialog() {
    let dialogRef = this.dialog.open(ConfirmUserChoiceComponent,{
      autoFocus:false,
    });
    return dialogRef;
  }

  redirectMeToHomePage(){
    this.router.navigate([""]);
  }

  redirectMeToMyCommands(){
    this.router.navigate(["commandes"]);
  }


  ngOnDestroy(){
    this.__subscribtions.forEach(e=>e.unsubscribe());
  }

}
