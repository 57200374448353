<div class="selected-heading__container">
  <div *ngIf="heading.banner" class="selected-heading__family-banner">
    <img
      [src]="heading.banner"
      alt=""
      class="selected-heading__family-banner__img"
    />
  </div>
  <div
    *ngIf="heading.produits"
    [ngClass]="{
      'selected-heading__title-highlighted': highlightTitle === true
    }"
    class="selected-heading__title"
    [attr.id]="heading.cat_name.split(' ')[0]"
  >
    <!--<span class="selected-heading__count">{{ heading.produits.length }}</span>-->
    <span class="selected-heading__text">{{ heading.cat_name }}</span>
  </div>
  <div class="selected-heading__body">
    <app-selected-heading-item
      class="selected-heading__body-item"
      *ngFor="let item of heading.produits"
      [headingItem]="item"
      [restaurantClosed]="restaurantClosed"
      [preOrderStatus]="preOrderStatus"
    >
    </app-selected-heading-item>
  </div>
</div>
