import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { BasketService } from "src/app/services/basket.service";
import { HomePageService } from "src/app/services/home-page.service";
import { HttpService } from "../../services/http.service";


@Component({
  selector: "app-restaurants",
  templateUrl: "./restaurants.component.html",
  styleUrls: ["./restaurants.component.scss"],
})
export class RestaurantsComponent implements OnInit, OnChanges {
  @Input()
  category: string;

  /*
  @Input()
  city: any;
  */

  /*
  @Input()
  street: any;
  */

  restaurants = undefined;
  page = 0;

  showSpinnerForGetMore = false;
  title: string;

  constructor(
    private httpService: HttpService,
    private __router:Router,
    private __basketService:BasketService,
    private __homePageService:HomePageService
    ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentUrl = window.location.href
    if (currentUrl.includes('restaurants')){
      this.title = 'Restaurants'
    }else if(currentUrl.includes('boutiques')){
      this.title = 'Boutiques'
    }else if(currentUrl.includes('patisseries')){
      this.title = 'Pâtisseries'
    }else{
      this.title = 'Spécial Nord'
    }
    this.page = 0;
    this.httpService
    .afterLocateUserAutomaticlllyGetResturants(this.category,this.page++)
    .subscribe((restaurants: any[]) => {
      (this.restaurants = restaurants)
    }
    );
  }

  /*
  getRestaurants(): any[] {
    let restaurants = this.restaurants;
    if (this.restaurants && this.category !== "Tout") {
      restaurants = this.restaurants.filter((restaurant) =>
        restaurant.categories.includes(this.category)
      );
    }
    if (this.city) {
      restaurants = this.restaurants.filter(
        (restaurant) => restaurant.city === this.city.name
      );
    }
    return restaurants;
  }
  */

  getMore(): void {
    this.showSpinnerForGetMore = true;
    /*
    if (this.city) {
      this.httpService
        .getRestaurants(this.category, this.page++)
        .subscribe((restaurants: any[]) => {
          restaurants.forEach((restaurant) =>
            this.restaurants.push(restaurant)
          );
          this.showSpinnerForGetMore = false;
        });
    } else {
      this.httpService
        .getRestaurants(this.category, this.page++)
        .subscribe((restaurants: any[]) => {
          restaurants.forEach((restaurant) =>
            this.restaurants.push(restaurant)
          );
          this.showSpinnerForGetMore = false;
        });
    }
    */
    this.httpService
    .getRestaurants(this.category, this.page++)
    .subscribe((restaurants: any[]) => {
      restaurants.forEach((restaurant) =>
        this.restaurants.push(restaurant)
      );
      this.showSpinnerForGetMore = false;
    });
  }

  redirectToRestaurantPage(restaurantName){
    /*
    if(this.__basketService.promoCodeInfos.restaurantName && restaurantName!= this.__basketService.promoCodeInfos.restaurantName) 
      this.__basketService.initializePromoCodeInfos();
    */
   this.__router.navigate(["restaurant"],{queryParams:{name:restaurantName}});
  }
}
