<div
    [hidden]="restaurants.length==0"
    class="new-restaurants">
    <div class="new-restaurants-header">
         <img [src]="'assets/images/'+icon" alt="" class="new-restaurants-header-img">
         <div class="new-restaurants-header-text">
            {{title}}
        </div> 
    </div>
    <div class="new-restaurants-carousel">
        <button
            [ngClass]="{ 'hideMe':  restaurants.length<=2}"
            class="new-restaurants-carousel-left-btn"
            style="z-index: 99"
            mat-icon-button
            (click)="scrollLeft(carouselRef)"
        >
        <mat-icon class="carousel__items-swiper">keyboard_arrow_left</mat-icon>
       </button>
    
        <div class="new-restaurants-carousel-container" #carouselRef>
            <div 
            *ngFor="let restaurant of restaurants;index as i"
            [ngStyle]="{'margin-right': getMarginRight(i)}"
            class="new-restaurants-carousel-container-item">
            <app-restaurant
            [image]="restaurant.image"
            [city]="restaurant.city"
            [name]="restaurant.name | uppercase"
            [delivery]="restaurant.delivery"
            [takeAway]="restaurant.takeAway"
            [type]="restaurant.type"
            [categories]="restaurant.categories"
            [closed]="restaurant.ferme"
            [preOrder]="restaurant.pre_commande"
            class="restaurants__restaurant"
            (click)="redirectToRestaurantPage(restaurant.url_unique)"
            >
            </app-restaurant>
          <span class="new-restaurants-carousel-container-item-new-background">
              
          </span>

          <span class="new-restaurants-carousel-container-item-new-text">
            New
        </span>
            </div>
        </div>

        <button
        class="new-restaurants-carousel-right-btn"
        style="z-index: 99"
        mat-icon-button
        (click)="scrollRight(carouselRef)"
        [ngClass]="{ 'hideMe':  restaurants.length<=2}"
    >
    <mat-icon class="carousel__items-swiper">keyboard_arrow_right</mat-icon>
  </button>
    </div>
</div>
