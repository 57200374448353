import { ViewCompileResult } from "@angular/compiler/src/view_compiler/view_compiler";
import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy, AfterContentInit} from "@angular/core";
import {ActivatedRoute, Router, Scroll} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedDataService } from "src/app/services/shared-data.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy{
  selectedCategory = "Tout";
  //selectedCity: string = null;
  //selectedStreet: string = null;
  searchFiledValue:string = '';
  utm: string="";
  heightImageSpecialNordforMobile: any;
  heightImageSpecialNordforDesktop: any;
  heightCardOfCategoriesforMobile: any;
  heightCardOfCategoriesforDesktop: any;
 
  //Variables of CountDown
  interval: any;
  currentDate: any;
  targetDate: any;
  cDateMillisecs: any;
  tDateMillisecs: any;
  difference = 1;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toaster:ToastrService,
    public __sharedDataService:SharedDataService,
    ) {}
 
  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    this.heightImageSpecialNordforMobile = window.innerWidth * 0.94 / 2.62
    this.heightImageSpecialNordforDesktop = window.innerWidth * 0.75 / 4,65
    this.heightCardOfCategoriesforMobile = Math.round((window.innerHeight - this.heightImageSpecialNordforMobile - 24 - 30 - 75) / 3) + 'px'
    this.heightCardOfCategoriesforDesktop = Math.round((window.innerHeight - this.heightImageSpecialNordforDesktop - 8 - 75) / 2) + 'px'


    //this.heightImageSpecialNord = document.getElementById('seconds').offsetHeight

    this.utm = this.route.snapshot.queryParamMap.get('utm');
    if(this.utm) this.saveUtmInSessionStorage()
    this.toaster.clear();
    let uniqueId = this.route.snapshot.paramMap.get('id');
    if (uniqueId) {
      uniqueId = 'ghostkitchen';
      this.router.navigate(['/restaurant'], {queryParams: {name: uniqueId}});
    }
  }


  /*
  myTimer() {
    this.currentDate = new Date();
    this.targetDate = new Date(2022, 7, 1);
    this.cDateMillisecs = this.currentDate.getTime();
    this.tDateMillisecs = this.targetDate.getTime();
    this.difference = this.tDateMillisecs - this.cDateMillisecs;
    this.seconds = Math.floor(this.difference / 1000);
    this.minutes = Math.floor(this.seconds / 60);
    this.hours = Math.floor(this.minutes / 60);
    this.days = Math.floor(this.hours / 24);

    this.hours %= 24;
    this.minutes %= 60;
    this.seconds %= 60;
    this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
    this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

    document.getElementById('days-desktop').innerText = this.days;
    document.getElementById('days-mobile').innerText = this.days;
    
    document.getElementById('hours-desktop').innerText = this.hours;
    document.getElementById('hours-mobile').innerText = this.hours;

    document.getElementById('minutes-desktop').innerText = this.minutes;
    document.getElementById('minutes-mobile').innerText = this.minutes;

    document.getElementById('seconds-desktop').innerText = this.seconds;
    document.getElementById('seconds-mobile').innerText = this.seconds;


    
    this.interval = setInterval(this.myTimer, 1000);
    
  }
  */

  saveUtmInSessionStorage(){
    sessionStorage.setItem('utm', this.utm)
  }

  /*
  handleCityEmitted($event: string): void {
    this.selectedCity = $event;
  }
  */

  /*
  handleStreetEmitted($event: string): void {
    this.selectedStreet = $event;
  }
  */

  handleCategoryEmitted($event: string): void {
    this.selectedCategory = $event;
  }

  redirectToLalivraisonExpress(){
    this.router.navigateByUrl('restaurant?name=lalivraisonexpress')
  }
  
}
