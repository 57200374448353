import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PromotionRestaurantsService } from '../../services/promotion-restaurants.service'

@Component({
  selector: 'app-promotion-restaurants',
  templateUrl: './promotion-restaurants.component.html',
  styleUrls: ['./promotion-restaurants.component.scss']
})
export class PromotionRestaurantsComponent implements OnInit, OnChanges {
  restaurants:{name, image, codePromo, valeurPromo, url_unique}[] = [];
  @Input() category:string;
  @Input() city:any;
  @Input() street:any;

  constructor(
    private __promotionRestaurantsService:PromotionRestaurantsService
  ) { }
  

  ngOnInit(): void {
    this.getAllRestaurantsThatAreDiscounting();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAllRestaurantsThatAreDiscounting();
  }

  scrollLeft(item:HTMLInputElement){
    item.scrollBy({
      left:-150,
      behavior:"smooth"
    })
  }

  scrollRight(item:HTMLInputElement){
    item.scrollBy({
      left:150,
      behavior:"smooth"
    })
  }

  getAllRestaurantsThatAreDiscounting(){
    this.street = (this.street!=null && this.street) ? this.street : "Tout";
    let city = (this.city!=null && this.city) ? this.city.name : "Tout";
    this.__promotionRestaurantsService.getAllRestaurantsThatAreDiscounting(this.category,city,this.street)
    .subscribe(data => {
      this.restaurants = data;
    });
  }

}
