import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AddressListService } from 'src/app/services/address-list.service';
import { BasketService } from 'src/app/services/basket.service';
import { MapService } from 'src/app/services/map.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UserAddress } from 'src/app/models/user-address';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {

  @Input() userAddresses: Object[] = [];
  idOfSelectedAddress:number = null;

  isTheAppShowingMap: boolean[] = [];
  visualizeAddress: number[]=[]

  lat: number = 51.678418;
  lng: number = 7.809007;

  constructor(
    private __mapService:MapService,
    private __basketService:BasketService,
    private __addressListService:AddressListService,
    private __location:Location,
    private __sharedDataService:SharedDataService
  ) { }

  ngOnInit(): void {
   this.handleSelectedAddress();
   
  }

  goToNextStep(){
    this.__mapService.goToNextStep();
  }

  goToPreviousPage(){
    this.__mapService.goToPreviousStep();
  }

  onChooseAnAddress(indexOfAddress:number){
    const userAddress = this.userAddresses[indexOfAddress];
    this.__addressListService.setCityAndDistrictFromGeolocation(userAddress["lat"],userAddress["long"])
    .pipe(
      tap(({ street, ville }) => this.onGettingCityAndStreet(street,ville,userAddress))
    )
    .subscribe({
      error:() => {
        const restaurantName = this.__mapService.getRestaurantName(this.__sharedDataService.getPreviousUrl());
        if(!restaurantName) this.__addressListService.informUserThatWeDidNotFindAnyStreet();
        else{
          this.__basketService.setLocation(
            "random value",
            "random value",
            userAddress["lat"],
            userAddress["long"],
            userAddress["mainAddress"],
            userAddress["additionalAddressInfos"],
            userAddress["id"],
          );
          this.goBack();
        }
      }
    });
  }

  handleSelectedAddress(){
    const selectedAddress = this.__addressListService.getSelectedAddress(this.userAddresses);
    if(selectedAddress) {
      this.idOfSelectedAddress = selectedAddress["id"];
      const index = this.userAddresses.findIndex(address => address["id"]==this.idOfSelectedAddress);
      const t = this.userAddresses.splice(index,1);
      this.userAddresses.unshift(selectedAddress)
    }
  }

  goBack(){
    this.__location.back();
  }

  getSourceImageForItem(userAddressId):string{
    let src = "../../../../assets/images/home-address-green.png";
    if(userAddressId==this.idOfSelectedAddress) src="../../../../assets/images/home-address-white.png";
    return src;
  }

  onGettingCityAndStreet(street, ville,userAddress){
    this.__basketService.setLocation(
      "",
      "",
      userAddress["lat"],
      userAddress["long"],
      userAddress["mainAddress"],
      userAddress["additionalAddressInfos"],
      userAddress["id"]
    );
    const location  = this.__basketService.location;
    location.city = ville[0]?.name;
    location.district = street;
    this.goBack();
  }

  deleteAddress(id){
    this.__addressListService.deleteAddress(id,this.userAddresses)
    .pipe(tap((response)=> this.userAddresses = response ))
    .subscribe();
  }

  showTheMap(indexOfAddress: number): void{
    let length = this.userAddresses.length
    for (let index = 0; index < length; index++) {
      if(this.isTheAppShowingMap.length < length)
      this.isTheAppShowingMap.push(false)
    }
    this.isTheAppShowingMap.splice(indexOfAddress,1,true)
  }

  hideTheMap(indexOfAddress: number): void{
    this.isTheAppShowingMap.splice(indexOfAddress,1,false)
  }

}

