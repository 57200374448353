<div class="about-zone__container-desktop">
  <div class="about-zone__middle-zone">
    <img
      src="../../../assets/images/logo-header.png"
      height="70"
      width="250"
    />
  </div>

  <div
    class="about-zone__container-desktop-container-items"
    style="
      display: flex;
      height: 170px;
      width: 70%;
      align-items: center;
      border-bottom: 2px white solid;
    "
  >
    <div
      class="about-zone__left-zone-contact"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: CALC(100% / 3);
      "
    >
      <div class="about-zone__left-zone-contact-title" style="">
        Nous contacter
      </div>
      <!-- <div style="">06 82 67 23 17</div> -->
      <div style="margin-top: -2px">06 68 78 27 67</div>
    </div>

    <div
      style="
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: CALC(100% / 3);
      "
    >
      <div class="about-zone__right-zone-title">Nous suivre</div>

      <div>
        <a href="https://www.instagram.com/ghostkitchenrabat/" target="_blank">
          <img height="65" width="65" src="../../../assets/images/instagram.png" alt="">
        </a>
        <a href="https://www.facebook.com/people/Ghost-kitchen-Rabat/100088206106282/" target="_blank">
          <img height="65" width="65" src="../../../assets/images/facebook.png" alt="">
        </a>
        <a href="https://wa.me/message/GI2ZD4UFDS3YB1" target="_blank">
          <img height="65" width="65" src="../../../assets/images/whatssap.png" alt="">
        </a>
      
      </div>

    </div>


    <div class="about-zone__left-zone-cities" style="text-align: center; width: CALC(100% / 3);">
      <div class="about-zone__left-zone-cities-title">Villes</div>
      <div>Rabat</div>
    </div>

  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <img src="../../../assets/images/paiement.png" height="50" width="300" />

    <div class="about-zone__middle-zone-text">
      Lalivraison &copy; 2021 - Tous droits réservés<br />
      <a href="" class="about-zone__middle-zone-text-link"
        >Conditions générales de vente</a
      >
    </div>
  </div>
</div>

<div class="about-zone__container-mobile">
  <!--<img src="../../../assets/images/store.png" height="60" width="300" />
  <img src="../../../assets/images/social_size.png" height="43" width="220" />-->
  <div>

    <a href="https://www.instagram.com/ghostkitchenrabat/" target="_blank">
      <img height="65" width="65" src="../../../assets/images/instagram.png" alt="">
    </a>
    <a href="https://www.facebook.com/people/Ghost-kitchen-Rabat/100088206106282/" target="_blank">
      <img height="65" width="65" src="../../../assets/images/facebook.png" alt="">
    </a>
    <a href="https://wa.me/message/GI2ZD4UFDS3YB1" target="_blank">
      <img height="65" width="65" src="../../../assets/images/whatssap.png" alt="">
    </a>
    
  </div>
  <img src="../../../assets/images/paiement.png" height="40" width="220" />
  <div
    class="about-zone__left-zone-contact"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 65px;
    "
  >
    <div class="about-zone__left-zone-contact-title" style="margin-bottom: 5px; color: white;">
      Contact
    </div>
    <!-- <div style="">06 82 67 23 17</div> -->
    <div style="margin-top: -2px; color: white;">06 68 78 27 67</div>
  </div>
  <div class="about-zone__middle-zone-text">
    Lalivraison &copy; 2021 - Tous droits réservés<br />
    <a
      href="https://lalivraison.ma/conditionpdf/cgvlalivraison.ma.pdf"
      class="about-zone__middle-zone-text-link"
      >Conditions générales de vente</a
    >
  </div>
</div>
