<div class="join-us__container">
  <div class="join-us__title">Rejoignez nous</div>

  <div class="join-us__content">
    <!--for desktop and tablet-->
    <div class="join-us__cards-container">
      <div class="join-us__card">
        <img
          class="join-us__card-image"
          src="../../../assets/images/1421849520restauFotolia_65288735_XS.jpg"
        />
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            height: 50%;
          "
        >
          <div>Commerçants</div>
          <div
            style="
              font-size: 18px;
              font-weight: 300;
              width: 95%;
              text-align: center;
              color: gray;
              margin-top: -5px;
            "
          >
            Gagnez en visibilité !
          </div>
          <div
            style="
              font-size: 14px;
              font-weight: normal;
              width: 95%;
              text-align: center;
            "
          >
            Démarquez-vous et générez plus de revenus en rejoignant la première communauté de restaurants et commerces premiums de votre ville.
          </div>
          <button
            class="join-us__button"
            [routerLink]="['/devenir-partenaire']"
            mat-raised-button
            color="primary"
          >
            <span class="join-us__button-partner"></span>
          </button>
        </div>
      </div>
      <div class="join-us__card">
        <img
          class="join-us__card-image"
          src="../../../assets/images/delivery_man.png"
        />
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            height: 50%;
          "
        >
          <div>Livreur</div>
          <div
            style="
              font-size: 18px;
              font-weight: 300;
              width: 95%;
              text-align: center;
              color: gray;
              margin-top: -5px;
            "
          >
            Roulez quand vous voulez !
          </div>
          <div
            style="
              font-size: 14px;
              font-weight: normal;
              width: 95%;
              text-align: center;
            "
          >
          Choisissez vos horaires, générez un revenu attractif et découvrez votre ville en livrant les meilleurs restaurants et commerces. C’est maintenant possible, Inscrivez-vous vite !
          </div>
          <button
            class="join-us__button"
            [routerLink]="['/devenir-livreur']"
            mat-raised-button
            color="primary"
          >
            <span class="join-us__button-delivery"></span>
          </button>
        </div>
      </div>
    </div>

    <!--for mobile
    <div class="join-us__mobile-line">
      <div
        class="join-us__mobile-line-text"
        [routerLink]="['/devenir-partenaire']"
      >
        Devenir livreur
      </div>-->
      <!-- <span class="join-us__mobile-line-right-arrow">&#8594;</span> -->
      <!-- <div class="join-us__mobile-line-button-container">
        <button mat-raised-button
                [routerLink]="['/devenir-livreur']"
                class="join-us__mobile-line-button">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>

    <div class="join-us__mobile-line">
      <div
        class="join-us__mobile-line-text"
        [routerLink]="['/devenir-partenaire']"
      >
        Devenir Partenaire
      </div> -->
      <!-- <span class="join-us__mobile-line-right-arrow">&#8594;</span> -->
      <!-- <div class="join-us__mobile-line-button-container">
        <button mat-raised-button
                [routerLink]="['/devenir-partenaire']"
                class="join-us__mobile-line-button">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div> 
    </div>-->
  </div>
</div>
