import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BankCardsService } from 'src/app/services/bank-cards.service';
import { PopupConfirmSavingCardsComponent } from '../popup-confirm-saving-cards/popup-confirm-saving-cards.component';

@Component({
  selector: 'app-bank-cards',
  templateUrl: './bank-cards.component.html',
  styleUrls: ['./bank-cards.component.scss'],
})
export class BankCardsComponent implements OnInit, AfterViewInit ,OnDestroy {

  @ViewChild("carouselRef",{static:true}) carouselRef:ElementRef
  isTheUserHasAnyBankCard:boolean = false;
  userCards:Object[] = [];
  usageHistory:Object[] = [];
  confirmSavingCardsObservable:Subscription = null;
  labelModifedObservable:Subscription = null;


  constructor(
    private __activatedRoute:ActivatedRoute,
    private __bankCardsService:BankCardsService,
    private __dialog:MatDialog,
    private __router:Router
  )
   { }

  ngAfterViewInit(): void {
    this.handleResponsivityForBankCards();
  }

  ngOnInit(): void {
    this.__activatedRoute.data
    .subscribe(response => this.handleResponse(response.uls));
  }

  DoAnimationForNextCard(){
    let animationRightName = "animation-right";
    let animationLeftName = "animation-left";
    this.carouselRef.nativeElement.classList.remove(animationLeftName);
    if(this.carouselRef.nativeElement.classList.contains(animationRightName))  this.carouselRef.nativeElement.classList.remove(animationRightName)
    setTimeout(
      ()=>{this.carouselRef.nativeElement.classList.add(animationRightName);},
      1
    )
  }

  DoAnimationForPreviousCard(){
    let animationRightName = "animation-right";
    let animationLeftName = "animation-left";
    this.carouselRef.nativeElement.classList.remove(animationRightName);
    if(this.carouselRef.nativeElement.classList.contains(animationLeftName))  this.carouselRef.nativeElement.classList.remove(animationLeftName)
    setTimeout(
      ()=>{this.carouselRef.nativeElement.classList.add(animationLeftName);},
      1
    )
  }

  handleResponse(response){
    this.userCards = response.cards;
    this.usageHistory = response.usageHistory;
    if(this.userCards.length > 0) {this.isTheUserHasAnyBankCard = true;}
  }

  addCard(){
    this.__bankCardsService.addCard();
  }

  submit(){
    this.showPopupConfirmSavingCards();
  }

  showPopupConfirmSavingCards(){
    let errorMessage = 
    "Vous serez désormais redirigé vers la plateforme du Centre Monétique Interbancaire afin d'enregistrer votre carte carte bancaire."+
    "<br>Ainsi, pour vos prochaine commandes, vous n'aurez plus à re-saisir que votre CVV et "+
    "le code reçu par sms de la part de votre banque."
    let dialogRef = this.__dialog.open(PopupConfirmSavingCardsComponent,{
      data:{errorMessage},
      panelClass:"saveCardPopup",
      disableClose:true
    });
    this.handlePopupConfirmSavingCardsComponentReference(dialogRef);
  }

  handlePopupConfirmSavingCardsComponentReference(dialogRef:MatDialogRef<PopupConfirmSavingCardsComponent>){
    this.confirmSavingCardsObservable = dialogRef.componentInstance.confirmSavingCards.asObservable()
    .subscribe(data=>this.addCard());
  }

  ModifyBankCard(card){
    let queryParams = {
      tokencmi:card["token"],
      label:card["label"]
    }
    this.__router.navigate(["modifier-carte-bancaire"],{queryParams});
  }

  handleResponsivityForBankCards(){
    if(this.userCards.length > 1){
      this.carouselRef.nativeElement.classList.add("space-between-class");
    }
  }

  ngOnDestroy(){
    if(this.confirmSavingCardsObservable) this.confirmSavingCardsObservable.unsubscribe();
    if(this.labelModifedObservable) this.labelModifedObservable.unsubscribe();
  }
  

}
