import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckoutService } from './checkout.service';
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { OrderService } from './order.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandsService implements Resolve<any>{
  constructor(
    private ___httpClientWithToken:HttpClientWithTokenService,
    private __checkoutService:CheckoutService,
    private __orderService:OrderService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return new Promise((resolve,reject)=>{
        this.getCommands(0).then(response=>resolve(response));
      });
  }

  getCommands(pageNumber):Promise<{orders, count}>{
    return new Promise((resolve,reject)=>{
      let url = environment.apiUrl + "Mescommandes/listingcommande";
      let params:HttpParams = new HttpParams().append("page",pageNumber).append("restaurant_id", "505");
      this.___httpClientWithToken.get(url,params)
      .subscribe(data => {
        let tab = data.orders;
        tab = this.rankingTable(tab);
        tab.forEach((e,i)=>e["index"]=i+1);
        resolve({orders:tab,count:data.count});
      },
      error => resolve({orders:[],count:0})
      );
    })
  }

  updatePayementModForSprecificCommand(commandId,payementMod):Observable<any>{
    if(payementMod == "cb") this.createNecessarySessionForOnlinePayement(commandId);
    let url = environment.apiUrl + "suivicommande/updateorder";
    let body = {commandId,payementMod};
    return this.___httpClientWithToken.put(url,body);
  }

  createNecessarySessionForOnlinePayement(commandeId){
    this.__checkoutService.createSessionToHshwoSuccessPopupForSucessefulPayment(commandeId);
  }

  rankingTable(tab:{price, date, status}[]):Array<{price, date, status}>{
    let rankedTable:[] = [];
    let index = -1;
    tab.forEach(e=>{
      index = this.pushElement(e,rankedTable,index);
    });
    return rankedTable;
  }

  pushElement(e:{price, date, status},rankedTable:{price, date, status}[],index):number{
    if(e.status == "En cours") rankedTable.splice(++index,0,e);
    else rankedTable.push(e);
    return index;
  }
}
