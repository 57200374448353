<!--{{data | json}}-->
<div class="order-item-dialog__container">
  <div class="order-item-dialog__top">
    <button
      mat-icon-button
      class="order-item-dialog__top-close-button"
      [mat-dialog-close]="true"
    >
      <mat-icon class="order-item-dialog__top-close-button-icon"
        >close</mat-icon
      >
    </button>
    <img [src]="data.item.image" class="order-item-dialog__top-image" />
  </div>

  <div class="order-item-dialog__content">
    <div class="order-item-dialog__lower-zone-name">
      {{ data.item.name | uppercase }}
    </div>

    <div class="order-item-dialog__lower-zone-description">
      {{ data.item.description }}
    </div>

    <!--Compositions-->
    <div *ngIf="data.item.composants">
      <div *ngFor="let composition of data.item.composants" class="">
        <!-- <div
          class="order-item-dialog__content-items-container-composition-name"
        >
          {{ composition.name }}
        </div> -->
        <mat-checkbox
          disabled=""
          [(ngModel)]="composition.selected"
          class="order-item-dialog__content-items-container-composition-item"
          color="primary"
        >
          <div
            class="
              order-item-dialog__content-items-container-composition-item-label
            "
          >
            <span>{{
              composition.label.length >= 18
                ? composition.label.slice(0, 18) + "..."
                : composition.label
            }}</span>
            <span>{{ composition.prix }} Dhs</span>
          </div>
        </mat-checkbox>
      </div>
    </div>

    <!--Déclinaisons-->
    <!-- <div *ngIf="data.item.declinations && data.item.declinations.length > 1">
      <mat-radio-group
        class="order-item-dialog__content-items-container"
        disabled
        [(ngModel)]="data.item.declinations.selectedValue"
      >
        <mat-radio-button
          *ngFor="let declination of data.item.declinations"
          class="order-item-dialog__content-items-container-declination-item"
          color="primary"
          [value]="declination"
        >
          <div
            class="
              order-item-dialog__content-items-container-declination-item-label
            "
          >
            <span>{{ declination.label }}</span>
            <span>{{ declination.prix }} Dhs</span>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div> -->

    <!--Formule-->
    <!--[(ngModel)]="category.selectedItem"-->
    <!-- <div *ngIf="data.item.composants">
      <div
        *ngFor="let category of data.item.composants"
        class="order-item-dialog__content-items-container"
      >
        <div class="order-item-dialog__content-items-container-formula-name">
          {{ category.titre }}
        </div>
        <mat-checkbox
          *ngFor="let i of category.composants"
          disabled
          class="order-item-dialog__content-items-container-formula-label"
          color="primary"
          [(ngModel)]="i.selected"
        >
          <div
            style="
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 210px;
              font-size: 12px;
            "
          >
            <span>{{ i.name }}</span>
            <span>{{ i.price }} Dhs</span>
          </div>
        </mat-checkbox>
      </div>
    </div>
  </div> -->

    <!-- <div class="order-item-dialog__bottom"> -->
    <!-- <button
        mat-button
        [mat-dialog-close]="true"
        (click)="navigateToRestaurant()"
      >
        Revisiter le restaurant
      </button> -->
    <!--<div class="order-item-dialog__bottom-container">-->
    <!--<div class="order-item-dialog__bottom-price">{{data.item.price * itemCount}} Dhs</div>-->

    <!--<div class="order-item-dialog__bottom-counter">-->
    <!--<button mat-icon-button-->
    <!--style="background: #ed145b"-->
    <!--(click)="decrementCount()"-->
    <!--class="order-item-dialog__bottom-counter-button">-->
    <!--<mat-icon class="order-item-dialog__bottom-counter-button-icon">remove</mat-icon>-->
    <!--</button>-->
    <!--<span style="font-weight: bold">{{itemCount}}</span>-->
    <!--<button mat-icon-button-->
    <!--(click)="incrementCount()"-->
    <!--class="order-item-dialog__bottom-counter-button">-->
    <!--<mat-icon class="order-item-dialog__bottom-counter-button-icon">add</mat-icon>-->
    <!--</button>-->
    <!--</div>-->

    <!--<button [mat-dialog-close]="true"-->
    <!--(click)="addToorder()"-->
    <!--mat-icon-button-->
    <!--class="order-item-dialog__bottom-button"-->
    <!--color="primary">-->
    <!--<mat-icon>shopping_cart</mat-icon>-->
    <!--</button>-->
    <!--</div>-->
    <!-- </div> -->
  </div>
</div>
