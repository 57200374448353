<div *ngIf="isOpen" display="none" class="location-popup__root">
  <div class="location-popup__container">
    <img *ngIf="!franchise"
      (click)="close()"
      src="../../../assets/images/close-icon.png"
      class="location-popup__close-btn"
    />
    <div class="location-popup__title">{{franchise ? 'Zone de livraison' : 'Choisir votre quartier'}}</div>
    <div class="location-popup__content">
            <!--id="standard-select"-->
      <div class="location-popup__input" *ngIf="franchise">
        <div class="select">
          <select
            
            (change)="onCityChange($event.target.value)"
          >
            <option disabled [selected]="true">
              Ville
            </option>
            <option
              *ngFor="let c of getFranchiseCities()"
              [value]="c"
            >
              {{ c }}
            </option>
          </select>
        </div>
      </div>
              <!--id="standard-select"-->
      <div class="location-popup__input">
        <div class="select">
          <ng-container>
            <select
                [(ngModel)]="location.district"
                (change)="onLocationChange($event.target.value)"
            >
              <option disabled  [value]="null">
                Quartier
              </option>
              <option
                  *ngFor="let street of getStreets()"
                  [value]="street.nom_quartier"
              >
                {{ street.nom_quartier }}
              </option>
            </select>
          </ng-container>
        </div>
      </div>
      <div *ngIf="noLocationIsAvailable" class="location-error-no-location-is-available">
        Nous n'arrivons pas à localiser votre quartier, veuillez le choisir manuellement.
      </div>
      <div class="location-popup__input">
        <div class="location-popup__btn" (click)="updateLocation()">
          Choisir
        </div>

        <button
            id="location-button"
            class="location-chooser__button"
            mat-raised-button
            color="primary"
            (click)="getLocation()"
            style="display: flex; align-items: center; justify-content: center"
        >
          <mat-icon style="margin-right: 5px" matSuffix>location_on</mat-icon>
          <span id="location-button__text"> Localisation</span>
        </button>

      </div>
    </div>
  </div>
</div>
