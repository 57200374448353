import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

type dataType = {imgSrc:string, text:string, btn1Text:string, btn2Text:string}

@Component({
  selector: 'app-popup-confirm-user-choice',
  templateUrl: './popup-confirm-user-choice.component.html',
  styleUrls: ['./popup-confirm-user-choice.component.scss']
})
export class PopupConfirmUserChoiceComponent implements OnInit {
  emitValueWhenBtn1GetClicked:BehaviorSubject<boolean> =  new BehaviorSubject(false);
  emitValueWhenBtn2GetClicked:BehaviorSubject<boolean> =  new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:dataType
  ) { }

  ngOnInit(): void {}

  onBtn1GetClicked(){
    this.emitValueWhenBtn1GetClicked.next(true);
  }

  onBtn2GetClicked(){
    this.emitValueWhenBtn2GetClicked.next(true);
  }

}
