import { HttpClient, HttpHeaders } from '@angular/common/http';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {User} from 'src/app/interfaces/user'
import { HttpClientWithTokenService } from './http-client-with-token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService implements Resolve<any> {

  private userBeforeModifications:{nom,prenom,telephone,email,adresse};

  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.getToken()}`,
  })
  
  constructor(
    //private __http:HttpClient,
    private __httpClientWithTokenService:HttpClientWithTokenService
  ) 
  { }



  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let uuid = route.paramMap.get("uuid");
    return new Promise(
      (resolve,reject)=>{
        this.getUserInfos(uuid)
        .then(response=>{
          this.userBeforeModifications = {...response};
          resolve(response)
        })
      }
    )
  }

  private getUserInfos(uuid):Promise<any>{
    return new Promise(
      (resolve,reject)=>{
        /*this.__http.get(`${environment.apiUrl}user/profile.php/${uuid}`,{headers:this.headers} )
        .subscribe(
          data=>resolve(data)
        )*/
        this.__httpClientWithTokenService.get(`${environment.apiUrl}user/profile.php/${uuid}`,{})
        .subscribe(
          data=>resolve(data)
        )
      }
    )
  }

  getToken():string{
    return localStorage.getItem("user_token");
  }

  modifyUser(user:{nom,prenom,telephone,email,adresse,password}):Observable<any>{
    /*return this.__http
    .put(`${environment.apiUrl}user/update`, user, {headers:this.headers});*/
    return this.__httpClientWithTokenService.put(`${environment.apiUrl}user/update`, user);
  }

  checkIfTheUserModifiedHisProfile(user:User):boolean{
    if(user["password"]!="") return true;
    for(let key of Object.keys(this.userBeforeModifications)){
      if(this.userBeforeModifications[key] != user[key]) return true;
    }
    return false;
  }

  setTheUserObjectThatMakeUsToCheckIfTheUserModifiedHisProfile(user:User){
    this.userBeforeModifications = {...user};
    delete this.userBeforeModifications["password"];
  }
}
