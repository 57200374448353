<section>
    <div class="rating-container">
        <div class="rating-text">
                Evaluer votre expérience
                <span style="color:#000">THE BOX</span>
        </div>

        <div class="rating-restaurant">
            <div class="first-line">
                <div class="bad">
                    <img src="../../../assets/images/Bad.png" alt="">
                </div>
                <div class="restaurant-img">
                    <img src="../../../assets/images/restaurantOhmybun.jpeg" alt="">
                </div>
                <div class="good">
                    <img src="../../../assets/images/Good.png" alt="">
                </div>
            </div>
            <div class="second-line">
                <div class="restaurant-name"><h2>OH MY BUN !</h2></div> 
            </div>
        </div>

        <div class="rating-delivery">
            <div class="first-line">
                <div class="bad">
                    <img src="../../../assets/images/Bad.png" alt="">
                </div>
                <div class="delivery-img">
                    <img src="../../../assets/images/Photo identité.jpeg" alt="">
                </div>
                <div class="good">
                    <img src="../../../assets/images/Good.png" alt="">
                </div>
            </div>
            <div class="second-line">
                <div class="delivery-name"><h2>Ahmed Ezzat</h2></div>
            </div>
        </div>
        
        <div class="rating-submit">VALIDER</div>
    </div>
</section>